import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import styled from 'styled-components';

import { ReactComponent as Checkbox } from 'assets/image/checkbox.svg';
import { ReactComponent as CheckboxChecked } from 'assets/image/checkbox-checked.svg';

import { I_Exchange } from 'etc/types';
import { dateTimeFormat } from 'utils/common';
import RefundReasonModal from 'components/Modal/RefundReasonModal';

interface I_ExchangeShipping {
  item: I_Exchange;
  checkItem: number;
  setCheckItem: React.Dispatch<React.SetStateAction<number>>;
  setCheckRefundLogId: React.Dispatch<React.SetStateAction<number>>;
  isCheck?: boolean;
}

function ExchangeShipping({
  item,
  checkItem,
  setCheckItem,
  setCheckRefundLogId,
  isCheck,
}: I_ExchangeShipping) {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  return (
    <S_TableRow>
      {isCheck && (
        <S_TableColData style={{ width: '38px' }}>
          {checkItem === item.refundLogProductId ? (
            <S_Checkbox
              onClick={() => {
                setCheckItem(0);
                setCheckRefundLogId(0);
              }}
            >
              <CheckboxChecked />
            </S_Checkbox>
          ) : (
            <S_Checkbox
              onClick={() => {
                setCheckItem(item.refundLogProductId);
                setCheckRefundLogId(item.refundLogId);
              }}
            >
              <Checkbox />
            </S_Checkbox>
          )}
        </S_TableColData>
      )}
      <S_TableColData>{item.refundLogId}</S_TableColData>
      <S_TableColData>
        {dateTimeFormat(item.orderStatusProcessedDate)}
      </S_TableColData>
      <S_TableColData>{item.deliveryCompany}</S_TableColData>
      <S_TableColData>{item.trackingNumber}</S_TableColData>
      <S_TableColData>
        <span
          onClick={() => setIsModalOpen(true)}
          style={{ textDecoration: 'underLine', cursor: 'pointer' }}
        >
          {item.exchageRequestReason}
        </span>
      </S_TableColData>
      <S_TableColData style={{ minWidth: '320px' }}>
        {item.productName}
      </S_TableColData>
      <S_TableColData>{item.productOptionName}</S_TableColData>
      <S_TableColData>{item.productCount}</S_TableColData>
      <S_TableColData>
        {dateTimeFormat(item.orderStatusProcessedDate)}
      </S_TableColData>
      <S_TableColData>{item.userNickname}</S_TableColData>
      <S_TableColData>{item.userPhone}</S_TableColData>
      <S_TableColData>
        <span
          onClick={() => navigate(`/user/${item.userId}`)}
          style={{ textDecoration: 'underLine', cursor: 'pointer' }}
        >
          {item.userCode}
        </span>
      </S_TableColData>

      <td>
        {isModalOpen && (
          <RefundReasonModal
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            refundLogId={item.refundLogId}
          />
        )}
      </td>
    </S_TableRow>
  );
}

export default ExchangeShipping;

const S_Checkbox = styled.div`
  width: 16.67px;
  height: 16.67px;
  color: #aeaeae;
  margin: 0 auto;
  cursor: pointer;
`;

const S_TableColData = styled.td`
  text-align: center;
  vertical-align: middle;
  border: 1px solid ${(props) => props.theme.colors.hd3};
  padding: 10px;
`;

const S_TableRow = styled.tr`
  height: 36px;
  font-weight: 500;
  font-size: 13px;
`;
