import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import MainLayout from 'components/Layout/MainLayout';
import LongButton from 'components/Button/LongButton';
import DisabledInput from 'components/Input/DisabledInput';
import Textarea from 'components/Input/Textarea';
import Input from 'components/Input/Input';
import { postBreed } from 'api/breed';
import useLogout from 'hooks/useLogout';

function RegisterKindInfo() {
  const Logout = useLogout();
  const location = useLocation();
  const navigate = useNavigate();

  const [content, setContent] = useState<string>('');
  const [kind, setKind] = useState<string>('');
  const [kindCode, setKindCode] = useState<string>('');
  const animalKind = location.state?.kindType;
  const kindDefaultCode = location.state?.code;

  const onClickRegister = async () => {
    if (!kind) {
      alert('품종 명을 입력해주세요.');
      return false;
    } else if (!kindCode) {
      alert('품종 코드를 입력해주세요.');
      return false;
    } else if (!content) {
      alert('상세내용을 입력해주세요.');
      return false;
    } else {
      const data = await postBreed(
        kindDefaultCode === 'D' ? 1 : kindDefaultCode === 'C' ? 2 : 3,
        kind,
        kindCode,
        content
      );
      if (data.ok) {
        alert('품종 정보가 등록되었습니다.');
        navigate('/service/kind');
      } else {
        if (data.res.status === 315) Logout();
        else if (data.res.status === 400) {
          alert('이미 등록된 품종 코드입니다. 다른 코드를 입력해주세요.');
          return;
        } else {
          alert('잠시 후 다시 시도해주세요.');
          console.error(data.res.error.message);
        }
      }
    }
  };

  return (
    <MainLayout>
      <S_Section>
        <S_Title>상세 정보</S_Title>
        <S_Table>
          <tbody>
            <tr>
              <th>동물 종</th>
              <td>
                <DisabledInput text={animalKind} />
              </td>
            </tr>
            <tr>
              <th>품종 명</th>
              <td>
                <Input
                  state={kind}
                  setState={setKind}
                  placeholder='여기에 입력하세요'
                />
              </td>
            </tr>
            <tr>
              <th>품종 코드</th>
              <td>
                <S_CodeBox>
                  <S_Code>
                    <S_DefaultCode>{kindDefaultCode}</S_DefaultCode>
                    <Input
                      state={kindCode}
                      setState={setKindCode}
                      placeholder=''
                      style={{ paddingLeft: 25 }}
                    />
                  </S_Code>
                </S_CodeBox>
              </td>
            </tr>
            <tr>
              <th className='alignTop'>상세 내용</th>
              <td colSpan={3}>
                <Textarea
                  state={content}
                  setState={setContent}
                  style={{ height: 127 }}
                  placeholder='여기에 입력하세요'
                />
              </td>
            </tr>
          </tbody>
        </S_Table>
        <S_ButtonBox>
          <LongButton onClick={() => onClickRegister()}>등록하기</LongButton>
        </S_ButtonBox>
      </S_Section>
    </MainLayout>
  );
}

export default RegisterKindInfo;

const S_DefaultCode = styled.span`
  position: absolute;
  left: 16px;
  font-size: 14px;
`;

const S_Code = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
`;

const S_CodeBox = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;

  button {
    width: 100px;
    height: 34px;
    font-size: 13px;
  }
`;

const S_ButtonBox = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 50px;
  padding-right: 20px;
`;

const S_Table = styled.table`
  border-collapse: separate;
  border-spacing: 20px;

  th {
    width: 120px;
    text-align: left;
    font-size: 14px;
    font-weight: 500;
    &:nth-child(1) {
      width: 85px;
    }
    &:nth-child(3) {
      width: 120px;
    }
    &.alignTop {
      vertical-align: top;
      padding-top: 14px;
    }
  }
`;

const S_Title = styled.p`
  font-size: 16px;
  color: ${(props) => props.theme.colors.hd1};
  font-weight: 700;
`;

const S_Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 17px;
  padding: 30px 20px;
  border-bottom: 1px solid ${(props) => props.theme.colors.hd4};

  &:last-child {
    border-bottom: 0;
  }
`;
