import styled from 'styled-components';

import { I_Award } from 'etc/types';
import { dateTimeFormat, numberFormat } from 'utils/common';

interface I_PointTableItem {
  item: I_Award;
}

function AwardTableItem({ item }: I_PointTableItem) {
  return (
    <S_TableRow>
      <S_TableColData>{item?.user?.userCode}</S_TableColData>
      <S_TableColData>{item?.user?.profile?.nickname}</S_TableColData>
      <S_TableColData>{numberFormat(item?.point)}</S_TableColData>
      <S_TableColData>{dateTimeFormat(item?.createdAt)}</S_TableColData>
      <S_TableColData>{item?.reason}</S_TableColData>
    </S_TableRow>
  );
}

export default AwardTableItem;

const S_TableColData = styled.td`
  text-align: center;
  vertical-align: middle;
  border: 1px solid ${(props) => props.theme.colors.hd3};
  padding: 10px;
`;

const S_TableRow = styled.tr`
  height: 36px;
  font-weight: 500;
  font-size: 13px;
`;
