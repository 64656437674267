import { useEffect, useState } from 'react';

import styled from 'styled-components';

import { ReactComponent as Checkbox } from 'assets/image/checkbox.svg';

import MainLayout from 'components/Layout/MainLayout';
import Dropdown from 'components/Dropdown/Dropdown';
import TableInput from 'components/Input/TableInput';
import TableButton from 'components/Button/TableButton';
import PagenationButton from 'components/Button/PagenationButton';
import DateInput from 'components/Input/DateInput';
import StoreReviewTableItem from 'components/Item/StoreReviewTableItem';
import useLogout from 'hooks/useLogout';
import { S_ButtonWrapper } from './StoreProduct';
import { I_StoreReview } from 'etc/types';

import { getReviewList } from 'api/store';

// 상세 검색 dropdown
const SEARCH_TYPE = ['상품번호', '상품명', '유저코드', '구매자명'];

// 평점 별 dropdown
const GRADE_TYPE = ['전체', '1점', '2점', '3점', '4점', '5점'];

const TABLE_HEADER = [
  '포인트 지급',
  '상품명',
  '상품 번호',
  '평점',
  '이미지',
  '리뷰 내용',
  '구매자명',
  'User Code',
  '최초 작성일',
  '최종 수정일',
];

function StoreReview() {
  const Logout = useLogout();
  const [reviewList, setReviewList] = useState<I_StoreReview[]>([]); // 목록
  const [totalCount, setTotalCount] = useState<number>(0); // 전체 개수
  const [activePage, setActivePage] = useState<number>(1); // 현재 페이지
  // 기간
  const [fromDate, setFromDate] = useState<string>('');
  const [toDate, setToDate] = useState<string>('');
  // 상세 검색
  const [isSearchyDropdownOpen, setIsSearchDropdownOpen] =
    useState<boolean>(false);
  const [selectedSearchItem, setSelectedSearchItem] = useState<string>(
    SEARCH_TYPE[0]
  );
  const onSearchItemClick = (item: string) => {
    setSelectedSearchItem(item);
    setIsSearchDropdownOpen(false);
  };
  const [InputValue, setInputValue] = useState<string>('');
  // 평점 별
  const [isGradeDropdownOpen, setIsGradeDropdownOpen] =
    useState<boolean>(false);
  const [selectedGradeItem, setSelectedGradeItem] = useState<string>(
    GRADE_TYPE[0]
  );
  const onGradeItemClick = (item: string) => {
    setSelectedGradeItem(item);
    setIsGradeDropdownOpen(false);
  };

  // 검색 초기화
  const handleResetClick = () => {
    setFromDate('');
    setToDate('');
    setSelectedSearchItem(SEARCH_TYPE[0]);
    setSelectedGradeItem(GRADE_TYPE[0]);
    setInputValue('');
  };

  // table pagenation
  const handlePageChange = (page: number) => {
    setActivePage(page);
  };

  // 상품 리스트
  const getAsyncReviewList = async () => {
    let search;
    if (InputValue) {
      search =
        selectedSearchItem === SEARCH_TYPE[0]
          ? `&productId=${InputValue}`
          : selectedSearchItem === SEARCH_TYPE[1]
          ? `&productName=${InputValue}`
          : selectedSearchItem === SEARCH_TYPE[2]
          ? `&userCode=${InputValue.replace('#', '%23')}`
          : `&userName=${InputValue}`;
    } else search = '';
    const data = await getReviewList(
      activePage,
      10,
      fromDate,
      toDate,
      selectedGradeItem,
      search
    );
    if (data.ok) {
      setTotalCount(data.res.totalCount);
      setReviewList(data.res.item);
    } else {
      if (data.res.status === 315) Logout();
      else if (data.res.error?.code === 104)
        alert('상품번호는 숫자만 입력해주세요.');
      else console.error(data.res.error.message);
    }
  };

  useEffect(() => {
    getAsyncReviewList();
  }, [activePage]);

  return (
    <MainLayout>
      <S_CategoryContainer>
        <S_CategoryTitle>리뷰 리스트 필터</S_CategoryTitle>
        <S_CategoryFilterContainer>
          <S_CategoryFilterRow>
            <S_CategoryFilterTitle>기간</S_CategoryFilterTitle>
            <S_CategoryFilterWrapper>
              <div>
                <DateInput
                  state={fromDate}
                  setState={setFromDate}
                  max={toDate}
                />
                <span> ~ </span>
                <DateInput
                  state={toDate}
                  setState={setToDate}
                  startDate={fromDate}
                  min={fromDate}
                />
              </div>
            </S_CategoryFilterWrapper>
          </S_CategoryFilterRow>
          <S_CategoryFilterRow>
            <S_CategoryFilterTitle>상세 검색</S_CategoryFilterTitle>
            <S_CategoryFilterWrapper>
              <Dropdown
                list={SEARCH_TYPE}
                selectedItem={selectedSearchItem}
                isOpen={isSearchyDropdownOpen}
                setIsOpen={setIsSearchDropdownOpen}
                onClick={(item: string) => onSearchItemClick(item)}
              />
              <TableInput
                state={InputValue}
                setState={setInputValue}
                style={{ width: '235px' }}
              />
            </S_CategoryFilterWrapper>
          </S_CategoryFilterRow>
          <S_CategoryFilterRow>
            <S_CategoryFilterTitle>평점 별</S_CategoryFilterTitle>
            <S_CategoryFilterWrapper>
              <Dropdown
                list={GRADE_TYPE}
                selectedItem={selectedGradeItem}
                isOpen={isGradeDropdownOpen}
                setIsOpen={setIsGradeDropdownOpen}
                onClick={(item: string) => onGradeItemClick(item)}
              />
            </S_CategoryFilterWrapper>
          </S_CategoryFilterRow>
        </S_CategoryFilterContainer>
        <S_ButtonWrapper>
          <TableButton onClick={handleResetClick}>검색 초기화</TableButton>
          <TableButton onClick={getAsyncReviewList}>조회하기</TableButton>
        </S_ButtonWrapper>
      </S_CategoryContainer>

      {/* 상품 목록 */}
      <S_TableButtonContainer>
        <S_TableTitle>상품 목록({totalCount})</S_TableTitle>
      </S_TableButtonContainer>

      <S_TableContainer>
        <S_TableBox>
          <S_Table>
            <thead>
              <tr>
                {TABLE_HEADER.map((v, i) => (
                  <S_TableHeaderItem key={i}>
                    {v === 'check' ? (
                      <Checkbox
                        width='16.67px'
                        height='16.67px'
                        color='#2E2E2E'
                      />
                    ) : (
                      v
                    )}
                  </S_TableHeaderItem>
                ))}
              </tr>
            </thead>
            <tbody>
              {reviewList?.map((v: I_StoreReview, i: number) => (
                <StoreReviewTableItem key={i} item={v} />
              ))}
            </tbody>
          </S_Table>
        </S_TableBox>

        <PagenationButton
          activePage={activePage}
          totalCount={totalCount}
          postPerPage={10}
          handlePageChange={handlePageChange}
        />
      </S_TableContainer>
    </MainLayout>
  );
}

export default StoreReview;

const S_TableHeaderItem = styled.th`
  vertical-align: middle;
  padding: 10px;
  height: 36px;
  background-color: ${(props) => props.theme.colors.hd4};
  border: 0.5px solid ${(props) => props.theme.colors.hd3};
  font-weight: 500;
  font-size: 13px;
  letter-spacing: -0.02em;
  color: ${(props) => props.theme.colors.n10};
`;

const S_Table = styled.table`
  width: 100%;
`;

const S_TableBox = styled.div`
  overflow-x: overlay;
  &::-webkit-scrollbar {
    width: 4px;
    height: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.theme.colors.hd3};
    border-radius: 6px;
  }
`;

const S_TableContainer = styled.div`
  margin-top: 8px;
  position: relative;
`;

const S_TableButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const S_TableTitle = styled.span`
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.02em;
  display: flex;
  align-items: flex-end;
`;

const S_TableButtonContainer = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
`;

const S_CategoryFilterWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const S_CategoryFilterTitle = styled.span`
  width: 90px;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  letter-spacing: -0.02em;
`;

const S_CategoryFilterRow = styled.div`
  display: flex;
  align-items: center;
  gap: 48px;
`;

const S_CategoryFilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 14px 16px;
  border: 1px solid ${(props) => props.theme.colors.hd3};
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  letter-spacing: -0.02em;
  /* border-radius: 8px; */
`;

const S_CategoryTitle = styled.span`
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.02em;
`;

const S_CategoryContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
