import { useEffect, useState } from 'react';
import styled from 'styled-components';

import MainLayout from 'components/Layout/MainLayout';
import PagenationButton from 'components/Button/PagenationButton';
import CareUserManagementTableItem from 'components/Item/CareUserManagementTableItem';
import TabButton from 'components/Button/TabButton';

import { I_CareApply } from 'etc/types';
import { getCaringList } from 'api/caring';
import { TABLE_LIMIT } from 'etc/variable';
import useLogout from 'hooks/useLogout';

function CareUserApply() {
  const Logout = useLogout();

  const [tabIndex, setTabIndex] = useState<number>(0); // tab
  const [totalCount, setTotalCount] = useState<number>(0); // 전체 개수
  const [activePage, setActivePage] = useState<number>(1); // 현재 페이지
  const [caringApplyList, setCaringApplyList] = useState<I_CareApply[]>([]);

  // tab button 클릭 시
  const onTabClick = (index: number) => {
    setTabIndex(index);
    setActivePage(1);
  };

  const handlePageChange = (page: number) => {
    setActivePage(page);
  };

  const getAsyncCaringApplyList = async () => {
    const data = await getCaringList(
      TABLE_LIMIT,
      activePage,
      tabIndex === 0 ? 'Reviewing' : 'Active'
    );

    if (data.ok) {
      // console.log(data.res.caringProfiles);
      setCaringApplyList(data.res.caringProfiles);
      setTotalCount(data.res.totalCount);
    } else {
      if (data.res.status === 315) Logout();
      else {
        alert('잠시 후 다시 시도해주세요.');
        console.error(data.res.error.message);
      }
    }
  };

  useEffect(() => {
    getAsyncCaringApplyList();
  }, [activePage, tabIndex]);

  return (
    <MainLayout>
      <S_TabTitle>돌보미 신청 현황</S_TabTitle>
      <S_TabButtonBox>
        <TabButton
          title='승인 필요'
          onClick={() => onTabClick(0)}
          isActive={tabIndex === 0}
        />
        <TabButton
          title='승인 완료'
          onClick={() => onTabClick(1)}
          isActive={tabIndex === 1}
        />
      </S_TabButtonBox>
      <S_TableButtonContainer>
        <S_TableTitle>상세 목록({totalCount})</S_TableTitle>
      </S_TableButtonContainer>
      <S_TableContainer>
        <S_TableBox>
          <S_Table>
            <thead>
              <tr>
                {TABLE_HEADER.map((v, i) => (
                  <S_TableHeaderItem key={i}>{v}</S_TableHeaderItem>
                ))}
              </tr>
            </thead>
            <tbody>
              {caringApplyList.map((v: I_CareApply) => (
                <CareUserManagementTableItem key={v.id} item={v} />
              ))}
            </tbody>
          </S_Table>
        </S_TableBox>

        <PagenationButton
          activePage={activePage}
          totalCount={totalCount}
          postPerPage={TABLE_LIMIT}
          handlePageChange={handlePageChange}
        />
      </S_TableContainer>
    </MainLayout>
  );
}

export default CareUserApply;

const S_TabTitle = styled.p`
  margin-bottom: 16px;
  font-size: 16px;
  font-weight: 700;
  color: ${(props) => props.theme.colors.hd1};
`;

const S_TabButtonBox = styled.div`
  display: flex;
  gap: 17px;
`;

const S_TableTitle = styled.span`
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.02em;
  display: flex;
  align-items: flex-end;
`;

const S_TableButtonContainer = styled.div`
  margin-top: 64px;
  display: flex;
  justify-content: space-between;
`;

const S_TableHeaderItem = styled.th`
  vertical-align: middle;
  padding: 10px;
  height: 36px;
  background-color: ${(props) => props.theme.colors.hd4};
  border: 0.5px solid ${(props) => props.theme.colors.hd3};
  font-weight: 500;
  font-size: 13px;
  letter-spacing: -0.02em;
  color: ${(props) => props.theme.colors.n10};
`;

const S_Table = styled.table`
  width: 100%;
`;

const S_TableBox = styled.div`
  overflow-x: scroll;
  &::-webkit-scrollbar {
    width: 4px;
    height: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.theme.colors.hd3};
    border-radius: 6px;
  }
`;

const S_TableContainer = styled.div`
  margin-top: 8px;
  position: relative;
`;

const TABLE_HEADER = ['신청일자', 'User Code', '돌보미 신청인', '승인 상태'];
