import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import MainLayout from 'components/Layout/MainLayout';
import NormalButton from 'components/Button/NormalButton';
import NormalModal from 'components/Modal/NormalModal';
import MiniButton from 'components/Button/MiniButton';

import { postImageUpload } from 'api/images';
import useLogout from 'hooks/useLogout';
import {
  getServiceInfo,
  postCaringService,
  postCowalkService,
  postDistributionService,
} from 'api/serviceInfo';
import { I_ServiceInfo } from 'etc/types';

function ServiceGuide() {
  const Logout = useLogout();

  const input = useRef<HTMLInputElement>(null);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isImageModalOpen, setIsImageModalOpen] = useState<boolean>(false);
  const [imageUrl, setImageUrl] = useState<string>('');
  const [filename, setFilename] = useState<string>('여기에 입력하세요');
  const [file, setFile] = useState<File>(new File([], ''));
  const [volumeError, setVolumeError] = useState<boolean>(false);
  const [uploadType, setUploadType] = useState<number>(0);
  const [images, setImages] = useState<I_ServiceInfo>({
    caringImage: [{ id: 0, caringServiceInfoImage: { url: '' } }],
    cowalkImage: [{ id: 0, cowalkServiceImage: { url: '' } }],
    distributionImage: [{ id: 0, distributionServiceImage: { url: '' } }],
  });

  const onClickImage = (url: string) => {
    setIsImageModalOpen(true);
    setImageUrl(url);
  };

  const onClickModal = (type: number) => {
    setIsModalOpen(true);
    setUploadType(type);
  };

  const onCloseModal = () => {
    setFile(new File([], ''));
    setFilename('');
    setIsModalOpen(false);
    setVolumeError(false);
  };

  const onChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files !== null) {
      const file: File = e.target.files[0];

      if (file) {
        const img = new Image();
        const objectUrl = URL.createObjectURL(file);
        img.src = objectUrl;
        img.onload = function () {
          if (file?.size > 20000000) {
            setFile(new File([], ''));
            setFilename('');
            setVolumeError(true);
          } else {
            setVolumeError(false);
            setFile(file);
            setFilename(file?.name);
          }
        };
      }
    }
  };

  const onClickUploadFile = async () => {
    if (!file.name) {
      alert('이미지를 추가해주세요.');
    } else {
      const formData = new FormData();
      formData.append('file', file);

      const imageData = await postImageUpload(formData);

      if (imageData.ok) {
        if (uploadType === 0) {
          const data1 = await postCaringService(imageData.res.id);
          if (data1.ok) {
            // console.log(data1.res);
            alert('이미지 등록이 완료되었습니다.');
            setIsModalOpen(false);
            setFile(new File([], ''));
            setFilename('');
            getAsyncServiceList();
          } else {
            if (imageData.res.status === 315) Logout();
            else {
              alert('잠시 후 다시 시도해주세요.');
              console.error(data1.res.error.message);
            }
          }
        } else if (uploadType === 1) {
          const data2 = await postDistributionService(imageData.res.id);
          if (data2.ok) {
            // console.log(data2.res);
            alert('이미지 등록이 완료되었습니다.');
            setIsModalOpen(false);
            setFile(new File([], ''));
            setFilename('');
            getAsyncServiceList();
          } else {
            if (imageData.res.status === 315) Logout();
            else {
              alert('잠시 후 다시 시도해주세요.');
              console.error(data2.res.error.message);
            }
          }
        } else {
          const data3 = await postCowalkService(imageData.res.id);
          if (data3.ok) {
            // console.log(data3.res);
            alert('이미지 등록이 완료되었습니다.');
            setIsModalOpen(false);
            setFile(new File([], ''));
            setFilename('');
            getAsyncServiceList();
          } else {
            if (imageData.res.status === 315) Logout();
            else {
              alert('잠시 후 다시 시도해주세요.');
              console.error(data3.res.error.message);
            }
          }
        }
      } else {
        if (imageData.res.status === 315) Logout();
        else {
          alert('잠시 후 다시 시도해주세요.');
          console.error(imageData.res.error.message);
        }
      }
    }
  };

  const getAsyncServiceList = async () => {
    const data = await getServiceInfo();
    if (data.ok) {
      setImages(data.res);
      // console.log(data.res);
    } else {
      if (data.res.status === 315) Logout();
      else alert('잠시 후 다시 시도해주세요.');
    }
  };

  useEffect(() => {
    getAsyncServiceList();
  }, []);

  return (
    <MainLayout>
      <S_TableButtonContainer></S_TableButtonContainer>
      <S_TableContainer>
        <S_TableBox>
          <S_Table>
            <thead>
              <tr>
                {TABLE_HEADER.map((v, i) => (
                  <S_TableHeaderItem key={i}>{v}</S_TableHeaderItem>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr>
                <S_TableDataItem>돌봄 서비스 안내 이미지</S_TableDataItem>
                <S_TableDataItem
                  onClick={() =>
                    onClickImage(
                      images.caringImage[0]?.caringServiceInfoImage.url
                    )
                  }
                >
                  <img
                    src={images.caringImage[0]?.caringServiceInfoImage.url}
                  />
                </S_TableDataItem>
                <S_TableDataItem>
                  <NormalButton
                    onClick={() => onClickModal(0)}
                    style={{ margin: 'auto' }}
                  >
                    {images.caringImage[0] ? '수정하기' : '등록하기'}
                  </NormalButton>
                </S_TableDataItem>
              </tr>
              <tr>
                <S_TableDataItem>분양 서비스 안내 이미지</S_TableDataItem>
                <S_TableDataItem
                  onClick={() =>
                    onClickImage(
                      images.distributionImage[0]?.distributionServiceImage.url
                    )
                  }
                >
                  <img
                    src={
                      images.distributionImage[0]?.distributionServiceImage.url
                    }
                  />
                </S_TableDataItem>
                <S_TableDataItem>
                  <NormalButton
                    onClick={() => onClickModal(1)}
                    style={{ margin: 'auto' }}
                  >
                    {images.distributionImage[0] ? '수정하기' : '등록하기'}
                  </NormalButton>
                </S_TableDataItem>
              </tr>
              <tr>
                <S_TableDataItem>동반산책 서비스 안내 이미지</S_TableDataItem>
                <S_TableDataItem
                  onClick={() =>
                    onClickImage(images.cowalkImage[0]?.cowalkServiceImage.url)
                  }
                >
                  <img src={images.cowalkImage[0]?.cowalkServiceImage.url} />
                </S_TableDataItem>
                <S_TableDataItem>
                  <NormalButton
                    onClick={() => onClickModal(2)}
                    style={{ margin: 'auto' }}
                  >
                    {images.cowalkImage[0] ? '수정하기' : '등록하기'}
                  </NormalButton>
                </S_TableDataItem>
              </tr>
            </tbody>
          </S_Table>
        </S_TableBox>
      </S_TableContainer>

      {isModalOpen && (
        <NormalModal
          title={'파일등록'}
          state={isModalOpen}
          setState={setIsModalOpen}
          isClosedButton={false}
          onClose={() => onCloseModal()}
        >
          <S_ModalContent>
            <S_RegisterBox>
              <S_InputBox>
                <S_Input ref={input} onChange={(e) => onChangeFile(e)} />
                <S_FileName onClick={() => input.current?.click()}>
                  {filename}
                </S_FileName>
                <MiniButton
                  onClick={() => input.current?.click()}
                  style={{ width: 111, height: 34 }}
                >
                  사진 추가
                </MiniButton>
              </S_InputBox>
              {volumeError && (
                <S_Error>업로드 가능한 용량을 초과했습니다.</S_Error>
              )}
              <S_Text>
                *제한 용량 : 20MB
                <br />
                *지원 양식 : JPG/JPEG/PNG
              </S_Text>
            </S_RegisterBox>
            <NormalButton onClick={() => onClickUploadFile()}>
              확인
            </NormalButton>
          </S_ModalContent>
        </NormalModal>
      )}

      {isImageModalOpen && (
        <NormalModal
          title={'이미지'}
          state={isImageModalOpen}
          setState={setIsImageModalOpen}
          isClosedButton={true}
        >
          <S_ModalContent>
            <S_BigImg src={imageUrl} />
          </S_ModalContent>
        </NormalModal>
      )}
    </MainLayout>
  );
}

export default ServiceGuide;

const S_BigImg = styled.img`
  max-width: 1000px;
  max-height: 500px;
`;

const S_Error = styled.p`
  font-size: 14px;
  color: ${(props) => props.theme.colors.ho6};
`;

const S_ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
  padding: 24px 80px 50px;
`;

const S_FileName = styled.div`
  display: flex;
  align-items: center;
  width: 380px;
  height: 46px;
  padding: 0 18px;
  border: 1px solid ${(props) => props.theme.colors.hd3};
  border-radius: 12px;
  font-size: 14px;
  color: ${(props) => props.theme.colors.hd2};
  cursor: pointer;
`;

const S_Text = styled.div`
  font-size: 12px;
  color: ${(props) => props.theme.colors.hd2};
  line-height: 150%;
`;

const S_Input = styled.input.attrs({
  type: 'file',
  accept: '.jpg, .jpeg, .png',
})`
  display: none;
`;

const S_InputBox = styled.div`
  display: flex;
  align-items: center;
  gap: 23px;
`;

const S_RegisterBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const S_TableDataItem = styled.td`
  padding: 9px;
  border: 1px solid ${(props) => props.theme.colors.hd3};
  text-align: center;
  vertical-align: middle;
  font-size: 13px;

  span {
    cursor: pointer;
  }

  img {
    max-width: 200px;
    max-height: 150px;
    cursor: pointer;
  }
`;

const S_TableButtonContainer = styled.div`
  display: flex;
  gap: 10px;
`;

const S_TableHeaderItem = styled.th`
  vertical-align: middle;
  padding: 10px;
  height: 36px;
  background-color: ${(props) => props.theme.colors.hd4};
  border: 0.5px solid ${(props) => props.theme.colors.hd3};
  font-weight: 500;
  font-size: 13px;
  letter-spacing: -0.02em;
  color: ${(props) => props.theme.colors.n10};
`;

const S_Table = styled.table`
  width: 100%;
`;

const S_TableBox = styled.div`
  overflow-x: scroll;
  &::-webkit-scrollbar {
    width: 4px;
    height: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.theme.colors.hd3};
    border-radius: 6px;
  }
`;

const S_TableContainer = styled.div`
  margin-top: 15px;
  position: relative;
`;

const TABLE_HEADER = ['이름', '이미지', '등록/수정'];
