import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { dateFormat } from 'utils/common';
import { I_Notice } from 'etc/types';

interface I_NoticeTableItem {
  item: I_Notice;
}

function NoticeTableItem({ item }: I_NoticeTableItem) {
  const navigate = useNavigate();

  return (
    <S_TableRow>
      <S_TableColData>
        {dateFormat(item.createdAt).format('YYYY년 MM월 DD일')}
      </S_TableColData>
      <S_TableColData
        style={{ textDecoration: 'underline', cursor: 'pointer' }}
        onClick={() => navigate(`/service/notice/${item.id}`)}
      >
        {item.title}
      </S_TableColData>
    </S_TableRow>
  );
}

export default NoticeTableItem;

const S_TableColData = styled.td`
  text-align: center;
  vertical-align: middle;
  border: 1px solid ${(props) => props.theme.colors.hd3};
  padding: 10px;
`;

const S_TableRow = styled.tr`
  height: 36px;
  font-weight: 500;
  font-size: 13px;
`;
