import { useEffect, useState } from 'react';

import styled from 'styled-components';

import { ReactComponent as Close } from 'assets/image/close.svg';

import ModalLayout from 'components/Layout/ModalLayout';
import DisabledInput from 'components/Input/DisabledInput';
import StoreOrderRefundTableItem from 'components/Item/StoreOrderRefundTableItem';
import LongButton from 'components/Button/LongButton';
import { I_OrderCancel } from 'etc/types';
import useLogout from 'hooks/useLogout';

import { getUnpaid, postUnpaid } from 'api/order';

interface I_CancelUnpaidModal {
  onClose: () => void;
  isOpen: boolean;
  billLogId: number;
  getAsyncDeliveryList: () => void;
}

function CancelUnpaidModal({
  onClose,
  isOpen,
  billLogId,
  getAsyncDeliveryList,
}: I_CancelUnpaidModal) {
  const Logout = useLogout();
  const [orderList, setOrderList] = useState<I_OrderCancel[]>();

  const postAsyncUnpaid = async () => {
    const data = await postUnpaid(billLogId);
    if (data.ok) {
      alert('주문이 취소되었습니다.');
      getAsyncDeliveryList();
      document.body.style.overflow = 'unset'; // body scroll 생성
      onClose();
    } else {
      if (data.res.status === 315) Logout();
      else console.error(data.res.error.message);
    }
  };

  const getAsyncUnpaid = async () => {
    const data = await getUnpaid(billLogId);
    if (data.ok) {
      setOrderList(data.res.item);
    } else {
      if (data.res.status === 315) Logout();
      else console.error(data.res.error.message);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden'; // body scroll 제거
      getAsyncUnpaid();
    }
  }, [isOpen]);

  return (
    <ModalLayout isOpen={isOpen} onClose={onClose}>
      <S_Container>
        <S_CloseButton
          onClick={() => {
            document.body.style.overflow = 'unset'; // body scroll 생성
            onClose();
          }}
        >
          <Close />
        </S_CloseButton>

        <S_ContentContainer>
          <S_ModalTitle>주문 취소</S_ModalTitle>
          <S_ModalContent>
            <S_ModalContentRow>
              <S_Label>주문 번호</S_Label>
              <DisabledInput text={billLogId} style={{ width: '380px' }} />
            </S_ModalContentRow>
            <S_ModalContentCol>
              <S_Label>주문 상품 내역</S_Label>
              <S_TableBox>
                <S_Table>
                  <thead>
                    <tr>
                      {TABLE_HEADER_ORDER.map((v, i) => (
                        <S_TableHeaderItem key={i}>{v}</S_TableHeaderItem>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {orderList?.map((v: I_OrderCancel, i: number) => (
                      <StoreOrderRefundTableItem key={i} item={v} />
                    ))}
                  </tbody>
                </S_Table>
              </S_TableBox>
            </S_ModalContentCol>
          </S_ModalContent>
          <LongButton
            onClick={postAsyncUnpaid}
            style={{ float: 'right', marginTop: '39px' }}
          >
            주문 취소
          </LongButton>
        </S_ContentContainer>
      </S_Container>
    </ModalLayout>
  );
}

export default CancelUnpaidModal;

const S_TableHeaderItem = styled.th`
  vertical-align: middle;
  padding: 10px;
  height: 36px;
  background-color: ${(props) => props.theme.colors.hd4};
  border: 0.5px solid ${(props) => props.theme.colors.hd3};
  font-weight: 500;
  font-size: 13px;
  letter-spacing: -0.02em;
  color: ${(props) => props.theme.colors.n10};
`;

const S_Table = styled.table`
  width: 100%;

  th:first-child {
    width: 40px;
  }
`;

const S_TableBox = styled.div`
  overflow-x: scroll;
  &::-webkit-scrollbar {
    width: 4px;
    height: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.theme.colors.hd3};
    border-radius: 6px;
  }
`;

const S_Label = styled.span`
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.02em;
`;

const S_ModalContentCol = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const S_ModalContentRow = styled.div`
  display: flex;
  align-items: center;
  gap: 40px;
`;

const S_ModalContent = styled.div`
  margin-top: 34px;
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

const S_ModalTitle = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 35px;
  letter-spacing: -0.02em;
  text-align: center;
`;

const S_CloseButton = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
  :hover {
    opacity: 0.5;
  }
`;

const S_ContentContainer = styled.div`
  width: 1019px;
  margin: 0 auto;
`;

const S_Container = styled.div`
  width: 100%;
  height: 100%;
  background: ${(props) => props.theme.colors.hd5};
  padding: 70px 70px 144px;
  overflow-y: overlay;
  z-index: 10;
`;

const TABLE_HEADER_ORDER = [
  '상품 주문 번호',
  '주문 상태',
  '상품 정보',
  '상세 옵션',
  '주문 수량',
  '최종 결제 금액',
  '상품 판매가',
  '사용 포인트',
  '쿠폰 할인',
  '상품 번호',
];
