import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';

import MainLayout from 'components/Layout/MainLayout';
import LongButton from 'components/Button/LongButton';
import RegisterInput from 'components/Input/RegisterInput';
import Spinner from 'components/Spinner/Spinner';
import { I_MarketingUtmForm } from 'etc/types';
import { postMarketingUtm } from 'api/marketingUtm';
import useLogout from 'hooks/useLogout';

export enum EUtmSource {
  FACEBOOK = 'FACEBOOK',
  GOOGLE = 'GOOGLE',
  NAVER = 'NAVER',
  KAKAO = 'KAKAO',
  MAIL = 'MAIL',
  YOUTUBE = 'YOUTUBE',
  INSTAGRAM = 'INSTAGRAM',
  NULL = '',
}

export enum EUtmMedium {
  NEWSLETTER = 'NEWSLETTER',
  FEED = 'FEED',
  BLOG = 'BLOG',
  STORY = 'STORY',
  NULL = '',
}

function UtmRegist() {
  const navigate = useNavigate();
  const Logout = useLogout();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { register, handleSubmit, formState: { errors } } = useForm<I_MarketingUtmForm>({
    mode: 'onChange',
    shouldFocusError: true,
  });

  const onSubmit = async (data: I_MarketingUtmForm) => {
    setIsLoading(true);

    const addData = await postMarketingUtm(data);
    if (addData.ok) {
      alert('UTM 정보가 등록되었습니다.');
      setIsLoading(false);
      navigate(`/marketing/utm/list`);
    } else {
      setIsLoading(false);
      if (addData.res.status === 315) Logout();
      else {
        console.error(addData.res.error.message);
        alert('잠시 후 다시 시도해주세요.');
      }
    }
  };

  useEffect(() => {
    console.log('UTM 등록 페이지 렌더링');
  }, []);

  return (
    <MainLayout>
      <form onSubmit={handleSubmit(onSubmit)}>
        <S_TopContainer>
          <S_BasicInfoTitle>UTM 정보</S_BasicInfoTitle>
          <S_BasicInfoContainer>
            <S_BasicInfoRowWrapper>
              <S_BasicInfoWrapper>
                <S_Label>Source</S_Label>
                <S_Select {...register('source', { required: 'Required field.' })}>
                  {Object.keys(EUtmSource).map((key) => (
                    <option key={key} value={EUtmSource[key as keyof typeof EUtmSource]}>
                      {EUtmSource[key as keyof typeof EUtmSource]}
                    </option>
                  ))}
                </S_Select>
                {errors.source && <S_ErrorMessage>{errors.source.message}</S_ErrorMessage>}
              </S_BasicInfoWrapper>

              <S_BasicInfoWrapper>
                <S_Label>Medium</S_Label>
                <S_Select {...register('medium', { required: 'Required field.' })}>
                  {Object.keys(EUtmMedium).map((key) => (
                    <option key={key} value={EUtmMedium[key as keyof typeof EUtmMedium]}>
                      {EUtmMedium[key as keyof typeof EUtmMedium]}
                    </option>
                  ))}
                </S_Select>
                {errors.medium && <S_ErrorMessage>{errors.medium.message}</S_ErrorMessage>}
              </S_BasicInfoWrapper>
            </S_BasicInfoRowWrapper>


            <S_BasicInfoRowWrapper style={{ marginTop: '12px' }}>
              <S_BasicInfoWrapper>
                <S_Label>Campaign</S_Label>
                <RegisterInput
                  isError={!!errors.campaign}
                  register={register('campaign', { required: 'Required field.' })}
                  style={{ width: '380px' }}
                />
              </S_BasicInfoWrapper>
              <S_BasicInfoWrapper>
                <S_Label>Content</S_Label>
                <RegisterInput
                  isError={!!errors.content}
                  register={register('content', { required: 'Required field.' })}
                  style={{ width: '380px' }}
                />
              </S_BasicInfoWrapper>
            </S_BasicInfoRowWrapper>


            <S_BasicInfoRowWrapper>
              <S_BasicInfoWrapper>
                <S_Label>Term</S_Label>
                <RegisterInput
                  isError={!!errors.term}
                  register={register('term', { required: 'Required field.' })}
                  style={{ width: '380px' }}
                />
              </S_BasicInfoWrapper>
            </S_BasicInfoRowWrapper>

            <S_BasicInfoRowWrapper>

              <S_BasicInfoWrapper>
                <S_Label>Name</S_Label>
                <RegisterInput
                  isError={!!errors.name}
                  register={register('name', { required: 'Required field.' })}
                  style={{ width: '380px' }}
                />
              </S_BasicInfoWrapper>
              <S_BasicInfoWrapper>
                <S_Label>Redirect URL</S_Label>
                <RegisterInput
                  isError={!!errors.redirectUrl}
                  register={register('redirectUrl', { required: 'Required field.' })}
                  style={{ width: '380px' }}
                />
              </S_BasicInfoWrapper>
            </S_BasicInfoRowWrapper>
          </S_BasicInfoContainer>
        </S_TopContainer>

        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <LongButton>{isLoading ? <Spinner /> : '등록하기'}</LongButton>
        </div>

        <S_Description>
          <h3>*** UTM 파라미터 종류</h3>
          <ul>
            <li><strong>utm_source:</strong> 유입 채널 (어떤 공간에서 사용자가 이 링크를 클릭하나요?)</li>
            <li><strong>utm_medium:</strong> 유입 매체 (어떤 매체를 통해 이 링크를 공유했나요?)</li>
            <li><strong>utm_campaign:</strong> 판매 상품명, 프로모션명, 서비스명 (어떤 마케팅 캠페인인가요?)</li>
            <li><strong>utm_content:</strong> 광고 소재 (한 캠페인에 소재가 여러 개인가요? 소재를 입력해 주세요)</li>
            <li><strong>utm_term:</strong> 광고 타겟 혹은 키워드 (어떤 검색어에서 뜨는 링크인가요? 검색어를 입력해주세요)</li>
          </ul>
          <br />
          <h4>*** UTM 파라미터 예시</h4>
          <ul>
            <li><strong>utm_source:</strong> facebook, google, naver, kakao, mail, youtube, blog</li>
            <li><strong>utm_medium:</strong> newsletter, referral_sns, product_sns, blog, fb_da</li>
            <li><strong>utm_campaign:</strong> prod_a, prod_b, prod_c, blog-sales, offline, blog-info, website</li>
            <li><strong>utm_content:</strong> promotion, product_feature_a, product_feature_b, product_feature_c</li>
            <li><strong>utm_term:</strong> startup, smb, enterprise</li>
          </ul>
        </S_Description>
      </form>
    </MainLayout>
  );
}

export default UtmRegist;

// 스타일링 컴포넌트 정의
const S_Description = styled.div`
  margin-top: 20px;
  padding: 10px;
  background-color: #f9f9f9;
  border-radius: 8px;
  font-size: 14px;
  line-height: 1.5;

  h3 {
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: bold;
  }

  h4 {
    margin-top: 15px;
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: bold;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      margin-bottom: 8px;

      strong {
        font-weight: bold;
      }
    }
  }
`;

const S_TopContainer = styled.div`
  padding-bottom: 32px;
  border-bottom: 1px solid ${(props) => props.theme.colors.hd4};
`;

const S_BasicInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 16px;
`;

const S_BasicInfoTitle = styled.span`
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.02em;
`;

const S_BasicInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const S_BasicInfoRowWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 45px;
  width: 100%;
`;

const S_Label = styled.div`
  width: 100px;
  flex-shrink: 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.02em;
`;

const S_Select = styled.select`
  width: 380px;
  height: 46px;
  padding: 10px;
  border-radius: 12px;
  border: 1px solid ${(props) => props.theme.colors.hd3};
`;

const S_ErrorMessage = styled.span`
  color: ${(props) => props.theme.colors.ho6};
  font-size: 12px;
  margin-top: 5px;
`;
