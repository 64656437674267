import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import MainLayout from 'components/Layout/MainLayout';
import LongButton from 'components/Button/LongButton';
import Input from 'components/Input/Input';
import Textarea from 'components/Input/Textarea';

import { postNotice } from 'api/notice';
import useLogout from 'hooks/useLogout';

function RegisterNotice() {
  const Logout = useLogout();
  const navigate = useNavigate();

  const [title, setTitle] = useState<string>('');
  const [content, setContent] = useState<string>('');

  const onSubmit = async () => {
    if (!title) {
      alert('제목을 입력해주세요.');
      return false;
    } else if (!content) {
      alert('내용을 입력해주세요');
      return false;
    } else {
      const data = await postNotice(title, content);

      if (data.ok) {
        alert('공지사항이 등록되었습니다.');
        navigate('/service/notice');
      } else {
        if (data.res.status === 315) Logout();
        else {
          alert('잠시 후 다시 시도해주세요.');
          console.error(data.res.error.message);
        }
      }
    }
  };

  return (
    <MainLayout>
      <S_Section>
        <S_Title>공지사항 작성</S_Title>
        <S_Table>
          <tbody>
            <tr>
              <th>제목</th>
              <td colSpan={3}>
                <Input state={title} setState={setTitle} />
              </td>
            </tr>
            <tr>
              <th className='alignTop'>본문 작성</th>
              <td colSpan={3}>
                <Textarea
                  state={content}
                  setState={setContent}
                  style={{ height: 630 }}
                />
              </td>
            </tr>
          </tbody>
        </S_Table>
      </S_Section>
      <S_ButtonBox>
        <LongButton onClick={() => onSubmit()}>저장하기</LongButton>
      </S_ButtonBox>
    </MainLayout>
  );
}

export default RegisterNotice;

const S_Table = styled.table`
  border-collapse: separate;
  border-spacing: 20px;

  th {
    width: 120px;
    text-align: left;
    font-size: 14px;
    font-weight: 500;
    &:nth-child(1) {
      width: 85px;
    }
    &:nth-child(3) {
      width: 120px;
    }
    &.alignTop {
      vertical-align: top;
      padding-top: 14px;
    }
  }
`;

const S_ButtonBox = styled.div`
  display: flex;
  justify-content: center;
  padding-right: 20px;
`;

const S_Title = styled.p`
  font-size: 16px;
  color: ${(props) => props.theme.colors.hd1};
  font-weight: 700;
`;

const S_Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 17px;
  padding: 30px 20px;

  &:last-child {
    border-bottom: 0;
  }
`;
