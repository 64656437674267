import { callApi } from './callApi';

// 유저 리스트
export const getUserList = async (
  userStatus: string,
  paid: string,
  limit: number,
  currentPage: number
) => {
  const res = await callApi({
    url: `/admin/users/list?${userStatus}&paid=${paid}&currentPage=${currentPage}&limit=${limit}`,
    method: 'GET',
  });
  return res;
};

// 유저 상세
export const getUserDetail = async (userId: number) => {
  const res = await callApi({
    url: `/admin/users/${userId}`,
    method: 'GET',
  });
  return res;
};

// 유저 탈퇴 사유
export const getUserWithdrawReason = async (userId: number) => {
  const res = await callApi({
    url: `/admin/users/withdraw/reason/${userId}`,
    method: 'GET',
  });
  return res;
};

// 영구 활동 제재
export const patchUserBen = async (userId: number) => {
  const res = await callApi({
    url: `/admin/users/ben/${userId}`,
    method: 'PATCH',
  });
  return res;
};

// 반려동물
export const getUserPet = async (userId: number) => {
  const res = await callApi({
    url: `/admin/users/pet/${userId}`,
    method: 'GET',
  });
  return res;
};

// 유저 정보 검색
export const getUserSearch = async (userCode: string) => {
  const res = await callApi({
    url: `/admin/users/search/${userCode}`,
    method: 'GET',
  });
  return res;
};

// 유저 닉네임 검색
export const getUserNicknameSearch = async (userNickname: string) => {
  const res = await callApi({
    url: `/admin/users/search/nickname/${userNickname}`,
    method: 'GET',
  });
  return res;
};
