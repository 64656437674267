import { useEffect, useState } from 'react';
import styled from 'styled-components';

import MainLayout from 'components/Layout/MainLayout';
import SearchBarInput from 'components/Input/SearchBarInput';
import PagenationButton from 'components/Button/PagenationButton';
import CareUserTableItem from 'components/Item/CareUserTableItem';
import Dropdown from 'components/Dropdown/Dropdown';

import { getCaringSearch, getCaringUser } from 'api/caring';
import { I_CareUser } from 'etc/types';
import { TABLE_LIMIT } from 'etc/variable';
import useLogout from 'hooks/useLogout';

function CareUser() {
  const Logout = useLogout();

  const [searchValue, setSearchValue] = useState<string>('');
  const [isFilterDropdownOpen, setIsFilterDropdownOpen] =
    useState<boolean>(false);
  const [selectedFilterItem, setSelectedFilterItem] = useState<string>(
    FILTER_LIST[0]
  );
  const [totalCount, setTotalCount] = useState<number>(0); // 전체 개수
  const [activePage, setActivePage] = useState<number>(1); // 현재 페이지
  const [caringUserList, setCaringUserList] = useState<I_CareUser[]>([]);

  // 검색 엔터 클릭
  const onKeyPressHandler = async (
    e: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (e.key === 'Enter') {
      getAsyncCaringSearch();
    }
  };

  // 검색 확인
  const onClickSearch = () => {
    getAsyncCaringSearch();
  };

  const handlePageChange = (page: number) => {
    setActivePage(page);
  };

  const onFilterItemClick = (item: string) => {
    setSelectedFilterItem(item);
    setIsFilterDropdownOpen(false);
    setActivePage(1);
  };

  const getAsyncCaringSearch = async () => {
    if (!searchValue.trim()) {
      getAsyncCaringUserList();
    } else {
      const data = await getCaringSearch(searchValue.replaceAll('#', '%23'));
      if (data.ok) {
        // console.log(data.res);
        if (data.res) {
          setCaringUserList([data.res]);
          setActivePage(1);
          setTotalCount(1);
        } else setCaringUserList([]);
      } else {
        if (data.res.status === 315) Logout();
        else {
          alert('잠시 후 다시 시도해주세요.');
          console.error(data.res.error.message);
        }
      }
    }
  };

  const getAsyncCaringUserList = async () => {
    const data = await getCaringUser(
      TABLE_LIMIT,
      activePage,
      selectedFilterItem === '별점 낮은 순' ? SORT[0] : SORT[1]
    );
    if (data.ok) {
      // console.log(data.res);
      setCaringUserList(data.res.caringProfiles);
      setTotalCount(data.res.totalCount);
    } else {
      if (data.res.status === 315) Logout();
      else {
        alert('잠시 후 다시 시도해주세요.');
        console.error(data.res.error.message);
      }
    }
  };

  useEffect(() => {
    getAsyncCaringUserList();
  }, [activePage, selectedFilterItem]);

  return (
    <MainLayout>
      <SearchBarInput
        state={searchValue}
        setState={setSearchValue}
        onKeyPress={onKeyPressHandler}
        onClick={() => onClickSearch()}
      />
      <S_TableButtonContainer>
        <S_TableTitle>전체({totalCount})</S_TableTitle>
        <Dropdown
          list={FILTER_LIST}
          selectedItem={selectedFilterItem}
          isOpen={isFilterDropdownOpen}
          setIsOpen={setIsFilterDropdownOpen}
          onClick={(item: string) => onFilterItemClick(item)}
        />
      </S_TableButtonContainer>
      <S_TableContainer>
        <S_TableBox>
          <S_Table>
            <thead>
              <tr>
                {TABLE_HEADER.map((v, i) => (
                  <S_TableHeaderItem key={i}>{v}</S_TableHeaderItem>
                ))}
              </tr>
            </thead>
            <tbody>
              {caringUserList.map((v: I_CareUser) => (
                <CareUserTableItem key={v.id} item={v} />
              ))}
            </tbody>
          </S_Table>
        </S_TableBox>

        <PagenationButton
          activePage={activePage}
          totalCount={totalCount}
          postPerPage={TABLE_LIMIT}
          handlePageChange={handlePageChange}
        />
      </S_TableContainer>
    </MainLayout>
  );
}

export default CareUser;

const S_TableTitle = styled.span`
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.02em;
  display: flex;
  align-items: flex-end;
`;

const S_TableButtonContainer = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
`;

const S_TableHeaderItem = styled.th`
  vertical-align: middle;
  padding: 10px;
  height: 36px;
  background-color: ${(props) => props.theme.colors.hd4};
  border: 0.5px solid ${(props) => props.theme.colors.hd3};
  font-weight: 500;
  font-size: 13px;
  letter-spacing: -0.02em;
  color: ${(props) => props.theme.colors.n10};
`;

const S_Table = styled.table`
  width: 100%;
`;

const S_TableBox = styled.div`
  overflow-x: scroll;
  &::-webkit-scrollbar {
    width: 4px;
    height: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.theme.colors.hd3};
    border-radius: 6px;
  }
`;

const S_TableContainer = styled.div`
  margin-top: 8px;
  position: relative;
`;

const TABLE_HEADER = [
  'User Code',
  '돌보미 닉네임',
  '활동 지역',
  '누적 활동 수',
  '프로필 신고 수',
  '평균 별점',
  '후기 수',
  '활동 현황',
];

const FILTER_LIST = ['별점 낮은 순', '별점 높은 순'];

const SORT = ['ASC', 'DESC'];
