import styled, { keyframes } from 'styled-components';

interface I_Spinner {
  style?: React.CSSProperties;
}

export default function Spinner({ style }: I_Spinner) {
  return <S_Spinner style={style} />;
}

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const S_Spinner = styled.div`
  border: 4px solid ${(props) => props.theme.colors.hd4};
  border-top: 4px solid ${(props) => props.theme.colors.ho4};
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: ${spin} 1s linear infinite;
`;
