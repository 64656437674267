import { useState, useRef } from "react";
import { Map, MapMarker } from "react-kakao-maps-sdk"
import useKakaoLoader from "./useKakaoLoader"
import styled from "styled-components"

interface SearchMapWithClickAndMarkerProps {
  setLattLong: (lat: number, lng: number) => void;
}

export default function SearchMapWithClickAndMarker({ setLattLong }: SearchMapWithClickAndMarkerProps) {

  useKakaoLoader()
  const inputRef = useRef<HTMLInputElement>(null);

  const [address, setAddress] = useState("")
  const [position, setPosition] = useState<{ lat: number; lng: number }>({
    lat: 37.51441,
    lng: 126.9395,
  })

  const searchAddress = () => {
    if (!window.kakao) return

    const geocoder = new window.kakao.maps.services.Geocoder()

    geocoder.addressSearch(address, function (result, status) {
      if (status === window.kakao.maps.services.Status.OK) {
        const newPosition = {
          lat: parseFloat(result[0].y),
          lng: parseFloat(result[0].x),
        }
        setPosition(newPosition)
        setLattLong(newPosition.lat, newPosition.lng) // 주소 검색 후 부모 컴포넌트의 위도 경도 값 업데이트
      } else {
        alert("주소를 찾을 수 없습니다.")
      }
    })
  }

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      e.preventDefault(); // Prevent form submission
      searchAddress(); // Trigger address search when Enter is pressed
    }
  };

  return (
    <Container>
      <MapContainer>
        <Map
          center={position}
          style={{
            width: "100%",
            height: "350px",
          }}
          level={3}
          onClick={(_, mouseEvent) => {
            const latlng = mouseEvent.latLng;
            const lat = latlng.getLat();
            const lng = latlng.getLng();
            setPosition({
              lat,
              lng,
            });
            setLattLong(lat, lng); // 지도 클릭 시 부모 컴포넌트의 위도 경도 값 업데이트
          }}
  
        >
          <MapMarker position={position} />
        </Map>
      </MapContainer>
      
      <Form>
        <Input
          ref={inputRef}
          type="text"
          value={address}
          onChange={(e) => setAddress(e.target.value)}
          onKeyDown={handleKeyDown} 
          placeholder="주소를 입력하세요"
        />
        <Button onClick={searchAddress}>주소 검색</Button>
      </Form>

      {/* <Coordinates>
        <Label>위도</Label>
        <Input readOnly value={position.lat} />
        <Label>경도</Label>
        <Input readOnly value={position.lng} />
      </Coordinates> */}
    </Container>
  )
}

// 스타일드 컴포넌트
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  width: 100%;
`

const MapContainer = styled.div`
  width: 100%;
`

const Form = styled.div`
  display: flex;
  justify-content: center;
  gap: 8px;
  width: 100%;
`

const Coordinates = styled.div`
  display: flex;
  gap: 16px;
  width: 100%;
  justify-content: center;
`

const Label = styled.label`
  font-weight: bold;
`

const Input = styled.input`
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 500px;
`

const Button = styled.button`
  padding: 8px 16px;
  background-color: #13a872;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: #0e8462;
  }
`
