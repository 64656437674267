import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import styled from 'styled-components';

import { I_Coupon } from 'etc/types';
import { dateFormatUTC, numberFormat } from 'utils/common';

interface I_CouponTableItem {
  item: I_Coupon;
}

function CouponTableItem({ item }: I_CouponTableItem) {
  const navigate = useNavigate();

  const endDate = moment(item.endDate);
  const currentDate = moment();
  const hasNotPassed = endDate.isSameOrAfter(currentDate);

  return (
    <S_TableRow>
      <S_TableColData style={{ color: hasNotPassed ? '#13A872' : '#FC1D1D' }}>
        {hasNotPassed ? '사용 가능' : '기간 종료'}
      </S_TableColData>
      <S_TableColData
        style={{
          minWidth: 260,
          textDecoration: 'underLine',
          cursor: 'pointer',
          textAlign: 'left',
        }}
        onClick={() => navigate(`/store/coupon/${item.id}`)}
      >
        {item.couponName}
      </S_TableColData>
      <S_TableColData>{item.discountPercent}%</S_TableColData>
      <S_TableColData>
        {!item.brandName ? '전체' : item.brandName}
      </S_TableColData>
      <S_TableColData>
        {item.productId === 0 ? '전체' : item.productId}
      </S_TableColData>
      <S_TableColData>{numberFormat(item.minBuyPrice)}원 이상</S_TableColData>
      <S_TableColData>{numberFormat(item.maxDiscountPrice)}원</S_TableColData>
      <S_TableColData>{item.membership ? '유료' : '전체'}</S_TableColData>
      <S_TableColData style={{ minWidth: 310 }}>
        {dateFormatUTC(item.startDate).format('YYYY년 M월 D일 HH:mm') +
          ' ~ ' +
          dateFormatUTC(item.endDate).format('YYYY년 M월 D일 HH:mm')}
      </S_TableColData>
    </S_TableRow>
  );
}

export default CouponTableItem;

const S_TableColData = styled.td`
  text-align: center;
  vertical-align: middle;
  border: 1px solid ${(props) => props.theme.colors.hd3};
  padding: 10px;
  line-height: 150%;
`;

const S_TableRow = styled.tr`
  height: 36px;
  font-weight: 500;
  font-size: 13px;
`;
