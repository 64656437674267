import { useEffect, useState } from 'react';

import styled from 'styled-components';

import { ReactComponent as Checkbox } from 'assets/image/checkbox.svg';

function PolicyTableItem() {
  return (
    <S_TableRow>
      <S_TableColData>2023년 3월 29일</S_TableColData>
      <S_TableColData style={{ textDecoration: 'underline' }}>
        올펫 서비스 이용 약관
      </S_TableColData>
    </S_TableRow>
  );
}

export default PolicyTableItem;

const S_TableColData = styled.td`
  text-align: center;
  vertical-align: middle;
  border: 1px solid ${(props) => props.theme.colors.hd3};
  padding: 10px;
`;

const S_TableRow = styled.tr`
  height: 36px;
  font-weight: 500;
  font-size: 13px;
`;
