import { ThemeProvider } from 'styled-components';
import GlobalStyle from 'etc/GlobalStyle';
import { theme } from 'etc/theme';

import Routes from 'pages/Routes';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Routes />
      <GlobalStyle />
    </ThemeProvider>
  );
}

export default App;
