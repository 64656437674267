import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import styled from 'styled-components';
import { S_FlexColumn } from 'etc/GlobalCSS';

import MainLayout from 'components/Layout/MainLayout';
import DisabledInput from 'components/Input/DisabledInput';
import TableButton from 'components/Button/TableButton';
import StoreOrderDetailTableItem from 'components/Item/StoreOrderDetailTableItem';
import { I_OrderDetail } from 'etc/types';
import Input from 'components/Input/Input';
import { dateTimeFormat, numberFormat } from 'utils/common';
import useLogout from 'hooks/useLogout';

import { getOrderDetail, patchAddress } from 'api/order';

declare global {
  interface Window {
    daum: any;
  }
}

interface I_Address {
  address: string;
  zonecode: string;
}

function OrderDetail() {
  const { id } = useParams();
  const Logout = useLogout();
  const [order, setOrder] = useState<I_OrderDetail>();
  const [postCode, setPostCode] = useState<string>('');
  const [address, setAddress] = useState<string>('');
  const [addressDetail, setAddressDetail] = useState<string>('');

  // 주소 검색 API
  const searchAddress = () => {
    new window.daum.Postcode({
      oncomplete: function (data: I_Address) {
        setPostCode(data.zonecode);
        setAddress(data.address);
      },
    }).open();
  };

  // 주소 변경
  const onSearchAddressSubmit = async () => {
    const data = await patchAddress(
      Number(id),
      postCode,
      address,
      addressDetail
    );
    if (data.ok) {
      alert('주소가 변경되었습니다.');
      if (order) {
        order.receiverPostcode = Number(postCode);
        order.receiverAddress = address;
        order.receiverAddressDetail = addressDetail;
        setPostCode('');
        setAddress('');
        setAddressDetail('');
      }
    } else {
      if (data.res.status === 315) Logout();
      else {
        alert('잠시 후 다시 시도해주세요.');
        console.error(data.res.error.message);
      }
    }
  };

  const getAsyncOrder = async (id: number) => {
    const data = await getOrderDetail(id);
    if (data.ok) {
      setOrder(data.res.item);
    } else {
      if (data.res.status === 315) Logout();
      else {
        alert('잠시 후 다시 시도해주세요.');
        console.error(data.res.error.message);
      }
    }
  };

  useEffect(() => {
    getAsyncOrder(Number(id));
  }, [id]);

  return (
    <MainLayout>
      <S_TopContainer>
        <S_BasicInfoTitle>기본 정보</S_BasicInfoTitle>
        <S_BasicInfoContainer>
          <S_BasicInfoRowWrapper>
            <S_BasicInfoWrapper>
              <S_Label>구매자 명</S_Label>
              <DisabledInput
                text={order?.user?.nickname}
                style={{ width: '380px' }}
              />
            </S_BasicInfoWrapper>
            <S_BasicInfoWrapper>
              <S_Label>User code</S_Label>
              <DisabledInput
                text={order?.user?.userCode}
                style={{ width: '380px' }}
              />
            </S_BasicInfoWrapper>
          </S_BasicInfoRowWrapper>
          <S_BasicInfoRowWrapper>
            <S_BasicInfoWrapper>
              <S_Label>연락처</S_Label>
              <DisabledInput
                text={order?.user?.phone}
                style={{ width: '380px' }}
              />
            </S_BasicInfoWrapper>
            
          </S_BasicInfoRowWrapper>
          <S_BasicInfoRowWrapper>
            <S_BasicInfoWrapper>
              <S_Label>받는 사람</S_Label>
              <DisabledInput
                text={order?.receiver}
                style={{ width: '380px' }}
              />
            </S_BasicInfoWrapper>
            
            <S_BasicInfoWrapper>
              <S_Label>받는 사람 연락처</S_Label>
              <DisabledInput
                text={order?.receiverPhoneNumber}
                style={{ width: '380px' }}
              />
            </S_BasicInfoWrapper>
          </S_BasicInfoRowWrapper>
          <S_BasicInfoRowWrapper>
            <S_BasicInfoWrapper style={{ alignItems: 'flex-start' }}>
              <S_Label style={{ marginTop: '14px' }}>배송지</S_Label>
              {postCode ? (
                <S_FlexColumn style={{ gap: '4px', width: '100%' }}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      gap: '8px',
                    }}
                  >
                    <DisabledInput
                      text={address}
                      style={{ minWidth: '380px' }}
                    />
                    <DisabledInput
                      text={postCode}
                      style={{ maxWidth: '380px' }}
                    />
                    <Input
                      state={addressDetail}
                      setState={setAddressDetail}
                      style={{ minWidth: '380px' }}
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <TableButton onClick={onSearchAddressSubmit}>
                      저장
                    </TableButton>
                  </div>
                </S_FlexColumn>
              ) : (
                <S_FlexColumn style={{ gap: '4px', width: '100%' }}>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      gap: '8px',
                    }}
                  >
                    <DisabledInput
                      text={order?.receiverAddress}
                      style={{ minWidth: '380px' }}
                    />
                    <DisabledInput
                      text={order?.receiverPostcode}
                      style={{ maxWidth: '380px' }}
                    />
                    <DisabledInput
                      text={order?.receiverAddressDetail}
                      style={{ minWidth: '380px' }}
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <TableButton onClick={searchAddress}>주소 변경</TableButton>
                  </div>
                </S_FlexColumn>
              )}
            </S_BasicInfoWrapper>
          </S_BasicInfoRowWrapper>
          <S_BasicInfoRowWrapper>
            <S_BasicInfoWrapper style={{ alignItems: 'flex-start' }}>
              <S_Label style={{ marginTop: '14px' }}>배송 메모</S_Label>
              <DisabledInput
                text={order?.requirement}
                style={{ width: '100%', height: '86px' }}
              />
            </S_BasicInfoWrapper>
          </S_BasicInfoRowWrapper>
        </S_BasicInfoContainer>
      </S_TopContainer>
      <S_MidContainer>
        <S_BasicInfoTitle>주문 정보</S_BasicInfoTitle>
        <S_BasicInfoContainer>
          <S_BasicInfoRowWrapper>
            <S_BasicInfoWrapper>
              <S_Label>주문 번호</S_Label>
              <DisabledInput
                text={order?.orderNumber}
                style={{ width: '380px' }}
              />
            </S_BasicInfoWrapper>
            <S_BasicInfoWrapper>
              <S_Label>주문 일시</S_Label>
              <DisabledInput
                text={dateTimeFormat(order?.createdAt)}
                style={{ width: '380px' }}
              />
            </S_BasicInfoWrapper>
          </S_BasicInfoRowWrapper>
          <S_TableContainer>
            <S_TableLabel>주문 상품</S_TableLabel>
            <div>
              <S_TableBox>
                <S_Table>
                  <thead>
                    <tr>
                      {TABLE_HEADER.map((v, i) => (
                        <S_TableHeaderItem key={i}>{v}</S_TableHeaderItem>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {order?.billLogProduct?.map((v: any, i: number) => (
                      <StoreOrderDetailTableItem key={i} item={v} />
                    ))}
                  </tbody>
                </S_Table>
              </S_TableBox>
            </div>
          </S_TableContainer>
        </S_BasicInfoContainer>
      </S_MidContainer>
      <S_BottomContainer>
        <S_BasicInfoTitle>결제 정보</S_BasicInfoTitle>
        <S_BasicInfoContainer style={{ gap: '11px' }}>
          <S_BasicInfoRowWrapper>
            <S_BasicInfoWrapper>
              <S_Label>결제 방식</S_Label>
              <DisabledInput
                text={order?.paymentMethod}
                style={{ width: '380px' }}
              />
            </S_BasicInfoWrapper>
            <S_BasicInfoWrapper>
              <S_Label>최종 결제 금액</S_Label>
              <DisabledInput
                text={numberFormat(order?.totalPaymentPrice)}
                inlineText='원'
                style={{ width: '380px' }}
              />
            </S_BasicInfoWrapper>
          </S_BasicInfoRowWrapper>
          <S_PriceContainer>
            <S_PriceWrapper>
              <span>총 상품 금액</span>
              <span>{numberFormat(order?.totalProductPrice)}원</span>
            </S_PriceWrapper>
            <S_PriceWrapper>
              <span>발생 배송비</span>
              <span>{numberFormat(order?.shippingCost)}원</span>
            </S_PriceWrapper>
            <S_PriceWrapper>
              <span>총 포인트 사용 금액</span>
              <span>-{numberFormat(order?.totalPointDiscount)}원</span>
            </S_PriceWrapper>
            <S_PriceWrapper>
              <span>총 쿠폰 혜택 금액</span>
              <span>-{numberFormat(order?.totalCouponDiscount)}원</span>
            </S_PriceWrapper>
          </S_PriceContainer>
        </S_BasicInfoContainer>
      </S_BottomContainer>
    </MainLayout>
  );
}

export default OrderDetail;

const S_PriceWrapper = styled.div`
  width: 344px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const S_PriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 8px;
  margin-right: 18px;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.02em;
`;

const S_TableHeaderItem = styled.th`
  vertical-align: middle;
  padding: 10px;
  height: 36px;
  background-color: ${(props) => props.theme.colors.hd4};
  border: 0.5px solid ${(props) => props.theme.colors.hd3};
  font-weight: 500;
  font-size: 13px;
  letter-spacing: -0.02em;
  color: ${(props) => props.theme.colors.n10};
`;

const S_Table = styled.table`
  width: 100%;
`;

const S_TableBox = styled.div`
  overflow-x: overlay;
  &::-webkit-scrollbar {
    width: 4px;
    height: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.theme.colors.hd3};
    border-radius: 6px;
  }
`;

const S_BottomContainer = styled.div`
  padding: 24px 0 64px;
`;

const S_MidContainer = styled.div`
  padding: 24px 0 32px;
  border-bottom: 1px solid ${(props) => props.theme.colors.hd4};
`;

const S_Label = styled.div`
  width: 100px;
  flex-shrink: 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.02em;
`;

const S_TableLabel = styled.span`
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.02em;
`;

const S_TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 21px;
`;

const S_BasicInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const S_BasicInfoRowWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 45px;
  width: 100%;
`;

const S_BasicInfoTitle = styled.span`
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.02em;
`;

const S_BasicInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 16px;
`;

const S_TopContainer = styled.div`
  padding-bottom: 32px;
  border-bottom: 1px solid ${(props) => props.theme.colors.hd4};
`;

const TABLE_HEADER = [
  '상품 주문 번호',
  '주문 상태',
  '상품 정보',
  '상세 옵션',
  '주문 수량',
  '결제 금액',
  '상품 코드',
];
