import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import MainLayout from 'components/Layout/MainLayout';
import LongButton from 'components/Button/LongButton';
import DisabledInput from 'components/Input/DisabledInput';
import DisabledTextarea from 'components/Input/DisabledTextarea';

import { dateFormat } from 'utils/common';
import { I_Notice } from 'etc/types';
import { deletehNotice, getNoticeDetail } from 'api/notice';
import useLogout from 'hooks/useLogout';

function NoticeDetail() {
  const Logout = useLogout();
  const navigate = useNavigate();
  const { id } = useParams();

  const [notice, setNotice] = useState<I_Notice>({
    id: 0,
    createdAt: '',
    title: '',
    content: '',
  });

  const onClickDelete = async () => {
    const data = await deletehNotice(Number(id));

    if (data.ok) {
      alert('공지사항이 삭제되었습니다.');
      navigate('/service/notice');
    } else {
      if (data.res.status === 315) Logout();
      else {
        alert('잠시 후 다시 시도해주세요.');
        console.error(data.res.error.message);
      }
    }
  };

  const getAsyncUserApplyDetail = async () => {
    const data = await getNoticeDetail(Number(id));

    if (data.ok) {
      // console.log(data.res);
      setNotice(data.res);
    } else {
      if (data.res.status === 315) Logout();
      else {
        alert('잠시 후 다시 시도해주세요.');
        console.error(data.res.error.message);
      }
    }
  };

  useEffect(() => {
    getAsyncUserApplyDetail();
  }, []);

  return (
    <MainLayout>
      <S_Section>
        <S_Title>공지사항 상세</S_Title>
        <S_Table>
          <tbody>
            <tr>
              <th>제목</th>
              <td>
                <DisabledInput text={notice.title} />
              </td>
              <th>등록 일자</th>
              <td>
                <DisabledInput
                  text={dateFormat(notice.createdAt).format(
                    'YYYY년 MM월 DD일 HH:mm'
                  )}
                />
              </td>
            </tr>
            <tr>
              <th className='alignTop'>본문 내용</th>
              <td colSpan={3}>
                <DisabledTextarea
                  text={notice.content}
                  style={{ height: 630 }}
                />
              </td>
            </tr>
          </tbody>
        </S_Table>
      </S_Section>

      <S_ButtonBox>
        <LongButton
          onClick={() => onClickDelete()}
          style={{ backgroundColor: '#F04349' }}
        >
          삭제하기
        </LongButton>
      </S_ButtonBox>
    </MainLayout>
  );
}

export default NoticeDetail;

const S_Table = styled.table`
  border-collapse: separate;
  border-spacing: 20px;

  td {
    width: 40%;
  }
  th {
    width: 120px;
    text-align: left;
    font-size: 14px;
    font-weight: 500;
    &:nth-child(1) {
      width: 100px;
    }
    &:nth-child(3) {
      width: 100px;
      text-align: right;
      padding-right: 15px;
    }
    &.alignTop {
      vertical-align: top;
      padding-top: 14px;
    }
  }
`;

const S_ButtonBox = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 50px;
  padding-right: 20px;
`;

const S_Title = styled.p`
  font-size: 16px;
  color: ${(props) => props.theme.colors.hd1};
  font-weight: 700;
`;

const S_Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 17px;
  padding: 30px 20px;

  &:last-child {
    border-bottom: 0;
  }
`;

const USER_LIST = ['유저1', '유저2', '유저3', '유저4'];
