import { useNavigate } from 'react-router-dom';

import styled from 'styled-components';

import { I_UserOrder } from 'etc/types';
import { dateTimeFormat, numberFormat } from 'utils/common';

interface I_StorePurchaseTableItem {
  item: I_UserOrder;
}

function StorePurchaseTableItem({ item }: I_StorePurchaseTableItem) {
  const navigate = useNavigate();

  return (
    <S_TableRow>
      <S_TableColData
        style={{ textDecoration: 'underline', cursor: 'pointer' }}
        onClick={() => navigate(`/store/order/${item.id}`)}
      >
        {item.id}
      </S_TableColData>
      <S_TableColData style={{ minWidth: 180 }}>
        {dateTimeFormat(item.createdAt)}
      </S_TableColData>
      <S_TableColData style={{ minWidth: 280 }}>
        <span style={{ fontWeight: 700 }}>
          {item.brandCommaProduct.split(',')[0]}{' '}
        </span>
        {item.brandCommaProduct.split(',')[1]}
      </S_TableColData>
      <S_TableColData style={{ minWidth: 100 }}>
        {numberFormat(item.totalPaymentPrice)}원
      </S_TableColData>
    </S_TableRow>
  );
}

export default StorePurchaseTableItem;

const S_TableColData = styled.td`
  text-align: center;
  vertical-align: middle;
  border: 1px solid ${(props) => props.theme.colors.hd3};
  padding: 10px;
`;

const S_TableRow = styled.tr`
  height: 36px;
  font-weight: 500;
  font-size: 13px;
`;
