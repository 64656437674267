import { callApi } from './callApi';

const BASE_URL = '/admin/order';

// 주문 카테고리 목록
export const getOrderCategoryList = async (type: string) => {
  const typeQuery = type ? `type=${type}` : '';

  const res = await callApi({
    url: `${BASE_URL}/categories?${typeQuery}`,
    method: 'GET',
  });
  return res;
};

// 유저 주문내역 호출
export const getUserOrderList = async (
  userId: number,
  page: number,
  limit: number
) => {
  const res = await callApi({
    url: `${BASE_URL}/userBillLogs/${userId}?page=${page}&limit=${limit}`,
    method: 'GET',
  });
  return res;
};

// 주문관리 내역
export const getOrderList = async (
  page: number,
  limit: number,
  search: string,
  startDate: string,
  endDate: string
) => {
  const startDateQuery = startDate ? `&startDate=${startDate}` : '';
  const endDateQuery = endDate ? `&endDate=${endDate}` : '';

  const res = await callApi({
    url: `${BASE_URL}/billLogs?page=${page}&limit=${limit}${search}${startDateQuery}${endDateQuery}`,
    method: 'GET',
  });
  return res;
};

// 주문 단건 호출
export const getOrderDetail = async (billLogId: number) => {
  const res = await callApi({
    url: `${BASE_URL}/billLog/${billLogId}`,
    method: 'GET',
  });
  return res;
};

// 배송 주소 수정
export const patchAddress = async (
  billLogId: number,
  postCode: string,
  address: string,
  addressDetail: string
) => {
  const res = await callApi({
    url: `${BASE_URL}/billLog/address/${billLogId}`,
    method: 'PATCH',
    data: {
      receiverPostcode: postCode,
      receiverAddress: address,
      receiverAddressDetail: addressDetail,
    },
  });
  return res;
};

// 배송관리 내역
export const getDeliveryList = async (
  page: number,
  limit: number,
  code: number,
  temp: boolean
) => {
  const orderStatusCode = temp ? '' : `&orderStatusCode=${code}`;

  const res = await callApi({
    url: `${BASE_URL}/deliveryBillLogs?page=${page}&limit=${limit}${orderStatusCode}`,
    method: 'GET',
  });
  return res;
};

// 배송 상태 변경
export const patchDeliveryStatus = async (
  billLogProductId: number,
  orderStatusCode: number
) => {
  const res = await callApi({
    url: `${BASE_URL}/deliveryBillLogs/status/${billLogProductId}`,
    method: 'PATCH',
    data: {
      orderStatusCode: orderStatusCode,
    },
  });
  return res;
};

// 운송장 번호 기입 (배송 중 처리)
export const postDeliveryTracking = async (
  billLogProductId: number,
  deliveryCompany: string,
  trackingNumber: string
) => {
  const res = await callApi({
    url: `${BASE_URL}/deliveryBillLogs/trackingNumber/${billLogProductId}`,
    method: 'POST',
    data: {
      deliveryCompany: deliveryCompany,
      trackingNumber: trackingNumber,
    },
  });
  return res;
};

// 미입금 주문 상품 내역
export const getUnpaid = async (id: number) => {
  const res = await callApi({
    url: `${BASE_URL}/deliveryBillLog/unpaid/${id}`,
    method: 'GET',
  });
  return res;
};

// 미입금 주문 취소 처리
export const postUnpaid = async (id: number) => {
  const res = await callApi({
    url: `${BASE_URL}/deliveryBillLog/unpaid/${id}`,
    method: 'POST',
  });
  return res;
};

// 주문 취소
export const postCancelAdmin = async (
  billLogProductIds: number[],
  cancelPaymentPrice: number,
  addReturnPrice: number
) => {
  const res = await callApi({
    url: `${BASE_URL}/cancelOrder`,
    method: 'POST',
    data: {
      billLogProductIds: billLogProductIds,
      cancelPaymentPrice: cancelPaymentPrice,
      addReturnPrice: addReturnPrice,
    },
  });
  return res;
};

// 취소 관리 내역
export const getCancelList = async (
  page: number,
  limit: number,
  code: number
) => {
  const res = await callApi({
    url: `${BASE_URL}/cancelBillLogs?page=${page}&limit=${limit}&orderStatusCode=${code}`,
    method: 'GET',
  });
  return res;
};

// 취소 거부 상품 내역
export const getCancelRefusalList = async (
  page: number,
  limit: number,
  code: number
) => {
  const res = await callApi({
    url: `${BASE_URL}/cancelBillLog/products?page=${page}&limit=${limit}&orderStatusCode=${code}`,
    method: 'GET',
  });
  return res;
};

// 취소 요청 호출
export const getCancelRequestHistory = async (refundLogId: number) => {
  const res = await callApi({
    url: `${BASE_URL}/cancelBillLog/${refundLogId}`,
    method: 'GET',
  });
  return res;
};

// 결제 취소 내역
export const getCancelHistory = async (refundLogId: number) => {
  const res = await callApi({
    url: `${BASE_URL}/cancelPayment/${refundLogId}`,
    method: 'GET',
  });
  return res;
};

// 교환 관리 내역
export const getExchangeList = async (
  page: number,
  limit: number,
  code: number
) => {
  const res = await callApi({
    url: `${BASE_URL}/exchangeBillLogs?page=${page}&limit=${limit}&orderStatusCode=${code}`,
    method: 'GET',
  });
  return res;
};

// 교환 운송장 번호 기입 (교환 배송 중 처리)
export const postExchangeTracking = async (
  billLogProductId: number,
  deliveryCompany: string,
  trackingNumber: string
) => {
  const res = await callApi({
    url: `${BASE_URL}/exchangeBillLog/trackingNumber/${billLogProductId}`,
    method: 'POST',
    data: {
      deliveryCompany: deliveryCompany,
      trackingNumber: trackingNumber,
    },
  });
  return res;
};

// 교환주문 => 반품주문으로 변경
export const postConvertReturn = async (
  refundLogProductId: number,
  refundLogStatus: number,
  orderStatusCode: number
) => {
  const res = await callApi({
    url: `${BASE_URL}/exchangeBillLog/convertReturn/${refundLogProductId}`,
    method: 'POST',
    data: {
      refundLogStatus: refundLogStatus,
      orderStatusCode: orderStatusCode,
    },
  });
  return res;
};

// 반품 관리 내역
export const getReturnList = async (
  page: number,
  limit: number,
  code: number
) => {
  const res = await callApi({
    url: `${BASE_URL}/returnBillLogs?page=${page}&limit=${limit}&orderStatusCode=${code}`,
    method: 'GET',
  });
  return res;
};

// 반품 거부 상품 내역
export const getReturnRefusalList = async (
  page: number,
  limit: number,
  code: number
) => {
  const res = await callApi({
    url: `${BASE_URL}/returnBillLog/products?page=${page}&limit=${limit}&orderStatusCode=${code}`,
    method: 'GET',
  });
  return res;
};

// 구매 확정 상품 내역
export const getConfirmList = async (
  page: number,
  limit: number,
  code: number
) => {
  const res = await callApi({
    url: `${BASE_URL}/confirmedBillLog/products?page=${page}&limit=${limit}&orderStatusCode=${code}`,
    method: 'GET',
  });
  return res;
};

// 취소 | 교환 | 반품 주문 확인
export const patchRequestConfirm = async (refundLogId: number) => {
  const res = await callApi({
    url: `${BASE_URL}/checkingOrder/refundLog/${refundLogId}`,
    method: 'PATCH',
  });
  return res;
};

// 교환 | 반품 주문 상태 변경 (수거완료, 교환준비)
export const patchRefundStatus = async (
  refundLogProductId: number,
  code: number
) => {
  const res = await callApi({
    url: `${BASE_URL}/exchangeRefundLog/status/${refundLogProductId}`,
    method: 'PATCH',
    data: {
      orderStatusCode: code,
    },
  });
  return res;
};

// 반품 주문 상태 변경 (수거 완료)
export const patchReturnStatus = async (refundLogProductIds: number[]) => {
  const res = await callApi({
    url: `${BASE_URL}/exchangeRefundLog/status`,
    method: 'PATCH',
    data: {
      refundLogProductIds: refundLogProductIds,
      orderStatusCode: 32,
    },
  });
  return res;
};

// 거부 | 지연 사유 작성 및 상태전환
export const postReason = async (
  refundLogProductId: number,
  reason: string,
  reject: boolean
) => {
  const res = await callApi({
    url: `${BASE_URL}/refundLogProduct/reason/${refundLogProductId}`,
    method: 'POST',
    data: {
      reason: reason,
      reject: reject,
    },
  });
  return res;
};

// 교환 주문 완료 처리
export const patchExchangeComplete = async (id: number) => {
  const res = await callApi({
    url: `${BASE_URL}/completeOrder/refundLog/${id}`,
    method: 'PATCH',
  });
  return res;
};

// 결제 취소 (환불 | 취소, 반품 완료)
export const postCancel = async (
  id: number,
  cancelPaymentPrice: number,
  _addReturnPrice: string
) => {
  const addReturnPrice = _addReturnPrice ? Number(_addReturnPrice) : 0;
  const res = await callApi({
    url: `${BASE_URL}/refundLog/cancelPayment/${id}`,
    method: 'POST',
    data: {
      refundLogId: id,
      cancelPaymentPrice: cancelPaymentPrice,
      addReturnPrice: addReturnPrice,
    },
  });
  return res;
};

// 교환/반품 상세 사유 호출
export const getDetailReason = async (refundLogId: number) => {
  const res = await callApi({
    url: `${BASE_URL}/refundLog/deatilReason/${refundLogId}`,
    method: 'GET',
  });
  return res;
};
