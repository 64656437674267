import styled from 'styled-components';

interface I_NormalButton {
  children: React.ReactNode;
  onClick: () => void;
  isDelete?: boolean;
  style?: React.CSSProperties;
}

function NormalButton({ children, onClick, isDelete, style }: I_NormalButton) {
  return (
    <S_Container onClick={onClick} isDelete={isDelete} style={style}>
      {children}
    </S_Container>
  );
}

export default NormalButton;

const S_Container = styled.button<{ isDelete?: boolean }>`
  width: 200px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) =>
    props.isDelete ? props.theme.colors.ho6 : props.theme.colors.ho1};
  border-radius: 8px;
  font-weight: 700;
  font-size: 14px;
  letter-spacing: -0.02em;
  color: ${(props) => props.theme.colors.hd5};
  &:hover {
    opacity: 0.5;
  }
`;
