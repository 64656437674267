import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { I_Inquire } from 'etc/types';
import { dateFormat } from 'utils/common';

interface I_AppServiceTableItem {
  item: I_Inquire;
}

function AppServiceTableItem({ item }: I_AppServiceTableItem) {
  const navigate = useNavigate();

  return (
    <S_TableRow>
      <S_TableColData>
        {dateFormat(item.createdAt).format('YYYY년 MM월 DD일 HH:mm')}
      </S_TableColData>
      <S_TableColData
        style={{ textDecoration: 'underLine', cursor: 'pointer' }}
        onClick={() => navigate(`/service/app/${item.id}`)}
      >
        {item.userCode}
      </S_TableColData>
      <S_TableColData>{item.nickname}</S_TableColData>
      <S_TableColData>{item.email}</S_TableColData>
      <S_TableColData>{item.requestType}
        {/* {item.requestType === 'App' && 'App'}
        {item.requestType === 'Web' && 'Web'} */}
        </S_TableColData>
      <S_TableColData>
        {item.status === '답변 완료' ? (
          <span style={{ color: '#13A872' }}>{item.status}</span>
        ) : (
          <span style={{ color: '#FF0000' }}>{item.status}</span>
        )}
      </S_TableColData>
    </S_TableRow>
  );
}

export default AppServiceTableItem;

const S_TableColData = styled.td`
  text-align: center;
  vertical-align: middle;
  border: 1px solid ${(props) => props.theme.colors.hd3};
  padding: 10px;
`;

const S_TableRow = styled.tr`
  height: 36px;
  font-weight: 500;
  font-size: 13px;
`;
