import { useEffect, useState } from 'react';

import styled from 'styled-components';

import { ReactComponent as Checkbox } from 'assets/image/checkbox.svg';

import MainLayout from 'components/Layout/MainLayout';
import Dropdown from 'components/Dropdown/Dropdown';
import TableInput from 'components/Input/TableInput';
import TableButton from 'components/Button/TableButton';
import PagenationButton from 'components/Button/PagenationButton';
import DateInput from 'components/Input/DateInput';
import StoreInquiryTableItem from 'components/Item/StoreInquiryTableItem';
import { I_StoreInquiry } from 'etc/types';
import useLogout from 'hooks/useLogout';

import { getInquiryList } from 'api/store';

// 문의유형 dropdown
const INQUIRY_TYPE = [
  '전체',
  '상품문의',
  '배송문의',
  '취소문의',
  '반품문의',
  '교환문의',
  '기타문의',
];

// 답변여부 dropdown
const ANSWER_TYPE = ['전체', '답변완료', '미답변'];

const TABLE_HEADER = [
  '답변상태',
  '문의 유형',
  '문의 등록 일시',
  '문의 상품',
  '상품/주문 번호',
  '구매자명',
  '구매자 연락처',
];

function StoreInquiry() {
  const Logout = useLogout();
  const [inquiryList, setInquiryList] = useState<I_StoreInquiry[]>([]); // 상품
  const [totalCount, setTotalCount] = useState<number>(0); // 전체 개수
  const [activePage, setActivePage] = useState<number>(1); // 현재 페이지
  // 기간
  const [fromDate, setFromDate] = useState<string>('');
  const [toDate, setToDate] = useState<string>('');
  // 문의유형
  const [isInquiryDropdownOpen, setIsInquiryDropdownOpen] =
    useState<boolean>(false);
  const [selectedInquiryItem, setSelectedInquiryItem] = useState<string>(
    INQUIRY_TYPE[0]
  );
  const onInquiryItemClick = (item: string) => {
    setSelectedInquiryItem(item);
    setIsInquiryDropdownOpen(false);
  };
  // 답변여부
  const [isAnswerDropdownOpen, setIsAnswerDropdownOpen] =
    useState<boolean>(false);
  const [selectedAnswerItem, setSelectedAnswerItem] = useState<string>(
    ANSWER_TYPE[0]
  );
  const onAnswerItemClick = (item: string) => {
    setSelectedAnswerItem(item);
    setIsAnswerDropdownOpen(false);
  };
  // 상세 검색
  const [inputValue, setInputValue] = useState<string>('');

  // 검색 초기화
  const handleResetClick = () => {
    setFromDate('');
    setToDate('');
    setSelectedInquiryItem(INQUIRY_TYPE[0]);
    setSelectedAnswerItem(ANSWER_TYPE[0]);
    setInputValue('');
  };

  // table pagenation
  const handlePageChange = (page: number) => {
    setActivePage(page);
  };

  // 상품 리스트
  const getAsyncInquiryList = async () => {
    const data = await getInquiryList(
      activePage,
      10,
      fromDate,
      toDate,
      selectedInquiryItem,
      selectedAnswerItem,
      inputValue
    );
    if (data.ok) {
      setTotalCount(data.res.totalCount);
      setInquiryList(data.res.item);
    } else {
      if (data.res.status === 315) Logout();
      else if (data.res.error?.code === 104)
        alert('상품코드는 숫자만 입력해주세요.');
      else console.error(data.res.error.message);
    }
  };

  useEffect(() => {
    getAsyncInquiryList();
  }, [activePage]);

  return (
    <MainLayout>
      <S_CategoryContainer>
        <S_CategoryTitle>문의 리스트 필터</S_CategoryTitle>
        <S_CategoryFilterContainer>
          <S_CategoryFilterRow>
            <S_CategoryFilterTitle>기간</S_CategoryFilterTitle>
            <S_CategoryFilterWrapper>
              <div>
                <DateInput
                  state={fromDate}
                  setState={setFromDate}
                  max={toDate}
                />
                <span> ~ </span>
                <DateInput
                  state={toDate}
                  setState={setToDate}
                  startDate={fromDate}
                  min={fromDate}
                />
              </div>
            </S_CategoryFilterWrapper>
          </S_CategoryFilterRow>
          <S_CategoryFilterRow>
            <S_CategoryFilterTitle>문의유형</S_CategoryFilterTitle>
            <S_CategoryFilterWrapper>
              <Dropdown
                list={INQUIRY_TYPE}
                selectedItem={selectedInquiryItem}
                isOpen={isInquiryDropdownOpen}
                setIsOpen={setIsInquiryDropdownOpen}
                onClick={(item: string) => onInquiryItemClick(item)}
              />
            </S_CategoryFilterWrapper>
          </S_CategoryFilterRow>
          <S_CategoryFilterRow>
            <S_CategoryFilterTitle>답변여부</S_CategoryFilterTitle>
            <S_CategoryFilterWrapper>
              <Dropdown
                list={ANSWER_TYPE}
                selectedItem={selectedAnswerItem}
                isOpen={isAnswerDropdownOpen}
                setIsOpen={setIsAnswerDropdownOpen}
                onClick={(item: string) => onAnswerItemClick(item)}
              />
            </S_CategoryFilterWrapper>
          </S_CategoryFilterRow>
          <S_CategoryFilterRow>
            <S_CategoryFilterTitle>상세 검색</S_CategoryFilterTitle>
            <S_CategoryFilterWrapper>
              <TableInput
                state={inputValue}
                setState={setInputValue}
                style={{ width: '235px' }}
                placeholder='주문번호 및 상품번호 입력'
              />
            </S_CategoryFilterWrapper>
          </S_CategoryFilterRow>
        </S_CategoryFilterContainer>
        <S_ButtonWrapper>
          <TableButton onClick={handleResetClick}>검색 초기화</TableButton>
          <TableButton onClick={getAsyncInquiryList}>조회하기</TableButton>
        </S_ButtonWrapper>
      </S_CategoryContainer>

      {/* 상품 목록 */}
      <S_TableButtonContainer>
        <S_TableTitle>상품 목록({totalCount})</S_TableTitle>
      </S_TableButtonContainer>

      <S_TableContainer>
        <S_TableBox>
          <S_Table>
            <thead>
              <tr>
                {TABLE_HEADER.map((v, i) => (
                  <S_TableHeaderItem key={i}>
                    {v === 'check' ? (
                      <Checkbox
                        width='16.67px'
                        height='16.67px'
                        color='#2E2E2E'
                      />
                    ) : (
                      v
                    )}
                  </S_TableHeaderItem>
                ))}
              </tr>
            </thead>
            <tbody>
              {inquiryList?.map((v: I_StoreInquiry, i: number) => (
                <StoreInquiryTableItem key={i} item={v} />
              ))}
            </tbody>
          </S_Table>
        </S_TableBox>

        <PagenationButton
          activePage={activePage}
          totalCount={totalCount}
          postPerPage={10}
          handlePageChange={handlePageChange}
        />
      </S_TableContainer>
    </MainLayout>
  );
}

export default StoreInquiry;

const S_TableHeaderItem = styled.th`
  vertical-align: middle;
  padding: 10px;
  height: 36px;
  background-color: ${(props) => props.theme.colors.hd4};
  border: 0.5px solid ${(props) => props.theme.colors.hd3};
  font-weight: 500;
  font-size: 13px;
  letter-spacing: -0.02em;
  color: ${(props) => props.theme.colors.n10};
`;

const S_Table = styled.table`
  width: 100%;
`;

const S_TableBox = styled.div`
  overflow-x: overlay;
  &::-webkit-scrollbar {
    width: 4px;
    height: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.theme.colors.hd3};
    border-radius: 6px;
  }
`;

const S_TableContainer = styled.div`
  margin-top: 8px;
  position: relative;
`;

const S_TableTitle = styled.span`
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.02em;
  display: flex;
  align-items: flex-end;
`;

const S_TableButtonContainer = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
`;

const S_ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
`;

const S_CategoryFilterWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const S_CategoryFilterTitle = styled.span`
  width: 90px;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  letter-spacing: -0.02em;
`;

const S_CategoryFilterRow = styled.div`
  display: flex;
  align-items: center;
  gap: 48px;
`;

const S_CategoryFilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 14px 16px;
  border: 1px solid ${(props) => props.theme.colors.hd3};
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  letter-spacing: -0.02em;
  /* border-radius: 8px; */
`;

const S_CategoryTitle = styled.span`
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.02em;
`;

const S_CategoryContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
