import { callApi } from './callApi';

// 포인트 리스트
export const getPoint = async (
  limit: number,
  currentPage: number,
  filter: string
) => {
  const res = await callApi({
    url: `/admin/point?limit=${limit}&currentPage=${currentPage}&filter=${filter}`,
    method: 'GET',
  });
  return res;
};

// 포인트 내역
export const getPointHistory = async (
  limit: number,
  currentPage: number,
  filter: string,
  userId: number
) => {
  const res = await callApi({
    url: `/admin/point/${userId}?filter=${filter}&limit=${limit}&currentPage=${currentPage}`,
    method: 'GET',
  });
  return res;
};

// 포인트 지급 내역
export const getPointAwardHistory = async (
  limit: number,
  currentPage: number
) => {
  const res = await callApi({
    url: `/admin/point/award/list?limit=${limit}&currentPage=${currentPage}`,
    method: 'GET',
  });
  return res;
};

// 포인트 지급
export const postAward = async (userCode: string, point: number) => {
  console.log(userCode);
  const res = await callApi({
    url: `/admin/point/award/${point}/point?userCode=${userCode}`,
    method: 'POST',
  });
  return res;
};
