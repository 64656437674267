import { useNavigate } from 'react-router-dom';
import moment from 'moment-timezone';

import styled from 'styled-components';

import { I_Order } from 'etc/types';
import { dateTimeFormat } from 'utils/common';

interface I_StoreOrderTableItem {
  item: I_Order;
}

function StoreOrderTableItem({ item }: I_StoreOrderTableItem) {
  const navigate = useNavigate();

  return (
    <S_TableRow>
      <S_TableColData>{item.id}</S_TableColData>
      <S_TableColData
        style={{ textDecoration: 'underLine', cursor: 'pointer' }}
      >
        <span
          onClick={() => navigate(`/store/order/${item.billLogId}`)}
          style={{ textDecoration: 'underLine', cursor: 'pointer' }}
        >
          {item.billLogId}
        </span>
      </S_TableColData>
      <S_TableColData>{dateTimeFormat(item.createdAt)}</S_TableColData>
      <S_TableColData style={{ minWidth: '248px' }}>
        <span style={{ fontWeight: '700' }}>{item.brandName}</span>{' '}
        <span style={{ fontWeight: '400' }}>{item.productName}</span>
      </S_TableColData>
      <S_TableColData>{item.orderStatusName}</S_TableColData>
      <S_TableColData>
        {dateTimeFormat(item.orderStatusProcessedDate)}
      </S_TableColData>
      <S_TableColData>{item.userNickname}</S_TableColData>
      <S_TableColData>{item.userPhone}</S_TableColData>
      <S_TableColData>
        <span
          onClick={() => navigate(`/user/${item.userId}`)}
          style={{ textDecoration: 'underLine', cursor: 'pointer' }}
        >
          {item.userCode}
        </span>
      </S_TableColData>
    </S_TableRow>
  );
}

export default StoreOrderTableItem;

const S_TableColData = styled.td`
  text-align: center;
  vertical-align: middle;
  border: 1px solid ${(props) => props.theme.colors.hd3};
  padding: 10px;
`;

const S_TableRow = styled.tr`
  height: 36px;
  font-weight: 500;
  font-size: 13px;
`;
