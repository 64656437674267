import { useNavigate } from 'react-router-dom';

import styled from 'styled-components';

import { ReactComponent as Checkbox } from 'assets/image/checkbox.svg';
import { ReactComponent as CheckboxChecked } from 'assets/image/checkbox-checked.svg';

import { I_Delivery } from 'etc/types';
import { dateTimeFormat } from 'utils/common';

interface I_OrderBeforeDeposit {
  item: I_Delivery;
  checkItem: number;
  setCheckItem: React.Dispatch<React.SetStateAction<number>>;
  setCheckBillLogId: React.Dispatch<React.SetStateAction<number>>;
}

function OrderBeforeDeposit({
  item,
  checkItem,
  setCheckItem,
  setCheckBillLogId,
}: I_OrderBeforeDeposit) {
  const navigate = useNavigate();

  return (
    <S_TableRow>
      <S_TableColData style={{ width: '38px' }}>
        {checkItem === item.id ? (
          <S_Checkbox
            onClick={() => {
              setCheckItem(0);
              setCheckBillLogId(0);
            }}
          >
            <CheckboxChecked />
          </S_Checkbox>
        ) : (
          <S_Checkbox
            onClick={() => {
              setCheckItem(item.id);
              setCheckBillLogId(item.billLogId);
            }}
          >
            <Checkbox />
          </S_Checkbox>
        )}
      </S_TableColData>
      <S_TableColData>{item.id}</S_TableColData>
      <S_TableColData>
        <span
          onClick={() => navigate(`/store/order/${item.billLogId}`)}
          style={{ textDecoration: 'underLine', cursor: 'pointer' }}
        >
          {item.billLogId}
        </span>
      </S_TableColData>
      <S_TableColData>{item.userNickname}</S_TableColData>
      <S_TableColData>{dateTimeFormat(item.createdAt)}</S_TableColData>
      <S_TableColData style={{ minWidth: '260px' }}>
        {item.productName}
      </S_TableColData>
      <S_TableColData>{item.userPhone}</S_TableColData>
      <S_TableColData>
        <span
          onClick={() => navigate(`/user/${item.userId}`)}
          style={{ textDecoration: 'underLine', cursor: 'pointer' }}
        >
          {item.userCode}
        </span>
      </S_TableColData>
    </S_TableRow>
  );
}

export default OrderBeforeDeposit;

const S_Checkbox = styled.div`
  width: 16.67px;
  height: 16.67px;
  color: #aeaeae;
  margin: 0 auto;
  cursor: pointer;
`;

const S_TableColData = styled.td`
  text-align: center;
  vertical-align: middle;
  border: 1px solid ${(props) => props.theme.colors.hd3};
  padding: 10px;
`;

const S_TableRow = styled.tr`
  height: 36px;
  font-weight: 500;
  font-size: 13px;
`;
