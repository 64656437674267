import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import styled from 'styled-components';

import MainLayout from 'components/Layout/MainLayout';
import LongButton from 'components/Button/LongButton';
import DisabledInput from 'components/Input/DisabledInput';
import DisabledTextarea from 'components/Input/DisabledTextarea';
import Textarea from 'components/Input/Textarea';
import { I_StoreInquiryDetail } from 'etc/types';
import useLogout from 'hooks/useLogout';
import { dateTimeFormat } from 'utils/common';
import Spinner from 'components/Spinner/Spinner';

import { getInquiry, patchInquiry } from 'api/store';

function StoreInquiryDetail() {
  const { id } = useParams();
  const Logout = useLogout();
  const [inquiry, setInquiry] = useState<I_StoreInquiryDetail>();
  const [answer, setAnswer] = useState<string>('');
  const [isAnswerLoading, setIsAnswerLoading] = useState<boolean>(false);

  const patchAsyncInquiry = async () => {
    if (!answer) {
      alert('답변을 작성해주세요.');
      return;
    }
    setIsAnswerLoading(true);

    const data = await patchInquiry(Number(id), answer);
    if (data.ok) {
      getAsyncInquiry();
      setAnswer('');
      alert('답변 등록이 완료되었습니다.');
      setIsAnswerLoading(false);
    } else {
      if (data.res.status === 315) Logout();
      else {
        alert('잠시 후 다시 시도해주세요.');
        console.error(data.res.error.message);
        setIsAnswerLoading(false);
      }
    }
  };

  const getAsyncInquiry = async () => {
    const data = await getInquiry(Number(id));
    if (data.ok) {
      setInquiry(data.res.item);
    } else {
      if (data.res.status === 315) Logout();
      else console.error(data.res.error.message);
    }
  };

  useEffect(() => {
    getAsyncInquiry();
  }, [id]);

  return (
    <MainLayout>
      <S_TopContainer>
        <S_BasicInfoTitle>문의 상세 내용</S_BasicInfoTitle>
        <S_BasicInfoContainer>
          <S_BasicInfoRowWrapper>
            <S_BasicInfoWrapper>
              <S_Label>등록 일시</S_Label>
              <DisabledInput
                text={dateTimeFormat(inquiry?.createdAt)}
                style={{ width: '380px' }}
              />
            </S_BasicInfoWrapper>
            <S_BasicInfoWrapper>
              <S_Label>답변 상태</S_Label>
              <DisabledInput
                text={inquiry?.answer ? '답변완료' : '미답변'}
                style={{
                  width: '380px',
                  color: inquiry?.answer ? '#3CBF0E' : '#FF0000',
                }}
              />
            </S_BasicInfoWrapper>
          </S_BasicInfoRowWrapper>
          <S_BasicInfoRowWrapper>
            <S_BasicInfoWrapper>
              <S_Label>문의상품</S_Label>
              <DisabledInput
                text={inquiry?.product.name}
                style={{ width: '380px' }}
              />
            </S_BasicInfoWrapper>
            <S_BasicInfoWrapper>
              <S_Label>
                {inquiry?.billLogProduct ? '주문' : '상품'} 번호
              </S_Label>
              <DisabledInput
                text={
                  inquiry?.billLogProduct
                    ? inquiry?.billLogProduct.id
                    : inquiry?.product.id
                }
                style={{ width: '380px' }}
              />
            </S_BasicInfoWrapper>
          </S_BasicInfoRowWrapper>
          <S_BasicInfoRowWrapper>
            <S_BasicInfoWrapper>
              <S_Label>구매자 명</S_Label>
              <DisabledInput
                text={inquiry?.user.nickname}
                style={{ width: '380px' }}
              />
            </S_BasicInfoWrapper>
            <S_BasicInfoWrapper>
              <S_Label>구매자 연락처</S_Label>
              <DisabledInput
                text={inquiry?.user.phone}
                style={{ width: '380px' }}
              />
            </S_BasicInfoWrapper>
          </S_BasicInfoRowWrapper>
          <S_BasicInfoRowWrapper>
            <S_BasicInfoWrapper>
              <S_Label>문의 유형</S_Label>
              <DisabledInput
                text={inquiry?.category}
                style={{ width: '380px', background: '#C4E9DC' }}
              />
            </S_BasicInfoWrapper>
          </S_BasicInfoRowWrapper>
          <S_BasicInfoRowWrapper>
            <S_BasicInfoWrapper style={{ alignItems: 'flex-start' }}>
              <S_Label style={{ marginTop: '14px' }}>상세 내용</S_Label>
              <DisabledTextarea
                text={inquiry?.content}
                style={{ height: '127px' }}
              />
            </S_BasicInfoWrapper>
          </S_BasicInfoRowWrapper>
        </S_BasicInfoContainer>
      </S_TopContainer>
      <S_BottomContainer>
        <S_BasicInfoTitle>문의 상세 내용</S_BasicInfoTitle>
        <S_BasicInfoContainer>
          <S_BasicInfoRowWrapper>
            <S_BasicInfoWrapper style={{ alignItems: 'flex-start' }}>
              <S_Label style={{ marginTop: '14px' }}>문의 답변</S_Label>
              {inquiry?.answer ? (
                <DisabledTextarea
                  text={inquiry?.answer}
                  style={{ height: '233px' }}
                />
              ) : (
                <Textarea
                  state={answer}
                  setState={setAnswer}
                  style={{ height: '233px' }}
                />
              )}
            </S_BasicInfoWrapper>
          </S_BasicInfoRowWrapper>
        </S_BasicInfoContainer>
        {inquiry?.answer ? (
          <></>
        ) : (
          <LongButton
            onClick={patchAsyncInquiry}
            style={{ margin: '80px auto 0' }}
          >
            {isAnswerLoading ? <Spinner /> : '답변 등록'}
          </LongButton>
        )}
      </S_BottomContainer>
    </MainLayout>
  );
}

export default StoreInquiryDetail;

const S_BottomContainer = styled.div`
  padding: 24px 0 64px;
`;

const S_Label = styled.div`
  width: 100px;
  flex-shrink: 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.02em;
`;

const S_BasicInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const S_BasicInfoRowWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 45px;
  width: 100%;
`;

const S_BasicInfoTitle = styled.span`
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.02em;
`;

const S_BasicInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 16px;
`;

const S_TopContainer = styled.div`
  padding-bottom: 32px;
  border-bottom: 1px solid ${(props) => props.theme.colors.hd4};
`;
