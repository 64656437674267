import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import styled from 'styled-components';

import { I_CancelAndReturnRefusal } from 'etc/types';
import { dateTimeFormat } from 'utils/common';
import ReasonModal from 'components/Modal/ReasonModal';

interface I_CancelRefusal {
  item: I_CancelAndReturnRefusal;
}

function CancelRefusal({ item }: I_CancelRefusal) {
  const navigate = useNavigate();
  const [isReasonModalOpen, setIsReasonModalOpen] = useState<boolean>(false);

  return (
    <S_TableRow>
      <S_TableColData>{item.refundLogId}</S_TableColData>
      <S_TableColData>{dateTimeFormat(item.createdAt)}</S_TableColData>
      <S_TableColData>
        <span
          onClick={() => setIsReasonModalOpen(true)}
          style={{ textDecoration: 'underLine', cursor: 'pointer' }}
        >
          작성 내용 확인
        </span>
      </S_TableColData>
      <S_TableColData style={{ minWidth: '248px' }}>
        {item.productName}
      </S_TableColData>
      <S_TableColData>{dateTimeFormat(item.orderCreatedAt)}</S_TableColData>
      <S_TableColData>{item.userNickname}</S_TableColData>
      <S_TableColData>{item.userPhone}</S_TableColData>
      <S_TableColData>
        <span
          onClick={() => navigate(`/user/${item.userId}`)}
          style={{ textDecoration: 'underLine', cursor: 'pointer' }}
        >
          {item.userCode}
        </span>
      </S_TableColData>

      <td>
        {isReasonModalOpen && (
          <ReasonModal
            isOpen={isReasonModalOpen}
            onClose={() => setIsReasonModalOpen(false)}
            status='취소 거부'
            isDone={true}
            product={{
              refundLogProductId: item.refundLogProductId,
              brandName: item.brandName,
              productName: item.productName,
              productOptionName: item.productOptionName,
              productCount: item.productCount,
              delayReason: item.processReason,
            }}
          />
        )}
      </td>
    </S_TableRow>
  );
}

export default CancelRefusal;

const S_TableColData = styled.td`
  text-align: center;
  vertical-align: middle;
  border: 1px solid ${(props) => props.theme.colors.hd3};
  padding: 10px;
`;

const S_TableRow = styled.tr`
  height: 36px;
  font-weight: 500;
  font-size: 13px;
`;
