import { useEffect, useState } from 'react';
import styled from 'styled-components';

import MainLayout from 'components/Layout/MainLayout';
import SearchBarInput from 'components/Input/SearchBarInput';
import PagenationButton from 'components/Button/PagenationButton';
import Dropdown from 'components/Dropdown/Dropdown';
import ReportTableItem from 'components/Item/ReportTableItem';

import { TABLE_LIMIT } from 'etc/variable';
import { getReportList, getReportUserSearch } from 'api/report';
import useLogout from 'hooks/useLogout';
import { I_Report } from 'etc/types';

function Report() {
  const Logout = useLogout();

  const [searchValue, setSearchValue] = useState<string>('');
  const [isFilterDropdownOpen, setIsFilterDropdownOpen] =
    useState<boolean>(false);
  const [selectedFilterItem, setSelectedFilterItem] = useState<string>(
    FILTER_LIST[0]
  );
  const [totalCount, setTotalCount] = useState<number>(0); // 전체 개수
  const [activePage, setActivePage] = useState<number>(1); // 현재 페이지
  const [reportList, setReportList] = useState<I_Report[]>([]);
  const [isSearch, setIsSearch] = useState<boolean>(false);

  const handlePageChange = (page: number) => {
    setActivePage(page);

    if (isSearch) {
      getAsyncUserSearch(page);
    } else {
      getAsyncReportList(page, selectedFilterItem);
    }
  };

  const onFilterItemClick = (item: string) => {
    setSelectedFilterItem(item);
    setIsFilterDropdownOpen(false);
    setActivePage(1);
    setSearchValue('');
    setIsSearch(false);

    getAsyncReportList(1, item);
  };

  // 검색 엔터
  const onKeyPressHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      getAsyncUserSearch(1);
      setActivePage(1);
      setSelectedFilterItem('전체');
    }
  };

  // 검색 확인
  const onClickSearch = () => {
    setActivePage(1);
    getAsyncUserSearch(1);
    setSelectedFilterItem('전체');
  };

  const getAsyncUserSearch = async (page: number) => {
    if (!searchValue.trim()) {
      getAsyncReportList(1, selectedFilterItem);
      setIsSearch(false);
    } else {
      setIsSearch(true);
      const data = await getReportUserSearch(
        searchValue.replaceAll('#', '%23'),
        TABLE_LIMIT,
        page
      );

      if (data.ok) {
        setReportList(data.res.item);
        setTotalCount(data.res.totalCount);
      } else {
        if (data.res.status === 315) Logout();
        else {
          alert('잠시 후 다시 시도해주세요.');
          console.error(data.res.error.message);
        }
      }
    }
  };

  const getAsyncReportList = async (page: number, filter: string) => {
    const index = FILTER_LIST.indexOf(filter);

    const data = await getReportList(
      index === 0 ? '' : `reportStatus=${FILTER_LIST_E[index]}`,
      TABLE_LIMIT,
      page
    );

    if (data.ok) {
      // console.log(data.res);
      setReportList(data.res.item);
      setTotalCount(data.res.totalCount);
    } else {
      if (data.res.status === 315) Logout();
      else {
        alert('잠시 후 다시 시도해주세요.');
        console.error(data.res.error.message);
      }
    }
  };

  useEffect(() => {
    getAsyncReportList(1, selectedFilterItem);
  }, []);

  return (
    <MainLayout>
      <SearchBarInput
        state={searchValue}
        setState={setSearchValue}
        onKeyPress={onKeyPressHandler}
        onClick={() => onClickSearch()}
      />
      <S_TableButtonContainer>
        <Dropdown
          list={FILTER_LIST}
          selectedItem={selectedFilterItem}
          isOpen={isFilterDropdownOpen}
          setIsOpen={setIsFilterDropdownOpen}
          onClick={(item: string) => onFilterItemClick(item)}
        />
      </S_TableButtonContainer>
      <S_TableContainer>
        <S_TableBox>
          <S_Table>
            <thead>
              <tr>
                {TABLE_HEADER.map((v, i) => (
                  <S_TableHeaderItem key={i}>{v}</S_TableHeaderItem>
                ))}
              </tr>
            </thead>
            <tbody>
              {reportList.map((v: I_Report, i: number) => (
                <ReportTableItem key={i} item={v} />
              ))}
            </tbody>
          </S_Table>
        </S_TableBox>

        <PagenationButton
          activePage={activePage}
          totalCount={totalCount}
          postPerPage={TABLE_LIMIT}
          handlePageChange={handlePageChange}
        />
      </S_TableContainer>
    </MainLayout>
  );
}

export default Report;

const S_TableButtonContainer = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: flex-end;
`;

const S_TableHeaderItem = styled.th`
  vertical-align: middle;
  padding: 10px;
  height: 36px;
  background-color: ${(props) => props.theme.colors.hd4};
  border: 0.5px solid ${(props) => props.theme.colors.hd3};
  font-weight: 500;
  font-size: 13px;
  letter-spacing: -0.02em;
  color: ${(props) => props.theme.colors.n10};
`;

const S_Table = styled.table`
  width: 100%;
`;

const S_TableBox = styled.div`
  overflow-x: scroll;
  &::-webkit-scrollbar {
    width: 4px;
    height: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.theme.colors.hd3};
    border-radius: 6px;
  }
`;

const S_TableContainer = styled.div`
  margin-top: 8px;
  position: relative;
`;

const TABLE_HEADER = [
  '신고 날짜',
  '닉네임',
  'User Code',
  '신고 유형',
  '신고 누적 수',
  '신고 사유',
  '처리 상태',
];

const FILTER_LIST = ['전체', '처리 필요', '처리 완료'];
const FILTER_LIST_E = ['', 'NECESSARY', 'PROCESSED'];
