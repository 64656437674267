import styled from 'styled-components';

interface I_TableButton {
  children: React.ReactNode;
  onClick: () => void;
  isDelete?: boolean;
  style?: React.CSSProperties;
  [key: string]: any;
}

function TableButton({
  children,
  onClick,
  isDelete,
  style,
  ...rest
}: I_TableButton) {
  return (
    <S_Container onClick={onClick} style={style} isDelete={isDelete} {...rest}>
      {children}
    </S_Container>
  );
}

export default TableButton;

const S_Container = styled.button<{ isDelete?: boolean }>`
  width: 111px;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) =>
    props.isDelete ? props.theme.colors.ho6 : props.theme.colors.ho1};
  border-radius: 8px;
  font-weight: 700;
  font-size: 13px;
  letter-spacing: -0.02em;
  color: ${(props) => props.theme.colors.hd5};
  &:hover {
    opacity: 0.5;
  }
`;
