import { useEffect, useState } from 'react';
import styled from 'styled-components';

import MainLayout from 'components/Layout/MainLayout';
import SearchBarInput from 'components/Input/SearchBarInput';
import PagenationButton from 'components/Button/PagenationButton';
import UserTableItem from 'components/Item/UserTableItem';
import PetstivalRankingTableItem from 'components/Item/PetstivalRankingTableItem';
import Dropdown from 'components/Dropdown/Dropdown';

import { I_PetstivalRanking } from 'etc/types';
import { getUserList, getUserNicknameSearch } from 'api/user';
import { getPetstivalList, getPetstivalDetail, getPetstivalRankingList } from 'api/petstival';
import useLogout from 'hooks/useLogout';

function RankingList() {

  const Logout = useLogout();

  const month = new Date().getMonth()+1;
  const [isUserStatusDropdownOpen, setIsUserStatusDropdownOpen] = useState<boolean>(false);
  const [isActivityTypeDropdownOpen, setIsActivityTypeDropdownOpen] = useState<boolean>(false);
  // const [selectedActivityTypeItem, setSelectedActivityTypeItem] = useState<string>(ACTVITY_TYPE_FILTER_LIST[0]);
  const [selectedActivityTypeItem, setSelectedActivityTypeItem] = useState<string>('월 선택');
  
  const [totalCount, setTotalCount] = useState<number>(0); // 전체 개수
  const [activePage, setActivePage] = useState<number>(1); // 현재 페이지
  const [isMonth, setMonth] = useState<number>(month); // 현재 셀렉트된 월
  const [isList, setList] = useState<I_PetstivalRanking[]>([]);

  const onActivityTypeItemClick = (item: string) => {
    // console.log('onActivityTypeItemClick  item :' ,item);
    setSelectedActivityTypeItem(item);
    setIsActivityTypeDropdownOpen(false);
    setActivePage(1);

    if(item){
      setMonth(Number(item.replace('월', '')));
    }
  };

  const handlePageChange = (page: number) => {
    setActivePage(page);
  };


  const getAsyncList = async () => {
    
    const data = await getPetstivalRankingList(
      10,
      activePage,
      isMonth
    );

    console.log('isMonth :' , isMonth, 'selectedActivityTypeItem : ', selectedActivityTypeItem)
    // console.log('data : ', data.res);
    if (data.ok) {
      setList(data.res.list);
      setTotalCount(data.res.totalCount);
    } else {
      if (data.res.status === 315) Logout();
      else {
        alert('잠시 후 다시 시도해주세요.');
        console.error(data.res.error.message);
      }
    }
  };

  useEffect(() => {
    getAsyncList();
  }, [activePage, isMonth]);

  return (
    <MainLayout>
  
      <S_TableButtonContainer>
        <S_TableTitle>전체({totalCount})</S_TableTitle>
        <S_DropdownBox>
          <Dropdown
            list={ACTVITY_TYPE_FILTER_LIST}
            selectedItem={selectedActivityTypeItem}
            isOpen={isActivityTypeDropdownOpen}
            setIsOpen={setIsActivityTypeDropdownOpen}
            onClick={(item: string) => onActivityTypeItemClick(item)}
          />
        </S_DropdownBox>
      </S_TableButtonContainer>
      
      <S_TableContainer>
        <S_TableBox>
          <S_Table>
            <thead>
              <tr>
                {TABLE_HEADER.map((v, i) => (
                  <S_TableHeaderItem key={i}>{v}</S_TableHeaderItem>
                ))}
              </tr>
            </thead>
            <tbody>
              {
                isList.length === 0 &&
                <S_TableRow>
                  <S_TableColData colSpan={TABLE_HEADER.length}>
                    <>조회된 데이터가 없습니다</>
                  </S_TableColData>
                </S_TableRow>
              }
              {
                isList.length !== 0 &&
                isList.map((v: I_PetstivalRanking, i: number) => (
                  <PetstivalRankingTableItem key={i} item={v}  />
                ))
              }
            </tbody>
          </S_Table>
        </S_TableBox>

        
      </S_TableContainer>
    </MainLayout>
  );
}

export default RankingList;

const S_TableTitle = styled.span`
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.02em;
  display: flex;
  align-items: flex-end;
`;

const S_DropdownBox = styled.div`
  display: flex;
  gap: 16px;
`;

const S_TableButtonContainer = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
`;

const S_TableHeaderItem = styled.th`
  vertical-align: middle;
  padding: 10px;
  height: 36px;
  background-color: ${(props) => props.theme.colors.hd4};
  border: 0.5px solid ${(props) => props.theme.colors.hd3};
  font-weight: 500;
  font-size: 13px;
  letter-spacing: -0.02em;
  color: ${(props) => props.theme.colors.n10};
`;

const S_Table = styled.table`
  width: 100%;
`;

const S_TableBox = styled.div`
  overflow-x: scroll;
  &::-webkit-scrollbar {
    width: 4px;
    height: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.theme.colors.hd3};
    border-radius: 6px;
  }
`;

const S_TableContainer = styled.div`
  margin-top: 8px;
  position: relative;
`;

const TABLE_HEADER = [
  '펫스티벌id',
  '랭킹',
  '좋아요수',
  '제목',
  '내용',
  '회원id',
  '본인인증',
  // '회원이름',
  // '회원생일',
  // '핸드폰번호',
  '반려견 이름(번호)',
  '반려견 생일',
  '이미지',
];


const ACTVITY_TYPE_FILTER_LIST = ['5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월' ];

const S_TableRow = styled.tr`
  height: 36px;
  font-weight: 500;
  font-size: 13px;
`;


const S_TableColData = styled.td`
  text-align: center;
  vertical-align: middle;
  border: 1px solid ${(props) => props.theme.colors.hd3};
  padding: 10px;
`;