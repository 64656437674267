import { callApi } from './callApi';

// 돌보미 신청 관리
export const getCaringList = async (
  limit: number,
  currentPage: number,
  status: string
) => {
  const res = await callApi({
    url: `/admin/caring/list?caringStatus=${status}&limit=${limit}&currentPage=${currentPage}`,
    method: 'GET',
  });
  return res;
};

// 돌보미 신청 내역
export const getCaringDetail = async (userId: number) => {
  const res = await callApi({
    url: `/admin/caring/detail/${userId}`,
    method: 'GET',
  });
  return res;
};

// 돌보미 프로필 활동 승인
export const postCaringAllow = async (id: number) => {
  const res = await callApi({
    url: `/admin/caring/allow`,
    method: 'POST',
    data: { caringProfileId: id },
  });
  return res;
};

// 돌보미 프로필 승인 거부
export const postCaringReject = async (
  caringProfileId: number,
  reason: string
) => {
  const res = await callApi({
    url: `/admin/caring/reject`,
    method: 'POST',
    data: { caringProfileId: caringProfileId, reason: reason },
  });
  return res;
};

// 돌보미 유저 정보
export const getCaringUser = async (
  limit: number,
  currentPage: number,
  filter: string
) => {
  const res = await callApi({
    url: `/admin/caring/userData?filter=${filter}&limit=${limit}&currentPage=${currentPage}`,
    method: 'GET',
  });
  return res;
};

// 돌보미 유저 정보 검색
export const getCaringSearch = async (userCode: string) => {
  const res = await callApi({
    url: `/admin/caring/search/userData?userCode=${userCode}`,
    method: 'GET',
  });
  return res;
};

// 돌보미 유저 정보 상세
export const getCaringDetail1 = async (userCode: string) => {
  const res = await callApi({
    url: `/admin/caring/search/userData?userCode=${userCode}`,
    method: 'GET',
  });
  return res;
};

// 돌보미 유저 정보 상세 - 후기
export const getCaringReview = async (
  limit: number,
  currentPage: number,
  caringProfileId: number
) => {
  const res = await callApi({
    url: `/admin/caring/review/detail/${caringProfileId}?currentPage=${currentPage}&limit=${limit}`,
    method: 'GET',
  });
  return res;
};

// 돌보미 프로필 활동 제재
export const postCaringBen = async (caringProfileId: number) => {
  const res = await callApi({
    url: `/admin/caring/ben/${caringProfileId}`,
    method: 'POST',
    data: { caringProfileId: caringProfileId },
  });
  return res;
};
