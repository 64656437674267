import Pagination from 'react-js-pagination';

import styled from 'styled-components';

import { ReactComponent as Left } from 'assets/image/expand-left.svg';
import { ReactComponent as Right } from 'assets/image/expand-right.svg';

interface I_PagenationButton {
  activePage: number;
  totalCount: number;
  postPerPage: number;
  handlePageChange: any;
  pageRangeDisplayed?: number;
  totalPage?: number;
  type?: string;
  currentPage?: number;
  style?: React.CSSProperties;
}

function PagenationButton({
  activePage,
  totalCount = 0,
  postPerPage,
  pageRangeDisplayed,
  handlePageChange,
  totalPage,
  type,
  currentPage,
  style,
}: I_PagenationButton) {
  return (
    <S_Pagination style={style}>
      <Pagination
        activePage={activePage} // 현재 페이지
        itemsCountPerPage={postPerPage} // 한 페이지에 출력할 아이템수
        totalItemsCount={totalCount} // 총 아이템수
        pageRangeDisplayed={pageRangeDisplayed} // 표시할 페이지수
        hideFirstLastPages={true} // 처음, 마지막 버튼 숨김
        onChange={handlePageChange}
        prevPageText={<Left />}
        nextPageText={<Right />}
      />
    </S_Pagination>
  );
}

export default PagenationButton;

const S_Pagination = styled.div`
  margin: 62px auto 0;
  .pagination {
    display: flex;
    justify-content: center;
    gap: 8px;
    li {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 24px;
      height: 24px;
      cursor: pointer;
      a {
        font-weight: 400;
        font-size: 14px;
        color: ${(props) => props.theme.colors.hd1};
      }
      &.active {
        border: 2px solid ${(props) => props.theme.colors.ho1};
        border-radius: 8px;
        a {
          font-weight: 500;
          color: ${(props) => props.theme.colors.ho1};
        }
      }
      &:first-child.disabled,
      &:last-child.disabled {
        a {
          color: ${(props) => props.theme.colors.hd2};
        }
      }
    }
  }
`;
