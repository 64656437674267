import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import MainLayout from 'components/Layout/MainLayout';
import LongButton from 'components/Button/LongButton';
import DisabledInput from 'components/Input/DisabledInput';
import DisabledTextarea from 'components/Input/DisabledTextarea';
import Textarea from 'components/Input/Textarea';
import NormalModal from 'components/Modal/NormalModal';
import NormalButton from 'components/Button/NormalButton';

import { getCaringDetail, postCaringAllow, postCaringReject } from 'api/caring';
import { I_CareApplyDetail } from 'etc/types';
import { CARE_TYPE, CATEGORY_MAIN } from 'etc/variable';
import useLogout from 'hooks/useLogout';

function CareUserApplyDetail() {
  const Logout = useLogout();
  const navigate = useNavigate();
  const { id } = useParams();

  const [careApply, setCareApply] = useState<I_CareApplyDetail>({
    activitieCount: 0,
    address: '',
    addressDetail: '',
    career: 0,
    caringStatus: '',
    id: 0,
    images: [],
    introduction: '',
    nickname: '',
    options: [],
    rating: 0,
    reportCount: 0,
    reviewCount: 0,
    times: { id: 0, start: '', end: '' },
    user: { id: 0, userCode: '', pets: [] },
    weeks: [],
  });
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [rejectContent, setRejectContent] = useState<string>('');
  const [activeTime, setActiveTime] = useState<string[]>([]);
  const [isImageModal, setIsImageModal] = useState<boolean>(false);
  const [imageUrl, setImageUrl] = useState<string>('');

  const onClickImage = (url: string) => {
    setIsImageModal(true);
    setImageUrl(url);
  };

  const onClickReject = async () => {
    if (!rejectContent.trim()) {
      alert('승인 거부 사유를 입력해주세요.');
    } else {
      const data = await postCaringReject(careApply.id, rejectContent);
      if (data.ok) {
        navigate('/user/care/apply');
      } else {
        if (data.res.status === 315) Logout();
        else {
          alert('잠시 후 다시 시도해주세요.');
          console.error(data.res.error.message);
        }
      }
    }
  };

  const onClickAllow = async () => {
    const data = await postCaringAllow(careApply.id);
    if (data.ok) {
      alert('승인이 완료되었습니다.');
      navigate('/user/care/apply');
    } else {
      if (data.res.status === 315) Logout();
      else {
        alert('잠시 후 다시 시도해주세요.');
        console.error(data.res.error.message);
      }
    }
  };

  const getAsyncUserApplyDetail = async () => {
    const data = await getCaringDetail(Number(id));
    const times: string[] = [];

    if (data.ok) {
      setCareApply(data.res.item);

      data.res.item.times.forEach((el: { start: string; end: string }) => {
        if (el.start === '24:00:00') {
          times.push('새벽');
        } else if (el.start === '08:00:00') {
          times.push('오전');
        } else if (el.start === '12:00:00') {
          times.push('오후');
        } else {
          times.push('저녁');
        }
      });
      setActiveTime(times);
    } else {
      if (data.res.status === 315) Logout();
      else {
        alert('잠시 후 다시 시도해주세요.');
        console.error(data.res.error.message);
      }
    }
  };

  useEffect(() => {
    getAsyncUserApplyDetail();
  }, []);

  return (
    <MainLayout>
      <S_Section>
        <S_Title>신청자 정보</S_Title>
        <S_Table>
          <tbody>
            <tr>
              <th>돌보미 닉네임</th>
              <td>
                <DisabledInput text={careApply.nickname} />
              </td>
              <th>User Code</th>
              <td
                style={{ cursor: 'pointer', textDecoration: 'underline' }}
                onClick={() => navigate(`/user/${careApply.user.id}`)}
              >
                <DisabledInput text={careApply.user.userCode} />
              </td>
            </tr>
            <tr>
              <th>집사 경력</th>
              <td>
                <DisabledInput text={careApply.career} />
              </td>
              <th>올펫 등록 반려동물</th>
              <td>
                <DisabledInput text={careApply.user.pets.length} />
              </td>
            </tr>
            <tr>
              <th>케어 주소</th>
              <td colSpan={3}>
                <DisabledInput
                  text={careApply.address + ' ' + careApply.addressDetail}
                />
              </td>
            </tr>
            <tr>
              <th className='alignTop'>소개글</th>
              <td colSpan={3}>
                <DisabledTextarea
                  text={careApply.introduction}
                  style={{ height: 86 }}
                />
              </td>
            </tr>
          </tbody>
        </S_Table>
      </S_Section>

      <S_Section>
        <S_Title>신청 내용</S_Title>
        <S_Table>
          <tbody>
            <tr>
              <th>케어 동물 종</th>
              <td>
                <DisabledInput
                  text={careApply.options
                    .filter((el) => CATEGORY_MAIN.includes(el))
                    .join('/')}
                />
              </td>
              <th>케어 가능 방식</th>
              <td>
                <DisabledInput
                  text={careApply.options
                    .filter((el) => CARE_TYPE.includes(el))
                    .join('/')}
                />
              </td>
            </tr>
            <tr>
              <th>케어 활동 시간</th>
              <td>
                <DisabledInput text={careApply.weeks.join('/')} />
              </td>
              <td colSpan={2}>
                <DisabledInput text={activeTime.join('/')} />
              </td>
            </tr>
            <tr>
              <th className='alignTop'>케어 환경 사진</th>
              <td colSpan={3}>
                <S_CareImgBox>
                  {careApply.images?.map((v, i) => (
                    <S_CareImg
                      src={v.url}
                      key={i}
                      onClick={() => onClickImage(v.url)}
                    />
                  ))}
                </S_CareImgBox>
              </td>
            </tr>
          </tbody>
        </S_Table>
        {careApply.caringStatus !== 'Active' && (
          <S_ButtonBox>
            <LongButton
              onClick={() => setIsModalOpen(true)}
              style={{ backgroundColor: '#B6B6B6' }}
            >
              승인 거부
            </LongButton>
            <LongButton onClick={() => onClickAllow()}>활동 승인</LongButton>
          </S_ButtonBox>
        )}
      </S_Section>

      {isModalOpen && (
        <NormalModal
          state={isModalOpen}
          setState={setIsModalOpen}
          title='승인 거부 사유 작성'
        >
          <S_ModalContent>
            <Textarea
              state={rejectContent}
              setState={setRejectContent}
              style={{ width: 380, height: 150 }}
              placeholder='여기에 입력하세요'
            />
            <S_ModalButtonBox>
              <NormalButton
                onClick={() => onClickReject()}
                style={{ marginTop: 30 }}
              >
                전달하기
              </NormalButton>
            </S_ModalButtonBox>
          </S_ModalContent>
        </NormalModal>
      )}

      {isImageModal && (
        <NormalModal
          state={isImageModal}
          setState={setIsImageModal}
          title='케어 환경 사진'
        >
          <S_ModalContent>
            <S_BigImg src={imageUrl} />
          </S_ModalContent>
        </NormalModal>
      )}
    </MainLayout>
  );
}
export default CareUserApplyDetail;

const S_BigImg = styled.img`
  max-width: 1000px;
  max-height: 500px;
`;

const S_ModalButtonBox = styled.div`
  display: flex;
  justify-content: center;
`;

const S_ModalContent = styled.div`
  padding: 30px 50px 40px;
`;

const S_CareImg = styled.img`
  width: 279px;
  height: 279px;
  border-radius: 16px;
  object-fit: contain;
  border: 1px solid ${(props) => props.theme.colors.hd3};
  cursor: pointer;
`;

const S_CareImgBox = styled.div`
  display: grid;
  gap: 16px;
  grid-template-columns: 1fr 1fr 1fr;
  justify-items: start;
`;

const S_Table = styled.table`
  border-collapse: separate;
  border-spacing: 20px;

  th {
    width: 120px;
    text-align: left;
    font-size: 14px;
    font-weight: 500;
    &:nth-child(1) {
      min-width: 85px;
      width: 85px;
    }
    &:nth-child(3) {
      min-width: 120px;
      padding-left: 10px;
    }
    &.alignTop {
      vertical-align: top;
      padding-top: 14px;
    }
  }
`;

const S_ButtonBox = styled.div`
  display: flex;
  justify-content: center;
  gap: 80px;
  margin-top: 100px;
  margin-left: 105px;
  padding-right: 20px;
`;

const S_Title = styled.p`
  font-size: 16px;
  color: ${(props) => props.theme.colors.hd1};
  font-weight: 700;
`;

const S_Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 17px;
  padding: 30px 20px;

  &:first-child {
    border-bottom: 1px solid ${(props) => props.theme.colors.hd4};
  }

  td {
    min-width: 380px;
  }
`;
