import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { I_CareApply } from 'etc/types';
import { dateFormat } from 'utils/common';

interface I_CareUserManagementTableItem {
  item: I_CareApply;
}

function CareUserManagementTableItem({ item }: I_CareUserManagementTableItem) {
  const navigate = useNavigate();

  return (
    <S_TableRow>
      <S_TableColData>
        {dateFormat(item.createdAt).format('YYYY년 MM월 DD일 HH:mm')}
      </S_TableColData>
      <S_TableColData
        style={{ textDecoration: 'underLine', cursor: 'pointer' }}
        onClick={() => navigate(`/user/care/apply/${item.user.id}`)}
      >
        {item.user.userCode}
      </S_TableColData>
      <S_TableColData>{item.nickname}</S_TableColData>
      <S_TableColData>
        {item.caringStatus === 'Active' ? (
          <span style={{ color: '#13A872' }}>승인 완료</span>
        ) : (
          <span style={{ color: '#FC1D1D' }}>승인 필요</span>
        )}
      </S_TableColData>
    </S_TableRow>
  );
}

export default CareUserManagementTableItem;

const S_TableColData = styled.td`
  text-align: center;
  vertical-align: middle;
  border: 1px solid ${(props) => props.theme.colors.hd3};
  padding: 10px;
`;

const S_TableRow = styled.tr`
  height: 36px;
  font-weight: 500;
  font-size: 13px;
`;
