import { useEffect, useState } from 'react';
import {  I_MarketingUtm } from 'etc/types';

import { useNavigate } from 'react-router-dom';
import { dateFormat } from 'utils/common';

import LongButton from 'components/Button/LongButton';
import MiniButton from 'components/Button/MiniButton';
import CustomButton from 'components/Button/CustomButton';
import NormalButton from 'components/Button/NormalButton';
import PeriodButton from 'components/Button/PeriodButton';
import { getPetstivalList, getPetstivalDetail, patchPetstivalStatus } from 'api/petstival';


import styled from 'styled-components';

type Status = 'Active' | 'Deactive' ;
interface I_MarketingUtmTableItem {
  item: I_MarketingUtm;
}

function MarketingUtmTableItem({ item }: I_MarketingUtmTableItem) {
  const navigate = useNavigate();

  
  return (
    <S_TableRow>
      <S_TableColData
        // style={{ textDecoration: 'underline', cursor: 'pointer' }}
        // onClick={() => navigate(`/user/${item.id}`)}
      >
        {item.id}
      </S_TableColData>
      <S_TableColData>{item.randomString}</S_TableColData>
      <S_TableColData>{item.name}</S_TableColData>
      <S_TableColData>{item.source}</S_TableColData>
      <S_TableColData>{item.medium}</S_TableColData>
      <S_TableColData>{item.campaign}</S_TableColData>
      <S_TableColData>{item.content}</S_TableColData>
      <S_TableColData>{item.term}</S_TableColData>
      <S_TableColData>{item.redirectUrl}</S_TableColData>
    </S_TableRow>
  );
}

export default MarketingUtmTableItem;


const S_Button = styled.button`
  /* background-color: skyblue; */
  width: 100%;
  display: flex;
  margin-left: '5px';
  justify-content: space-evenly;
`;


const S_TableColData = styled.td`
  text-align: center;
  vertical-align: middle;
  border: 1px solid ${(props) => props.theme.colors.hd3};
  padding: 10px;

  &:nth-child(1) {
    min-width: 120px;
  }
  &:nth-child(2) {
    min-width: 120px;
  }
  &:nth-child(3) {
    min-width: 230px;
  }
  &:nth-child(4) {
    min-width: 80px;
  }
  &:nth-child(5) {
    min-width: 80px;
  }
  &:nth-child(6) {
    min-width: 20px;
  }
  &:nth-child(7) {
    min-width: 100px;
  }
  &:nth-child(8) {
    min-width: 100px;
  }
  &:nth-child(9) {
    min-width: 120px;
  }
  &:nth-child(10) {
    min-width: 80px;
  }
`;

const S_TableRow = styled.tr`
  height: 36px;
  font-weight: 500;
  font-size: 13px;
`;


