import { I_User } from 'etc/types';
import { useNavigate } from 'react-router-dom';

import styled from 'styled-components';

interface I_UserTableItem {
  item: I_User;
}

function UserTableItem({ item }: I_UserTableItem) {
  const navigate = useNavigate();

  return (
    <S_TableRow>
      <S_TableColData
        style={{ textDecoration: 'underline', cursor: 'pointer' }}
        onClick={() => navigate(`/user/${item.id}`)}
      >
        {item.userCode}
      </S_TableColData>
      <S_TableColData>{item.profile?.nickname}</S_TableColData>
      <S_TableColData>
        {item.user_address?.address} {item.user_address?.addressDetail}
      </S_TableColData>
      <S_TableColData>{item.paid ? '유료회원' : '무료회원'}</S_TableColData>
      <S_TableColData>
        {item.provider === 'Local'
          ? '이메일'
          : item.provider === 'Naver'
          ? '네이버'
          : item.provider === 'Kakao'
          ? '카카오'
          : item.provider === 'Google'
          ? '구글'
          : '애플'}
      </S_TableColData>
      <S_TableColData>{item?.email}</S_TableColData>
      <S_TableColData>{item?.name}</S_TableColData>
      <S_TableColData>{item?.birth}</S_TableColData>
      <S_TableColData>{item?.phone}</S_TableColData>
      <S_TableColData
        style={{ color: item.userStatus === 'Active' ? '#13A872' : '#FC1D1D' }}
      >
        {item.userStatus === 'Active'
          ? '활동'
          : item.userStatus === 'Restriction'
          ? '제재'
          : item.userStatus === 'Withdrawal'
          ? '탈퇴'
          : '정지'}
      </S_TableColData>
    </S_TableRow>
  );
}

export default UserTableItem;

const S_TableColData = styled.td`
  text-align: center;
  vertical-align: middle;
  border: 1px solid ${(props) => props.theme.colors.hd3};
  padding: 10px;

  &:nth-child(1) {
    min-width: 120px;
  }
  &:nth-child(2) {
    min-width: 120px;
  }
  &:nth-child(3) {
    min-width: 230px;
  }
  &:nth-child(4) {
    min-width: 80px;
  }
  &:nth-child(5) {
    min-width: 80px;
  }
  &:nth-child(6) {
    min-width: 20px;
  }
  &:nth-child(7) {
    min-width: 100px;
  }
  &:nth-child(8) {
    min-width: 100px;
  }
  &:nth-child(9) {
    min-width: 120px;
  }
  &:nth-child(10) {
    min-width: 80px;
  }
`;

const S_TableRow = styled.tr`
  height: 36px;
  font-weight: 500;
  font-size: 13px;
`;
