import { useEffect, useState } from 'react';

import styled from 'styled-components';

import { ReactComponent as Checkbox } from 'assets/image/checkbox.svg';

import MainLayout from 'components/Layout/MainLayout';
import PagenationButton from 'components/Button/PagenationButton';
import TabButton from 'components/Button/TabButton';
import TableButton from 'components/Button/TableButton';
import ExchangeRequest from 'components/Item/StoreExchangeTable/ExchangeRequest';
import ExchangePreparingForDelivery from 'components/Item/StoreExchangeTable/ExchangePreparingForDelivery';
import ExchangeShipping from 'components/Item/StoreExchangeTable/ExchangeShipping';
import ExchangeRefusal from 'components/Item/StoreExchangeTable/ExchangeRefusal';
import ExchangeDelay from 'components/Item/StoreExchangeTable/ExchangeDelay';
import { I_Exchange } from 'etc/types';
import RefundReasonModal from 'components/Modal/RefundReasonModal';
import ExchangeCollect from 'components/Item/StoreExchangeTable/ExchangeCollect';
import useLogout from 'hooks/useLogout';
import ReasonModal from 'components/Modal/ReasonModal';

import {
  getExchangeList,
  patchExchangeComplete,
  patchRefundStatus,
  patchRequestConfirm,
  postConvertReturn,
  postExchangeTracking,
} from 'api/order';

function StoreExchange() {
  const Logout = useLogout();
  const [exchangeList, setExchangeList] = useState<I_Exchange[]>([]); // 교환
  const [exchange, setExchange] = useState<I_Exchange>(); // 교환
  const [totalCount, setTotalCount] = useState<number>(0); // 전체 개수
  const [activePage, setActivePage] = useState<number>(1); // 현재 페이지
  const [tabIndex, setTabIndex] = useState<number>(TAB[0].id); // tab
  const [tableColumnList, setTableColumnList] = useState<string[]>(
    TABLE_HEADER[0].list
  );
  const [isReasonModalOpen, setIsReasonModalOpen] = useState<boolean>(false); // 요청 내역 모달
  const [isRefundReasonModalOpen, setIsRefundReasonModalOpen] =
    useState<boolean>(false); // 요청 내역 모달
  const [reasonStatus, setReasonStatus] = useState<string>('교환 거부'); //
  const [checkItem, setCheckItem] = useState<number>(0); // refundLogProductId
  const [checkRefundLogId, setCheckRefundLogId] = useState<number>(0);
  const [courier, setCourier] = useState<string>('선택 필요'); // 택배사
  const [trackingInput, setTrackingInput] = useState<string>(''); // 운송장 번호
  const [changeToggle, setChangeToggle] = useState<boolean>(false);

  // tab button 클릭 시
  const onTabClick = (id: number, idx: number) => {
    setTabIndex(id);
    setTableColumnList(TABLE_HEADER[idx].list);
  };

  // table pagenation
  const handlePageChange = (page: number) => {
    setActivePage(page);
  };

  const handleReasonModal = (status: string) => {
    if (checkItem === 0) {
      alert('처리할 항목을 선택하세요.');
      return;
    }
    setReasonStatus(status);
    setIsReasonModalOpen(true);
  };

  // 배송 중 처리
  const postAsyncDeliveryTracking = async () => {
    if (checkItem === 0) {
      alert('처리할 주문을 선택하세요.');
      return;
    }
    if (courier === '선택 필요') {
      alert('택배사를 선택하세요.');
      return;
    }
    if (trackingInput === '') {
      alert('운송장 번호를 작성하세요.');
      return;
    }
    if (!/^\d*$/.test(trackingInput)) {
      alert('운송장 번호는 숫자만 입력해주세요.');
      return;
    }

    const data = await postExchangeTracking(checkItem, courier, trackingInput);
    if (data.ok) {
      alert('배송중 처리가 완료되었습니다. 해당 주문은 배송중으로 이동합니다.');
      setChangeToggle((pre) => !pre);
      getAsyncExchangeList();
    } else {
      if (data.res.status === 315) Logout();
      else {
        alert('잠시 후 다시 시도해주세요.');
        console.error(data.res.error.message);
      }
    }
  };

  // 교환 요청 확인
  const patchAsyncRequestConfirm = async () => {
    const data = await patchRequestConfirm(checkRefundLogId);
    if (data.ok) {
      alert('교환 요청이 확인되어 교환 수거중으로 이동합니다.');
      getAsyncExchangeList();
    } else {
      if (data.res.status === 315) Logout();
      else console.error(data.res.error.message);
    }
  };

  // 수거 완료, 교환 준비
  const patchAsyncRefundStatus = async (status: number) => {
    const data = await patchRefundStatus(checkItem, status);
    if (data.ok) {
      if (status === 22) {
        alert(
          '교환 수거 완료 처리가 완료되었습니다. 해당 주문은 수거 완료로 이동합니다.'
        );
      } else if (status === 23) {
        alert(
          '교환 준비 중 처리가 완료되었습니다. 해당 주문은 교환 준비 중으로 이동합니다.'
        );
      } else {
        alert('처리가 완료되었습니다.');
      }
      getAsyncExchangeList();
    } else {
      if (data.res.status === 315) Logout();
      else console.error(data.res.error.message);
    }
  };

  // 교환 완료
  const patchAsyncExchangeComplete = async () => {
    const data = await patchExchangeComplete(checkItem);
    if (data.ok) {
      alert(
        '교환 완료 처리가 완료되었습니다. 해당 주문은 교환 완료로 이동합니다.'
      );
      getAsyncExchangeList();
    } else {
      if (data.res.status === 315) Logout();
      else console.error(data.res.error.message);
    }
  };

  // 반품 전환
  const postAsyncConvertReturn = async () => {
    if (checkItem === 0) {
      alert('처리할 항목을 선택하세요.');
      return;
    }

    let status = 0;
    if (tabIndex === 20) {
      status = 30;
    } else if (tabIndex === 21) {
      status = 31;
    } else if (tabIndex === 22) {
      status = 32;
    } else {
      status = 37;
    }
    const data = await postConvertReturn(checkItem, status, status);
    if (data.ok) {
      if (tabIndex === 20) {
        alert(
          '반품 전환 처리가 완료되었습니다. 해당 항목은 반품 요청으로 이동합니다.'
        );
      } else if (tabIndex === 21) {
        alert(
          '반품 전환 처리가 완료되었습니다. 해당 항목은 반품 수거 중으로 이동합니다.'
        );
      } else if (tabIndex === 22) {
        alert(
          '반품 전환 처리가 완료되었습니다. 해당 항목은 반품 수거 완료로 이동합니다.'
        );
      } else {
        alert(
          '반품 전환 처리가 완료되었습니다. 해당 항목은 반품 지연으로 이동합니다.'
        );
      }
      getAsyncExchangeList();
    } else {
      if (data.res.status === 315) Logout();
      else console.error(data.res.error.message);
    }
  };

  // 교환 목록
  const getAsyncExchangeList = async () => {
    const data = await getExchangeList(activePage, 10, tabIndex);
    if (data.ok) {
      setTotalCount(data.res.totalCount);
      setExchangeList(data.res.item);
      setCheckItem(0);
      setCheckRefundLogId(0);
      setCourier('선택 필요');
      setTrackingInput('');
    } else {
      if (data.res.status === 315) Logout();
      else if (data.res.status === 400)
        alert('번호나 코드는 숫자만 입력해주세요.');
      else console.error(data.res.error.message);
    }
  };

  useEffect(() => {
    if (exchangeList) {
      const temp = exchangeList.find(
        (item) => item.refundLogProductId === checkItem
      );
      if (temp) setExchange(temp);
    }
  }, [checkItem]);

  useEffect(() => {
    getAsyncExchangeList();
  }, [tabIndex, activePage]);

  return (
    <MainLayout>
      {/* 카테고리 */}
      <S_CategoryContainer>
        <S_CategoryTitle>주문 별 현황</S_CategoryTitle>
        <S_CategoryFilterContainer>
          {TAB.map((v, i) => (
            <TabButton
              key={v.id}
              title={v.title}
              isActive={tabIndex === v.id}
              onClick={() => onTabClick(v.id, i)}
            />
          ))}
        </S_CategoryFilterContainer>
      </S_CategoryContainer>

      {/* 상품 목록 */}
      <S_TableButtonContainer>
        <S_TableTitle>상품 목록({totalCount})</S_TableTitle>
        <S_TableButtonWrapper>
          {tabIndex === 20 && (
            <TableButton onClick={patchAsyncRequestConfirm}>
              요청 확인
            </TableButton>
          )}
          {tabIndex === 21 && (
            <TableButton onClick={() => patchAsyncRefundStatus(22)}>
              수거 완료 처리
            </TableButton>
          )}
          {(tabIndex === 22 || tabIndex === 27) && (
            <TableButton onClick={() => patchAsyncRefundStatus(23)}>
              교환 준비 중 처리
            </TableButton>
          )}
          {tabIndex === 23 && (
            <TableButton onClick={postAsyncDeliveryTracking}>
              교환 배송 중 처리
            </TableButton>
          )}
          {tabIndex === 24 && (
            <TableButton onClick={patchAsyncExchangeComplete}>
              교환 완료 처리
            </TableButton>
          )}
          {(tabIndex === 20 || tabIndex === 21 || tabIndex === 22) && (
            <TableButton onClick={() => handleReasonModal('교환 거부')}>
              교환 거부
            </TableButton>
          )}
          {tabIndex === 23 && (
            <TableButton onClick={() => handleReasonModal('교환 지연')}>
              교환 지연
            </TableButton>
          )}
          {(tabIndex === 20 ||
            tabIndex === 21 ||
            tabIndex === 22 ||
            tabIndex === 27) && (
            <TableButton onClick={postAsyncConvertReturn}>
              반품 전환
            </TableButton>
          )}
        </S_TableButtonWrapper>
      </S_TableButtonContainer>

      <S_TableContainer>
        <S_TableBox isVisible={tabIndex === 23 ? true : false}>
          <S_Table>
            <thead>
              <tr>
                {tableColumnList.map((v, i) => (
                  <S_TableHeaderItem key={i}>
                    {v === 'check' ? (
                      <Checkbox
                        width='16.67px'
                        height='16.67px'
                        color='#2E2E2E'
                      />
                    ) : (
                      v
                    )}
                  </S_TableHeaderItem>
                ))}
              </tr>
            </thead>
            <tbody>
              {exchangeList?.map((v: I_Exchange, i: number) =>
                tabIndex === 20 ? (
                  <ExchangeRequest
                    key={i}
                    item={v}
                    checkItem={checkItem}
                    setCheckItem={setCheckItem}
                    setCheckRefundLogId={setCheckRefundLogId}
                  />
                ) : tabIndex === 21 ? (
                  <ExchangeCollect
                    key={i}
                    item={v}
                    checkItem={checkItem}
                    setCheckItem={setCheckItem}
                    setCheckRefundLogId={setCheckRefundLogId}
                  />
                ) : tabIndex === 22 ? (
                  <ExchangeCollect
                    key={i}
                    item={v}
                    checkItem={checkItem}
                    setCheckItem={setCheckItem}
                    setCheckRefundLogId={setCheckRefundLogId}
                  />
                ) : tabIndex === 23 ? (
                  <ExchangePreparingForDelivery
                    key={i}
                    item={v}
                    checkItem={checkItem}
                    setCheckItem={setCheckItem}
                    setCheckRefundLogId={setCheckRefundLogId}
                    setCourier={setCourier}
                    setTrackingInput={setTrackingInput}
                    changeToggle={changeToggle}
                  />
                ) : tabIndex === 24 ? (
                  <ExchangeShipping
                    key={i}
                    item={v}
                    checkItem={checkItem}
                    setCheckItem={setCheckItem}
                    setCheckRefundLogId={setCheckRefundLogId}
                    isCheck={true}
                  />
                ) : tabIndex === 25 ? (
                  <ExchangeShipping
                    key={i}
                    item={v}
                    checkItem={checkItem}
                    setCheckItem={setCheckItem}
                    setCheckRefundLogId={setCheckRefundLogId}
                  />
                ) : tabIndex === 26 ? (
                  <ExchangeRefusal key={i} item={v} />
                ) : (
                  <ExchangeDelay
                    key={i}
                    item={v}
                    checkItem={checkItem}
                    setCheckItem={setCheckItem}
                  />
                )
              )}
            </tbody>
          </S_Table>
        </S_TableBox>

        <PagenationButton
          activePage={activePage}
          totalCount={totalCount}
          postPerPage={10}
          handlePageChange={handlePageChange}
        />
      </S_TableContainer>

      <ReasonModal
        isOpen={isReasonModalOpen}
        onClose={() => setIsReasonModalOpen(false)}
        onSuccess={getAsyncExchangeList}
        status={reasonStatus}
        isDone={false}
        product={{
          refundLogProductId: exchange?.refundLogProductId,
          brandName: exchange?.brandName,
          productName: exchange?.productName,
          productOptionName: exchange?.productOptionName,
          productCount: exchange?.productCount,
          delayReason: exchange?.processReason,
        }}
      />

      <RefundReasonModal
        isOpen={isRefundReasonModalOpen}
        onClose={() => setIsRefundReasonModalOpen(false)}
        refundLogId={checkRefundLogId}
      />
    </MainLayout>
  );
}

export default StoreExchange;

const S_TableHeaderItem = styled.th`
  vertical-align: middle;
  padding: 10px;
  height: 36px;
  background-color: ${(props) => props.theme.colors.hd4};
  border: 0.5px solid ${(props) => props.theme.colors.hd3};
  font-weight: 500;
  font-size: 13px;
  letter-spacing: -0.02em;
  color: ${(props) => props.theme.colors.n10};
`;

const S_Table = styled.table`
  width: 100%;
`;

const S_TableBox = styled.div<{ isVisible: boolean }>`
  overflow-x: ${(props) => (props.isVisible ? 'visible' : 'overlay')};
  &::-webkit-scrollbar {
    width: 4px;
    height: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.theme.colors.hd3};
    border-radius: 6px;
  }
`;

const S_TableContainer = styled.div`
  margin-top: 8px;
  position: relative;
`;

const S_TableButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const S_TableTitle = styled.span`
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.02em;
  display: flex;
  align-items: flex-end;
`;

const S_TableButtonContainer = styled.div`
  margin-top: 36px;
  display: flex;
  justify-content: space-between;
`;

const S_CategoryFilterContainer = styled.div`
  min-width: 915px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

const S_CategoryTitle = styled.span`
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.02em;
`;

const S_CategoryContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const TAB = [
  {
    id: 20,
    title: '교환 요청',
  },
  {
    id: 21,
    title: '교환 수거 중',
  },
  {
    id: 22,
    title: '수거 완료',
  },
  {
    id: 23,
    title: '교환 준비 중',
  },
  {
    id: 24,
    title: '교환 배송 중',
  },
  {
    id: 25,
    title: '교환 완료',
  },
  {
    id: 26,
    title: '교환 거부',
  },
  {
    id: 27,
    title: '교환 지연',
  },
];

const TABLE_HEADER = [
  {
    id: 20,
    list: [
      'check',
      '교환 주문 번호',
      '교환 요청 일시',
      '교환 요청 사유',
      '교환 요청 상품',
      '상세 옵션',
      '수량',
      '구매자명',
      '구매자 연락처',
      'User Code',
    ],
  },
  {
    id: 21,
    list: [
      'check',
      '교환 주문 번호',
      '상태 처리 일시',
      '교환 요청 사유',
      '교환 요청 상품',
      '상세 옵션',
      '수량',
      '구매자명',
      '구매자 연락처',
      'User Code',
    ],
  },
  {
    id: 22,
    list: [
      'check',
      '교환 주문 번호',
      '상태 처리 일시',
      '교환 요청 사유',
      '교환 요청 상품',
      '상세 옵션',
      '수량',
      '구매자명',
      '구매자 연락처',
      'User Code',
    ],
  },
  {
    id: 23,
    list: [
      'check',
      '교환 주문 번호',
      '상태 처리 일시',
      '택배사',
      '운송장 번호',
      '교환 요청 사유',
      '교환 요청 상품',
      '상세 옵션',
      '수량',
      '구매자명',
      '구매자 연락처',
      'User Code',
    ],
  },
  {
    id: 24,
    list: [
      'check',
      '교환 주문 번호',
      '상태 처리 일시',
      '택배사',
      '운송장 번호',
      '교환 요청 사유',
      '교환 요청 상품',
      '상세 옵션',
      '수량',
      '교환 요청 일시',
      '구매자명',
      '구매자 연락처',
      'User Code',
    ],
  },
  {
    id: 25,
    list: [
      '교환 주문 번호',
      '상태 처리 일시',
      '택배사',
      '운송장 번호',
      '교환 요청 사유',
      '교환 요청 상품',
      '상세 옵션',
      '수량',
      '교환 요청 일시',
      '구매자명',
      '구매자 연락처',
      'User Code',
    ],
  },
  {
    id: 26,
    list: [
      '교환 주문 번호',
      '상태 처리 일시',
      '처리 사유',
      '상품 정보',
      '상세 옵션',
      '수량',
      '교환 요청 일시',
      '구매자명',
      '구매자 연락처',
      'User Code',
    ],
  },
  {
    id: 27,
    list: [
      'check',
      '교환 주문 번호',
      '상태 처리 일시',
      '택배사',
      '운송장 번호',
      '처리 사유',
      '상품 정보',
      '상세 옵션',
      '수량',
      '교환 요청 일시',
      '구매자명',
      '구매자 연락처',
      'User Code',
    ],
  },
];
