import { useEffect, useState } from 'react';
import styled from 'styled-components';

import MainLayout from 'components/Layout/MainLayout';
import SearchBarInput from 'components/Input/SearchBarInput';
import PagenationButton from 'components/Button/PagenationButton';
import UserTableItem from 'components/Item/UserTableItem';
import PetstivalTableItem from 'components/Item/PetstivalTableItem';
import Dropdown from 'components/Dropdown/Dropdown';

import { I_Petstival } from 'etc/types';
import { getUserList, getUserNicknameSearch } from 'api/user';
import { getPetstivalList, getPetstivalDetail } from 'api/petstival';
import useLogout from 'hooks/useLogout';

function List() {

  const Logout = useLogout();

  const [searchValue, setSearchValue] = useState<string>('');
  const [isUserStatusDropdownOpen, setIsUserStatusDropdownOpen] =
    useState<boolean>(false);
  const [selectedUserStatusItem, setSelectedUserStatusItem] = useState<string>(
    USER_STATUS_FILTER_LIST[0]
  );
  const [isActivityTypeDropdownOpen, setIsActivityTypeDropdownOpen] =
    useState<boolean>(false);
  const [selectedActivityTypeItem, setSelectedActivityTypeItem] =
    useState<string>(ACTVITY_TYPE_FILTER_LIST[0]);
  const [totalCount, setTotalCount] = useState<number>(0); // 전체 개수
  const [activePage, setActivePage] = useState<number>(1); // 현재 페이지
  // const [userList, setList] = useState<I_User[]>([]);
  const [isList, setList] = useState<I_Petstival[]>([]);

  const onUserStatusItemClick = (item: string) => {
    setSelectedUserStatusItem(item);
    setIsUserStatusDropdownOpen(false);
    setActivePage(1);
    setSearchValue('');
  };

  const onActivityTypeItemClick = (item: string) => {
    setSelectedActivityTypeItem(item);
    setIsActivityTypeDropdownOpen(false);
    setActivePage(1);
    setSearchValue('');
  };

  const handlePageChange = (page: number) => {
    setActivePage(page);
  };

  // 검색 엔터 클릭
  const onKeyPressHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      // getAsyncUserSearch();
      // setSelectedActivityTypeItem('전체');
      // setSelectedUserStatusItem('전체');
    }
  };


  const getAsyncList = async () => {
    const statusIndex = USER_STATUS_FILTER_LIST.indexOf(selectedUserStatusItem);
    const data = await getPetstivalList(
      10,
      activePage
    );

    console.log('data : ', data.res);
    if (data.ok) {
      setList(data.res.list);
      setTotalCount(data.res.totalCount);
    } else {
      if (data.res.status === 315) Logout();
      else {
        alert('잠시 후 다시 시도해주세요.');
        console.error(data.res.error.message);
      }
    }
  };

  useEffect(() => {
    // console.log('use Effect ::::::::::::::::: ');
    getAsyncList();
  }, [activePage, selectedUserStatusItem, selectedActivityTypeItem]);


  const handlerUpdateResult = (updatePetstival: I_Petstival) => {

    // list 수정에서 api 재호출로 변경
    // setList(isList =>
    //   isList.map(item => (item.id === updatePetstival.id ? { ...item, ...updatePetstival } : item))
    // );

    getAsyncList();

  };

  return (
    <MainLayout>
      {/* <SearchBarInput
        state={searchValue}
        setState={setSearchValue}
        onKeyPress={onKeyPressHandler}
        onClick={() => onClickSearch()}
      />
      <S_TableButtonContainer>
        <S_TableTitle>전체({totalCount})</S_TableTitle>
        <S_DropdownBox>
          <Dropdown
            list={USER_STATUS_FILTER_LIST}
            selectedItem={selectedUserStatusItem}
            isOpen={isUserStatusDropdownOpen}
            setIsOpen={setIsUserStatusDropdownOpen}
            onClick={(item: string) => onUserStatusItemClick(item)}
          />
          <Dropdown
            list={ACTVITY_TYPE_FILTER_LIST}
            selectedItem={selectedActivityTypeItem}
            isOpen={isActivityTypeDropdownOpen}
            setIsOpen={setIsActivityTypeDropdownOpen}
            onClick={(item: string) => onActivityTypeItemClick(item)}
          />
        </S_DropdownBox>
      </S_TableButtonContainer>
       */}
      <S_TableContainer>
        <S_TableBox>
          <S_Table>
            <thead>
              <tr>
                {TABLE_HEADER.map((v, i) => (
                  <S_TableHeaderItem key={i}>{v}</S_TableHeaderItem>
                ))}
              </tr>
            </thead>
            <tbody>
              {isList.map((v: I_Petstival, i: number) => (
                <PetstivalTableItem key={i} item={v} onChangeData={handlerUpdateResult}  />
              ))}
            </tbody>
          </S_Table>
        </S_TableBox>

        <PagenationButton
          activePage={activePage}
          totalCount={totalCount}
          postPerPage={10}
          handlePageChange={handlePageChange}
        />
      </S_TableContainer>
    </MainLayout>
  );
}

export default List;

const S_TableTitle = styled.span`
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.02em;
  display: flex;
  align-items: flex-end;
`;

const S_DropdownBox = styled.div`
  display: flex;
  gap: 16px;
`;

const S_TableButtonContainer = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
`;

const S_TableHeaderItem = styled.th`
  vertical-align: middle;
  padding: 10px;
  height: 36px;
  background-color: ${(props) => props.theme.colors.hd4};
  border: 0.5px solid ${(props) => props.theme.colors.hd3};
  font-weight: 500;
  font-size: 13px;
  letter-spacing: -0.02em;
  color: ${(props) => props.theme.colors.n10};
`;

const S_Table = styled.table`
  width: 100%;
`;

const S_TableBox = styled.div`
  overflow-x: scroll;
  &::-webkit-scrollbar {
    width: 4px;
    height: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.theme.colors.hd3};
    border-radius: 6px;
  }
`;

const S_TableContainer = styled.div`
  margin-top: 8px;
  position: relative;
`;

const TABLE_HEADER = [
  '펫스티벌id',
  '제목',
  '내용',
  '반려견 이름(번호)',
  '회원id',
  '등록일 ',
  '이미지',
  '노출상태',
  '심사',
  '',
];

const USER_STATUS_FILTER_LIST = ['전체', '활동', '탈퇴', '정지', '제재'];

const USER_STATUS_E_FILTER_LIST = [
  '',
  'Active',
  'Withdrawal',
  'Suspended',
  'Restriction',
];

const ACTVITY_TYPE_FILTER_LIST = ['전체', '무료', '유료'];
