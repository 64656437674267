import { useEffect, useState } from 'react';

import styled from 'styled-components';
import { S_FlexAlignCenter } from 'etc/GlobalCSS';

import { ReactComponent as Checkbox } from 'assets/image/checkbox.svg';
import { ReactComponent as Close } from 'assets/image/close.svg';

import ModalLayout from 'components/Layout/ModalLayout';
import DisabledInput from 'components/Input/DisabledInput';
import Input from 'components/Input/Input';
import MiniButton from 'components/Button/MiniButton';
import LongButton from 'components/Button/LongButton';
import useLogout from 'hooks/useLogout';
import { I_BillLogProduct, I_OrderDetail } from 'etc/types';
import { dateTimeFormat, numberFormat } from 'utils/common';
import StoreDeliveryCancelTableItem from 'components/Item/StoreDeliveryCancelTableItem';

import { getOrderDetail, postCancelAdmin } from 'api/order';

interface I_CancelDeliveryModal {
  onClose: () => void;
  isOpen: boolean;
  checkBillLogId: number;
  getAsyncDeliveryList: () => void;
}

function CancelDeliveryModal({
  onClose,
  isOpen,
  checkBillLogId,
  getAsyncDeliveryList,
}: I_CancelDeliveryModal) {
  const Logout = useLogout();
  const [history, setHistory] = useState<I_OrderDetail>();
  const [checkList, setCheckList] = useState<number[]>([]); // 체크박스 클릭한 항목
  const [totalAmount, setTotalAmount] = useState<number>(0);
  const [exTotAmount, setExTotAmount] = useState<number>(0); // 전체 환불 예정 금액 - 부분 환불 요청
  const [extraShippingCost, setExtraShippingCost] = useState<number>(0);
  const [inputValue, setInputValue] = useState<string>('');

  const handleExtraCost = () => {
    if (!inputValue.trim()) {
      alert('값을 입력해주세요.');
      return;
    }

    if (/^\d*$/.test(inputValue)) {
      const temp = Number(inputValue);
      if (temp <= totalAmount) {
        setExTotAmount(totalAmount - temp);
      } else {
        alert('전체 환불 예정 금액 보다 금액이 큽니다.');
      }
    } else {
      alert('숫자만 입력해주세요.');
    }
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const temp = event.target.value.replace(/[^0-9]/gm, '');
    setInputValue(temp);
    if (!temp) {
      setExTotAmount(0);
    }
  };

  const postAsyncCancel = async () => {
    if (exTotAmount === 0 && inputValue) {
      alert('부분 환불 요청의 값을 적용해주시거나 지워주세요.');
      return;
    }

    const data = await postCancelAdmin(
      checkList,
      totalAmount,
      Number(inputValue)
    );
    if (data.ok) {
      alert('결제가 취소 되었습니다.');
      getAsyncDeliveryList();
      onClose();
    } else {
      if (data.res.status === 315) Logout();
      else console.error(data.res.error.message);
    }
  };

  const getAsyncOrderDetail = async () => {
    const data = await getOrderDetail(checkBillLogId);
    if (data.ok) {
      setHistory(data.res.item);
      calcRefund(data.res.item);
    } else {
      if (data.res.status === 315) Logout();
      else console.error(data.res.error.message);
    }
  };

  const calcRefund = (data: I_OrderDetail) => {
    const lenOrder = data.billLogProduct.length;
    const sumOrder = data.billLogProduct.reduce(
      (sum: number, b: I_BillLogProduct) => sum + b.productPaymentPrice,
      0
    );

    const lenCancel = data.billLogProduct.filter((v) => {
      if (checkList.includes(v.id)) {
        return v;
      }
    }).length;
    const sumCancel = data.billLogProduct.reduce(
      (sum: number, b: I_BillLogProduct) => {
        if (checkList.includes(b.id)) {
          return sum + b.productPaymentPrice;
        } else {
          return sum;
        }
      },
      0
    );

    const shipping = data.shippingCost;
    setExtraShippingCost(0);

    // 전체 취소
    if (lenOrder === lenCancel) {
      setTotalAmount(sumOrder + shipping);
      return;
    }

    // 부분 취소
    if (shipping === 0) {
      // 배송비 내지 않은 경우
      if (sumOrder - sumCancel < data.freeShippingPrice) {
        // 주문 금액에서 취소 상품 금액 제외했을 때 무료 배송 조건 만족 못할 경우
        setExtraShippingCost(shipping); // 추가 배송비 발생
        setTotalAmount(sumCancel - shipping); // 발생된 배송비 만큼 환불 금액에서 제외
      } else {
        setTotalAmount(sumCancel);
      }
    } else {
      // 배송비 낸 경우
      setTotalAmount(sumCancel);
    }
  };

  useEffect(() => {
    if (history) {
      // setTotalAmount(
      //   history.billLogProduct.reduce((sum, item) => {
      //     if (checkList.includes(item.id)) {
      //       return sum + item.productPaymentPrice;
      //     }
      //     return sum;
      //   }, 0)
      // );
      calcRefund(history);
    }
  }, [checkList]);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden'; // body scroll 제거
      if (checkBillLogId) getAsyncOrderDetail();
    }
  }, [isOpen]);

  return (
    <ModalLayout isOpen={isOpen} onClose={onClose}>
      <S_Container>
        <S_CloseButton
          onClick={() => {
            document.body.style.overflow = 'unset'; // body scroll 생성
            onClose();
          }}
        >
          <Close />
        </S_CloseButton>

        <S_ContentContainer>
          <S_ModalTitle>결제 취소</S_ModalTitle>
          <S_ModalContent>
            <S_ModalContentRow>
              <S_Label>주문 번호</S_Label>
              <DisabledInput text={history?.id} style={{ width: '380px' }} />
            </S_ModalContentRow>
            <S_ModalContentCol>
              <S_Label>주문 상품 내역</S_Label>
              <S_TableBox>
                <S_Table>
                  <thead>
                    <tr>
                      {TABLE_HEADER_ORDER.map((v, i) => (
                        <S_TableHeaderItem key={i}>
                          {v === 'check' ? (
                            <Checkbox
                              width='16.67px'
                              height='16.67px'
                              color='#2E2E2E'
                            />
                          ) : (
                            v
                          )}
                        </S_TableHeaderItem>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {history?.billLogProduct?.map((v: any, i: number) => (
                      <StoreDeliveryCancelTableItem
                        key={i}
                        item={v}
                        checkList={checkList}
                        setCheckList={setCheckList}
                      />
                    ))}
                  </tbody>
                </S_Table>
              </S_TableBox>
            </S_ModalContentCol>
            <S_ModalContentCol>
              <S_Label>결제 정보</S_Label>
              <S_TableBox>
                <S_Table>
                  <thead>
                    <tr>
                      {TABLE_HEADER_PAYMENT.map((v, i) => (
                        <S_TableHeaderItem key={i}>{v}</S_TableHeaderItem>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    <S_TableRow>
                      <S_TableColData style={{ width: '274px' }}>
                        {history?.payment.length
                          ? dateTimeFormat(history?.payment[0].createdAt)
                          : '-'}
                      </S_TableColData>
                      <S_TableColData>
                        {history?.payment.length
                          ? history?.payment[0].paymentMethod === 'card'
                            ? '신용/체크카드'
                            : history?.payment[0].paymentMethod ===
                              'virtualAccount'
                            ? '무통장 입금'
                            : '-'
                          : '-'}
                      </S_TableColData>
                    </S_TableRow>
                  </tbody>
                </S_Table>
              </S_TableBox>
            </S_ModalContentCol>
            <S_ModalContentCol>
              <S_Label>환불 예정 정보</S_Label>
              <S_TableBox>
                <S_Table>
                  <thead>
                    <tr>
                      {TABLE_HEADER_REFUND.map((v, i) => (
                        <S_TableHeaderItem key={i}>{v}</S_TableHeaderItem>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    <S_TableRow>
                      <S_TableColData>
                        {exTotAmount ? (
                          <>
                            {`${numberFormat(exTotAmount)}원`}
                            {`(${numberFormat(totalAmount)}원 - ${numberFormat(
                              Number(inputValue)
                            )}원)`}
                          </>
                        ) : (
                          `${numberFormat(totalAmount)}원`
                        )}
                      </S_TableColData>
                    </S_TableRow>
                  </tbody>
                </S_Table>
              </S_TableBox>
            </S_ModalContentCol>
            <S_ModalContentRow
              style={{ justifyContent: 'flex-end', marginBottom: '72px' }}
            >
              <S_Label>부분 환불 요청</S_Label>
              <S_FlexAlignCenter style={{ gap: '16px' }}>
                <Input
                  state={inputValue}
                  setState={setInputValue}
                  onCustomChange={onChange}
                  rightText='원'
                  style={{ width: '380px' }}
                />
                <MiniButton onClick={handleExtraCost}>적용</MiniButton>
              </S_FlexAlignCenter>
            </S_ModalContentRow>
          </S_ModalContent>
          {checkList.length ? (
            <LongButton
              onClick={postAsyncCancel}
              style={{ background: '#FC1D1D', float: 'right' }}
            >
              환불 요청
            </LongButton>
          ) : (
            <S_DisabledButton>환불 요청</S_DisabledButton>
          )}
        </S_ContentContainer>
      </S_Container>
    </ModalLayout>
  );
}

export default CancelDeliveryModal;

const S_DisabledButton = styled.div`
  width: 300px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.colors.hd4};
  border-radius: 8px;
  font-weight: 700;
  font-size: 14px;
  letter-spacing: -0.02em;
  color: ${(props) => props.theme.colors.hd5};
  float: right;
`;

const S_TableColData = styled.td`
  text-align: center;
  vertical-align: middle;
  border: 1px solid ${(props) => props.theme.colors.hd3};
  padding: 10px;
`;

const S_TableRow = styled.tr`
  height: 36px;
  font-weight: 500;
  font-size: 13px;
`;

const S_TableHeaderItem = styled.th`
  vertical-align: middle;
  padding: 10px;
  height: 36px;
  background-color: ${(props) => props.theme.colors.hd4};
  border: 0.5px solid ${(props) => props.theme.colors.hd3};
  font-weight: 500;
  font-size: 13px;
  letter-spacing: -0.02em;
  color: ${(props) => props.theme.colors.n10};
`;

const S_Table = styled.table`
  width: 100%;

  th:first-child {
    width: 40px;
  }
`;

const S_TableBox = styled.div`
  overflow-x: scroll;
  &::-webkit-scrollbar {
    width: 4px;
    height: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.theme.colors.hd3};
    border-radius: 6px;
  }
`;

const S_Label = styled.span`
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.02em;
`;

const S_ModalContentCol = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const S_ModalContentRow = styled.div`
  display: flex;
  align-items: center;
  gap: 40px;
`;

const S_ModalContent = styled.div`
  margin-top: 34px;
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

const S_ModalTitle = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 35px;
  letter-spacing: -0.02em;
  text-align: center;
`;

const S_CloseButton = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
  :hover {
    opacity: 0.5;
  }
`;

const S_ContentContainer = styled.div`
  width: 1019px;
  margin: 0 auto;
`;

const S_Container = styled.div`
  width: 100%;
  height: 100%;
  background: ${(props) => props.theme.colors.hd5};
  padding: 70px 70px 144px;
  overflow-y: overlay;
  z-index: 10;
`;

const TABLE_HEADER_ORDER = [
  'check',
  '상품 주문 번호',
  '주문 상태',
  '상품 정보',
  '상세 옵션',
  '주문 수량',
  '최종 결제 금액',
  '상품 판매가',
  '사용 포인트',
  '쿠폰 할인',
  '상품 번호',
];
const TABLE_HEADER_PAYMENT = ['결제 일시', '결제 방식'];
const TABLE_HEADER_REFUND = ['환불 예정 금액'];
