import { I_EventForm } from 'etc/types';
import { callApi } from './callApi';

// 이벤트 리스트
export const getEventList = async (limit: number, currentPage: number) => {
  const res = await callApi({
    url: `/admin/event/list?page=${currentPage}&limit=${limit}`,
    method: 'GET',
  });
  return res;
};

// 이벤트 상세
export const getEventDetail = async (eventId: number) => {
  const res = await callApi({
    url: `/admin/event/${eventId}`,
    method: 'GET',
  }); 
  return res;
};

// 이벤트 등록
export const postEvent = async (body: I_EventForm) => {
  const res = await callApi({
    url: `/admin/event`,
    method: 'POST',
    data: body,
  });
  return res;
};

// 이벤트 종료
export const patchEvent = async (eventId: number) => {
  const res = await callApi({
    url: `/admin/event/${eventId}`,
    method: 'PATCH',
  });
  return res;
};

export const updateEvent = async (eventId: number, data: any) => {
  const res = await callApi({
    url: `/admin/event/${eventId}`,
    method: 'PATCH',
    data: data
  });
  return res;
};

// 이벤트 검색
export const getEventSearch = async (
  eventName: string,
  page: number,
  limit: number
) => {
  const res = await callApi({
    url: `/admin/event?name=${eventName}&page=${page}&limit=${limit}`,
    method: 'GET',
  });
  return res;
};
