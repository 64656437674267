import { callApi } from './callApi';

// 품종별 리스트
export const getBreedList = async (
  limit: number,
  currentPage: number,
  animal: number
) => {
  const res = await callApi({
    url: `/admin/breed/detail/list?limit=${limit}&currentPage=${currentPage}&animal=${animal}`,
    method: 'GET',
  });
  return res;
};

// 품종 상세 정보
export const getBreedDetail = async (breedId: number) => {
  const res = await callApi({
    url: `/admin/breed/${breedId}`,
    method: 'GET',
  });
  return res;
};

// 품종 정보 저장
export const postBreedHealthInfo = async (
  breedId: number,
  healthInfo: string
) => {
  const res = await callApi({
    url: `/admin/breed/healthinfo/${breedId}`,
    method: 'POST',
    data: { healthInfo: healthInfo },
  });
  return res;
};

// 품종 정보 등록
export const postBreed = async (
  animalType: number,
  breed: string,
  code: string,
  healthInfo: string
) => {
  const res = await callApi({
    url: `/admin/breed`,
    method: 'POST',
    data: {
      animalType: animalType,
      breed: breed,
      code: code,
      healthInfo: healthInfo,
    },
  });
  return res;
};
