import { Editor } from '@toast-ui/react-editor';
import '@toast-ui/editor/dist/toastui-editor.css';
import colorSyntax from '@toast-ui/editor-plugin-color-syntax'
import 'tui-color-picker/dist/tui-color-picker.css';
import '@toast-ui/editor-plugin-code-syntax-highlight/dist/toastui-editor-plugin-code-syntax-highlight.css'
import { useRef, useState, useEffect } from 'react'
// import { requestPostFormApi } from "../../common/common";
import '@toast-ui/editor/dist/theme/toastui-editor-dark.css'



import { postUploadFiles, postImageUpload } from 'api/images';

type Props = {
  description?: string,
  onParentDescription: (description: string) => void;
  initialValue?: string,
  placeholder?: string,
  initialEditType?: 'markdown' | 'wysiwyg',
  // initialEditType?: string,
  previewStyle?: 'vertical' | 'tab'; // 추가: 프리뷰 스타일 제한
  height?: string,
  theme?: string,
  plugins?: any,
}
interface ApiResponse<T = object> {
  success: boolean;
  code: number;
  message: string;
  data: T;
}
interface FileUploadResponse {
  message: string;
  url: string[];
}

const ToastEditor = ({
  description, 
  onParentDescription,
  initialValue = ' ',
  placeholder = '여행상품 상세정보를 작성해주세요',
  initialEditType = 'markdown',
  previewStyle = 'vertical',
  // previewStyle = 'tab',
  theme='white',
  height = '30rem',
}: Props) => { 

  const editorRef = useRef<Editor>(null);
  // const editorRef = useRef(null);
  const toolbarItems = [
    ['heading', 'bold', 'italic', 'strike'], 
    ['hr', 'quote'],
    ['ul', 'ol', 'task', 'indent', 'outdent'],
    ['table', 'image', 'link'],
    ['code', 'codeblock'],
    ['scrollSync']
  ];

  const onUploadImage = async (file: File | Blob, callback: any) => {
    try {

      console.log('file : ', file, ' callback : ', callback)
      const formData = new FormData();
      // const changeArray = Array.from(file);
      // changeArray.forEach((v: any) => {
      //   formData.append('files', v);
      // });
      formData.append('file', file);
      formData.append('path', 'test2');

      const res = await postImageUpload(formData);
      console.log('res : ', res)

      if (res.ok) {
        // callback('https://humanizone-s3.s3.amazonaws.com/etc/test/20240813_odin_01.png');
        callback(res.res.imageUrl);
      } else {
        console.log('image upload fail')
      }

    } catch (e) {
      console.log('error : ', e)
    }
  };

  const handlerEditor = () => {
    if (editorRef.current) {
      const markdown = editorRef.current.getInstance().getMarkdown();
      const htmlElement = editorRef.current.getInstance().getHTML();
      console.log('htmlElement : ', htmlElement)
      onParentDescription(htmlElement);
    }
  };

  // useEffect(() => {
  //   if (editorRef.current) {
  //     editorRef.current.getInstance().setMarkdown(initialValue || ' ');
  //   }
  // }, [initialValue]);  // initialValue가 변경될 때마다 실행


  // initialValue가 변경될 때마다 에디터의 HTML 내용을 업데이트
  useEffect(() => {
    if (editorRef.current && initialValue) {
      editorRef.current.getInstance().setHTML(initialValue);  // HTML 값을 에디터에 반영
    }
  }, [initialValue]);  // initialValue가 변경될 때마다 실행
  
  
  return (
  <>
    <Editor
      ref={editorRef}
      initialValue={initialValue ||  ' asdasdasd '}
      placeholder={placeholder}
      initialEditType={initialEditType}
      previewStyle={previewStyle}
      height={height ?? '20rem'}
      hideModeSwitch={true}
      theme={theme ?? 'dark'}
      toolbarItems={toolbarItems}
      plugins={[colorSyntax]}
      hooks={{ addImageBlobHook: onUploadImage }}
      onChange={handlerEditor}
      useCommandShortcut={true}
      // language="ko-KR"
    />
    
  </>
  )
}
export default ToastEditor