import { useEffect, useState } from 'react';
import { I_Petstival } from 'etc/types';
import { useNavigate } from 'react-router-dom';
import { dateFormat } from 'utils/common';

import LongButton from 'components/Button/LongButton';
import MiniButton from 'components/Button/MiniButton';
import CustomButton from 'components/Button/CustomButton';
import NormalButton from 'components/Button/NormalButton';
import PeriodButton from 'components/Button/PeriodButton';
import { getPetstivalList, getPetstivalDetail, patchPetstivalStatus } from 'api/petstival';


import styled from 'styled-components';

type Status = 'Active' | 'Deactive' ;
interface I_PetstivalTableItem {
  item: I_Petstival;
  onChangeData: (updatePetstival: I_Petstival) => void;
}

function PetstivalTableItem({ item, onChangeData }: I_PetstivalTableItem) {
  const navigate = useNavigate();

  const [inputValue, setInputValue] = useState<string>('');

  const updatePetstival = async (petstivalId: number, currentStatus: string) => {

    const changeStatus = currentStatus === 'Active' ? 'Deactive' : 'Active';
    let msg = '';

    if(changeStatus === 'Active') {
      msg ='공개로 변경할까요?? (변경하면 푸시메세지가 전송됩니다)';
    }else{
      msg ='비공개로 변경할까요??';
    }
    
    if(confirm(msg)){

      const response = await patchPetstivalStatus(
        petstivalId,
        changeStatus,
        'Active'
      );
  
      console.log('response : ', response.res);
      if (response.ok) {
        alert('변경됐습니다!');
        onChangeData(response.res);

      } else {
        alert('에러 : '+response.res.error.message);
      }
  
      // console.log('updateActive petstivalId : ', petstivalId, ' currentStatus : ', currentStatus);

    }else{

      //alert('취소됐습니다!');
    }

  }
  const deletePetstival = async (petstivalId: number, currentStatus='Deactive', petstivalStatus='Deactive') => {

    const msg = '삭제하시겠습니까??';
    
    if(confirm(msg)){

      const response = await patchPetstivalStatus(
        petstivalId,
        currentStatus,
        petstivalStatus
      );
  
      console.log('response : ', response.res);
      if (response.ok) {
        alert('삭제됐습니다!');
        // setList(data.res.list);
        // setTotalCount(data.res.totalCount);
        onChangeData(response.res);

      } else {
        alert('에러 : '+response.res.error.message);
      }
  
      console.log('updateActive petstivalId : ', petstivalId, ' currentStatus : ', currentStatus);

    }else{

      //alert('취소됐습니다!');
    }

  }
  return (
    <S_TableRow>
      <S_TableColData
        // style={{ textDecoration: 'underline', cursor: 'pointer' }}
        // onClick={() => navigate(`/user/${item.id}`)}
      >
        {item.id}
      </S_TableColData>
      <S_TableColData>{item.title}</S_TableColData>
      <S_TableColData>{item.content}</S_TableColData>
      <S_TableColData>{item.petName}({item.petId})</S_TableColData>
      
      <S_TableColData
      style={{ textDecoration: 'underline', cursor: 'pointer' }}
      onClick={() => navigate(`/user/${item.userId}`)}
      >{item.userId}</S_TableColData>
      <S_TableColData>{dateFormat(item.createdAt).format('YYYY-MM-DD H:m:s')}</S_TableColData>
      <S_TableColData>
      { item.images && 
        item.images.map((index) =>
          <img src={index} key={`image_`+index} width='60px' style={{ marginLeft: '3px', cursor: 'pointer' }}  onClick={() => window.open(index)} />
          )
      }
      </S_TableColData>

      <S_TableColData
        style={{ color: item.adminStatus === 'Active' ? '#13A872' : '#f24662' }}
      >
        {item.adminStatus === 'Active' ? '심사완료' : item.adminStatus === 'Deactive' ? '심사전' : ''
        }
      </S_TableColData>

      <S_TableColData >
        <S_Button>
          { item.adminStatus === 'Active' &&
          <CustomButton style={{ width: '60px', height: '30px', backgroundColor: '#6c4ae8', borderRadius: '20px' }} onClick={()=>updatePetstival(item.id, item.adminStatus)} type='button'> 공개</CustomButton>
          }
          { item.adminStatus === 'Deactive' &&
          <CustomButton style={{ width: '60px', height: '30px', backgroundColor: '#b649c4', borderRadius: '20px' }} onClick={()=>updatePetstival(item.id, item.adminStatus)} type='button'> 비공개</CustomButton>
          }
        </S_Button>
      </S_TableColData>

      <S_TableColData >
          <CustomButton style={{ width: '60px', height: '30px', backgroundColor: '#f24662', borderRadius: '20px' }} onClick={()=>deletePetstival(item.id, item.adminStatus )} type='button'> 삭제</CustomButton>
      </S_TableColData>

    </S_TableRow>
  );
}

export default PetstivalTableItem;


const S_Button = styled.button`
  /* background-color: skyblue; */
  width: 100%;
  display: flex;
  margin-left: '5px';
  justify-content: space-evenly;
`;


const S_TableColData = styled.td`
  text-align: center;
  vertical-align: middle;
  border: 1px solid ${(props) => props.theme.colors.hd3};
  padding: 10px;

  &:nth-child(1) {
    min-width: 120px;
  }
  &:nth-child(2) {
    min-width: 120px;
  }
  &:nth-child(3) {
    min-width: 230px;
  }
  &:nth-child(4) {
    min-width: 80px;
  }
  &:nth-child(5) {
    min-width: 80px;
  }
  &:nth-child(6) {
    min-width: 20px;
  }
  &:nth-child(7) {
    min-width: 100px;
  }
  &:nth-child(8) {
    min-width: 100px;
  }
  &:nth-child(9) {
    min-width: 120px;
  }
  &:nth-child(10) {
    min-width: 80px;
  }
`;

const S_TableRow = styled.tr`
  height: 36px;
  font-weight: 500;
  font-size: 13px;
`;


