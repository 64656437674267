import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import moment from 'moment';

import MainLayout from 'components/Layout/MainLayout';
import LongButton from 'components/Button/LongButton';
import Input from 'components/Input/Input';
import ImageInput from 'components/Input/ImageInput';
import Textarea from 'components/Input/Textarea';
import DateInput from 'components/Input/DateInput';

import { postImagesUpload } from 'api/images';
import { I_EventForm } from 'etc/types';
import { postEvent } from 'api/event';
import useLogout from 'hooks/useLogout';
import { eventTypes, linkTypes, eventStatuTypes } from 'etc/variable'

function RegisterEvent() {
  
  const Logout = useLogout();
  const navigate = useNavigate();

  const [title, setTitle] = useState<string>('');
  const [webUrl, setWebUrl] = useState<string>('');
  const [appRoute, setAppRoute] = useState<string>('');
  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');
  const [explain, setExplain] = useState<string>('');
  const [thumbnail, setThumbnail] = useState<File>(new File([], ''));
  const [detailImage, setDetailImage] = useState<File>(new File([], ''));
  const [eventType, setEventType] = useState<string>('');
  const [linkType, setLinkType] = useState<string>('');
  const [eventStatus, setEventStatus] = useState<string>('');

  const today = moment(new Date()).format('YYYY-MM-DDTHH:mm');

  const onClickSave = async () => {

    if(!title) return alert('이벤트 명을 입력해주세요.');
    if(!startDate) return alert('시작 기간을 입력해주세요.');
    if(!endDate) return alert('종료 기간을 입력해주세요.');
    if(!eventType) return alert('이벤트 배너 위치를 선택해주세요.');
    if(!linkType) return alert('클릭시 연결타입을 선택해주세요.');
    // if(!webUrl && !appRoute) {
    //   return alert('연결 웹주소 또는 앱 라우트 중 하나를 입력해주세요.');
    // }
    if(!thumbnail.name) return alert('썸네일을 등록해주세요.');
    // if(!detailImage.name) return alert('상세 이미지를 등록해주세요.');
    // if(!explain) return alert('추가 설명을 입력해주세요.');

    const formData = new FormData();
    const body: I_EventForm = {
      eventName: '',
      content: '',
      start: '',
      end: '',
      bannerImageId: 0,
      mainImageId: 0,
      type: eventType, // Add event type to the body
      linkType: linkType,
      webUrl: '',
      appRoute: '',
      status: '',
    };

    formData.append('files', thumbnail);
    formData.append('files', detailImage);
    formData.append('path', 'event');

    const uploadfile = await postImagesUpload(formData);
    if (uploadfile.ok) {
      body.bannerImageId = uploadfile.res.ids[0];
      body.mainImageId = uploadfile.res.ids[1];
    } else {
      if (uploadfile.res.status === 315) Logout();
      else {
        alert('잠시 후 다시 시도해주세요.');
        console.error(uploadfile.res.error.message);
        return;
      }
    }

    body.eventName = title;
    body.start = startDate;
    body.end = endDate;
    body.webUrl = webUrl;
    body.appRoute = appRoute;
    body.type = eventType;
    body.linkType = linkType;
    body.content = explain;
    body.status = eventStatus;


    const data = await postEvent(body);

    if (data.ok) {
      alert('이벤트가 게시되었습니다.');
      navigate('/service/event');
    } else {
      if (data.res.status === 315) Logout();
      else {
        alert('잠시 후 다시 시도해주세요.');
        console.error(data.res.error.message);
      }
    }
  };

  return (
    <MainLayout>
      <S_Section>
        <S_Title>이벤트 정보 기입</S_Title>
        <S_Table>
          <tbody>
            <tr>
              <th>이벤트 명</th>
              <td colSpan={3}>
                <Input state={title} setState={setTitle} />
              </td>
            </tr>

            <tr>
              <th style={{ verticalAlign: 'top' }}>
                이벤트 시작 기간/종료 기간
              </th>
              <td colSpan={3}>
                <S_DateBox>
                  <DateInput
                    state={startDate}
                    setState={setStartDate}
                    type="datetime-local"
                    min={today}
                  />
                  <DateInput
                    state={endDate}
                    setState={setEndDate}
                    type="datetime-local"
                    min={today}
                  />
                </S_DateBox>
              </td>
            </tr>

            <tr>
              <th className='alignTop'>이벤트 팝업 위치</th>
              <td>
                <S_Select value={eventType} onChange={(e) => setEventType(e.target.value)}>
                  <option value="">선택하세요</option>
                  {eventTypes.map((type) => (
                    <option key={type.value} value={type.value}>
                      {type.label}
                    </option>
                  ))}
                </S_Select>
              </td>
            </tr>
            

            <tr>
              <th>연결타입/ 연결 웹주소 / 앱라우트</th>
              <td colSpan={3}>
                <S_FlexBox>
                  <S_SelectBox>
                    <S_Select value={linkType} onChange={(e) => setLinkType(e.target.value)}>
                      <option value="">선택하세요</option>
                      {linkTypes.map((type) => (
                      <option key={type.value} value={type.value}>
                        {type.label}
                      </option>
                      ))}
                    </S_Select>
                  </S_SelectBox>

                  <S_HalfWidthInput>
                    <Input state={webUrl} setState={setWebUrl} placeholder="https://" />
                  </S_HalfWidthInput>

                  <S_HalfWidthInput>
                    <Input state={appRoute} setState={setAppRoute} placeholder="앱라우트" />
                  </S_HalfWidthInput>
                </S_FlexBox>
              </td>
            </tr>

            <tr>
              <th className='alignTop'>배너</th>
              <td>
                <ImageInput setState={setThumbnail} id={1} />
              </td>
            </tr>
            <tr>
              <th className='alignTop'>상세 이미지</th>
              <td colSpan={3}>
                <S_ImgBox>
                  <ImageInput
                    style={{ width: 279, height: 279 }}
                    setState={setDetailImage}
                    id={2}
                  />
                </S_ImgBox>
              </td>
            </tr>
            <tr>
              <th className='alignTop'>추가 설명</th>
              <td>
                <Textarea
                  state={explain}
                  setState={setExplain}
                  style={{ height: 90 }}
                />
              </td>
            </tr>

            <tr>
              <th className='alignTop'>진행 상태</th>
              <td>
                <S_Select value={eventStatus} onChange={(e) => setEventStatus(e.target.value)}>
                  <option value="">선택하세요</option>
                  {eventStatuTypes.map((type) => (
                    <option key={type.value} value={type.value}>
                      {type.label}
                    </option>
                  ))}
                </S_Select>
              </td>
            </tr>

          </tbody>
        </S_Table>
      </S_Section>
      <S_ButtonBox>
        <LongButton onClick={() => onClickSave()}>이벤트 등록</LongButton>
      </S_ButtonBox>
    </MainLayout>
  );
}

export default RegisterEvent;

const S_DateBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%; /* 전체 박스가 100% 영역을 차지 */
  /* gap: 30px; */

  input {
    width: 40%; /* input 요소들이 50%씩 영역을 차지 */
    height: 46px;
    border-radius: 12px;
    padding: 0 10px;
    box-sizing: border-box;
  }
`;


const S_ImgBox = styled.div`
  display: flex;
  display: flex;
  justify-content: space-between;
  gap: 40px;
`;

const S_Select = styled.select`
  width: 50%;
  height: 46px;
  border-radius: 12px;
  border: 1px solid #ccc;
  padding: 0 10px;
`;

const S_Table = styled.table`
  border-collapse: separate;
  border-spacing: 20px;

  th {
    width: 160px; /* 전체 th 너비를 더 넓게 설정 */
    text-align: left;
    font-size: 14px;
    font-weight: 500;
    line-height: 150%;
    &:nth-child(1) {
      width: 160px; /* 첫 번째 th의 너비를 설정 */
    }
    &:nth-child(3) {
      width: 120px;
    }
    &.alignTop {
      vertical-align: top;
      padding-top: 14px;
    }
  }
`;


const S_ButtonBox = styled.div`
  display: flex;
  justify-content: center;
  padding-right: 20px;
`;

const S_Title = styled.p`
  font-size: 16px;
  color: ${(props) => props.theme.colors.hd1};
  font-weight: 700;
`;

const S_Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 17px;
  padding: 30px 20px;

  &:last-child {
    border-bottom: 0;
  }
`;

const S_FlexBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 10px; /* 선택 박스와 인풋 간 간격을 조정 */
`;

const S_SelectBox = styled.div`
  width: 20%; /* 셀렉트 박스의 너비를 설정 */
`;

const S_HalfWidthInput = styled.div`
  width: 40%; /* 인풋 필드들이 50%씩 차지하게 설정 */
`;
