import React, { useState, useCallback } from 'react';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';

// Helper function to reorder items
const reorder = (list: any[], startIndex: number, endIndex: number): any[] => {
  
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  console.log('reorder list : ', list)
  return result;
};

// Styles
const grid = 8;

const getItemStyle = (isDragging: boolean, draggableStyle: React.CSSProperties | undefined): React.CSSProperties => ({
  userSelect: 'none',
  margin: `0 ${grid}px 0 0`,
  background: isDragging ? 'lightgreen' : 'white',
  padding: grid,
  border: '1px solid #ccc',
  borderRadius: '4px',
  display: 'inline-block',
  textAlign: 'center',
  ...draggableStyle,
});

const getListStyle = (isDraggingOver: boolean): React.CSSProperties => ({
  background: isDraggingOver ? 'lightblue' : 'lightgrey',
  display: 'flex',
  padding: grid,
  overflow: 'auto',
});


interface DraggableListProps {
  items: { id: string; fileName: string, file: File }[];
  onItemsChange?: (items: { id: string; content: string }[]) => void;
  direction?: 'horizontal' | 'vertical';
}

const DraggableList: React.FC<DraggableListProps> = ({ items: initialItems, onItemsChange, direction = 'horizontal' }) => {
  const [items, setItems] = useState(initialItems);

  const onDragEnd = useCallback((result: DropResult) => {

    console.log('onDragEnd : ', onDragEnd)
    if (!result.destination) {
      return;
    }

    const reorderedItems = reorder(
      items,
      result.source.index,
      result.destination.index
    );

    setItems(reorderedItems);
    if (onItemsChange) {
      onItemsChange(reorderedItems);
    }
  }, [items, onItemsChange]);

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable" direction={direction}>
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            style={getListStyle(snapshot.isDraggingOver)}
            {...provided.droppableProps}
          >
            {items.map((item, index) => (
              <Draggable key={item.id} draggableId={item.id} index={index}>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={getItemStyle(
                      snapshot.isDragging,
                      provided.draggableProps.style
                    )}
                  >
                    <img
                      src={URL.createObjectURL(item.file)}
                      alt={`preview-${item.id}`}
                      style={{ width: '100px', height: '100px', objectFit: 'cover', borderRadius: '4px' }}
                    />
                    <div style={{ marginTop: '8px', fontSize: '12px', color: '#333' }}>
                      {item.fileName}
                    </div>
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default DraggableList;
