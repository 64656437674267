import styled from 'styled-components';

interface I_DisabledTextarea {
  text: string | undefined;
  style?: React.CSSProperties;
}

function DisabledTextarea({ text, style }: I_DisabledTextarea) {
  return (
    <S_Container style={style}>
      <S_Textarea value={text} disabled={true} />
    </S_Container>
  );
}

export default DisabledTextarea;

const S_Textarea = styled.textarea`
  width: 100%;
  height: 100%;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.02em;
  color: ${(props) => props.theme.colors.hd1};
  background: ${(props) => props.theme.colors.hd4};
  border: 0;
  line-height: 150%;
  resize: none;
  &::placeholder {
    color: ${(props) => props.theme.colors.hd2};
  }
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.theme.colors.hd3};
    border-radius: 6px;
  }
`;

const S_Container = styled.div`
  width: 100%;
  padding: 14px 16px;
  background: ${(props) => props.theme.colors.hd4};
  border: 1px solid ${(props) => props.theme.colors.hd3};
  border-radius: 12px;
`;
