import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import Header from 'components/Nav/Header';
import Sidebar from 'components/Nav/Sidebar';
import Category from 'components/Nav/Category';
import PageContentLayout from './PageContentLayout';

interface I_MainLayout {
  children: React.ReactNode;
}

function MainLayout({ children }: I_MainLayout) {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.style.overflow = 'unset';
    // console.log('main layout pathname : ', pathname)
  }, [pathname]);

  return (
    <>
      <Header />
      <S_ContentLayout>
        <Sidebar />
        <S_Content>
          <Category />
          <PageContentLayout>{children}</PageContentLayout>
        </S_Content>
      </S_ContentLayout>
    </>
  );
}

export default MainLayout;

const S_Content = styled.main`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const S_ContentLayout = styled.div`
  display: flex;
  padding-top: 53px;
`;
