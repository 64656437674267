import { Navigate, Routes as ReactRouterRoutes, Route } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import Login from './Login';
import Admin from './Admin';
import Report from './service/Report';
import ReportDetail from './service/ReportDetail';
import AppService from './service/AppService';
import AppServiceDetail from './service/AppServiceDetail';
import ServiceGuide from './service/ServiceGuide';
import KindInfo from './service/KindInfo';
import KindInfoDetail from './service/KindInfoDetail';
import RegisterKindInfo from './service/RegisterKindInfo';
import StoreProduct from './store/StoreProduct';
import User from './user/User';
import UserDetail from './user/UserDetail';
import CareUser from './user/CareUser';
import CareUserDetail from './user/CareUserDetail';
import CareUserApply from './user/CareUserApply';
import CareUserApplyDetail from './user/CareUserApplyDetail';
import Event from './service/Event';
import EventDetail from './service/EventDetail';
import EventDetailEdit from './service/EventDetailEdit';
import RegisterEvent from './service/RegisterEvent';
import Coupon from './service/Coupon';
import RegisterCoupon from './service/RegisterCoupon';
import CouponDetail from './service/CouponDetail';
import Notice from './service/Notice';
import RegisterNotice from './service/RegisterNotice';
import NoticeDetail from './service/NoticeDetail';
import Point from './service/Point';
import Policy from './service/Policy';
import PolicyDetail from './service/PolicyDetail';
import PolicyUpdate from './service/PolicyUpdate';
import Chatting from './service/Chatting';
import Firebase from './Firebase';
import PetstivalList from './petstival/List';

import PetstivalRankingList from './petstival/RankingList';
import PetstivalDetail from './petstival/Detail';
import FcmSend from './fcm/Send';

import StoreOrder from './store/StoreOrder';
import StoreDelivery from './store/StoreDelivery';
import StoreCancel from './store/StoreCancel';
import StoreExchange from './store/StoreExchange';
import StoreReturn from './store/StoreReturn';
import StoreConfirm from './store/StoreConfirm';
import StoreInquiry from './store/StoreInquiry';
import StoreReview from './store/StoreReview';
import ProductRegistration from './store/ProductRegistration';
import ProductDetail from './store/ProductDetail';
import OrderDetail from './store/OrderDetail';
import StoreInquiryDetail from './store/StoreInquiryDetail';
import StoreReviewDetail from './store/StoreReviewDetail';
import { isLoginAtom } from 'etc/atoms';

import UtmRegist from './marketing/utm/Regist';
import UtmList from './marketing/utm/List';
import UtmChart from './marketing/utm/Chart';

import TravelProductList from './travelProduct/List';
import TravelProductRegistration from './travelProduct/Registration';
import TravelProductDetail from './travelProduct/Detail';
import OptionRegistration from './travelProduct/option/Registration';
import TravelProductOptionDetail from './travelProduct/option/Detail';

import CategoryIndex from './travelProduct/category/Index';


function Routes() {
  const isLogin = useRecoilValue(isLoginAtom);

  return (
    <ReactRouterRoutes>
      <Route
        path='/'
        element={
          isLogin ? (
            <Navigate replace to='/admin' />
          ) : (
            <Navigate replace to='/login' />
          )
        }
      />
      <Route
        path='/login'
        element={!isLogin ? <Login /> : <Navigate replace to='/' />}
      />
      <Route
        path='/admin'
        element={isLogin ? <Admin /> : <Navigate replace to='/login' />}
      />
      <Route
        path='/user'
        element={isLogin ? <User /> : <Navigate replace to='/login' />}
      />
      <Route
        path='/user/:id'
        element={isLogin ? <UserDetail /> : <Navigate replace to='/login' />}
      />
      <Route
        path='/user/care'
        element={isLogin ? <CareUser /> : <Navigate replace to='/login' />}
      />
      <Route
        path='/user/care/:id'
        element={
          isLogin ? <CareUserDetail /> : <Navigate replace to='/login' />
        }
      />
      <Route
        path='/user/care/apply'
        element={isLogin ? <CareUserApply /> : <Navigate replace to='/login' />}
      />
      <Route
        path='/user/care/apply/:id'
        element={
          isLogin ? <CareUserApplyDetail /> : <Navigate replace to='/login' />
        }
      />
      <Route
        path='/service/event'
        element={isLogin ? <Event /> : <Navigate replace to='/login' />}
      />
      {/* <Route
        path='/service/event/:id'
        element={isLogin ? <EventDetail /> : <Navigate replace to='/login' />}
      /> */}
      <Route
        path='/service/event/:id'
        element={isLogin ? <EventDetailEdit /> : <Navigate replace to='/login' />}
      />
      <Route
        path='/service/event/register'
        element={isLogin ? <RegisterEvent /> : <Navigate replace to='/login' />}
      />
      <Route
        path='/service/report'
        element={isLogin ? <Report /> : <Navigate replace to='/login' />}
      />
      <Route
        path='/service/report/:id'
        element={isLogin ? <ReportDetail /> : <Navigate replace to='/login' />}
      />
      <Route
        path='/service/app'
        element={isLogin ? <AppService /> : <Navigate replace to='/login' />}
      />
      <Route
        path='/service/app/:id'
        element={
          isLogin ? <AppServiceDetail /> : <Navigate replace to='/login' />
        }
      />
      <Route
        path='/service/guide'
        element={isLogin ? <ServiceGuide /> : <Navigate replace to='/login' />}
      />
      <Route
        path='/service/kind'
        element={isLogin ? <KindInfo /> : <Navigate replace to='/login' />}
      />
      <Route
        path='/service/kind/:id'
        element={
          isLogin ? <KindInfoDetail /> : <Navigate replace to='/login' />
        }
      />
      <Route
        path='/service/kind/register'
        element={
          isLogin ? <RegisterKindInfo /> : <Navigate replace to='/login' />
        }
      />
      <Route
        path='/service/notice'
        element={isLogin ? <Notice /> : <Navigate replace to='/login' />}
      />
      <Route
        path='/service/notice/register'
        element={
          isLogin ? <RegisterNotice /> : <Navigate replace to='/login' />
        }
      />
      <Route
        path='/service/notice/:id'
        element={isLogin ? <NoticeDetail /> : <Navigate replace to='/login' />}
      />
      <Route
        path='/service/point'
        element={isLogin ? <Point /> : <Navigate replace to='/login' />}
      />
      <Route
        path='/service/policy'
        element={isLogin ? <Policy /> : <Navigate replace to='/login' />}
      />
      <Route
        path='/service/policy/detail'
        element={isLogin ? <PolicyDetail /> : <Navigate replace to='/login' />}
      />
      <Route
        path='/service/policy/update'
        element={isLogin ? <PolicyUpdate /> : <Navigate replace to='/login' />}
      />
      <Route
        path='/service/chatting'
        element={isLogin ? <Chatting /> : <Navigate replace to='/login' />}
      />
      <Route
        path='/app/firebase'
        element={isLogin ? <Firebase /> : <Navigate replace to='/login' />}
      />

      {/* 펫스티벌 */}
      <Route
        path='/petstival/list'
        element={isLogin ? <PetstivalList /> : <Navigate replace to='/login' />}
      />
      <Route
        path='/petstival/:id'
        element={isLogin ? <PetstivalDetail /> : <Navigate replace to='/login' />}
      />
      <Route
        path='/petstival/rankingList'
        element={isLogin ? <PetstivalRankingList /> : <Navigate replace to='/login' />}
      />

      {/* 여행상품 */}
      <Route
        path='/travelProduct/category'
        element={isLogin ? <CategoryIndex /> : <Navigate replace to='/login' />}
      />
      <Route
        path='/travelProduct/category/register'
        element={isLogin ? <ProductRegistration /> : <Navigate replace to='/login' />}
      />
      <Route
        path='/travelProduct/register'
        element={isLogin ? <TravelProductRegistration /> : <Navigate replace to='/login' />}
      />
      <Route
        path='/travelProduct/list'
        element={isLogin ? <TravelProductList /> : <Navigate replace to='/login' />}
      />
      <Route
        path='/travelProduct/:travelProductId'
        element={isLogin ? <TravelProductDetail /> : <Navigate replace to='/login' />}
      />
      
      <Route
        path='/travelProduct/:travelProductId/options'
        element={isLogin ? <OptionRegistration /> : <Navigate replace to='/login' />}
      />

      <Route
        path='/travelProduct/:travelProductId/options/:travelProductOptionId'
        element={isLogin ? <TravelProductOptionDetail /> : <Navigate replace to='/login' />}
      />

      {/* 푸시메세지 */}
      <Route
        path='/fcm/send'
        element={isLogin ? <FcmSend /> : <Navigate replace to='/login' />}
      />


      {/* store */}
      <Route
        path='/store/product'
        element={isLogin ? <StoreProduct /> : <Navigate replace to='/login' />}
      />
      <Route
        path='/store/product/registration'
        element={
          isLogin ? <ProductRegistration /> : <Navigate replace to='/login' />
        }
      />
      <Route
        path='/store/product/:id'
        element={isLogin ? <ProductDetail /> : <Navigate replace to='/login' />}
      />
      <Route
        path='/store/order'
        element={isLogin ? <StoreOrder /> : <Navigate replace to='/login' />}
      />
      <Route
        path='/store/order/:id'
        element={isLogin ? <OrderDetail /> : <Navigate replace to='/login' />}
      />
      <Route
        path='/store/delivery'
        element={isLogin ? <StoreDelivery /> : <Navigate replace to='/login' />}
      />
      <Route
        path='/store/cancel'
        element={isLogin ? <StoreCancel /> : <Navigate replace to='/login' />}
      />
      <Route
        path='/store/exchange'
        element={isLogin ? <StoreExchange /> : <Navigate replace to='/login' />}
      />
      <Route
        path='/store/return'
        element={isLogin ? <StoreReturn /> : <Navigate replace to='/login' />}
      />
      <Route
        path='/store/confirm'
        element={isLogin ? <StoreConfirm /> : <Navigate replace to='/login' />}
      />
      <Route
        path='/store/inquiry'
        element={isLogin ? <StoreInquiry /> : <Navigate replace to='/login' />}
      />
      <Route
        path='/store/inquiry/:id'
        element={
          isLogin ? <StoreInquiryDetail /> : <Navigate replace to='/login' />
        }
      />
      <Route
        path='/store/review'
        element={isLogin ? <StoreReview /> : <Navigate replace to='/login' />}
      />
      <Route
        path='/store/review/:id'
        element={
          isLogin ? <StoreReviewDetail /> : <Navigate replace to='/login' />
        }
      />
      <Route
        path='/store/coupon'
        element={isLogin ? <Coupon /> : <Navigate replace to='/login' />}
      />
      <Route
        path='/store/coupon/register'
        element={
          isLogin ? <RegisterCoupon /> : <Navigate replace to='/login' />
        }
      />
      <Route
        path='/store/coupon/:id'
        element={isLogin ? <CouponDetail /> : <Navigate replace to='/login' />}
      />


      <Route
        path='/marketing/utm/register'
        element={isLogin ? <UtmRegist /> : <Navigate replace to='/login' />}
      />

      <Route
        path='/marketing/utm/list'
        element={isLogin ? <UtmList /> : <Navigate replace to='/login' />}
      />

      <Route
        path='/marketing/utm/chart'
        element={isLogin ? <UtmChart /> : <Navigate replace to='/login' />}
      />


      <Route path='*' element={<Admin />} />
    </ReactRouterRoutes>
  );
}

export default Routes;
