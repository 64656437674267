import styled from 'styled-components';

import { I_BillLogProduct } from 'etc/types';

interface I_StoreOrderDetailTableItem {
  item: I_BillLogProduct;
}

function StoreOrderDetailTableItem({ item }: I_StoreOrderDetailTableItem) {
  return (
    <S_TableRow>
      <S_TableColData>{item.id}</S_TableColData>
      <S_TableColData>{item.orderStatusName}</S_TableColData>
      <S_TableColData style={{ minWidth: '279px' }}>
        {item.product.name}
      </S_TableColData>
      <S_TableColData>{item.productOptionName}</S_TableColData>
      <S_TableColData>{item.productCount}</S_TableColData>
      <S_TableColData>{item.productPaymentPrice}</S_TableColData>
      <S_TableColData>{item.product.id}</S_TableColData>
    </S_TableRow>
  );
}

export default StoreOrderDetailTableItem;

const S_TableColData = styled.td`
  text-align: center;
  vertical-align: middle;
  border: 1px solid ${(props) => props.theme.colors.hd3};
  padding: 10px;
`;

const S_TableRow = styled.tr`
  height: 36px;
  font-weight: 500;
  font-size: 13px;
`;
