import { useState } from 'react';
import styled from 'styled-components';

import MainLayout from 'components/Layout/MainLayout';
import Dropdown from 'components/Dropdown/Dropdown';
import TableInput from 'components/Input/TableInput';
import DateInput from 'components/Input/DateInput';
import NormalButton from 'components/Button/NormalButton';
import ChattingTableItem from 'components/Item/ChattingTableItem';
import MiniButton from 'components/Button/MiniButton';
import NormalModal from 'components/Modal/NormalModal';

import { getChatUserList, getChattingList } from 'api/chatting';
import { I_ChatRoom, I_ChatUser, I_Chatting } from 'etc/types';
import useLogout from 'hooks/useLogout';

function Chatting() {
  const Logout = useLogout();

  const [isCategoryDropdownOpen, setIsCategoryDropdownOpen] =
    useState<boolean>(false);
  const [isUserDropdownOpen, setIsUserDropdownOpen] = useState<boolean>(false);
  const [selectedCategoryItem, setSelectedCategoryItem] =
    useState<string>('카테고리 선택');
  const [user1, setUser1] = useState<string>('');
  const [user2, setUser2] = useState<number>(0);
  const [seletedUserItem, setSeletedUserItem] = useState<string>('User 2');
  const [fromDate, setFromDate] = useState<string>('');
  const [toDate, setToDate] = useState<string>('');
  const [chattingList, setChattingList] = useState<I_Chatting[]>([]);
  const [isSearchBoxOpen, setIsSearchBoxOpen] = useState<boolean>(false);
  const [chatUserList, setChatUserList] = useState<string[]>([]);
  const [chatRoomCodeList, setChatRoomCodeList] = useState<I_ChatRoom[]>([]);
  const [isImageModal, setIsImageModal] = useState<boolean>(false);
  const [imageUrl, setImageUrl] = useState<string>('');

  const onClickImage = (url: string) => {
    setIsImageModal(true);
    setImageUrl(url);
  };

  const onCategoryItemClick = (item: string) => {
    setSelectedCategoryItem(item);
    setIsCategoryDropdownOpen(false);
  };

  const onUserItemSearch = (item: string) => {
    setSeletedUserItem(item);
    setIsUserDropdownOpen(false);
  };

  const onKeyPressHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      getAsyncUserList();
    }
  };

  const onClickSearchUser = () => {
    getAsyncUserList();
  };

  const onClickResetSearch = () => {
    setSelectedCategoryItem('카테고리 선택');
    setUser1('');
    setIsSearchBoxOpen(false);
    setSeletedUserItem('User 2');
    setFromDate('');
    setToDate('');
    setChattingList([]);
  };

  const onClickChattingList = async () => {
    if (seletedUserItem === 'User 2') {
      alert('#User 2 code를 선택해주세요.');
      return false;
    } else if (!fromDate || !toDate) {
      alert('기간을 선택해주세요.');
      return false;
    } else {
      const user2Info = chatRoomCodeList.filter(
        (el: I_ChatRoom) => el.userCode === seletedUserItem
      );

      setUser2(user2Info[0].userId);

      const data = await getChattingList(
        user2Info[0].chatRoomCode,
        fromDate,
        toDate
      );

      if (data.ok) {
        // console.log(data.res);
        if (data.res.length === 0) {
          alert('메세지가 없습니다.');
        }
        setChattingList(data.res);
      } else {
        if (data.res.status === 315) Logout();
        else {
          alert('잠시 후 다시 시도해주세요.');
          console.error(data.res.error.message);
        }
      }
    }
  };

  const getAsyncUserList = async () => {
    setUser2(0);
    setSeletedUserItem('User 2');
    setChattingList([]);

    const categoryIndex = CATEGORY_FILTER_LIST.indexOf(selectedCategoryItem);
    const user: string[] = [];
    const chatRoomCode: I_ChatRoom[] = [];

    if (selectedCategoryItem === '카테고리 선택') {
      alert('카테고리를 선택해주세요.');
      return false;
    } else if (!user1) {
      alert('#User code를 입력해주세요.');
      return false;
    } else {
      const data = await getChatUserList(
        CATEGORY_LIST[categoryIndex],
        user1.replaceAll('#', '%23').trim()
      );
      if (data.ok) {
        if (data.res.length === 0) {
          alert('채팅을 나눈 유저가 없습니다.');
          setIsSearchBoxOpen(false);
          setChattingList([]);
        } else {
          setIsSearchBoxOpen(true);
          data.res.forEach((el: I_ChatUser) => {
            // console.log(el);
            user.push(el.user.userCode);
            chatRoomCode.push({
              userId: el.user.id,
              userCode: el.user.userCode,
              chatRoomCode: el.chatRoom.chatRoomCode,
            });
          });
          setChatUserList(user);
          setChatRoomCodeList(chatRoomCode);
        }
      } else {
        if (data.res.status === 315) Logout();
        else if (data.res.status === 404) {
          alert('올바른 유저코드를 입력해주세요.');
          setIsSearchBoxOpen(false);
          // console.error(data.res.error.message);
        } else {
          alert('잠시 후 다시 시도해주세요.');
          console.error(data.res.error.message);
        }
      }
    }
  };

  return (
    <MainLayout>
      {/* 카테고리 */}
      <S_CategoryContainer>
        <S_CategoryFilterContainer>
          <S_CategoryFilterRow>
            <S_CategoryFilterTitle>상세 검색</S_CategoryFilterTitle>
            <S_CategoryFilterWrapper>
              <S_CategoryFilterWrapper>
                <Dropdown
                  list={CATEGORY_FILTER_LIST}
                  selectedItem={selectedCategoryItem}
                  isOpen={isCategoryDropdownOpen}
                  setIsOpen={setIsCategoryDropdownOpen}
                  onClick={(item: string) => onCategoryItemClick(item)}
                  style={{ width: 160 }}
                />
              </S_CategoryFilterWrapper>
              <TableInput
                state={user1}
                setState={setUser1}
                style={{ width: '235px', fontSize: 13 }}
                placeholder='User 1'
                onKeyPress={onKeyPressHandler}
              />
              <MiniButton onClick={() => onClickSearchUser()}>검색</MiniButton>
            </S_CategoryFilterWrapper>
          </S_CategoryFilterRow>
          {isSearchBoxOpen && (
            <>
              <S_CategoryFilterRow>
                <S_CategoryFilterTitle>#User 2 code</S_CategoryFilterTitle>
                <S_CategoryFilterWrapper>
                  <Dropdown
                    list={chatUserList}
                    selectedItem={seletedUserItem}
                    isOpen={isUserDropdownOpen}
                    setIsOpen={setIsUserDropdownOpen}
                    onClick={(item: string) => onUserItemSearch(item)}
                    style={{ width: 160 }}
                  />
                </S_CategoryFilterWrapper>
              </S_CategoryFilterRow>
              <S_CategoryFilterRow>
                <S_CategoryFilterTitle>기간</S_CategoryFilterTitle>

                <S_DateBox>
                  <DateInput
                    state={fromDate}
                    setState={setFromDate}
                    max={toDate}
                    style={{ width: 136, borderRadius: 6 }}
                  />
                  <span> ~ </span>
                  <DateInput
                    state={toDate}
                    setState={setToDate}
                    startDate={fromDate}
                    min={fromDate}
                    style={{ width: 136, borderRadius: 6 }}
                  />
                  <MiniButton onClick={() => onClickChattingList()}>
                    검색
                  </MiniButton>
                </S_DateBox>
              </S_CategoryFilterRow>
            </>
          )}
        </S_CategoryFilterContainer>
      </S_CategoryContainer>

      <S_TableContainer>
        {isSearchBoxOpen && (
          <S_TableButtonContainer>
            <NormalButton
              onClick={() => onClickResetSearch()}
              style={{ width: 125 }}
            >
              검색 초기화
            </NormalButton>
          </S_TableButtonContainer>
        )}
        <S_TableBox>
          <S_Table>
            <thead>
              <tr>
                {TABLE_HEADER.map((v, i) => (
                  <S_TableHeaderItem key={i}>{v}</S_TableHeaderItem>
                ))}
              </tr>
            </thead>
            <tbody>
              {chattingList.map((v: I_Chatting, i: number) => (
                <ChattingTableItem
                  key={i}
                  item={v}
                  user2={user2}
                  onClick={() => onClickImage(v.message)}
                />
              ))}
            </tbody>
          </S_Table>
        </S_TableBox>
      </S_TableContainer>

      {isImageModal && (
        <NormalModal
          state={isImageModal}
          setState={setIsImageModal}
          title='채팅 이미지'
        >
          <S_ModalContent>
            <S_BigImg src={imageUrl} />
          </S_ModalContent>
        </NormalModal>
      )}
    </MainLayout>
  );
}

export default Chatting;

const S_BigImg = styled.img`
  max-width: 1000px;
  max-height: 500px;
`;

const S_ModalContent = styled.div`
  padding: 13px 50px 60px;
`;

const S_DateBox = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;

  button {
    margin-left: 8px;
  }
`;

const S_TableButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding: 16px 0;
`;

const S_TableHeaderItem = styled.th`
  vertical-align: middle;
  padding: 10px;
  height: 36px;
  background-color: ${(props) => props.theme.colors.hd4};
  border: 0.5px solid ${(props) => props.theme.colors.hd3};
  font-weight: 500;
  font-size: 13px;
  letter-spacing: -0.02em;
  color: ${(props) => props.theme.colors.n10};

  &:nth-child(1) {
    width: 200px;
  }

  &:nth-child(2),
  &:nth-child(3) {
    min-width: calc(50% - 100px);
  }
`;

const S_Table = styled.table`
  width: 100%;
  table-layout: fixed;
`;

const S_TableBox = styled.div`
  max-height: 600px;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.theme.colors.hd3};
    border-radius: 6px;
  }
`;

const S_TableContainer = styled.div`
  margin-top: 32px;
  position: relative;
`;

const S_CategoryFilterWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const S_CategoryFilterTitle = styled.span`
  width: 90px;
  font-weight: 400;
  font-size: 14px;
  line-height: 23px;
  letter-spacing: -0.02em;
`;

const S_CategoryFilterRow = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

const S_CategoryFilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 14px 16px;
  border: 1px solid ${(props) => props.theme.colors.hd3};
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  letter-spacing: -0.02em;
  /* border-radius: 8px; */
`;

const S_CategoryContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const TABLE_HEADER = ['업로드 날짜', 'User 1', 'User 2'];

const CATEGORY_FILTER_LIST = [
  '돌봄',
  '분양',
  '중고거래',
  '커뮤니티(가정교배)',
  '동반산책',
];
const CATEGORY_LIST = ['PETSITTER', 'SELL', 'MARKET', 'COMMUNITY', 'WALK'];
