import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import MainLayout from 'components/Layout/MainLayout';
import LongButton from 'components/Button/LongButton';
import DisabledInput from 'components/Input/DisabledInput';

import DisabledTextarea from 'components/Input/DisabledTextarea';

function PolicyDetail() {
  const navigate = useNavigate();

  return (
    <MainLayout>
      <S_Section>
        <S_Table>
          <tbody>
            <tr>
              <th>제목</th>
              <td colSpan={3}>
                <DisabledInput text={'올펫 서비스 이용 약관'} />
              </td>
            </tr>
            <tr>
              <th className='alignTop'>본문</th>
              <td colSpan={3}>
                <DisabledTextarea
                  text={'본문 내용~~ '}
                  style={{ height: 630 }}
                />
              </td>
            </tr>
          </tbody>
        </S_Table>
      </S_Section>

      <S_ButtonBox>
        <LongButton onClick={() => navigate('/service/policy/update')}>
          수정하기
        </LongButton>
      </S_ButtonBox>
    </MainLayout>
  );
}

export default PolicyDetail;

const S_Table = styled.table`
  border-collapse: separate;
  border-spacing: 20px;

  th {
    width: 120px;
    text-align: left;
    font-size: 14px;
    font-weight: 500;
    &:nth-child(1) {
      width: 100px;
    }
    &:nth-child(3) {
      width: 100px;
      text-align: right;
      padding-right: 15px;
    }
    &.alignTop {
      vertical-align: top;
      padding-top: 14px;
    }
  }
`;

const S_ButtonBox = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 50px;
  padding-right: 20px;
`;

const S_Title = styled.p`
  font-size: 16px;
  color: ${(props) => props.theme.colors.hd1};
  font-weight: 700;
`;

const S_Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 17px;
  padding: 30px 20px;
`;

const USER_LIST = ['유저1', '유저2', '유저3', '유저4'];
