import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import MainLayout from 'components/Layout/MainLayout';
import PagenationButton from 'components/Button/PagenationButton';
import TabButton from 'components/Button/TabButton';
import KindTableItem from 'components/Item/KindTableItem';
import TableButton from 'components/Button/TableButton';

import { TABLE_LIMIT } from 'etc/variable';
import { getBreedList } from 'api/breed';
import useLogout from 'hooks/useLogout';
import { I_Breed } from 'etc/types';

function KindInfo() {
  const Logout = useLogout();
  const navigate = useNavigate();

  const [tabIndex, setTabIndex] = useState<number>(0); // tab
  const [totalCount, setTotalCount] = useState<number>(0); // 전체 개수
  const [activePage, setActivePage] = useState<number>(1); // 현재 페이지
  const [kindList, setKindList] = useState<I_Breed[]>([]);

  const handlePageChange = (page: number) => {
    setActivePage(page);
  };

  // tab button 클릭 시
  const onTabClick = (index: number) => {
    setTabIndex(index);
    setActivePage(1);
  };

  const getAsyncKindList = async () => {
    const data = await getBreedList(TABLE_LIMIT, activePage, tabIndex + 1);
    if (data.ok) {
      // console.log(data.res);
      setKindList(data.res.breeds);
      setTotalCount(data.res.totalCount);
    } else {
      if (data.res.status === 315) Logout();
      else {
        alert('잠시 후 다시 시도해주세요.');
        console.error(data.res.error.message);
      }
    }
  };

  useEffect(() => {
    getAsyncKindList();
  }, [activePage, tabIndex]);

  return (
    <MainLayout>
      <S_TabTitle>동물종 별 등록 현황</S_TabTitle>
      <S_TabButtonBox>
        {TAB.map((v, i) => (
          <TabButton
            key={v.id}
            title={v.title}
            isActive={tabIndex === i}
            onClick={() => onTabClick(v.id)}
          />
        ))}
      </S_TabButtonBox>
      <S_TableButtonContainer>
        <S_TableTitle>상세 목록</S_TableTitle>
        {tabIndex !== 3 && (
          <TableButton
            onClick={() =>
              navigate(`/service/kind/register`, {
                state: {
                  kindType: TAB[tabIndex].title,
                  code: TAB[tabIndex].code,
                },
              })
            }
          >
            추가 등록하기
          </TableButton>
        )}
      </S_TableButtonContainer>
      <S_TableContainer>
        <S_TableBox>
          <S_Table>
            <thead>
              <tr>
                {TABLE_HEADER.map((v, i) => (
                  <S_TableHeaderItem key={i}>{v}</S_TableHeaderItem>
                ))}
              </tr>
            </thead>
            <tbody>
              {kindList.map((v: I_Breed, i: number) => (
                <KindTableItem key={i} item={v} kindType={tabIndex} />
              ))}
            </tbody>
          </S_Table>
        </S_TableBox>

        <PagenationButton
          activePage={activePage}
          totalCount={totalCount}
          postPerPage={TABLE_LIMIT}
          handlePageChange={handlePageChange}
        />
      </S_TableContainer>
    </MainLayout>
  );
}

export default KindInfo;

const S_TabTitle = styled.p`
  margin-bottom: 16px;
  font-size: 16px;
  font-weight: 700;
  color: ${(props) => props.theme.colors.hd1};
`;

const S_TabButtonBox = styled.div`
  display: flex;
  gap: 17px;
`;

const S_TableTitle = styled.span`
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.02em;
  display: flex;
  align-items: flex-end;
`;

const S_TableButtonContainer = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
`;

const S_TableHeaderItem = styled.th`
  vertical-align: middle;
  padding: 10px;
  height: 36px;
  background-color: ${(props) => props.theme.colors.hd4};
  border: 0.5px solid ${(props) => props.theme.colors.hd3};
  font-weight: 500;
  font-size: 13px;
  letter-spacing: -0.02em;
  color: ${(props) => props.theme.colors.n10};
`;

const S_Table = styled.table`
  width: 100%;
`;

const S_TableBox = styled.div`
  overflow-x: scroll;
  &::-webkit-scrollbar {
    width: 4px;
    height: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.theme.colors.hd3};
    border-radius: 6px;
  }
`;

const S_TableContainer = styled.div`
  margin-top: 8px;
  position: relative;
`;

const TABLE_HEADER = ['동물 종', '등록 품종 명', '품종 코드', '등록 상태'];

const TAB = [
  {
    id: 0,
    title: '강아지',
    code: 'D',
  },
  {
    id: 1,
    title: '고양이',
    code: 'C',
  },
  {
    id: 2,
    title: '다른 동물',
    code: 'N',
  },
  {
    id: 3,
    title: '직접 등록',
  },
];
