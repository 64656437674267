import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import MainLayout from 'components/Layout/MainLayout';
import LongButton from 'components/Button/LongButton';
import DisabledInput from 'components/Input/DisabledInput';
import { I_CouponDetail } from 'etc/types';
import { deleteCoupon, getCouponDetail } from 'api/coupon';
import useLogout from 'hooks/useLogout';
import { dateFormatUTC, numberFormat } from 'utils/common';
import moment from 'moment';

function CouponDetail() {
  const Logout = useLogout();
  const navigate = useNavigate();
  const { id } = useParams();

  const [coupon, setCoupon] = useState<I_CouponDetail>({
    id: 0,
    createdAt: '',
    updatedAt: '',
    product: 0,
    name: '',
    membership: false,
    minBuyPrice: 0,
    maxDiscountPrice: 0,
    discountPercent: 0,
    startDate: '',
    endDate: '',
    brand: {
      id: 0,
      createdAt: '',
      updatedAt: '',
      IsHidden: false,
      name: '',
    },
  });
  const endDate = moment(coupon.endDate);
  const currentDate = moment();
  const hasNotPassed = endDate.isSameOrAfter(currentDate);

  const onMoveProductpage = (productId: number) => {
    if (productId !== 0) {
      navigate(`/store/product/${coupon.product}`);
    }
  };

  const onClickDeleteCoupon = async () => {
    const data = await deleteCoupon(Number(id));

    if (data.ok) {
      alert('쿠폰이 삭제되었습니다.');
      navigate('/store/coupon');
    } else {
      if (data.res.status === 315) Logout();
      else {
        alert('잠시 후 다시 시도해주세요.');
        console.error(data.res);
      }
    }
  };

  const getAsyncCouponDetail = async () => {
    const data = await getCouponDetail(Number(id));
    if (data.ok) {
      // console.log(data.res.item);
      setCoupon(data.res.item);
    } else {
      if (data.res.status === 315) Logout();
      else {
        alert('잠시 후 다시 시도해주세요.');
        console.error(data.res.error.message);
      }
    }
  };

  useEffect(() => {
    getAsyncCouponDetail();
  }, []);

  return (
    <MainLayout>
      <S_Section>
        <S_Title>쿠폰 상세 정보</S_Title>
        <S_Table>
          <tbody>
            <tr>
              <th>쿠폰 이름</th>
              <td colSpan={3}>
                <DisabledInput text={coupon.name} />
              </td>
            </tr>
            <tr>
              <th>할인율</th>
              <td>
                <S_InputUnit>
                  <DisabledInput text={coupon.discountPercent} />
                  <S_Unit>%</S_Unit>
                </S_InputUnit>
              </td>
              <th>적용 유저</th>
              <td>
                <DisabledInput
                  text={coupon.membership ? '유료 유저' : '전체 유저'}
                />
              </td>
            </tr>
            <tr>
              <th>할인 브랜드</th>
              <td>
                <DisabledInput
                  text={!coupon.brand ? '전체 브랜드' : coupon.brand.name}
                />
              </td>
              <th>할인 상품</th>
              <td
                onClick={() => onMoveProductpage(coupon.product)}
                style={{
                  cursor: coupon.product !== 0 ? 'pointer' : '',
                  textDecoration: coupon.product !== 0 ? 'underline' : '',
                }}
              >
                <DisabledInput
                  text={coupon.product === 0 ? '전체 상품' : coupon.product}
                />
              </td>
            </tr>
          </tbody>
        </S_Table>
      </S_Section>

      <S_Section style={{ borderBottom: 0 }}>
        <S_Table>
          <tbody>
            <tr>
              <th>최소 사용 금액</th>
              <td>
                <DisabledInput text={numberFormat(coupon.minBuyPrice) + '원'} />
              </td>
              <th>최대 할인 금액</th>
              <td>
                <DisabledInput
                  text={numberFormat(coupon.maxDiscountPrice) + '원'}
                />
              </td>
            </tr>
            <tr>
              <th>쿠폰 사용 기능</th>
              <td colSpan={3}>
                <S_DateBox>
                  <DisabledInput
                    text={dateFormatUTC(coupon.startDate).format(
                      'YYYY년 M월 D일 HH:mm'
                    )}
                    style={{ width: '45%' }}
                  />
                  ~
                  <DisabledInput
                    text={dateFormatUTC(coupon.endDate).format(
                      'YYYY년 M월 D일 HH:mm'
                    )}
                    style={{ width: '45%' }}
                  />
                </S_DateBox>
              </td>
            </tr>
            <tr>
              <th>활성 상태</th>
              <td
                style={{
                  paddingTop: 50,
                  color: hasNotPassed ? '#13A872' : '#FC1D1D',
                }}
              >
                <DisabledInput
                  text={hasNotPassed ? '사용 가능' : '기간 종료'}
                />
              </td>
            </tr>
          </tbody>
        </S_Table>
      </S_Section>
      <S_ButtonBox>
        <LongButton
          onClick={() => onClickDeleteCoupon()}
          style={{ backgroundColor: '#FC1D1D' }}
        >
          쿠폰 삭제하기
        </LongButton>
      </S_ButtonBox>
    </MainLayout>
  );
}

export default CouponDetail;

const S_Unit = styled.span`
  position: absolute;
  font-size: 14px;
  color: #888888;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
`;

const S_InputUnit = styled.div`
  position: relative;
`;

const S_DateBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  input {
    width: 45%;
    border-radius: 12px;
  }
`;

const S_Table = styled.table`
  border-collapse: separate;
  border-spacing: 20px;

  td {
    width: 40%;
  }
  th {
    min-width: 100px;
    font-size: 14px;
    font-weight: 500;
    &:nth-child(3) {
      padding-right: 15px;
      text-align: right;
    }
    &.alignTop {
      vertical-align: top;
      padding-top: 14px;
    }
  }
`;

const S_ButtonBox = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 50px;
  padding-right: 20px;
`;

const S_Title = styled.p`
  font-size: 16px;
  color: ${(props) => props.theme.colors.hd1};
  font-weight: 700;
`;

const S_Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 17px;
  padding: 30px 20px;
  border-bottom: 1px solid ${(props) => props.theme.colors.hd4};

  &:last-child {
    border-bottom: 0;
  }
`;
