import { callApi } from './callApi';

// 신고 리스트
export const getReportList = async (
  reportStatus: string,
  limit: number,
  currentPage: number
) => {
  const res = await callApi({
    url: `/admin/report/list?${reportStatus}&currentPage=${currentPage}&limit=${limit}`,
    method: 'GET',
  });
  return res;
};

// 신고 상세
export const getReportDetail = async (reportId: number) => {
  const res = await callApi({
    url: `/admin/report/${reportId}`,
    method: 'GET',
  });
  return res;
};

// 신고 - 게시글 숨김
export const postReportHidden = async (reportId: number) => {
  const res = await callApi({
    url: `/admin/report/${reportId}/hidden`,
    method: 'POST',
  });
  return res;
};

// 신고 - 유저 제재
export const postReportSanction = async (reportId: number, userId: number) => {
  const res = await callApi({
    url: `/admin/report/${reportId}/sanction/${userId}`,
    method: 'POST',
  });
  return res;
};

// 유저 정보 검색
export const getReportUserSearch = async (
  userCode: string,
  limit: number,
  page: number
) => {
  const res = await callApi({
    url: `/admin/report/search/${userCode}?limit=${limit}&currentPage=${page}`,
    method: 'GET',
  });
  return res;
};
