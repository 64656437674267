import { callApi } from './callApi';

export const postLogin = async (data: {
  account: string;
  password: string;
}) => {
  const res = await callApi({
    url: `/admin/login`,
    method: 'POST',
    data: data,
  });
  return res;
};

export const patchPassword = async (data: {
  account: string;
  password: string;
}) => {
  const res = await callApi({
    url: `/admin/password`,
    method: 'PATCH',
    data: data,
  });
  return res;
};
