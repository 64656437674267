import { useEffect, useState } from 'react';
import { I_TravelProduct } from 'etc/types';
import { useNavigate } from 'react-router-dom';
import { dateFormat } from 'utils/common';

import LongButton from 'components/Button/LongButton';
import MiniButton from 'components/Button/MiniButton';
import CustomButton from 'components/Button/CustomButton';
import NormalButton from 'components/Button/NormalButton';
import PeriodButton from 'components/Button/PeriodButton';
import { patchTravelProduct } from 'api/travelProduct';


import styled from 'styled-components';

interface I_TravelProductTableItem {
  item: I_TravelProduct;
  onChangeData: (updateTravelProduct: I_TravelProduct) => void;
}
function TravelProductTableItem({ item, onChangeData }: I_TravelProductTableItem) {
  const navigate = useNavigate();

  const updateStatus = async (travelProductId: number) => {
    console.log('travelProductId : ', travelProductId);
    try {

      let msg = '';
      
      if(item.travelProductStatus === 'ACTIVE') {
        msg ='비공개로 변경할까요??';
      }else{
        msg ='공개로 변경할까요??';
      }

      if(confirm(msg)){

        const data = {
          travelProductStatus: item.travelProductStatus === 'ACTIVE' ? 'DEACTIVE' : 'ACTIVE',  // 상태를 반전
        };
      
        console.log('data : ', data);
      
        // FormData 생성
        const formData = new FormData();
        
        // 객체의 각 속성을 FormData에 추가
        Object.keys(data).forEach(key => {
          formData.append(key, (data as any)[key]);
        });
      
        const response = await patchTravelProduct(Number(travelProductId), formData);
        console.log('response :' , response)
        if (response.ok) {
            alert('업데이트 되었습니다.');
            onChangeData(response.res);

            // await getTravelProductOption(); // 변경된 데이터 다시 가져오기
          } else {
            alert('업데이트 실패');
          }
      }

    } catch (error) {
      console.error('옵션 수정 에러:', error);
    }
  };

  return (
    <S_TableRow>
      <S_TableColData>{item.id}</S_TableColData>
      <S_TableColData>{item.travelProductCategoryName}</S_TableColData>
      <S_TableColData
        style={{ textDecoration: 'underline', cursor: 'pointer', color: 'blue' }}
        onClick={() => navigate(`/travelProduct/${item.id}`)}
      >
        {item.title}
      </S_TableColData>
      <S_TableColData>{item.city}</S_TableColData>
      <S_TableColData>{item.place}</S_TableColData>
      <S_TableColData>{item.address}</S_TableColData>

      <S_TableColData>
        <S_Button>
          { item.travelProductStatus === 'ACTIVE' &&
            <CustomButton
              style={{ width: '60px', height: '30px', backgroundColor: '#6c4ae8', borderRadius: '20px' }}
              onClick={() => updateStatus(item.id)}
              type="button"
            >
              공개
            </CustomButton>
          }
          { item.travelProductStatus === 'DEACTIVE' &&
            <CustomButton
              style={{ width: '60px', height: '30px', backgroundColor: '#b649c4', borderRadius: '20px' }}
              onClick={() => updateStatus(item.id)}
              type="button"
            >
              비공개
            </CustomButton>
          }

        </S_Button>
      </S_TableColData>

      <S_TableColData>
        <S_Button>
          <CustomButton
            style={{ width: '60px', height: '30px', backgroundColor: '#0c9656', borderRadius: '20px' }}
            onClick={() => navigate(`/travelProduct/${item.id}/options`)}
            type="button"
          >
            옵션추가
          </CustomButton>
        </S_Button>
      </S_TableColData>
    </S_TableRow>
  );
}

export default TravelProductTableItem;


const S_Button = styled.div`
  /* background-color: skyblue; */
  width: 100%;
  display: flex;
  margin-left: '5px';
  justify-content: space-evenly;
`;


const S_TableColData = styled.td`
  text-align: center;
  vertical-align: middle;
  border: 1px solid ${(props) => props.theme.colors.hd3};
  padding: 10px;

  &:nth-child(1) {
    min-width: 120px;
  }
  &:nth-child(2) {
    min-width: 120px;
  }
  &:nth-child(3) {
    min-width: 230px;
  }
  &:nth-child(4) {
    min-width: 80px;
  }
  &:nth-child(5) {
    min-width: 80px;
  }
  &:nth-child(6) {
    min-width: 20px;
  }
  &:nth-child(7) {
    min-width: 100px;
  }
  &:nth-child(8) {
    min-width: 100px;
  }
  &:nth-child(9) {
    min-width: 120px;
  }
  &:nth-child(10) {
    min-width: 80px;
  }
`;

const S_TableRow = styled.tr`
  height: 36px;
  font-weight: 500;
  font-size: 13px;
`;


