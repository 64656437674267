import { I_UserPet } from 'etc/types';
import { useEffect, useState } from 'react';

import styled from 'styled-components';
import { dateFormat } from 'utils/common';

interface I_CareUserManagementTableItem {
  item: I_UserPet;
}

function PetTableItem({ item }: I_CareUserManagementTableItem) {
  const [progress, setProgress] = useState<string>('');
  const [cure, setCure] = useState<string>('');
  const [surgery, setSurgery] = useState<string>('');

  const filterDiseases = () => {
    const result1 = item.diseases
      .filter((item) => item.type === '진행')
      .map((item) => item.content)
      .join('/');
    const result2 = item.diseases
      .filter((item) => item.type === '완치')
      .map((item) => item.content)
      .join('/');
    const result3 = item.diseases
      .filter((item) => item.type === '수술')
      .map((item) => item.content)
      .join('/');

    setProgress(result1);
    setCure(result2);
    setSurgery(result3);
  };

  useEffect(() => {
    filterDiseases();
  }, []);

  return (
    <>
      <S_TableRow>
        <S_TableHeader>이름</S_TableHeader>
        <S_TableColData>{item?.name}</S_TableColData>
        <S_TableHeader>등록번호</S_TableHeader>
        <S_TableColData>{item.petNumber}</S_TableColData>
      </S_TableRow>
      <S_TableRow>
        <S_TableHeader>성별</S_TableHeader>
        <S_TableColData>{item?.gender ? '남아' : '여아'}</S_TableColData>
        <S_TableHeader>생년월일</S_TableHeader>
        <S_TableColData>
          {dateFormat(item.birth).format('YYYY-MM-DD')}
        </S_TableColData>
      </S_TableRow>
      <S_TableRow>
        <S_TableHeader>동물종</S_TableHeader>
        <S_TableColData>{item?.animalType?.animal}</S_TableColData>
        <S_TableHeader>털길이</S_TableHeader>
        <S_TableColData>{item?.furLength}</S_TableColData>
      </S_TableRow>
      <S_TableRow>
        <S_TableHeader>크기</S_TableHeader>
        <S_TableColData>{item?.size}</S_TableColData>
        <S_TableHeader>몸무게</S_TableHeader>
        <S_TableColData>{item?.weight}kg</S_TableColData>
      </S_TableRow>
      <S_TableRow>
        <S_TableHeader>품종</S_TableHeader>
        <S_TableColData>{item.breed?.breed}</S_TableColData>
        <S_TableHeader>중성화</S_TableHeader>
        <S_TableColData>
          {item.neutering === 0
            ? '안했어요'
            : item.neutering === 1
            ? '했어요'
            : '해당안됨'}
        </S_TableColData>
      </S_TableRow>
      <S_TableRow>
        <S_TableHeader>혈액형</S_TableHeader>
        <S_TableColData>{item.bloodType ? item.bloodType : '-'}</S_TableColData>
        <S_TableHeader>질환여부(진행중)</S_TableHeader>
        <S_TableColData>{progress ? progress : '-'}</S_TableColData>
      </S_TableRow>
      <S_TableRow>
        <S_TableHeader>질환여부(완치)</S_TableHeader>
        <S_TableColData>{cure ? cure : '-'}</S_TableColData>
        <S_TableHeader>수술 여부(완치)</S_TableHeader>
        <S_TableColData>{surgery ? surgery : '-'}</S_TableColData>
      </S_TableRow>
      <S_TableRow>
        <S_TableHeader>반려동물 성격</S_TableHeader>
        <S_TableColData colSpan={3}>
          {item?.personality ? item?.personality : '-'}
        </S_TableColData>
      </S_TableRow>
    </>
  );
}

export default PetTableItem;

const S_TableColData = styled.td`
  text-align: center;
  vertical-align: middle;
  border: 1px solid ${(props) => props.theme.colors.hd3};
  padding: 10px;
`;

const S_TableHeader = styled.th`
  vertical-align: middle;
  padding: 10px;
  height: 36px;
  background-color: ${(props) => props.theme.colors.hd4};
  border: 0.5px solid ${(props) => props.theme.colors.hd3};
  font-weight: 500;
  font-size: 13px;
  letter-spacing: -0.02em;
  color: ${(props) => props.theme.colors.n10};
`;

const S_TableRow = styled.tr`
  height: 36px;
  font-weight: 500;
  font-size: 13px;
`;
