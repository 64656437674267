import { useEffect, useState } from 'react';
import styled from 'styled-components';

import MainLayout from 'components/Layout/MainLayout';
import PagenationButton from 'components/Button/PagenationButton';
import PointTableItem from 'components/Item/PointTableItem';
import Dropdown from 'components/Dropdown/Dropdown';
import PointHistoryModal from 'components/Modal/PointHistoryModal';

import { TABLE_LIMIT } from 'etc/variable';
import { getPoint, getPointAwardHistory, postAward } from 'api/point';
import { I_Award, I_Point } from 'etc/types';
import { numberFormat } from 'utils/common';
import useLogout from 'hooks/useLogout';

import { ReactComponent as Checkbox } from 'assets/image/checkbox.svg';
import Input from 'components/Input/Input';
import NormalButton from 'components/Button/NormalButton';
import AwardTableItem from 'components/Item/AwardTableItem';

function Point() {
  const Logout = useLogout();

  const [isFilterDropdownOpen, setIsFilterDropdownOpen] =
    useState<boolean>(false);
  const [selectedFilterItem, setSelectedFilterItem] = useState<string>(
    FILTER_LIST[0]
  );
  const [pointTotalCount, setPointTotalCount] = useState<number>(0); // 포인트 전체 개수
  const [pointActivePage, setPointActivePage] = useState<number>(1); // 포인트 페이지
  const [awardTotalCount, setAwardTotalCount] = useState<number>(0); // 포인트 지급 전체 개수
  const [awardActivePage, setAwardActivePage] = useState<number>(1); // 포인트 지급 페이지
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [pointList, setPointList] = useState<I_Point[]>([]);
  const [awardList, setAwardList] = useState<I_Award[]>([]);
  const [totalPoint, setTotalPoint] = useState<number>(0);
  const [currentUserId, setCurrentUserId] = useState<number>(0);
  const [tabIndex, setTabIndex] = useState<number>(0);
  const [userCode, setUserCode] = useState<string>('');
  const [awardPoint, setAwardPoint] = useState<string>('');

  const handleAwardPageChange = (page: number) => {
    setAwardActivePage(page);
  };

  const handlePointPageChange = (page: number) => {
    setPointActivePage(page);
  };

  const onFilterItemClick = (item: string) => {
    setSelectedFilterItem(item);
    setIsFilterDropdownOpen(false);
    setPointActivePage(1);
  };

  const onClickModal = async (id: number | null) => {
    if (id) {
      setIsModalOpen(true);
      setCurrentUserId(id);
    }
  };

  const onClickTab = (tabIndex: number) => {
    setTabIndex(tabIndex);

    getAsyncAwardList();
  };

  const onClickAward = async () => {
    if (!userCode || !awardPoint) {
      alert('지급 항목을 입력해주세요.');
    } else {
      const data = await postAward(
        userCode.replaceAll('#', '%23'),
        Number(awardPoint)
      );
      if (data.ok) {
        alert('포인트가 지급되었습니다.');
        getAsyncAwardList();
        setUserCode('');
        setAwardPoint('');
      } else {
        if (data.res.status === 315) Logout();
        else {
          alert('잠시 후 다시 시도해주세요.');
          console.error(data.res.error.message);
        }
      }
    }
  };

  /* 포인트 지급 리스트 */
  const getAsyncAwardList = async () => {
    const data = await getPointAwardHistory(TABLE_LIMIT, awardActivePage);
    if (data.ok) {
      setAwardList(data.res.userPoints);
      setAwardTotalCount(data.res.totalCount);
    } else {
      if (data.res.status === 315) Logout();
      else {
        alert('잠시 후 다시 시도해주세요.');
        console.error(data.res.error.message);
      }
    }
  };

  /* 포인트 리스트 */
  const getAsyncPointList = async () => {
    const data = await getPoint(
      TABLE_LIMIT,
      pointActivePage,
      selectedFilterItem === '사용 포인트 많은 순' ? 'USED' : 'GET'
    );
    if (data.ok) {
      // console.log(data.res);
      setTotalPoint(data.res.totalPoint.totalUsedPoint);
      setPointList(data.res.userPoints);
      setPointTotalCount(data.res.totalCount);
    } else {
      if (data.res.status === 315) Logout();
      else {
        alert('잠시 후 다시 시도해주세요.');
        console.error(data.res.error.message);
      }
    }
  };

  useEffect(() => {
    
    if(pointActivePage || selectedFilterItem){
      getAsyncPointList();
    }
    
    if(awardActivePage){
      getAsyncAwardList();
    } 

  }, [pointActivePage, selectedFilterItem, awardActivePage]);


  return (
    <MainLayout>
      <S_TabBox>
        <S_Button
          className={tabIndex === 0 ? 'active' : ''}
          onClick={() => onClickTab(0)}
        >
          전체 포인트
        </S_Button>
        <S_Button
          className={tabIndex === 1 ? 'active' : ''}
          onClick={() => onClickTab(1)}
        >
          포인트 지급
        </S_Button>
      </S_TabBox>

      {tabIndex === 0 ? (
        <>
          <S_PointStatus>
            <p>포인트 지급 현황</p>
            <S_Table>
              <tbody>
                <tr>
                  <S_TableHeaderItem
                    style={{ width: 200, backgroundColor: '#C4E9DC' }}
                  >
                    총 사용 포인트
                  </S_TableHeaderItem>
                  <S_TableDataItem>
                    {numberFormat(totalPoint)} P
                  </S_TableDataItem>
                </tr>
              </tbody>
            </S_Table>
          </S_PointStatus>
          <S_PointPolicyBox>
            <S_PolicyTitle>뼈다귀 포인트 관련 정책</S_PolicyTitle>
            <S_PolicyItem>
              <p>유저는 산책 시 뼈다귀를 남길 수 있다</p>
              <S_TextBox>
                <S_Text>300m마다 1 뼈다귀를 남길 수 있다</S_Text>
                <S_Text>다른 유저가 남긴 뼈다귀를 주우면 1P</S_Text>
                <S_Text>
                  하루 산책 시 최대 10개의 뼈다귀 수집 가능 = 총 10P
                </S_Text>
              </S_TextBox>
            </S_PolicyItem>
            <S_PolicyItem>
              <p>유저는 산책 시 포인트를 쌓을 수 있다</p>
              <S_TextBox>
                <S_Text>50m마다 1 point 축적</S_Text>
                <S_Text>하루 최대 110p 축적 가능</S_Text>
              </S_TextBox>
            </S_PolicyItem>
          </S_PointPolicyBox>
          <S_TableButtonContainer>
            <Dropdown
              list={FILTER_LIST}
              selectedItem={selectedFilterItem}
              isOpen={isFilterDropdownOpen}
              setIsOpen={setIsFilterDropdownOpen}
              onClick={(item: string) => onFilterItemClick(item)}
              style={{ width: 160 }}
            />
          </S_TableButtonContainer>
          <S_TableContainer>
            <S_TableBox>
              <S_Table>
                <thead>
                  <tr>
                    {TABLE_HEADER.map((v, i) => (
                      <S_TableHeaderItem key={i}>
                        {v === 'check' ? (
                          <Checkbox
                            width='16.67px'
                            height='16.67px'
                            color='#2E2E2E'
                          />
                        ) : (
                          v
                        )}
                      </S_TableHeaderItem>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {pointList.map((v: I_Point, i: number) => (
                    <PointTableItem
                      key={i}
                      item={v}
                      onClick={() => onClickModal(v.user ? v.user.id : null)}
                    />
                  ))}
                </tbody>
              </S_Table>
            </S_TableBox>

            <PagenationButton
              activePage={pointActivePage}
              totalCount={pointTotalCount}
              postPerPage={TABLE_LIMIT}
              handlePageChange={handlePointPageChange}
            />
          </S_TableContainer>
        </>
      ) : (
        <>
          <S_PointStatus>
            <p style={{ marginBottom: 38 }}>유저 포인트 지급</p>
            <S_InputBox>
              <span>유저 코드</span>
              <S_Input>
                <Input state={userCode} setState={setUserCode} />
              </S_Input>
              <span>지급 포인트</span>
              <S_Input>
                <Input state={awardPoint} setState={setAwardPoint} />
              </S_Input>
            </S_InputBox>
            <S_ButtonBox>
              <NormalButton onClick={() => onClickAward()}>확인</NormalButton>
            </S_ButtonBox>
          </S_PointStatus>
          <S_TableTitle></S_TableTitle>
          <S_TableContainer>
            <S_TableBox>
              <S_Table>
                <thead>
                  <tr>
                    {TABLE_AWARD_HEADER.map((v, i) => (
                      <S_TableHeaderItem key={i}>
                        {v === 'check' ? (
                          <Checkbox
                            width='16.67px'
                            height='16.67px'
                            color='#2E2E2E'
                          />
                        ) : (
                          v
                        )}
                      </S_TableHeaderItem>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {awardList.map((v: I_Award, i: number) => (
                    <AwardTableItem key={i} item={v} />
                  ))}
                </tbody>
              </S_Table>
            </S_TableBox>
          </S_TableContainer>

          <PagenationButton
            activePage={awardActivePage}
            totalCount={awardTotalCount}
            postPerPage={TABLE_LIMIT}
            handlePageChange={handleAwardPageChange}
          />
        </>
      )}

      {isModalOpen && (
        <PointHistoryModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          id={currentUserId}
        />
      )}
    </MainLayout>
  );
}

export default Point;

const S_TableTitle = styled.p``;

const S_ButtonBox = styled.div`
  display: flex;
  justify-content: right;
  margin: 24px 0 66px;
`;

const S_Input = styled.div`
  flex: 1;
`;

const S_InputBox = styled.div`
  display: flex;
  align-items: center;
  gap: 40px;

  span {
    font-size: 14px;
  }
`;

const S_Button = styled.button`
  padding: 12px 26.5px;
  color: ${(props) => props.theme.colors.hd2};
  position: relative;

  &.active {
    font-weight: 700;
    color: ${(props) => props.theme.colors.hd1};

    &::after {
      content: '';
      display: block;
      width: 100%;
      height: 2px;
      background-color: ${(props) => props.theme.colors.hd1};
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }
`;

const S_TabBox = styled.div`
  display: flex;
  margin-bottom: 20px;
`;

const S_Text = styled.li`
  margin-left: 8px;
  padding-left: 15px;
  font-size: 13px;
  font-weight: 500;
  color: ${(props) => props.theme.colors.hd1};
  line-height: 150%;
  position: relative;

  &::before {
    content: '';
    display: block;
    width: 4px;
    height: 4px;
    margin: auto;
    border-radius: 50%;
    background-color: ${(props) => props.theme.colors.hd1};
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
  }
`;

const S_TextBox = styled.ul`
  display: flex;
  flex-direction: column;
`;

const S_PolicyItem = styled.div`
  p {
    font-size: 13px;
    font-weight: 700;
    color: #1b30f5;
    line-height: 150%;
  }
`;

const S_PolicyTitle = styled.p`
  font-size: 14px;
  font-weight: 700;
  color: ${(props) => props.theme.colors.hd2};
`;

const S_PointPolicyBox = styled.div`
  display: flex;
  gap: 80px;

  margin-top: 38px;
  padding: 16px;
  border: 1px solid ${(props) => props.theme.colors.hd3};
`;

const S_TableDataItem = styled.td`
  border: 1px solid ${(props) => props.theme.colors.hd3};
  text-align: center;
  vertical-align: middle;
`;

const S_PointStatus = styled.div`
  p {
    margin-bottom: 16px;
    font-size: 16px;
    font-weight: 700;
    color: ${(props) => props.theme.colors.hd1};
  }
`;

const S_TableButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  margin-top: 30px;
`;

const S_TableHeaderItem = styled.th`
  vertical-align: middle;
  padding: 10px;
  height: 36px;
  background-color: ${(props) => props.theme.colors.hd4};
  border: 0.5px solid ${(props) => props.theme.colors.hd3};
  font-weight: 500;
  font-size: 13px;
  letter-spacing: -0.02em;
  color: ${(props) => props.theme.colors.hd1};
`;

const S_Table = styled.table`
  width: 100%;
`;

const S_TableBox = styled.div`
  overflow-x: scroll;
  &::-webkit-scrollbar {
    width: 4px;
    height: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.theme.colors.hd3};
    border-radius: 6px;
  }
`;

const S_TableContainer = styled.div`
  margin-top: 8px;
  position: relative;
`;

const TABLE_HEADER = [
  'User Code',
  '닉네임',
  '총 취득 포인트',
  '총 사용 포인트',
];

const TABLE_AWARD_HEADER = ['User Code', '닉네임', '지급 포인트', '지급 일자', '지급 사유'];

const FILTER_LIST = ['사용 포인트 많은 순', '취득 포인트 많은 순 '];
