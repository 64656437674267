import styled from 'styled-components';

import { ReactComponent as Checkbox } from 'assets/image/checkbox.svg';
import { ReactComponent as CheckboxChecked } from 'assets/image/checkbox-checked.svg';

import { I_BillLogProduct } from 'etc/types';
import { numberFormat } from 'utils/common';

interface I_StoreDeliveryCancelTableItem {
  item: I_BillLogProduct;
  checkList: number[];
  setCheckList: React.Dispatch<React.SetStateAction<number[]>>;
}

function StoreDeliveryCancelTableItem({
  item,
  checkList,
  setCheckList,
}: I_StoreDeliveryCancelTableItem) {
  const onCheckBoxClick = (check: boolean) => {
    if (check) setCheckList((pre) => [...pre, item.id]);
    else setCheckList((pre) => pre.filter((v) => v !== item.id));
  };

  return (
    <S_TableRow>
      <S_TableColData style={{ width: '38px' }}>
        {checkList.includes(item.id) ? (
          <S_Checkbox onClick={() => onCheckBoxClick(false)}>
            <CheckboxChecked />
          </S_Checkbox>
        ) : (
          <S_Checkbox onClick={() => onCheckBoxClick(true)}>
            <Checkbox />
          </S_Checkbox>
        )}
      </S_TableColData>
      <S_TableColData>{item.id}</S_TableColData>
      <S_TableColData>{item.orderStatusName}</S_TableColData>
      <S_TableColData style={{ minWidth: '279px' }}>
        {item.product.name}
      </S_TableColData>
      <S_TableColData>{item.productOptionName}</S_TableColData>
      <S_TableColData>{item.productCount}</S_TableColData>
      <S_TableColData>
        {numberFormat(item.productPaymentPrice)}원
      </S_TableColData>
      <S_TableColData>{numberFormat(item.productPrice)}원</S_TableColData>
      <S_TableColData>
        {item.productPointDiscount
          ? `-${numberFormat(item.productPointDiscount)}원`
          : '-'}
      </S_TableColData>
      <S_TableColData>
        {item.productCouponDiscount
          ? `-${numberFormat(item.productCouponDiscount)}원`
          : '-'}
      </S_TableColData>
      <S_TableColData>{item.product.id}</S_TableColData>
    </S_TableRow>
  );
}

export default StoreDeliveryCancelTableItem;

const S_Checkbox = styled.div`
  width: 16.67px;
  height: 16.67px;
  color: #aeaeae;
  margin: 0 auto;
  cursor: pointer;
`;

const S_TableColData = styled.td`
  text-align: center;
  vertical-align: middle;
  border: 1px solid ${(props) => props.theme.colors.hd3};
  padding: 10px;
`;

const S_TableRow = styled.tr`
  height: 36px;
  font-weight: 500;
  font-size: 13px;
`;
