import { useEffect, useState } from 'react';

import styled from 'styled-components';

import { ReactComponent as Close } from 'assets/image/close.svg';

import ModalLayout from 'components/Layout/ModalLayout';
import useLogout from 'hooks/useLogout';
import { getPointHistory } from 'api/point';
import { I_PointHistory } from 'etc/types';
import { dateFormat, numberFormat } from 'utils/common';
import PagenationButton from 'components/Button/PagenationButton';

interface I_PointHistoryModal {
  onClose: () => void;
  isOpen: boolean;
  id: number;
}

function PointHistoryModal({ onClose, isOpen, id }: I_PointHistoryModal) {
  const Logout = useLogout();

  const [activePage, setActivePage] = useState<number>(1);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [tabState, setTabState] = useState<number>(0);
  const [pointHistory, setPointHistory] = useState<I_PointHistory[]>([]);

  const handleHistoryPageChange = (page: number) => {
    setActivePage(page);
  };

  const onClickPointTab = (i: number) => {
    setTabState(i);
    setActivePage(1);
  };

  /* 포인트 사용 내역 */
  const getAsyncPointHistory = async () => {
    const data = await getPointHistory(
      5,
      activePage,
      tabState === 0 ? 'GET' : 'USED',
      id
    );
    if (data.ok) {
      // console.log(data.res);
      setTotalCount(data.res.totalCount);
      setPointHistory(data.res.userPointLogs);
    } else {
      if (data.res.status === 315) Logout();
      else {
        alert('잠시 후 다시 시도해주세요.');
        console.error(data.res.error.message);
      }
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
      getAsyncPointHistory();
    } // body scroll 제거
  }, [isOpen, activePage, tabState]);

  return (
    <ModalLayout isOpen={isOpen} onClose={onClose}>
      <S_Container>
        <S_CloseButton
          onClick={() => {
            document.body.style.overflow = 'unset'; // body scroll 생성
            onClose();
          }}
        >
          <Close />
        </S_CloseButton>
        <S_Title>포인트 사용 내역</S_Title>

        <S_ModalContent>
          <S_ModalTabButtonBox>
            <S_ModalButton
              className={tabState == 0 ? 'active' : ''}
              onClick={() => onClickPointTab(0)}
            >
              포인트 취득 내역
            </S_ModalButton>
            <S_ModalButton
              className={tabState == 1 ? 'active' : ''}
              onClick={() => onClickPointTab(1)}
            >
              포인트 사용 내역
            </S_ModalButton>
          </S_ModalTabButtonBox>
          <S_PointList>
            {pointHistory.length === 0 && (
              <S_NoData>거래내역이 없습니다.</S_NoData>
            )}
            {pointHistory.map((v: I_PointHistory, i: number) => (
              <S_PointItem key={i}>
                <S_Date>
                  {dateFormat(v.createdAt).format('YY년 M월 D일')}
                </S_Date>
                <S_PointContent>
                  {!v.isGet && <S_Brand>{v.brand + ' '}</S_Brand>}
                  {!v.isGet ? v.productName + ' ' + v.option : v.reason}
                </S_PointContent>
                <S_PointCount>
                  {v.isGet ? '+' : '-'} {numberFormat(v.point)} P
                </S_PointCount>
              </S_PointItem>
            ))}
          </S_PointList>
          <S_Pagenation>
            <PagenationButton
              activePage={activePage}
              totalCount={totalCount}
              postPerPage={5}
              handlePageChange={handleHistoryPageChange}
            />
          </S_Pagenation>
        </S_ModalContent>
      </S_Container>
    </ModalLayout>
  );
}

export default PointHistoryModal;

const S_NoData = styled.p`
  text-align: center;
`;

const S_Brand = styled.span`
  font-weight: 700;
`;

const S_Pagenation = styled.div`
  margin-top: -30px;
`;

const S_PointCount = styled.span`
  font-size: 16px;
  font-weight: 700;
  color: ${(props) => props.theme.colors.hd1};
  line-height: 150%;
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
`;

const S_PointContent = styled.p`
  font-size: 14px;
  font-weight: 400;
  color: ${(props) => props.theme.colors.hd1};
  line-height: 150%;
`;

const S_Date = styled.span`
  font-size: 14px;
  font-weight: 700;
  color: ${(props) => props.theme.colors.hd1};
  line-height: 150%;
`;

const S_PointItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 535px;
  padding: 8px 16px;
  background-color: ${(props) => props.theme.colors.hd4};
  border-radius: 8px;
  position: relative;
`;

const S_PointList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  min-height: 400px;
  min-width: 535px;
  margin-top: 40px;
`;

const S_ModalButton = styled.button`
  width: 50%;
  padding: 10px;
  font-size: 18px;
  color: ${(props) => props.theme.colors.hd2};
  position: relative;

  &.active {
    color: ${(props) => props.theme.colors.hd1};
    &::after {
      content: '';
      display: block;
      width: 100%;
      height: 4px;
      background-color: ${(props) => props.theme.colors.ho1};
      position: absolute;
      bottom: -4px;
      left: 0;
    }
  }
`;

const S_ModalTabButtonBox = styled.div`
  display: flex;
`;

const S_Title = styled.p`
  margin-top: 48px;
  font-size: 24px;
  font-weight: 700;
  line-height: 150%;
`;

const S_ModalContent = styled.div`
  padding: 30px 60px 60px;
`;

const S_CloseButton = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
  :hover {
    opacity: 0.5;
  }
`;

const S_Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.colors.hd5};
  border-radius: 10px;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 11;
`;
