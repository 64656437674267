import { useNavigate } from 'react-router-dom';

import styled from 'styled-components';

import { ReactComponent as Checkbox } from 'assets/image/checkbox.svg';
import { ReactComponent as CheckboxChecked } from 'assets/image/checkbox-checked.svg';

import { I_CategoryHash, I_Product } from 'etc/types';
import { dateTimeFormat, numberFormat } from 'utils/common';

interface I_StoreProductTableItem {
  product: I_Product;
  checkList: number[];
  setCheckList: React.Dispatch<React.SetStateAction<number[]>>;
  categoryHash: I_CategoryHash | undefined;
}

function StoreProductTableItem({
  product,
  checkList,
  setCheckList,
  categoryHash,
}: I_StoreProductTableItem) {
  const navigate = useNavigate();

  const onCheckBoxClick = (check: boolean) => {
    if (check) setCheckList((pre) => [...pre, product.id]);
    else setCheckList((pre) => pre.filter((v) => v !== product.id));
  };

  return (
    <S_TableRow>
      <S_TableColData>
        {checkList.includes(product.id) ? (
          <S_Checkbox onClick={() => onCheckBoxClick(false)}>
            <CheckboxChecked />
          </S_Checkbox>
        ) : (
          <S_Checkbox onClick={() => onCheckBoxClick(true)}>
            <Checkbox />
          </S_Checkbox>
        )}
      </S_TableColData>
      <S_TableColData>
        <span
          onClick={() => navigate(`/store/product/${product.id}`)}
          style={{ textDecoration: 'underLine', cursor: 'pointer' }}
        >
          {product.id}
        </span>
      </S_TableColData>
      <S_TableColData>
        {categoryHash && (
          <>
            {categoryHash[product.mainCategory]}
            {categoryHash[product.middleCategory] &&
              ' 〉' + categoryHash[product.middleCategory]}
            {categoryHash[product.subCategory] &&
              ' 〉' + categoryHash[product.subCategory]}
          </>
        )}
      </S_TableColData>
      <S_TableColData style={{ minWidth: '243px' }}>
        <span style={{ fontWeight: '700' }}>{product.brand.name}</span>{' '}
        <span style={{ fontWeight: '400' }}>{product.name}</span>
      </S_TableColData>
      <S_TableColData>{numberFormat(product.price)}원</S_TableColData>
      <S_TableColData>
        {product.options[0].discountPercent
          ? `${product.options[0].discountPercent}%`
          : '-'}
      </S_TableColData>
      <S_TableColData>
        {' '}
        {product.options[0].limitPointPercent
          ? `${product.options[0].limitPointPercent}%`
          : '-'}
      </S_TableColData>
      <S_TableColData>{dateTimeFormat(product.createdAt)}</S_TableColData>
      <S_TableColData>
        <S_SaleStatus isSoldOut={product.hidden}>
          {product.hidden ? '일시 품절' : '판매중'}
        </S_SaleStatus>
      </S_TableColData>
    </S_TableRow>
  );
}

export default StoreProductTableItem;

const S_SaleStatus = styled.span<{ isSoldOut: boolean }>`
  color: ${(props) =>
    props.isSoldOut ? props.theme.colors.ho6 : props.theme.colors.ho2};
`;

const S_Checkbox = styled.div`
  width: 16.67px;
  height: 16.67px;
  color: #aeaeae;
  margin: 0 auto;
  cursor: pointer;
`;

const S_TableColData = styled.td`
  text-align: center;
  vertical-align: middle;
  border: 1px solid ${(props) => props.theme.colors.hd3};
  padding: 10px;
`;

const S_TableRow = styled.tr`
  height: 36px;
  font-weight: 500;
  font-size: 13px;
`;
