// sidebar menu
export const CATEGORY_DATA: { [key: string]: string[] } = {
  '': ['시스템 관리', '관리자 정보 관리'],

  admin: ['시스템 관리', '관리자 정보 관리'],

  user: ['서비스 관리', '유저 관리', '유저 정보'],
  'user/id': ['서비스 관리', '유저 관리', '유저 정보', '유저 상세 정보'],
  'user/care': ['서비스 관리', '유저 관리', '돌보미 유저 정보'],
  'user/care/id': [
    '서비스 관리',
    '유저 관리',
    '돌보미 유저 정보',
    '돌보미 유저 정보 상세',
  ],
  'user/care/apply': ['서비스 관리', '유저 관리', '돌보미 신청 관리'],
  'user/care/apply/id': [
    '서비스 관리',
    '유저 관리',
    '돌보미 신청 관리',
    '돌보미 신청 내역',
  ],
  'service/report': ['서비스 관리', '신고 관리'],
  'service/report/id': ['서비스 관리', '신고 관리', '신고 상세'],
  'service/app': ['서비스 관리', '서비스 문의'],
  'service/app/id': ['서비스 관리', '서비스 문의', '문의 상세'],
  'service/guide': [
    '서비스 관리',
    '서비스 컨텐츠 관리',
    '서비스 안내 정보 관리',
  ],
  'service/guide/care': [
    '서비스 관리',
    '서비스 컨텐츠 관리',
    '서비스 안내 정보 관리',
    '돌봄 서비스 안내 상세',
  ],
  'service/guide/adopt': [
    '서비스 관리',
    '서비스 컨텐츠 관리',
    '서비스 안내 정보 관리',
    '분양 서비스 안내',
  ],
  'service/guide/walk': [
    '서비스 관리',
    '서비스 컨텐츠 관리',
    '서비스 안내 정보 관리',
    '동반산책 서비스 안내',
  ],
  'service/guide/register': [
    '서비스 관리',
    '서비스 컨텐츠 관리',
    '서비스 안내 정보 관리',
    '서비스 안내 정보 등록',
  ],
  'service/kind': ['서비스 관리', '서비스 컨텐츠 관리', '품종별 정보 관리'],
  'service/kind/id': [
    '서비스 관리',
    '서비스 컨텐츠 관리',
    '품종별 정보 관리',
    '품종별 정보 상세',
  ],
  'service/kind/register': [
    '서비스 관리',
    '서비스 컨텐츠 관리',
    '품종별 정보 관리',
    '품종 정보 등록',
  ],
  'service/event': ['서비스 관리', '서비스 컨텐츠 관리', '이벤트 관리'],
  'service/event/id': [
    '서비스 관리',
    '서비스 컨텐츠 관리',
    '이벤트 관리',
    '이벤트 수정',
  ],
  // 'service/event/id': [
  //   '서비스 관리',
  //   '서비스 컨텐츠 관리',
  //   '이벤트 관리',
  //   '이벤트 상세',
  // ],
  'service/event/register': [
    '서비스 관리',
    '서비스 컨텐츠 관리',
    '이벤트 관리',
    '이벤트 등록',
  ],

  'service/notice': ['서비스 관리', '서비스 운영 관리', '공지사항 관리'],
  'service/notice/register': [
    '서비스 관리',
    '서비스 운영 관리',
    '공지사항 관리',
    '공지사항 등록',
  ],
  'service/notice/id': [
    '서비스 관리',
    '서비스 운영 관리',
    '공지사항 관리',
    '공지사항 상세',
  ],
  'service/point': ['서비스 관리', '서비스 운영 관리', '포인트 정책 관리'],
  'service/policy': ['서비스 관리', '서비스 운영 관리', '서비스 정책 관리'],
  'service/policy/detail': [
    '서비스 관리',
    '서비스 운영 관리',
    '서비스 정책 관리',
    '약관 상세',
  ],
  'service/policy/update': [
    '서비스 관리',
    '서비스 운영 관리',
    '서비스 정책 관리',
    '약관 수정',
  ],
  'service/chatting': ['서비스 관리', '서비스 운영 관리', '채팅 관리'],
  
  'petstival/list': ['서비스 관리', '펫스티벌 관리', '리스트'],
  'petstival/id': ['서비스 관리', '펫스티벌 관리', '상세 정보'],
  'petstival/rankingList': ['서비스 관리', '펫스티벌 관리', '월별 랭킹 리스트'],

  'travelProduct/category': ['서비스 관리', '여행상품 관리', '카테고리'],
  'travelProduct/register': ['서비스 관리', '여행상품 관리', '여행상품 등록'],
  'travelProduct/list': ['서비스 관리', '여행상품 관리', '리스트'],
  'travelProduct/id': ['서비스 관리', '여행상품 관리', '상세 정보'],
  'travelProduct/id/options': ['서비스 관리', '여행상품 관리', '옵션', '리스트'],
  // 'petstival/id': ['서비스 관리', '펫스티벌', '상세 정보'],

  'fcm/send': ['푸시메세지 관리', '푸시전송'],

  'app/firebase': ['앱 분석', '파이어베이스 연결'],
  'marketing/utm/register': ['UTM', 'UTM 등록'],
  'marketing/utm/list': ['UTM', 'UTM 리스트'],
  'marketing/utm/chart': ['UTM', 'UTM 차트'],

  'store/product': ['스토어 관리', '상품 관리'],
  'store/product/registration': ['스토어 관리', '상품 관리', '상품 등록'],
  'store/product/id': ['스토어 관리', '상품 관리', '상품 상세'],
  'store/order': ['스토어 관리', '주문 관리'],
  'store/order/id': ['스토어 관리', '주문 관리', '주문 상세 정보'],
  'store/delivery': ['스토어 관리', '배송 관리'],
  'store/cancel': ['스토어 관리', '취소 관리'],
  'store/exchange': ['스토어 관리', '교환 관리'],
  'store/return': ['스토어 관리', '반품 관리'],
  'store/confirm': ['스토어 관리', '구매 확정 관리'],
  'store/inquiry': ['스토어 관리', '고객 관리', '스토어 문의'],
  'store/inquiry/id': [
    '스토어 관리',
    '고객 관리',
    '스토어 문의',
    '스토어 문의 상세',
  ],
  'store/review': ['스토어 관리', '고객 관리', '스토어 리뷰'],
  'store/review/id': ['스토어 관리', '고객 관리', '스토어 리뷰', '리뷰 상세'],
  'store/coupon': ['스토어 관리', '쿠폰 발급 관리'],
  'store/coupon/register': ['스토어 관리', '쿠폰 발급 관리', '쿠폰 등록'],
  'store/coupon/id': ['스토어 관리', '쿠폰 발급 관리', '쿠폰 상세'],


};
