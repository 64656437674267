import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import styled from 'styled-components';

import MainLayout from 'components/Layout/MainLayout';
import LongButton from 'components/Button/LongButton';
import DisabledInput from 'components/Input/DisabledInput';
import DisabledTextarea from 'components/Input/DisabledTextarea';
import useLogout from 'hooks/useLogout';
import { I_StoreReviewDetail } from 'etc/types';
import { dateTimeFormat } from 'utils/common';
import Spinner from 'components/Spinner/Spinner';
import { S_ButtonWrapper } from './StoreProduct';

import { getReview, patchReview, postReviewPoint } from 'api/store';

function StoreReviewDetail() {
  const { id } = useParams();
  const Logout = useLogout();
  const [review, setReview] = useState<I_StoreReviewDetail>();
  const [isHidden, setIsHidden] = useState<boolean>(false);
  const [isPoint, setIsPoint] = useState<boolean>(false);
  const [isPatchLoading, setIsPatchLoading] = useState<boolean>(false);
  const [isPointLoading, setIsPointLoading] = useState<boolean>(false);

  // 숨김
  const patchAsyncReview = async () => {
    if (
      confirm(
        isHidden
          ? '앱 내 해당 리뷰를 공개하시겠습니까?'
          : '앱 내 해당 리뷰를 숨기겠습니까?'
      )
    ) {
      setIsPatchLoading(true);

      const data = await patchReview(Number(id), !isHidden);
      if (data.ok) {
        alert('저장 되었습니다.');
        setIsHidden(!isHidden);
        setIsPatchLoading(false);
      } else {
        if (data.res.status === 315) Logout();
        else {
          alert('잠시 후 다시 시도해주세요.');
          console.error(data.res.error.message);
          setIsPatchLoading(false);
        }
      }
    }
  };

  // 숨김
  const postAsyncReviewPoint = async () => {
    if (confirm('포인트 지급하시겠습니까?')) {
      setIsPointLoading(true);

      const data = await postReviewPoint(Number(id));
      if (data.ok) {
        alert('저장 되었습니다.');
        setIsPoint(!isPoint);
        setIsPointLoading(false);
      } else {
        if (data.res.status === 315) Logout();
        else {
          alert('잠시 후 다시 시도해주세요.');
          console.error(data.res.error.message);
          setIsPatchLoading(false);
        }
      }
    }
  };

  // 리뷰 상세
  const getAsyncReview = async () => {
    const data = await getReview(Number(id));
    if (data.ok) {
      setReview(data.res.item);
      setIsHidden(data.res.item.isHidden);
      if (data.res.item.pointLog === null) setIsPoint(false);
      else setIsPoint(true);
    } else {
      if (data.res.status === 315) Logout();
      else console.error(data.res.error.message);
    }
  };

  useEffect(() => {
    getAsyncReview();
  }, [id]);

  return (
    <MainLayout>
      <S_TopContainer>
        <S_BasicInfoTitle>리뷰 정보</S_BasicInfoTitle>
        <S_BasicInfoContainer>
          <S_BasicInfoRowWrapper>
            <S_BasicInfoWrapper>
              <S_Label>최초 작성일</S_Label>
              <DisabledInput
                text={dateTimeFormat(review?.createdAt)}
                style={{ width: '380px' }}
              />
            </S_BasicInfoWrapper>
            <S_BasicInfoWrapper>
              <S_Label>처리 상태</S_Label>
              <DisabledInput
                text={isPoint ? '지급완료' : '미지급'}
                style={{
                  width: '190px',
                  color: isPoint ? '#3CBF0E' : '#FF0000',
                }}
              />
              <DisabledInput
                text={isHidden ? '숨김' : '공개'}
                style={{
                  width: '190px',
                  color: isHidden ? '#FF0000' : '#3CBF0E',
                }}
              />
            </S_BasicInfoWrapper>
          </S_BasicInfoRowWrapper>
          <S_BasicInfoRowWrapper>
            <S_BasicInfoWrapper>
              <S_Label>상품 명</S_Label>
              <DisabledInput
                text={review?.product.name}
                style={{ width: '380px' }}
              />
            </S_BasicInfoWrapper>
            <S_BasicInfoWrapper>
              <S_Label>최종 수정일</S_Label>
              <DisabledInput
                text={dateTimeFormat(review?.updatedAt)}
                style={{ width: '380px' }}
              />
            </S_BasicInfoWrapper>
          </S_BasicInfoRowWrapper>
          <S_BasicInfoRowWrapper>
            <S_BasicInfoWrapper>
              <S_Label>구매자 명</S_Label>
              <DisabledInput
                text={review?.user.nickname}
                style={{ width: '380px' }}
              />
            </S_BasicInfoWrapper>
            <S_BasicInfoWrapper>
              <S_Label>상품 번호</S_Label>
              <DisabledInput
                text={review?.product.id}
                style={{ width: '380px' }}
              />
            </S_BasicInfoWrapper>
          </S_BasicInfoRowWrapper>
          <S_BasicInfoRowWrapper>
            <S_BasicInfoWrapper>
              <S_Label>평점</S_Label>
              <DisabledInput text={review?.rating} style={{ width: '380px' }} />
            </S_BasicInfoWrapper>
            <S_BasicInfoWrapper>
              <S_Label>User Code</S_Label>
              <DisabledInput
                text={review?.user.userCode}
                style={{ width: '380px' }}
              />
            </S_BasicInfoWrapper>
          </S_BasicInfoRowWrapper>
          <S_BasicInfoRowWrapper>
            <S_BasicInfoWrapper style={{ alignItems: 'flex-start' }}>
              <S_Label style={{ marginTop: '14px' }}>상세 내용</S_Label>
              <DisabledTextarea
                text={review?.content}
                style={{ height: '127px' }}
              />
            </S_BasicInfoWrapper>
          </S_BasicInfoRowWrapper>
          <S_BasicInfoRowWrapper>
            <S_BasicInfoWrapper style={{ alignItems: 'flex-start' }}>
              <S_Label style={{ marginTop: '14px' }}>참조 이미지</S_Label>
              <S_ImageContainer>
                <S_ImageWrapper>
                  {review?.reviewImages.map((v, i) => (
                    <S_Image key={v.id} src={v.url} />
                  ))}
                </S_ImageWrapper>
                {/* <S_CheckboxWrapper>
                  {isCheck ? (
                    <S_Checkbox
                      onClick={() => setIsCheck(false)}
                      style={{ color: '#FC1D1D' }}
                    >
                      <CheckboxChecked />
                    </S_Checkbox>
                  ) : (
                    <S_Checkbox onClick={() => setIsCheck(true)}>
                      <Checkbox />
                    </S_Checkbox>
                  )}
                  <span>
                    서비스 운영 정책에 맞춰 해당 내용을 블라인드 처리(서비스
                    내에서 숨기기) 하시겠습니까?
                  </span>
                </S_CheckboxWrapper> */}
              </S_ImageContainer>
            </S_BasicInfoWrapper>
          </S_BasicInfoRowWrapper>
        </S_BasicInfoContainer>
      </S_TopContainer>

      <S_ButtonWrapper style={{ gap: '47px', marginTop: '100px' }}>
        {isHidden ? (
          <LongButton onClick={patchAsyncReview}>
            {isPatchLoading ? <Spinner /> : '리뷰 숨김 해제'}
          </LongButton>
        ) : (
          <LongButton onClick={patchAsyncReview}>
            {isPatchLoading ? <Spinner /> : '리뷰 숨기기'}
          </LongButton>
        )}

        {isPoint ? (
          <S_DisabledButton>리뷰 포인트 지급하기</S_DisabledButton>
        ) : (
          <LongButton onClick={postAsyncReviewPoint}>
            {isPointLoading ? <Spinner /> : '리뷰 포인트 지급하기'}
          </LongButton>
        )}
      </S_ButtonWrapper>
    </MainLayout>
  );
}

export default StoreReviewDetail;

const S_DisabledButton = styled.div`
  width: 300px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.colors.hd4};
  border-radius: 8px;
  font-weight: 700;
  font-size: 14px;
  letter-spacing: -0.02em;
  color: ${(props) => props.theme.colors.hd5};
`;

const S_Checkbox = styled.div`
  width: 32px;
  height: 32px;
  color: #aeaeae;
  cursor: pointer;
`;

const S_CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  span {
    color: ${(props) => props.theme.colors.ho6};
    font-size: 16px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: -0.32px;
  }
`;

const S_Image = styled.img`
  width: 140px;
  height: 140px;
  border-radius: 16px;
`;

const S_ImageWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 10px;
`;

const S_ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const S_Label = styled.div`
  width: 100px;
  flex-shrink: 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.02em;
`;

const S_BasicInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const S_BasicInfoRowWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 45px;
  width: 100%;
`;

const S_BasicInfoTitle = styled.span`
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.02em;
`;

const S_BasicInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 16px;
`;

const S_TopContainer = styled.div`
  padding-bottom: 32px;
  /* border-bottom: 1px solid ${(props) => props.theme.colors.hd4}; */
`;
