import styled from 'styled-components';

interface I_DateInput {
  state: string;
  setState: React.Dispatch<React.SetStateAction<string>>;
  startDate?: string;
  style?: React.CSSProperties;
  type?: string;
  [key: string]: any;
}

function DateInput({
  state,
  setState,
  startDate,
  style,
  type = 'date',
  ...rest
}: I_DateInput) {
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState(event.target.value);
  };

  return (
    <S_Input
      type={type}
      value={state}
      onChange={onChange}
      style={style}
      {...rest}
    />
  );
}

export default DateInput;

const S_Input = styled.input`
  padding: 4px 6px 4px 12px;
  border: 1px solid ${(props) => props.theme.colors.hd3};
`;
