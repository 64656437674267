import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import MainLayout from 'components/Layout/MainLayout';
import { getTravelProductOptions, postTravelProductOption, patchTravelProductOption } from 'api/travelProduct'; // API 함수들
import { I_TravelProductOption, I_TravelProductOptionForm } from 'etc/types'; // 옵션 타입 인터페이스

import { travelproductActiveStatus, travelproductEnableStatus } from 'etc/json';

import RegisterInput from 'components/Input/RegisterInput';
import DraggableList from 'components/ReactBeautifulDnd/ReactBeautifulDnd';
import OptionItem from 'components/TravelProduct/Option/OptionItem';
import LongButton from 'components/Button/LongButton';
import MiniButton from 'components/Button/MiniButton';
import Spinner from 'components/Spinner/Spinner';

function TravelProductOptions() {

  const { travelProductId } = useParams<{ travelProductId: string }>();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  
  const [isOptions, setOptions] = useState<I_TravelProductOption[]>([]);
  
  const [ isOptionFiles, setOptionFiles ] = useState<File[]>([]); // 옵션 이미지 상태
  // const [ isOptionFiles, setOptionFiles ] = useState<{ [key: number]: File[] }>({}); // 옵션별 이미지 상태
  
  const { register, handleSubmit, formState: { errors }, reset } = useForm<I_TravelProductOptionForm>({
    defaultValues: {
      id: undefined,
      title: '',
      content: '',
      order: 0,
      maxCount: 0,
      travelProductId: Number(travelProductId),
      travelProductOptionStatus: '',
      travelProductOptionEnableStatus: '',
      optionUploadFiles: [],
    },
  });

  // 옵션 목록 불러오기
  const getTravelProductOption = async () => {
    console.log('travelProductId : ', travelProductId)
    if (!travelProductId) return;
    try {
      const response = await getTravelProductOptions(Number(travelProductId));
      
      if (response.ok) {
        setOptions(response.res.list); 
      } else {
        console.error('옵션을 불러오는데 실패했습니다.');
      }
    } catch (error) {
      console.error('옵션 불러오기 에러:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleOptionFileSet = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log('handleOptionFileSet')
    const files = Array.from(event.target.files || []) as File[];
    setOptionFiles(files);
  };

  const handleOptionItemsChangeDnd = (newItems: any) => {
    const reorderedFiles = newItems.map((item: any) => item.file);
    console.log('reorderedFiles : ', reorderedFiles)
    setOptionFiles(reorderedFiles);
  };

  useEffect(() => {
    getTravelProductOption();
  }, [travelProductId]);

  useEffect(()=>{

    if(isOptionFiles) console.log('isOptionFiles : ', isOptionFiles);

  }, [isOptionFiles])
  // 옵션 등록
  const optionRegist = async (data: I_TravelProductOptionForm) => {

    try {

      const formData = new FormData();

      if (isOptionFiles && isOptionFiles.length > 0) {
        isOptionFiles.forEach((file, fileIndex) => {
          formData.append(`optionUploadFiles[${fileIndex}]`, file);
        });
      }

      // 다른 데이터 추가
      Object.keys(data).forEach((key) => {
        if (key !== "optionUploadFiles"  ) {
          formData.append(key, (data as any)[key]); // 파일을 제외한 다른 데이터 추가
        }
      });
      
      Array.from(formData.entries()).forEach(([key, value]) => {
        console.log(`------------ ${key}:`, value);
      });
  
      const result = await postTravelProductOption(Number(travelProductId), formData);

      if (result.ok) {
        alert('옵션이 등록되었습니다.');
        // 옵션을 등록 후 폼을 초기화
        initialzationInput();
        await getTravelProductOption();
        // navigate(`/travelProduct/list`);
      } else {
        alert(`${result.res.error.code}:${result.res.error.message}` );
      }

    } catch (error) {
      console.error('옵션 등록 에러:', error);
    }
  };
  
  // input 초기화
  const initialzationInput = () => {
    reset({
      title: '',
      content: '',
      order: 0,
      maxCount: 0,
      travelProductId: Number(travelProductId),
      travelProductOptionStatus: '',
      travelProductOptionEnableStatus: '',
      optionUploadFiles: [],
    });
    setOptionFiles([]);
    const fileInput = document.getElementById('optionUploadFiles') as HTMLInputElement;
    if (fileInput) {
      fileInput.value = ''; // 파일 선택 필드를 초기화
    }
  }

  const handleUpdateOption = async (updatedOption: I_TravelProductOption) => {
    
    console.log('parent handleUpdateOption : ', updatedOption)
    console.log('parent handleUpdateOption type : ', typeof(updatedOption.id))
    // return;
    try {
      const response = await patchTravelProductOption(Number(travelProductId), Number(updatedOption.id), updatedOption);
      if (response.ok) {
        alert('옵션이 수정되었습니다.');
        await getTravelProductOption(); // 변경된 데이터 다시 가져오기
      } else {
        alert('옵션 수정 실패');
      }
    } catch (error) {
      console.error('옵션 수정 에러:', error);
    }
  };
  

  return (
    <MainLayout>
      <Container>
        <Title>옵션 등록</Title>

        <S_MidContainer>
          <form onSubmit={handleSubmit(optionRegist)}>
            <S_AddInfoContainer>
              <S_FieldContainer>
                <S_BasicInfoRowWrapper style={{ position: 'relative' }}>
                  <S_BasicInfoWrapper>
                    <S_Label style={{ color: '#13A872' }}>옵션 제목</S_Label>
                    <RegisterInput
                      isError={!!errors?.title}
                      register={register('title', { required: '내용을 입력해주세요' })}
                      placeholder='ex) 카누+커피2인세트'
                      style={{ width: '380px' }}
                    />
                    {errors?.title && <S_ErrorMessage>{errors?.title.message}</S_ErrorMessage>}
                  </S_BasicInfoWrapper>
                  <S_BasicInfoWrapper>
                    <S_Label>내용</S_Label>
                    <RegisterInput
                      isError={!!errors?.content}
                      register={register('content', { required: '내용을 입력해주세요' })}
                      placeholder='ex) 카누+커피2인세트 내용'
                      style={{ width: '380px' }}
                    />
                    {errors?.content && <S_ErrorMessage>{errors?.content.message}</S_ErrorMessage>}
                  </S_BasicInfoWrapper>
                </S_BasicInfoRowWrapper>

                <S_BasicInfoWrapper>
                  <S_Label>옵션 이미지</S_Label>
                  <S_InputContainer isError={!!errors?.optionUploadFiles}>
                    <input
                      type="file"
                      id='optionUploadFiles'
                      multiple
                      onChange={handleOptionFileSet}
                    />
                    {errors?.optionUploadFiles && (
                      <S_ErrorMessage>{errors?.optionUploadFiles.message}</S_ErrorMessage>
                    )}
                    {/* 이미지 이름 노출 */}
                    <S_ImageList>
                       {isOptionFiles.length === 0 ? (
                          <span>이미지가 없습니다.</span>
                        ) : (
                          isOptionFiles.map((file) => (
                            <span key={file.name}>{file.name}</span>
                          ))
                        )}
                    </S_ImageList>
                  </S_InputContainer>

                  {/* <S_AddImage>사진 추가</S_AddImage> */}
                  <S_AddImage htmlFor={`optionUploadFiles`}>사진 추가</S_AddImage>

                  {isOptionFiles.length > 0 && (
                    <S_BasicInfoRowWrapperImage>
                      <DraggableList
                        key={isOptionFiles.map((file) => file.name).join()}
                        items={isOptionFiles.map((file, index) => ({
                          id: index.toString(),
                          fileName: file.name,
                          file,
                        }))}
                        onItemsChange={handleOptionItemsChangeDnd}
                        direction="horizontal"
                      />
                    </S_BasicInfoRowWrapperImage>
                  )}
                </S_BasicInfoWrapper>

                <S_BasicInfoRowWrapper>
                  <S_BasicInfoWrapper>
                    <S_Label>옵션 최대 개수</S_Label>
                    <RegisterInput
                      isError={!!errors?.maxCount}
                      register={register('maxCount', { valueAsNumber: true, required: '입력해주세요' })}
                      placeholder='0~10'
                      style={{ width: '380px' }}
                      type='number'
                    />
                  </S_BasicInfoWrapper>
                </S_BasicInfoRowWrapper>

                <S_BasicInfoRowWrapper>
                  <S_BasicInfoWrapper>
                    <S_Label>노출 여부</S_Label>
                    <S_Select
                      {...register('travelProductOptionStatus', {
                        required: '선택해주세요',
                      })}
                      defaultValue=""
                    >
                      <option value="">선택해주세요</option>
                      {travelproductActiveStatus.map((item) => (
                        <option key={item.key} value={item.value}>
                          {item.name}
                        </option>
                      ))}
                    </S_Select>
                    {errors?.travelProductOptionStatus && (
                      <S_ErrorMessage>{errors?.travelProductOptionStatus.message}</S_ErrorMessage>
                    )}
                  </S_BasicInfoWrapper>
                  <S_BasicInfoWrapper>
                    <S_Label>비활성화 여부</S_Label>
                    <S_Select
                      {...register('travelProductOptionEnableStatus', {
                        required: '선택해주세요',
                      })}
                      defaultValue=""
                    >
                      <option value="">선택해주세요</option>
                      {travelproductEnableStatus.map((item) => (
                        <option key={item.key} value={item.value}>
                          {item.name}
                        </option>
                      ))}
                    </S_Select>
                    {errors?.travelProductOptionEnableStatus && (
                      <S_ErrorMessage>{errors?.travelProductOptionEnableStatus.message}</S_ErrorMessage>
                    )}
                  </S_BasicInfoWrapper>
                </S_BasicInfoRowWrapper>
              </S_FieldContainer>
            </S_AddInfoContainer>

            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '30px' }}>
              <LongButton>{isLoading ? <Spinner /> : '등록하기'}</LongButton>
            </div>
          </form>
        </S_MidContainer>



        {/* 옵션 리스트 */}
        {isLoading ? (
          <p>로딩 중...</p>
        ) : isOptions.length > 0 ? (
          <OptionsList>
          {isOptions.map((option, index) => (
            <OptionItem
              key={option.id}
              option={option}
              index={index}
              isOptions={isOptions}
              setOptions={setOptions}
              handleUpdateOption={handleUpdateOption}
              navigate={navigate}
              id={travelProductId}
            />
          ))}
        </OptionsList>
        ) : (
          <p>등록된 옵션이 없습니다.</p>
        )}



      </Container>
    </MainLayout>
  );
}

export default TravelProductOptions;

// 스타일드 컴포넌트
const Container = styled.div`
  padding: 20px;
`;

const Title = styled.h2`
  font-size: 24px;
  margin-bottom: 20px;
`;


const S_MidContainer = styled.div`
  padding: 24px 0 32px;
  border-bottom: 1px solid ${(props) => props.theme.colors.hd4};
`;


const S_BasicInfoTitle = styled.span`
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.02em;
`;


const S_AddInfoContainer = styled.div`
  margin: 16px 0 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const S_FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const S_BasicInfoRowWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 45px;
  width: 100%;
  margin-top: 10px;
`;


const S_BasicInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;


const S_Label = styled.div`
  width: 100px;
  flex-shrink: 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.02em;
`;

const S_ErrorMessage = styled.span`
  color: red;
  font-size: 12px;
`;

const S_Select = styled.select`
  width: 380px;
  height: 46px;
  padding: 10px;
  border-radius: 12px;
  border: 1px solid ${(props) => props.theme.colors.hd3};
`;

const S_Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${(props) => props.theme.colors.hd4}; /* 구분선 색상 */
  margin: 20px 0; /* 위아래 여백 */
`;



const SubmitButton = styled.button`
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 20px;
  /* float: right; */
`;

const OptionsList = styled.ul`
  list-style-type: none;
  padding: 0;
`;

// const OptionItem = styled.li`
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   border: 1px solid #ccc;
//   padding: 10px;
//   margin-bottom: 10px;
// `;

const OptionInfo = styled.div`
  flex: 1;
`;

const AddPriceButton = styled.button`
  padding: 8px 16px;
  background-color: #13a872;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: #0e8462;
  }
`;

const ImageGallery = styled.div`
  display: flex;
  gap: 8px;
  margin-top: 10px;
`;

const OptionImage = styled.img`
  width: 100px;
  height: 100px;
  object-fit: cover;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
`;

const S_ImageList = styled.div`
  font-weight: 400;
  font-size: 13px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  span {
    width: 346px;
    line-height: 18px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;


const S_InputContainer = styled.div<{
  isDisabled?: boolean;
  isError?: boolean;
}>`
  width: 380px;
  min-height: 46px;
  padding: 14px 16px;
  background: ${(props) =>
    props.isDisabled ? props.theme.colors.hd4 : props.theme.colors.hd5};
  border: 1px solid
    ${(props) =>
      props.isError ? props.theme.colors.ho6 : props.theme.colors.hd3};
  border-radius: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
`;

const S_BasicInfoRowWrapperImage = styled.div`
  margin-top: 16px;
  margin-left: 30px;
  display: flex;
  justify-content: flex-start; /* 이미지들을 왼쪽으로 정렬 */
  width: 100%; /* 부모 요소의 너비에 맞춤 */

`;

const S_AddImage = styled.label`
  width: 111px;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.colors.ho1};
  border-radius: 8px;
  font-weight: 700;
  font-size: 13px;
  letter-spacing: -0.02em;
  color: ${(props) => props.theme.colors.hd5};
  margin-left: 23px;
  /* margin-right: 20px; */
  cursor: pointer;
  &:hover {
    opacity: 0.5;
  }
`;




const StyledInput = styled.input<{
  isError?: boolean;
}>`
  width: 100%;
  padding: 10px 16px;
  border-radius: 12px;
  font-size: 14px;
  color: ${(props) => props.theme.colors.hd1};
  border: 1px solid
    ${(props) => (props.isError ? props.theme.colors.ho6 : props.theme.colors.hd3)};
  background: ${(props) => props.theme.colors.hd5};
  &::placeholder {
    color: ${(props) => props.theme.colors.hd2};
  }
  &:focus {
    outline: none;
    border-color: ${(props) => props.theme.colors.ho1};
  }
`;

// StyledSelect 컴포넌트
const StyledSelect = styled.select<{
  isError?: boolean;
}>`
  width: 100%;
  padding: 10px 16px;
  border-radius: 12px;
  font-size: 14px;
  color: ${(props) => props.theme.colors.hd1};
  border: 1px solid
    ${(props) => (props.isError ? props.theme.colors.ho6 : props.theme.colors.hd3)};
  background: ${(props) => props.theme.colors.hd5};
  &:focus {
    outline: none;
    border-color: ${(props) => props.theme.colors.ho1};
  }
`;
