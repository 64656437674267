import { useEffect, useState } from 'react';

import styled from 'styled-components';

import { ReactComponent as Close } from 'assets/image/close.svg';

import ModalLayout from 'components/Layout/ModalLayout';
import Input from 'components/Input/Input';
import { S_FlexAlignCenter } from 'etc/GlobalCSS';
import NormalButton from 'components/Button/NormalButton';
import useLogout from 'hooks/useLogout';
import Spinner from 'components/Spinner/Spinner';

import { getOrderInfo, patchOrderInfo } from 'api/store';

interface I_DeliveryModal {
  isOpen: boolean;
  onClose: () => void;
}

function DeliveryModal({ isOpen, onClose }: I_DeliveryModal) {
  const Logout = useLogout();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [address, setAddress] = useState<string>('');
  const [receiver, setReceiver] = useState<string>('');
  const [company, setCompany] = useState<string>('');
  const [shippingCost, setShippingCost] = useState<string>('');
  const [freeShippingPrice, setFreeShippingPrice] = useState<string>('');

  const getAsyncOrderInfo = async () => {
    const data = await getOrderInfo();
    if (data.ok) {
      setAddress(data.res.item.orderInfo.companyAddress);
      setReceiver(data.res.item.orderInfo.receiver);
      setCompany(data.res.item.orderInfo.courierCompany);
      setShippingCost(data.res.item.orderInfo.shippingCost);
      setFreeShippingPrice(data.res.item.orderInfo.freeShippingPrice);
    } else {
      if (data.res.status === 315) Logout();
      else console.error(data.res.error.message);
    }
  };

  const patchAsyncOrderInfo = async () => {
    setIsLoading(true);
    const data = await patchOrderInfo({
      companyAddress: address,
      receiver: receiver,
      courierCompany: company,
      shippingCost: Number(shippingCost),
      freeShippingPrice: Number(freeShippingPrice),
    });
    if (data.ok) {
      alert('수정되었습니다.');
      setIsDisabled(true);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      if (data.res.status === 315) Logout();
      else console.error(data.res.error.message);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden'; // body scroll 제거
      getAsyncOrderInfo();
    }
  }, [isOpen]);

  return (
    <ModalLayout isOpen={isOpen} onClose={onClose}>
      <S_Container>
        <S_CloseButton
          onClick={() => {
            onClose();
          }}
        >
          <Close />
        </S_CloseButton>

        <S_ModalTitle>배송 설정</S_ModalTitle>
        <S_TopSection>
          <S_SectionTitle>상품 수거 정보</S_SectionTitle>
          <S_Wraper>
            <S_SectionSubTitle>교환/반품 상품 수령 장소</S_SectionSubTitle>
            <Input
              state={address}
              setState={setAddress}
              isDisabled={isDisabled}
            />
          </S_Wraper>
          <S_WraperContainer>
            <S_Wraper>
              <S_SectionSubTitle>교환/반품 상품 수령인</S_SectionSubTitle>
              <Input
                state={receiver}
                setState={setReceiver}
                isDisabled={isDisabled}
              />
            </S_Wraper>
            <S_Wraper>
              <S_SectionSubTitle>교환/반품 상품 지정 택배사</S_SectionSubTitle>
              <Input
                state={company}
                setState={setCompany}
                isDisabled={isDisabled}
              />
            </S_Wraper>
          </S_WraperContainer>
        </S_TopSection>
        <S_BottomSection>
          <S_Wraper>
            <S_SectionTitle>배송비 설정</S_SectionTitle>
            <Input
              state={shippingCost}
              setState={setShippingCost}
              style={{ width: '380px' }}
              rightText='원'
              isDisabled={isDisabled}
              type='number'
            />
          </S_Wraper>
          <S_Wraper>
            <S_SectionTitle>무료배송 적용</S_SectionTitle>
            <S_FlexAlignCenter style={{ gap: '8px' }}>
              <Input
                state={freeShippingPrice}
                setState={setFreeShippingPrice}
                style={{ width: '380px' }}
                rightText='원'
                isDisabled={isDisabled}
                type='number'
              />
              <S_SectionSubText>이상 구매 시 배송비 없음</S_SectionSubText>
            </S_FlexAlignCenter>
          </S_Wraper>
        </S_BottomSection>
        <S_FlexAlignCenter style={{ justifyContent: 'center' }}>
          {isDisabled ? (
            <NormalButton onClick={() => setIsDisabled(false)}>
              수정하기
            </NormalButton>
          ) : (
            <NormalButton onClick={patchAsyncOrderInfo}>
              {isLoading ? <Spinner /> : '저장하기'}
            </NormalButton>
          )}
        </S_FlexAlignCenter>
      </S_Container>
    </ModalLayout>
  );
}

export default DeliveryModal;

const S_BottomSection = styled.div`
  display: flex;
  flex-direction: column;
  margin: 33px 0 67px;
  gap: 16px;
`;

const S_SectionSubText = styled.span`
  font-size: 13px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: -0.26px;
`;

const S_SectionSubTitle = styled.span`
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: -0.28px;
`;

const S_Wraper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const S_WraperContainer = styled.div`
  display: flex;
  gap: 24px;
`;

const S_SectionTitle = styled.span`
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: -0.28px;
`;

const S_TopSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 41px;
  padding-bottom: 56px;
  border-bottom: 1px solid ${(props) => props.theme.colors.hd3};
`;

const S_ModalTitle = styled.p`
  font-weight: 700;
  font-size: 24px;
  letter-spacing: -0.48px;
  text-align: center;
`;

const S_CloseButton = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
  :hover {
    opacity: 0.5;
  }
`;

const S_Container = styled.div`
  min-width: 916px;
  height: 760px;
  position: relative;
  background: ${(props) => props.theme.colors.hd5};
  padding: 46px 66px 49px;
  z-index: 10;
`;
