import { callApi } from './callApi';

export const sendMultiPush = async (body: any) => {
  const res = await callApi({
    url: `/admin/fcmMessage/sendMultiPush`,
    method: 'POST',
    data: body,
  });
  return res;
};

export const getFcmMessageList = async (
  currentPage: number,
  limit: number,
) => {
  const res = await callApi({
    url: `/admin/fcmMessage/list`,
    method: 'GET',
  });
  return res;
};