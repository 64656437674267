import { useEffect, useRef, useState } from 'react';

import styled from 'styled-components';

import { ReactComponent as ArrowDown } from 'assets/image/arrow-down.svg';

import { I_Brand } from 'etc/types';

interface I_BrandDropdown {
  isError: boolean;
  list: I_Brand[];
  field: string;
  register: any;
  getValues: any;
  setValue: any;
  isDisabled?: boolean;
  style?: React.CSSProperties;
}

function BrandDropdown({
  isError,
  list,
  field,
  register,
  getValues,
  setValue,
  isDisabled,
  style,
}: I_BrandDropdown) {
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  // 선택 시
  const onItemClick = (v: I_Brand) => {
    setValue(field, v.id);
    setIsOpen(false);
  };

  /* 외부영역 클릭했을 때 드롭다운 닫기 */
  function handleClickOutside(e: MouseEvent): void {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(e.target as Node)
    ) {
      setIsOpen(false);
    }
  }

  useEffect(() => {
    document.addEventListener('mouseup', handleClickOutside);
    return () => {
      document.removeEventListener('mouseup', handleClickOutside);
    };
  }, []);

  return (
    <S_Container isDropDownOpen={isOpen} ref={dropdownRef} style={style}>
      <S_DropDownBox
        onClick={() => !isDisabled && setIsOpen(!isOpen)}
        isError={isError}
        isDisabled={isDisabled}
      >
        {list.find((v) => v.id === getValues(field))
          ? list.find((v) => v.id === getValues(field))?.name
          : '등록 브랜드'}
        {!isDisabled && <ArrowDown width='13px' height='13px' />}
      </S_DropDownBox>
      <S_DropDownList
        isDropDownOpen={isOpen}
        isScroll={list.length > 7 ? true : false}
      >
        {list.map((v, i) => (
          <S_DropDownItem key={i} onClick={() => onItemClick(v)}>
            {v.name}
          </S_DropDownItem>
        ))}
      </S_DropDownList>
    </S_Container>
  );
}

export default BrandDropdown;

const S_DropDownItem = styled.li`
  padding: 8px 16px;
  font-weight: 500;
  font-size: 13px;
  cursor: pointer;
  transition: 0.5s;
  &:hover {
    background-color: ${(props) => props.theme.colors.ho3};
  }
`;

const S_DropDownList = styled.ul<{
  isDropDownOpen: boolean;
  isScroll: boolean;
}>`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: ${(props) => (props.isDropDownOpen ? '210px' : '0')};
  background: ${(props) => props.theme.colors.hd4};
  border-radius: 8px;
  overflow-y: ${(props) => (props.isScroll ? 'scroll' : 'hidden')};
  position: absolute;
  top: 0;
  z-index: 2;
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.theme.colors.hd3};
    border-radius: 6px;
  }
`;

const S_DropDownBox = styled.div<{ isError?: boolean; isDisabled?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
  width: 100%;
  background: ${(props) => props.theme.colors.hd4};
  border-radius: 8px;
  font-weight: 500;
  font-size: 13px;
  cursor: ${(props) => (props.isDisabled ? 'normal' : 'pointer')};
  z-index: 1;
  border: 1px solid
    ${(props) => (props.isError ? props.theme.colors.ho6 : 'none')};
`;

const S_Container = styled.div<{ isDropDownOpen: boolean }>`
  width: 128px;
  display: inline-flex;
  position: relative;
`;
