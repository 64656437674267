import { callApi } from './callApi';

import {
  I_TravelProductCategoryRegistrationForm,
  I_TravelProductRegistrationForm,
} from 'etc/types';

const BASE_URL = '/admin/travel-product';

// 상품 카테고리 목록
export const getTravelProductCategoryList = async () => {
  const res = await callApi({
    url: `${BASE_URL}/category`,
    method: 'GET',
  });
  return res;
};


// 상품 카테고리 목록
export const postTravelProductCategory = async (data: I_TravelProductCategoryRegistrationForm) => {
  const res = await callApi({
    url: `${BASE_URL}/category`,
    method: 'POST',
    data: data,
  });
  return res;
};

// 여행 상품 목록
export const getTravelProductList = async (
  limit: number,
  currentPage: number,
) => {
  const res = await callApi({
    url: `${BASE_URL}/list?currentPage=${currentPage}&limit=${limit}`,
    method: 'GET',
  });
  return res;
};


// 상품 카테고리 목록
export const postCreateTravelProduct = async (data:any) => {
  const res = await callApi({
    url: `${BASE_URL}`,
    method: 'POST',
    isFormData: true,
    data: data,
  });
  return res;
};

// 상품 단건 호출
export const getTravelProductDetail = async (id: number) => {
  const res = await callApi({
    url: `${BASE_URL}/${id}`,
    method: 'GET',
  });
  return res;
};


// 상품 옵션 등록
export const patchTravelProduct = async (travelProductId: number, data:any) => {
  const res = await callApi({
    url: `${BASE_URL}/${travelProductId}`,
    method: 'PATCH',
    isFormData: true,
    data: data,
  });
  return res;
};



// 상품에 대한 옵션 리스트 호출
export const getTravelProductOptions = async (id: number) => {
  const res = await callApi({
    url: `${BASE_URL}/${id}/options`,
    method: 'GET',
  });
  return res;
};


// 상품 옵션 등록
export const postTravelProductOption = async (travelProductId: number, data:any) => {
  const res = await callApi({
    url: `${BASE_URL}/${travelProductId}/option`,
    method: 'POST',
    isFormData: true,
    data: data,
  });
  return res;
};

// 상품 옵션 등록
export const patchTravelProductOption = async (travelProductId: number, travelProductOptionId: number, data:any) => {
  const res = await callApi({
    url: `${BASE_URL}/${travelProductId}/option/${travelProductOptionId}`,
    method: 'PATCH',
    isFormData: false,
    data: data,
  });
  return res;
};


// 상품 옵션에 대한 가격 리스트 호출
export const getTravelProductOptionPrice = async (travelProductId: number, travelProductOptionId: number) => {
  const res = await callApi({
    url: `${BASE_URL}/${travelProductId}/option/${travelProductOptionId}`,
    method: 'GET',
  });
  return res;
};


// 여행상품 id, optionid 로 가격 등록하기
export const postTravelProductOptionPrice = async (travelProductId: number, travelProductOptionId: number, data:any) => {
  const res = await callApi({
    url: `${BASE_URL}/${travelProductId}/option/${travelProductOptionId}`,
    method: 'POST',
    isFormData: false,
    data: data,
  });
  return res;
};

// 여행상품 id, optionid 로 날짜 정보 등록하기
export const postTravelProductOptionPriceDate = async (travelProductId: number, travelProductOptionId: number, travelProductOptionPriceId: number, data:any) => {
  const res = await callApi({
    url: `${BASE_URL}/${travelProductId}/option/${travelProductOptionId}/price/${travelProductOptionPriceId}`,
    method: 'POST',
    isFormData: false,
    data: data,
  });
  return res;
};

// 여행상품 id, optionid 로 가격, 날짜 정보 업데이트
export const postTravelProductOptionPriceDateUpdate = async (travelProductId: number, travelProductOptionId: number, travelProductOptionPriceId: number, data:any) => {
  const res = await callApi({
    url: `${BASE_URL}/${travelProductId}/option/${travelProductOptionId}/price/${travelProductOptionPriceId}/update`,
    method: 'POST',
    isFormData: false,
    data: data,
  });
  return res;
};