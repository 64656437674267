import { callApi } from './callApi';

// 서비스 안내 정보 관리 - 조회
export const getServiceInfo = async () => {
  const res = await callApi({
    url: `/admin/service-info`,
    method: 'GET',
  });
  return res;
};

// 서비스 안내 정보 관리 - 돌봄 서비스 안내 이미지
export const postCaringService = async (imageId: number) => {
  const res = await callApi({
    url: `/admin/service-info/caringService`,
    method: 'POST',
    data: { imageId: imageId },
  });
  return res;
};

// 서비스 안내 정보 관리 - 분양 서비스 안내 이미지
export const postDistributionService = async (imageId: number) => {
  const res = await callApi({
    url: `/admin/service-info/distributionServiceImage`,
    method: 'POST',
    data: { imageId: imageId },
  });
  return res;
};

// 서비스 안내 정보 관리 - 산책 서비스 안내 이미지
export const postCowalkService = async (imageId: number) => {
  const res = await callApi({
    url: `/admin/service-info/cowalkServiceImage`,
    data: { imageId: imageId },
    method: 'POST',
  });
  return res;
};
