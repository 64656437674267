import { useNavigate } from 'react-router-dom';

import styled from 'styled-components';

import { I_StoreReview } from 'etc/types';
import { dateTimeFormat } from 'utils/common';

interface I_StoreReviewTableItem {
  item: I_StoreReview;
}

function StoreReviewTableItem({ item }: I_StoreReviewTableItem) {
  const navigate = useNavigate();

  return (
    <S_TableRow>
      <S_TableColData>
        {item.pointLog ? (
          <span style={{ color: '#3CBF0E' }}>지급완료</span>
        ) : (
          <span style={{ color: '#FF0000' }}>미지급</span>
        )}
      </S_TableColData>
      <S_TableColData style={{ minWidth: '248px' }}>
        {item.productName}
      </S_TableColData>
      <S_TableColData>{item.productId}</S_TableColData>
      <S_TableColData>{item.rating}점</S_TableColData>
      <S_TableColData>
        <S_Image src={item.thumbnail} />
      </S_TableColData>
      <S_TableColData
        style={{
          cursor: 'pointer',
        }}
        onClick={() => navigate(`/store/review/${item.id}`)}
      >
        <S_TextHidden>{item.content}</S_TextHidden>
      </S_TableColData>
      <S_TableColData>{item.userNickname}</S_TableColData>
      <S_TableColData>
        <span
          onClick={() => navigate(`/user/${item.userId}`)}
          style={{ textDecoration: 'underLine', cursor: 'pointer' }}
        >
          {item.userCode}
        </span>
      </S_TableColData>
      <S_TableColData>{dateTimeFormat(item.createdAt)}</S_TableColData>
      <S_TableColData>{dateTimeFormat(item.updatedAt)}</S_TableColData>
    </S_TableRow>
  );
}

export default StoreReviewTableItem;

const S_Image = styled.img`
  width: 50px;
  height: 28px;
`;

const S_TextHidden = styled.div`
  max-width: 228px;
  text-decoration: underline;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.1;
`;

const S_TableColData = styled.td`
  text-align: center;
  vertical-align: middle;
  border: 1px solid ${(props) => props.theme.colors.hd3};
  padding: 10px;
`;

const S_TableRow = styled.tr`
  height: 36px;
  font-weight: 500;
  font-size: 13px;
`;
