import { useEffect, useState } from 'react';

import styled from 'styled-components';

import { ReactComponent as Checkbox } from 'assets/image/checkbox.svg';

import MainLayout from 'components/Layout/MainLayout';
import PagenationButton from 'components/Button/PagenationButton';
import TabButton from 'components/Button/TabButton';
import CancelRequest from 'components/Item/StoreCancelTable/CancelRequest';
import CancelCompleted from 'components/Item/StoreCancelTable/CancelCompleted';
import CancelRefusal from 'components/Item/StoreCancelTable/CancelRefusal';
import { I_Cancel, I_CancelAndReturnRefusal } from 'etc/types';
import CancelRequestHistoryModal from 'components/Modal/CancelRequestHistoryModal';
import CancelProgress from 'components/Item/StoreCancelTable/CancelProgress';
import useLogout from 'hooks/useLogout';
import CancelRequestHistoryModal2 from 'components/Modal/CancelRequestHistoryModal2';
import CancelHistoryModal from 'components/Modal/CancelHistoryModal';

import { getCancelList, getCancelRefusalList } from 'api/order';

function StoreCancel() {
  const Logout = useLogout();
  const [cancelList, setCancelList] = useState<I_Cancel[]>([]); // 취소
  const [cancelRefusalList, setCancelRefusalList] = useState<
    I_CancelAndReturnRefusal[]
  >([]); // 취소 거부
  const [totalCount, setTotalCount] = useState<number>(0); // 전체 개수
  const [activePage, setActivePage] = useState<number>(1); // 현재 페이지
  const [tabIndex, setTabIndex] = useState<number>(TAB[0].id); // tab
  const [tableColumnList, setTableColumnList] = useState<string[]>(
    TABLE_HEADER[0].list
  );
  const [isRequestModalOpen, setIsRequestModalOpen] = useState<boolean>(false); // 요청 내역 모달 (취소 요청)
  const [isRequestProgressModalOpen, setIsRequestProgressModalOpen] =
    useState<boolean>(false); // 요청 내역 모달 (취소 진행중)
  const [isCancelHistoryModalOpen, setIsCancelHistoryModalOpen] =
    useState<boolean>(false); // 환불 모달
  const [refundLogId, setRefundLogId] = useState<number>(0);

  // tab button 클릭 시
  const onTabClick = (id: number, idx: number) => {
    setTabIndex(id);
    setTableColumnList(TABLE_HEADER[idx].list);
  };

  // 취소 table pagenation
  const handlePageChange = (page: number) => {
    setActivePage(page);
  };

  // 취소 목록
  const getAsyncCancelList = async () => {
    if (tabIndex === 12) {
      const data = await getCancelRefusalList(activePage, 10, tabIndex);
      if (data.ok) {
        setTotalCount(data.res.totalCount);
        setCancelRefusalList(data.res.item);
      } else {
        if (data.res.status === 315) Logout();
        else if (data.res.status === 400)
          alert('번호나 코드는 숫자만 입력해주세요.');
        else console.error(data.res.error.message);
      }
    } else {
      const data = await getCancelList(activePage, 10, tabIndex);
      if (data.ok) {
        setTotalCount(data.res.totalCount);
        setCancelList(data.res.item);
      } else {
        if (data.res.status === 315) Logout();
        else if (data.res.status === 400)
          alert('번호나 코드는 숫자만 입력해주세요.');
        else console.error(data.res.error.message);
      }
    }
  };

  useEffect(() => {
    getAsyncCancelList();
  }, [activePage, tabIndex]);

  return (
    <MainLayout>
      {/* 카테고리 */}
      <S_CategoryContainer>
        <S_CategoryTitle>주문 별 현황</S_CategoryTitle>
        <S_CategoryFilterContainer>
          {TAB.map((v, i) => (
            <TabButton
              key={i}
              title={v.title}
              isActive={tabIndex === v.id}
              onClick={() => onTabClick(v.id, i)}
            />
          ))}
        </S_CategoryFilterContainer>
      </S_CategoryContainer>

      {/* 상품 목록 */}
      <S_TableButtonContainer>
        <S_TableTitle>상품 목록({totalCount})</S_TableTitle>
      </S_TableButtonContainer>

      <S_TableContainer>
        <S_TableBox>
          <S_Table>
            <thead>
              <tr>
                {tableColumnList.map((v, i) => (
                  <S_TableHeaderItem key={i}>
                    {v === 'check' ? (
                      <Checkbox
                        width='16.67px'
                        height='16.67px'
                        color='#2E2E2E'
                      />
                    ) : (
                      v
                    )}
                  </S_TableHeaderItem>
                ))}
              </tr>
            </thead>
            <tbody>
              {tabIndex === 12 ? (
                cancelRefusalList.map(
                  (v: I_CancelAndReturnRefusal, i: number) => (
                    <CancelRefusal key={i} item={v} />
                  )
                )
              ) : (
                <>
                  {cancelList?.map((v: I_Cancel, i: number) =>
                    tabIndex === 10 ? (
                      <CancelRequest
                        key={i}
                        item={v}
                        setIsModalOpen={setIsRequestModalOpen}
                        setRefundLogId={setRefundLogId}
                      />
                    ) : tabIndex === 13 ? (
                      <CancelProgress
                        key={i}
                        item={v}
                        setIsModalOpen={setIsRequestProgressModalOpen}
                        setRefundLogId={setRefundLogId}
                      />
                    ) : tabIndex === 11 ? (
                      <CancelCompleted
                        key={i}
                        item={v}
                        setIsModalOpen={setIsCancelHistoryModalOpen}
                        setRefundLogId={setRefundLogId}
                      />
                    ) : (
                      <tr key={i} />
                    )
                  )}
                </>
              )}
            </tbody>
          </S_Table>
        </S_TableBox>

        <PagenationButton
          activePage={activePage}
          totalCount={totalCount}
          postPerPage={10}
          handlePageChange={handlePageChange}
        />
      </S_TableContainer>

      <CancelRequestHistoryModal
        isOpen={isRequestModalOpen}
        onClose={() => {
          setIsRequestModalOpen(false);
          document.body.style.overflow = 'unset'; // body scroll 생성
        }}
        refundLogId={refundLogId}
      />

      <CancelRequestHistoryModal2
        isOpen={isRequestProgressModalOpen}
        onClose={() => {
          setIsRequestProgressModalOpen(false);
          getAsyncCancelList();
          document.body.style.overflow = 'unset'; // body scroll 생성
        }}
        refundLogId={refundLogId}
      />

      <CancelHistoryModal
        isOpen={isCancelHistoryModalOpen}
        onClose={() => {
          setIsCancelHistoryModalOpen(false);
          getAsyncCancelList();
          document.body.style.overflow = 'unset'; // body scroll 생성
        }}
        refundLogId={refundLogId}
      />
    </MainLayout>
  );
}

export default StoreCancel;

const S_TableHeaderItem = styled.th`
  vertical-align: middle;
  padding: 10px;
  height: 36px;
  background-color: ${(props) => props.theme.colors.hd4};
  border: 0.5px solid ${(props) => props.theme.colors.hd3};
  font-weight: 500;
  font-size: 13px;
  letter-spacing: -0.02em;
  color: ${(props) => props.theme.colors.n10};
`;

const S_Table = styled.table`
  width: 100%;
`;

const S_TableBox = styled.div`
  overflow-x: overlay;
  &::-webkit-scrollbar {
    width: 4px;
    height: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.theme.colors.hd3};
    border-radius: 6px;
  }
`;

const S_TableContainer = styled.div`
  margin-top: 8px;
  position: relative;
`;

const S_TableButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const S_TableTitle = styled.span`
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.02em;
  display: flex;
  align-items: flex-end;
`;

const S_TableButtonContainer = styled.div`
  margin-top: 36px;
  display: flex;
  justify-content: space-between;
`;

const S_CategoryFilterContainer = styled.div`
  min-width: 915px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

const S_CategoryTitle = styled.span`
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.02em;
`;

const S_CategoryContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const TAB = [
  {
    id: 10,
    title: '취소 요청',
  },
  {
    id: 13,
    title: '취소 진행중',
  },
  {
    id: 11,
    title: '취소 완료',
  },
  {
    id: 12,
    title: '취소 거부',
  },
];

const TABLE_HEADER = [
  {
    id: 10,
    list: [
      '취소 주문 번호',
      '요청 일시',
      '결제 방식',
      '요청 상품',
      '주문 일시',
      '구매자명',
      '구매자 연락처',
      'User Code',
    ],
  },
  {
    id: 13,
    list: [
      '취소 주문 번호',
      '요청 일시',
      '결제 방식',
      '요청 상품',
      '주문 일시',
      '구매자명',
      '구매자 연락처',
      'User Code',
    ],
  },
  {
    id: 11,
    list: [
      '취소 주문 번호',
      '요청 일시',
      '결제 방식',
      '요청 상품',
      '주문 일시',
      '구매자명',
      '구매자 연락처',
      'User Code',
    ],
  },
  {
    id: 12,
    list: [
      '취소 주문 번호',
      '요청 일시',
      '처리 사유',
      '주문 상품',
      '주문 일시',
      '구매자명',
      '구매자 연락처',
      'User Code',
    ],
  },
];
