// theme 정의
import { DefaultTheme } from 'styled-components';

// 어두운 테마
export const theme: DefaultTheme = {
  colors: {
    ho1: '#13A872',
    ho2: '#4EBE95',
    ho3: '#C4E9DC',
    ho4: '#277C5D',
    ho5: '#47C21C',
    ho6: '#FC1D1D',
    ho7: '#e82059',

    hd1: '#000000',
    hd2: '#888888',
    hd3: '#B6B6B6',
    hd4: '#F0F0F0',
    hd5: '#FFFFFF',
  },
};