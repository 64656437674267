import { useNavigate } from 'react-router-dom';

import styled from 'styled-components';

import { I_Cancel } from 'etc/types';
import { dateTimeFormat } from 'utils/common';

interface I_CancelCompleted {
  item: I_Cancel;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setRefundLogId: React.Dispatch<React.SetStateAction<number>>;
}

function CancelCompleted({
  item,
  setIsModalOpen,
  setRefundLogId,
}: I_CancelCompleted) {
  const navigate = useNavigate();
  const productName = () => {
    const temp = item.brandCommaProduct.split(',');
    const count = Number(item.refundProductCount) - 1;
    return (
      <>
        <span style={{ fontWeight: '700' }}>{temp[0]} </span>
        <span>{temp[1]}</span>
        {count ? <span style={{ fontWeight: '700' }}> 외 {count}</span> : <></>}
      </>
    );
  };

  return (
    <S_TableRow>
      <S_TableColData>
        {item.paymentMethod ? (
          <span
            onClick={() => {
              setRefundLogId(item.refundLogId);
              setIsModalOpen(true);
            }}
            style={{ textDecoration: 'underLine', cursor: 'pointer' }}
          >
            {item.refundLogId}
          </span>
        ) : (
          <span>{item.refundLogId}</span>
        )}
      </S_TableColData>
      <S_TableColData>{dateTimeFormat(item.createdAt)}</S_TableColData>
      <S_TableColData>
        {item.paymentMethod === 'card'
          ? '신용/체크카드'
          : item.paymentMethod === 'virtualAccount'
          ? '무통장 입금'
          : '-'}
      </S_TableColData>
      <S_TableColData style={{ minWidth: '248px' }}>
        {productName()}
      </S_TableColData>
      <S_TableColData>{dateTimeFormat(item.orderCreatedAt)}</S_TableColData>
      <S_TableColData>{item.userNickname}</S_TableColData>
      <S_TableColData>{item.userPhone}</S_TableColData>
      <S_TableColData>
        <span
          onClick={() => navigate(`/user/${item.userId}`)}
          style={{ textDecoration: 'underLine', cursor: 'pointer' }}
        >
          {item.userCode}
        </span>
      </S_TableColData>
    </S_TableRow>
  );
}

export default CancelCompleted;

const S_TableColData = styled.td`
  text-align: center;
  vertical-align: middle;
  border: 1px solid ${(props) => props.theme.colors.hd3};
  padding: 10px;
`;

const S_TableRow = styled.tr`
  height: 36px;
  font-weight: 500;
  font-size: 13px;
`;
