import { useNavigate } from 'react-router-dom';

import styled from 'styled-components';

import { dateFormat, dateFormatUTC } from 'utils/common';
import { I_Event } from 'etc/types';

import { ReactComponent as Checkbox } from 'assets/image/checkbox.svg';
import { ReactComponent as CheckboxChecked } from 'assets/image/checkbox-checked.svg';
import moment from 'moment';

interface I_EventTableItem {
  item: I_Event;
}

function EventTableItem({ item }: I_EventTableItem) {
  const navigate = useNavigate();

  return (
    <S_TableRow>
      <S_TableColData
        style={{ textDecoration: 'underLine', cursor: 'pointer' }}
        onClick={() => navigate(`/service/event/${item.id}`)}
      >
        {item.eventName}
      </S_TableColData>
      <S_TableColData>
        {dateFormatUTC(item.start).format('YYYY년 M월 DD일 HH:mm')} ~
        {dateFormatUTC(item.end).format('YYYY년 M월 DD일 HH:mm')}
      </S_TableColData>
      <S_TableColData>
        <S_Img src={item.bannerImage.url} />
      </S_TableColData>
      <S_TableColData>
        {item.status === 'PROCEEDING' ? (
          <span style={{ color: '#3CBF0E' }}>진행 중</span>
        ) : (
          <span style={{ color: '#FF0000' }}>종료</span>
        )}
      </S_TableColData>
    </S_TableRow>
  );
}

export default EventTableItem;

const S_Img = styled.img`
  width: 150px;
  aspect-ratio: 9/3.364;
  object-fit: contain;
  border-radius: 6px;
`;

const S_Checkbox = styled.div`
  width: 16.67px;
  height: 16.67px;
  color: #aeaeae;
  margin: 0 auto;
  cursor: pointer;
`;

const S_TableColData = styled.td`
  text-align: center;
  vertical-align: middle;
  border: 1px solid ${(props) => props.theme.colors.hd3};
  padding: 10px;
`;

const S_TableRow = styled.tr`
  height: 36px;
  font-weight: 500;
  font-size: 13px;
`;
