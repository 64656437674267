import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFieldArray, useForm } from 'react-hook-form';

import styled from 'styled-components';

import { ReactComponent as Delete } from 'assets/image/minus-circle.svg';

import MainLayout from 'components/Layout/MainLayout';
import MiniButton from 'components/Button/MiniButton';
import LongButton from 'components/Button/LongButton';
import NormalButton from 'components/Button/NormalButton';

import RegisterInput from 'components/Input/RegisterInput';
import CategoryDropdown from 'components/Dropdown/CategoryDropdown';
import BrandDropdown from 'components/Dropdown/BrandDropdown';
import {
  I_TravelProductCategory,
  I_TravelProductCategoryRegistrationForm,
} from 'etc/types';
import useLogout from 'hooks/useLogout';
import Spinner from 'components/Spinner/Spinner';

import { postTravelProductCategory, getTravelProductCategoryList } from 'api/travelProduct';

import CategoryList from 'components/Item/TravelProduct/Category/CategoryList';


function CategoryRegist() {

  const navigate = useNavigate();
  const Logout = useLogout();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [ isCategoryList, setCategoryList ] = useState<I_TravelProductCategory[]>([]);
  const [ isCategoryTotalCount, setCategoryTotalCount ] = useState<number>(0);

  // 카테고리 리스트
  const getAsyncCategoryList = async () => {

    const data = await getTravelProductCategoryList();
    if (data.ok) {
      setCategoryList(data.res.list);
      setCategoryTotalCount(data.res.totalCount);
    } else {
      if (data.res.status === 315) Logout();
      else console.error(data.res.error.message);
    }
  };

  useEffect(() => {
    getAsyncCategoryList();
  }, []);
  
  const {
    register,
    watch,
    getValues,
    setValue,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm<I_TravelProductCategoryRegistrationForm>({
    mode: 'onChange',
    shouldFocusError: true,
    defaultValues: {
      name: '',
    },
  });
  

  // 제출
  const onSubmit = async (data: I_TravelProductCategoryRegistrationForm) => {
    const result = await postTravelProductCategory(data);
    if (result.ok) {
      alert('등록됐습니다');
      getAsyncCategoryList();
      reset({ name: '' }); // 폼 초기화
    } else {
      if (result.res.status === 315) Logout();
      else console.error(result.res.error.message);
    }
  };


  return (
    <MainLayout>
      <form onSubmit={handleSubmit(onSubmit)}>
        <S_TopContainer>
          <S_BasicInfoTitle>카테고리</S_BasicInfoTitle>
          <S_BasicInfoContainer>
            <S_BasicInfoRowWrapper>
              <S_BasicInfoWrapper>
                <S_Label>카테고리명</S_Label>
                <RegisterInput
                  isError={errors?.name ? true : false}
                  register={register('name', {
                    required: 'Required field.',
                  })}
                  style={{ width: '380px' }}
                />
              </S_BasicInfoWrapper>

              <NormalButton onClick={() => console.log('NormalButton')}>카테고리 등록</NormalButton>
            </S_BasicInfoRowWrapper>

          </S_BasicInfoContainer>
        </S_TopContainer>
        <S_MidContainer>
          <S_BasicInfoTitle>리스트</S_BasicInfoTitle>
          <CategoryList categories={isCategoryList} />
        </S_MidContainer>
      </form>
    </MainLayout>
  );
}

export default CategoryRegist;

const S_RemoveButton = styled.div`
  position: absolute;
  right: -40px;
  width: 24px;
  height: 24px;
  cursor: pointer;
`;

const S_ImageDesc = styled.span`
  margin-top: 8px;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  color: ${(props) => props.theme.colors.hd2};
`;

const S_ImageTitle = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.02em;
`;

const S_ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin-top: 16px;
`;

const S_BottomContainer = styled.div`
  padding: 24px 0 64px;
`;

const S_FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const S_AddInfoContainer = styled.div`
  margin: 16px 0 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const S_MidContainer = styled.div`
  padding: 24px 0 32px;
  border-bottom: 1px solid ${(props) => props.theme.colors.hd4};
`;

const S_CategoryWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const S_AddImage = styled.label`
  width: 111px;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.colors.ho1};
  border-radius: 8px;
  font-weight: 700;
  font-size: 13px;
  letter-spacing: -0.02em;
  color: ${(props) => props.theme.colors.hd5};
  margin-left: 23px;
  cursor: pointer;
  &:hover {
    opacity: 0.5;
  }
`;

const S_ImageList = styled.div`
  font-weight: 400;
  font-size: 13px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  span {
    width: 346px;
    line-height: 18px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const S_InputContainer = styled.div<{
  isDisabled?: boolean;
  isError?: boolean;
}>`
  width: 380px;
  min-height: 46px;
  padding: 14px 16px;
  background: ${(props) =>
    props.isDisabled ? props.theme.colors.hd4 : props.theme.colors.hd5};
  border: 1px solid
    ${(props) =>
      props.isError ? props.theme.colors.ho6 : props.theme.colors.hd3};
  border-radius: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
`;

const S_Label = styled.div`
  width: 100px;
  flex-shrink: 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.02em;
`;

const S_BasicInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const S_BasicInfoRowWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 45px;
  width: 100%;
`;

const S_BasicInfoTitle = styled.span`
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.02em;
`;

const S_BasicInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 16px;
`;

const S_TopContainer = styled.div`
  padding-bottom: 32px;
  border-bottom: 1px solid ${(props) => props.theme.colors.hd4};
`;
