import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { I_Report } from 'etc/types';
import { dateFormat } from 'utils/common';
import { REPORT_CATEGORY } from 'etc/variable';

interface I_ReportTableItem {
  item: I_Report;
}

function ReportTableItem({ item }: I_ReportTableItem) {
  const navigate = useNavigate();
  const marketType = REPORT_CATEGORY.find(
    (el) => el.category === item.category.name
  )?.type;

  return (
    <S_TableRow>
      <S_TableColData>
        {dateFormat(item.createdAt).format('YYYY년 M월 D일')}
      </S_TableColData>
      <S_TableColData style={{ minWidth: 100 }}>
        {item.targetUser.profile?.nickname}
      </S_TableColData>
      <S_TableColData>{item.targetUser.userCode}</S_TableColData>
      <S_TableColData>{marketType}</S_TableColData>
      <S_TableColData>{item.targetUser.reportCount}</S_TableColData>
      <S_TableColData
        style={{
          minWidth: 170,
          textDecoration: 'underLine',
          cursor: 'pointer',
          wordBreak: 'break-all',
        }}
        onClick={() => navigate(`/service/report/${item.id}`)}
      >
        {item.reason}
      </S_TableColData>
      <S_TableColData
        style={{
          color: item.status === 'NECESSARY' ? '#FF0000' : '#3CBF0E',
          minWidth: 80,
        }}
      >
        {item.status === 'NECESSARY' ? '처리 필요' : '처리 완료'}
      </S_TableColData>
    </S_TableRow>
  );
}

export default ReportTableItem;

const S_TableColData = styled.td`
  text-align: center;
  vertical-align: middle;
  border: 1px solid ${(props) => props.theme.colors.hd3};
  padding: 10px;
`;

const S_TableRow = styled.tr`
  height: 36px;
  font-weight: 500;
  font-size: 13px;
`;
