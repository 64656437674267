import { useEffect, useState } from 'react';

import styled from 'styled-components';

import { ReactComponent as Close } from 'assets/image/close.svg';
import { ReactComponent as Eye } from 'assets/image/eye.svg';
import { ReactComponent as EyeSlash } from 'assets/image/eye-slash.svg';
import { ReactComponent as ArrowRight } from 'assets/image/arrow-right-full.svg';
import { ReactComponent as AddCircle } from 'assets/image/add-circle.svg';
import { ReactComponent as Edit } from 'assets/image/edit.svg';

import ModalLayout from 'components/Layout/ModalLayout';
import NormalButton from 'components/Button/NormalButton';
import { I_Category } from 'etc/types';
import TableInput from 'components/Input/TableInput';
import MiniButton from 'components/Button/MiniButton';
import SubModal from './SubModal';
import Input from 'components/Input/Input';
import useLogout from 'hooks/useLogout';

import { postCategoryUpdate } from 'api/store';

interface I_CategorySettingModal {
  isOpen: boolean;
  onClose: () => void;
  categories: I_Category[];
  getAsyncCategoryList: () => void;
}

function CategorySettingModal({
  isOpen,
  onClose,
  categories,
  getAsyncCategoryList,
}: I_CategorySettingModal) {
  const Logout = useLogout();
  // 현재 클릭한 카테고리
  const [mainCategory, setMainCategory] = useState<number>(0);
  const [midCategory, setMidCategory] = useState<number>(0);
  const [subCategory, setSubCategory] = useState<number>(0);
  const onCategoryMainItemClick = (item: I_Category) => {
    setMainCategory(item.id);
    setMidCategory(0);
    setSubCategory(0);
    setAddMidCategory(false);
    setAddSubCategory(false);
  };
  const onCategoryMidItemClick = (item: I_Category) => {
    setMidCategory(item.id);
    setSubCategory(0);
  };
  const onCategorySubItemClick = (item: I_Category) => {
    setSubCategory(item.id);
  };
  // const [addMainCategory, setAddMainCategory] = useState<boolean>(false);
  const [addMidCategory, setAddMidCategory] = useState<boolean>(false); // 추가
  const [addSubCategory, setAddSubCategory] = useState<boolean>(false);
  const [addInputValue, setAddInputValue] = useState<string>('');
  const [editCategory, setEditCategory] = useState<number>(0); // 수정
  const [editInputValue, setEditInputValue] = useState<string>('');

  const onAddSubmit = async (type: number) => {
    if (!addInputValue) {
      alert('카테고리 이름을 작성해주세요.');
      return;
    }

    let insert;
    if (type === 1) {
      insert = [
        {
          parent: mainCategory,
          name: addInputValue,
          childs: [],
        },
      ];
    } else {
      insert = [
        {
          parent: midCategory,
          name: addInputValue,
          childs: [],
        },
      ];
    }
    const data = await postCategoryUpdate(insert, []);
    if (data.ok) {
      getAsyncCategoryList();
      setAddMidCategory(false);
      setAddSubCategory(false);
      setAddInputValue('');
    } else {
      if (data.res.status === 315) Logout();
      else {
        alert('잠시 후 다시 시도해주세요.');
        console.error(data.res.error.message);
      }
    }
  };

  const handleEditClick = (
    e: React.MouseEvent<HTMLDivElement>,
    categoryId: number
  ) => {
    e.stopPropagation();

    setEditCategory(categoryId);
    setEditInputValue('');
  };

  const onEditSubmit = async (type: number) => {
    if (!editInputValue.trim()) {
      alert('값을 입력해주세요.');
      return;
    }
    let update;
    if (type === 1) {
      update = [
        {
          id: editCategory,
          parent: mainCategory,
          name: editInputValue,
          isHidden: false,
        },
      ];
    } else {
      update = [
        {
          id: editCategory,
          parent: midCategory,
          name: editInputValue,
          isHidden: false,
        },
      ];
    }

    const data = await postCategoryUpdate([], update);
    if (data.ok) {
      getAsyncCategoryList();
      setEditCategory(0);
      setEditInputValue('');
    } else {
      if (data.res.status === 315) Logout();
      else {
        alert('잠시 후 다시 시도해주세요.');
        console.error(data.res.error.message);
      }
    }
  };

  const handleHideClick = async (
    e: React.MouseEvent<HTMLDivElement>,
    type: number,
    item: I_Category,
    isHide: boolean
  ) => {
    e.stopPropagation();

    if (
      isHide
        ? confirm('해당 카테고리를 숨기겠습니까?')
        : confirm('해당 카테고리를 숨김 해제 하시겠습니까?')
    ) {
      let update;
      if (type === 1) {
        update = [
          {
            id: item.id,
            parent: mainCategory,
            name: item.name,
            isHidden: !item.isHidden,
          },
        ];
      } else {
        update = [
          {
            id: item.id,
            parent: midCategory,
            name: item.name,
            isHidden: !item.isHidden,
          },
        ];
      }

      const data = await postCategoryUpdate([], update);
      if (data.ok) {
        getAsyncCategoryList();
      } else {
        if (data.res.status === 315) Logout();
        else {
          alert('잠시 후 다시 시도해주세요.');
          console.error(data.res.error.message);
        }
      }
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden'; // body scroll 제거
    }
  }, [isOpen]);

  const checkListLength = (type: number) => {
    if (type === 1) {
      const temp = categories?.find((item) => item.id === mainCategory)?.childs;
      if (temp) return temp.length;
      else return 0;
    } else {
      const temp = categories
        ?.find((item) => item.id === mainCategory)
        ?.childs?.find((child) => child.id === midCategory)?.childs;
      if (temp) return temp.length;
      else return 0;
    }
  };

  return (
    <ModalLayout isOpen={isOpen} onClose={onClose}>
      <S_Container>
        <S_CloseButton
          onClick={() => {
            document.body.style.overflow = 'unset'; // body scroll 생성
            onClose();
          }}
        >
          <Close />
        </S_CloseButton>

        <S_ContentContainer>
          <S_CategoryModalTitle>상품 카테고리 수정</S_CategoryModalTitle>
          {/* <S_CategoryModalContent>
          *카테고리 생성 : 새로운 대분류/중분류/소분류 카테고리 생성 시 모든
          분류에 하위 카테고리가 생성되어야 저장 가능합니다.
          <br />
          *카테고리 수정 : 특정 카테고리에 대한 카테고리 명 수정이 가능합니다.
          최종 저장하기 버튼을 눌러야 앱 내 반영됩니다.
          <br />
          *카테고리 숨기기 : 잠시 판매가 진행되지 않는 분류에 대해 숨기기
          가능합니다.
        </S_CategoryModalContent> */}

          <S_CategoryTableContainer>
            <S_CategoryTable isScroll={categories?.length > 12 ? true : false}>
              <S_CategoryTableHeader>
                <span>대분류</span>
              </S_CategoryTableHeader>
              {categories?.map((v, i) => (
                <S_CategoryItem
                  key={i}
                  onClick={() => onCategoryMainItemClick(v)}
                  isActive={mainCategory === v.id}
                  isHidden={v.isHidden || false}
                >
                  <S_CategoryItemLeft>
                    <span>{v.name}</span>
                  </S_CategoryItemLeft>
                </S_CategoryItem>
              ))}
            </S_CategoryTable>
            <ArrowRight width='24px' height='24px' />
            <S_CategoryTable isScroll={checkListLength(1) > 12 ? true : false}>
              <S_CategoryTableHeader>
                <span>중분류</span>
                {!addMidCategory && (
                  <AddCircle
                    width='24px'
                    height='24px'
                    onClick={() =>
                      mainCategory
                        ? setAddMidCategory(true)
                        : alert('대분류를 선택하세요.')
                    }
                    style={{ cursor: 'pointer' }}
                  />
                )}
              </S_CategoryTableHeader>
              {categories
                ?.find((item) => item.id === mainCategory)
                ?.childs?.map((v, i) =>
                  editCategory === v.id ? (
                    <S_CategoryInputItem key={i}>
                      <TableInput
                        state={editInputValue}
                        setState={setEditInputValue}
                      />
                      <MiniButton
                        onClick={() => onEditSubmit(1)}
                        style={{ minWidth: '67px' }}
                      >
                        저장
                      </MiniButton>
                      <MiniButton
                        onClick={(e) => handleEditClick(e, 0)}
                        style={{ minWidth: '67px' }}
                      >
                        취소
                      </MiniButton>
                    </S_CategoryInputItem>
                  ) : (
                    <S_CategoryItem
                      key={i}
                      onClick={() => onCategoryMidItemClick(v)}
                      isActive={midCategory === v.id}
                      isHidden={v.isHidden || false}
                    >
                      <S_CategoryItemLeft>
                        <span>{v.name}</span>
                        <S_Edit onClick={(e) => handleEditClick(e, v.id)}>
                          <Edit width='20px' height='20px' />
                        </S_Edit>
                      </S_CategoryItemLeft>
                      {v.isHidden ? (
                        <MiniButton
                          onClick={(e) => handleHideClick(e, 1, v, false)}
                        >
                          숨기기 해제
                        </MiniButton>
                      ) : (
                        <MiniButton
                          onClick={(e) => handleHideClick(e, 1, v, true)}
                        >
                          숨기기
                        </MiniButton>
                      )}
                    </S_CategoryItem>
                  )
                )}
            </S_CategoryTable>
            <ArrowRight width='24px' height='24px' />
            <S_CategoryTable isScroll={checkListLength(2) > 12 ? true : false}>
              <S_CategoryTableHeader>
                <span>소분류</span>
                {!addSubCategory && (
                  <AddCircle
                    width='24px'
                    height='24px'
                    onClick={() =>
                      midCategory
                        ? setAddSubCategory(true)
                        : alert('중분류를 선택하세요.')
                    }
                    style={{ cursor: 'pointer' }}
                  />
                )}
              </S_CategoryTableHeader>
              {categories
                ?.find((item) => item.id === mainCategory)
                ?.childs?.find((child) => child.id === midCategory)
                ?.childs?.map((v, i) =>
                  editCategory === v.id ? (
                    <S_CategoryInputItem key={i}>
                      <TableInput
                        state={editInputValue}
                        setState={setEditInputValue}
                      />
                      <MiniButton
                        onClick={() => onEditSubmit(2)}
                        style={{ minWidth: '67px' }}
                      >
                        저장
                      </MiniButton>
                      <MiniButton
                        onClick={(e) => handleEditClick(e, 0)}
                        style={{ minWidth: '67px' }}
                      >
                        취소
                      </MiniButton>
                    </S_CategoryInputItem>
                  ) : (
                    <S_CategoryItem
                      key={i}
                      onClick={() => onCategorySubItemClick(v)}
                      isHidden={v.isHidden || false}
                    >
                      <S_CategoryItemLeft>
                        <span>{v.name}</span>
                        <S_Edit onClick={(e) => handleEditClick(e, v.id)}>
                          <Edit width='20px' height='20px' />
                        </S_Edit>
                      </S_CategoryItemLeft>
                      {v.isHidden ? (
                        <MiniButton
                          onClick={(e) => handleHideClick(e, 2, v, false)}
                        >
                          숨기기 해제
                        </MiniButton>
                      ) : (
                        <MiniButton
                          onClick={(e) => handleHideClick(e, 2, v, true)}
                        >
                          숨기기
                        </MiniButton>
                      )}
                    </S_CategoryItem>
                  )
                )}
            </S_CategoryTable>
          </S_CategoryTableContainer>
        </S_ContentContainer>
      </S_Container>
      {addMidCategory && (
        <SubModal
          state={addMidCategory}
          setState={setAddMidCategory}
          style={{
            width: '537px',
            height: '288px',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            padding: '48px 78px',
          }}
        >
          <S_BrandModalTitle>중분류 카테고리 추가</S_BrandModalTitle>
          <S_BrandUpdateInputContainer>
            <Input
              state={addInputValue}
              setState={setAddInputValue}
              style={{ width: '100%' }}
              placeholder='여기에 입력하세요'
            />
            <NormalButton onClick={() => onAddSubmit(1)}>저장</NormalButton>
          </S_BrandUpdateInputContainer>
        </SubModal>
      )}
      {addSubCategory && (
        <SubModal
          state={addSubCategory}
          setState={setAddSubCategory}
          style={{
            width: '537px',
            height: '288px',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            padding: '48px 78px',
          }}
        >
          <S_BrandModalTitle>소분류 카테고리 추가</S_BrandModalTitle>
          <S_BrandUpdateInputContainer>
            <Input
              state={addInputValue}
              setState={setAddInputValue}
              style={{ width: '100%' }}
              placeholder='여기에 입력하세요'
            />
            <NormalButton onClick={() => onAddSubmit(2)}>저장</NormalButton>
          </S_BrandUpdateInputContainer>
        </SubModal>
      )}
    </ModalLayout>
  );
}

export default CategorySettingModal;

const S_BrandUpdateInputContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 34px;
`;

const S_BrandModalTitle = styled.div`
  width: 100%;
  margin-bottom: 34px;
  font-weight: 700;
  font-size: 24px;
  line-height: 35px;
  letter-spacing: -0.02em;
  text-align: center;
`;

const S_Eye = styled.div`
  width: 20px;
  height: 20px;
  :hover {
    opacity: 0.5;
  }
`;

const S_Edit = styled.div`
  :hover {
    opacity: 0.5;
  }
`;

const S_CategoryItemLeft = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const S_CategoryInputItem = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 11.5px 24px;
  border-bottom: 1px solid ${(props) => props.theme.colors.hd3};
`;

const S_CategoryItem = styled.div<{ isActive?: boolean; isHidden: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 54px;
  padding: 16px 24px;
  border-bottom: 1px solid ${(props) => props.theme.colors.hd3};
  background-color: ${(props) =>
    props.isActive
      ? props.theme.colors.ho3
      : props.isHidden
      ? props.theme.colors.hd4
      : 'none'};
  cursor: pointer;
  :hover {
    background-color: ${(props) => props.theme.colors.ho3};
  }
`;

const S_CategoryTableHeader = styled.div`
  position: sticky;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  height: 57px;
  background-color: ${(props) => props.theme.colors.hd4};
  border-bottom: 1px solid ${(props) => props.theme.colors.hd3};
  span {
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: -0.02em;
  }
`;

const S_CategoryTable = styled.div<{
  isScroll: boolean | undefined;
}>`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 340px;
  height: 703px;
  border: 1px solid ${(props) => props.theme.colors.hd3};
  overflow-y: ${(props) => (props.isScroll ? 'scroll' : 'hidden')};
  &::-webkit-scrollbar {
    width: 10px;
    background-color: ${(props) => props.theme.colors.hd4};
    border-left: 1px solid ${(props) => props.theme.colors.hd3};
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.theme.colors.hd3};
  }
`;

const S_CategoryTableContainer = styled.div`
  width: 1172px;
  display: flex;
  align-items: center;
  gap: 26px;
  margin: 39px auto 44px;
`;

const S_CategoryModalContent = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.02em;
  margin-top: 4px;
`;

const S_CategoryModalTitle = styled.span`
  font-weight: 700;
  font-size: 28px;
  line-height: 41px;
  letter-spacing: -0.02em;
`;

const S_CloseButton = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
  :hover {
    opacity: 0.5;
  }
`;

const S_ContentContainer = styled.div`
  width: 1159px;
  margin: 0 auto;
  position: relative;
`;

const S_Container = styled.div`
  width: 100%;
  height: 100%;
  background: ${(props) => props.theme.colors.hd5};
  padding: 70px 70px 44px;
  overflow-y: overlay;
  z-index: 10;
`;
