import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import styled from 'styled-components';

import MainLayout from 'components/Layout/MainLayout';
import SearchBarInput from 'components/Input/SearchBarInput';
import PagenationButton from 'components/Button/PagenationButton';
import NormalButton from 'components/Button/NormalButton';

import { ReactComponent as Checkbox } from 'assets/image/checkbox.svg';
import CouponTableItem from 'components/Item/CouponTableItem';
import { TABLE_LIMIT } from 'etc/variable';
import { I_Coupon } from 'etc/types';
import { getCouponList } from 'api/coupon';
import useLogout from 'hooks/useLogout';

function Coupon() {
  const Logout = useLogout();
  const navigate = useNavigate();

  const [searchValue, setSearchValue] = useState<string>('');
  const [totalCount, setTotalCount] = useState<number>(0); // 전체 개수
  const [activePage, setActivePage] = useState<number>(1); // 현재 페이지
  const [couponList, setCouponList] = useState<I_Coupon[]>([]);
  const [isSearch, setIsSearch] = useState<boolean>(false);

  const handlePageChange = (page: number) => {
    setActivePage(page);

    if (isSearch) {
      getAsyncSearch(page);
    } else {
      getAsyncCouponList(page);
    }
  };

  // 검색 엔터 클릭
  const onKeyPressHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      setActivePage(1);
      getAsyncSearch(1);
    }
  };

  // 검색 확인
  const onClickSearch = () => {
    setActivePage(1);
    getAsyncSearch(1);
  };

  // 검색
  const getAsyncSearch = async (page: number) => {
    if (!searchValue.trim()) {
      getAsyncCouponList(1);
      setIsSearch(false);
    } else {
      setIsSearch(true);
      const data = await getCouponList(
        TABLE_LIMIT,
        page,
        `&search=${searchValue}`
      );

      if (data.ok) {
        // console.log(data.res);
        if (data.res) {
          setCouponList(data.res.item);
          setTotalCount(data.res.totalCount);
        } else setCouponList([]);
      } else {
        if (data.res.status === 315) Logout();
        else {
          alert('잠시 후 다시 시도해주세요.');
          console.error(data.res.error.message);
        }
      }
    }
  };

  const getAsyncCouponList = async (page: number) => {
    const data = await getCouponList(TABLE_LIMIT, page, '');
    if (data.ok) {
      // console.log(data.res.item);
      setCouponList(data.res.item);
      setTotalCount(data.res.totalCount);
    } else {
      if (data.res.status === 315) Logout();
      else {
        alert('잠시 후 다시 시도해주세요.');
        console.error(data.res.error.message);
      }
    }
  };

  useEffect(() => {
    getAsyncCouponList(1);
  }, []);

  return (
    <MainLayout>
      <SearchBarInput
        state={searchValue}
        setState={setSearchValue}
        onKeyPress={onKeyPressHandler}
        onClick={onClickSearch}
      />
      <S_TableButtonContainer>
        <NormalButton
          onClick={() => navigate(`/store/coupon/register`)}
          style={{ width: 103 }}
        >
          등록하기
        </NormalButton>
      </S_TableButtonContainer>
      <S_TableContainer>
        <S_TableBox>
          <S_Table>
            <thead>
              <tr>
                {TABLE_HEADER.map((v, i) => (
                  <S_TableHeaderItem key={i}>
                    {v === 'check' ? (
                      <Checkbox
                        width='16.67px'
                        height='16.67px'
                        color='#2E2E2E'
                      />
                    ) : (
                      v
                    )}
                  </S_TableHeaderItem>
                ))}
              </tr>
            </thead>
            <tbody>
              {couponList.map((v: I_Coupon, i: number) => (
                <CouponTableItem key={i} item={v} />
              ))}
            </tbody>
          </S_Table>
        </S_TableBox>

        <PagenationButton
          activePage={activePage}
          totalCount={totalCount}
          postPerPage={TABLE_LIMIT}
          handlePageChange={handlePageChange}
        />
      </S_TableContainer>
    </MainLayout>
  );
}

export default Coupon;

const S_TableButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  margin-top: 50px;
`;

const S_TableHeaderItem = styled.th`
  vertical-align: middle;
  padding: 10px;
  height: 36px;
  background-color: ${(props) => props.theme.colors.hd4};
  border: 0.5px solid ${(props) => props.theme.colors.hd3};
  font-weight: 500;
  font-size: 13px;
  letter-spacing: -0.02em;
  color: ${(props) => props.theme.colors.n10};

  &:nth-child(1) {
    min-width: 75px;
  }
  &:nth-child(2) {
    min-width: 260px;
  }
  &:nth-child(3) {
    min-width: 80px;
  }
  &:nth-child(4) {
    min-width: 200px;
  }
  &:nth-child(5) {
    min-width: 200px;
  }
  &:nth-child(6) {
    min-width: 150px;
  }
  &:nth-child(7) {
    min-width: 100px;
  }
  &:nth-child(8) {
    min-width: 90px;
  }
  &:nth-child(9) {
    min-width: 90px;
  }
`;

const S_Table = styled.table`
  width: 100%;
`;

const S_TableBox = styled.div`
  overflow-x: scroll;
  &::-webkit-scrollbar {
    width: 4px;
    height: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.theme.colors.hd3};
    border-radius: 6px;
  }
`;

const S_TableContainer = styled.div`
  margin-top: 8px;
  position: relative;
`;

const TABLE_HEADER = [
  '활성 상태',
  '쿠폰 이름',
  '할인율',
  '브랜드',
  '적용 상품',
  '최소 사용 금액',
  '최대 할인액',
  '발급 유저',
  '사용 가능 기간',
];
