export enum EEnableStatus {
  ENABLE = 'ENABLE',
  DISABLE = 'DISABLE',
}

export enum EActiveStatus {
  ACTIVE = 'ACTIVE',
  DEACTIVE = 'DEACTIVE',
}


export enum ERecommendStatus {
  RECOMMEND = 'RECOMMEND',
  NOT_RECOMMEND = 'NOT_RECOMMEND',
}

export enum EBooleanStatus {
  TRUE = 'true',
  FALSE = 'false',
}