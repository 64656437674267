import { useEffect, useState } from 'react';
import styled from 'styled-components';

import MainLayout from 'components/Layout/MainLayout';
import PagenationButton from 'components/Button/PagenationButton';
import Dropdown from 'components/Dropdown/Dropdown';
import AppServiceTableItem from 'components/Item/AppServiceTableItem';

import { getInquire } from 'api/inquire';
import { I_Inquire } from 'etc/types';
import { TABLE_LIMIT } from 'etc/variable';
import useLogout from 'hooks/useLogout';

function AppService() {
  const Logout = useLogout();

  const [isFilterDropdownOpen, setIsFilterDropdownOpen] =
    useState<boolean>(false);
  const [selectedFilterItem, setSelectedFilterItem] = useState<string>(
    FILTER_LIST[0]
  );
  const [totalCount, setTotalCount] = useState<number>(0); // 전체 개수
  const [activePage, setActivePage] = useState<number>(1); // 현재 페이지
  const [inquireList, setInquireList] = useState<I_Inquire[]>([]);

  const onFilterItemClick = (item: string) => {
    setSelectedFilterItem(item);
    setIsFilterDropdownOpen(false);
    setActivePage(1);
  };

  const handlePageChange = (page: number) => {
    setActivePage(page);
  };

  const getAsyncInquireList = async () => {
    const data = await getInquire(TABLE_LIMIT, activePage, selectedFilterItem);

    if (data.ok) {
      setInquireList(data.res.inquires);
      setTotalCount(data.res.totalCount);
    } else {
      if (data.res.status === 315) Logout();
      else {
        alert('잠시 후 다시 시도해주세요.');
        console.error(data.res.error.message);
      }
    }
  };

  useEffect(() => {
    getAsyncInquireList();
  }, [activePage, selectedFilterItem]);

  return (
    <MainLayout>
      <S_TableButtonContainer>
        <Dropdown
          list={FILTER_LIST}
          selectedItem={selectedFilterItem}
          isOpen={isFilterDropdownOpen}
          setIsOpen={setIsFilterDropdownOpen}
          onClick={(item: string) => onFilterItemClick(item)}
        />
      </S_TableButtonContainer>
      <S_TableContainer>
        <S_TableBox>
          <S_Table>
            <thead>
              <tr>
                {TABLE_HEADER.map((v, i) => (
                  <S_TableHeaderItem key={i}>{v}</S_TableHeaderItem>
                ))}
              </tr>
            </thead>
            <tbody>
              {inquireList.map((v: I_Inquire, i: number) => (
                <AppServiceTableItem key={i} item={v} />
              ))}
            </tbody>
          </S_Table>
        </S_TableBox>

        <PagenationButton
          activePage={activePage}
          totalCount={totalCount}
          postPerPage={TABLE_LIMIT}
          handlePageChange={handlePageChange}
        />
      </S_TableContainer>
    </MainLayout>
  );
}

export default AppService;

const S_TableButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
`;

const S_TableHeaderItem = styled.th`
  vertical-align: middle;
  padding: 10px;
  height: 36px;
  background-color: ${(props) => props.theme.colors.hd4};
  border: 0.5px solid ${(props) => props.theme.colors.hd3};
  font-weight: 500;
  font-size: 13px;
  letter-spacing: -0.02em;
  color: ${(props) => props.theme.colors.n10};
`;

const S_Table = styled.table`
  width: 100%;
`;

const S_TableBox = styled.div`
  overflow-x: scroll;
  &::-webkit-scrollbar {
    width: 4px;
    height: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.theme.colors.hd3};
    border-radius: 6px;
  }
`;

const S_TableContainer = styled.div`
  margin-top: 8px;
  position: relative;
`;

const TABLE_HEADER = [
  '문의 날짜',
  'User Code',
  '유저 닉네임',
  '답변 이메일',
  '요청타입',
  '답변 현황',
];

const FILTER_LIST = ['전체', '답변 완료', '확인 필요'];
