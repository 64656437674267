import styled from 'styled-components';

import { dateFormat } from 'utils/common';
import { I_Chatting } from 'etc/types';

interface ChattingTableItem {
  item: I_Chatting;
  user2: number;
  onClick: () => void;
}

function ChattingTableItem({ item, user2, onClick }: ChattingTableItem) {
  return (
    <S_TableRow>
      <S_TableColData>
        {dateFormat(item.createdAt).format('YYYY년 MM월 DD일 HH:mm')}
      </S_TableColData>
      <S_TableColData>
        {item.fromUserId !== user2 ? (
          item.message.includes('https://humanizone.s3') ? (
            <img src={item.message} onClick={onClick} />
          ) : (
            item.message
          )
        ) : (
          '-'
        )}
      </S_TableColData>
      <S_TableColData>
        {item.fromUserId === user2 ? (
          item.message.includes('https://humanizone.s3') ? (
            <img src={item.message} onClick={onClick} />
          ) : (
            item.message
          )
        ) : (
          '-'
        )}
      </S_TableColData>
    </S_TableRow>
  );
}

export default ChattingTableItem;

const S_TableColData = styled.td`
  text-align: center;
  vertical-align: middle;
  border: 1px solid ${(props) => props.theme.colors.hd3};
  padding: 10px;
  line-height: 130%;

  img {
    max-width: 100px;
    max-height: 100px;
    border-radius: 6px;
    cursor: pointer;
  }
`;

const S_TableRow = styled.tr`
  height: 36px;
  font-weight: 500;
  font-size: 13px;
`;
