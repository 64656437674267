import { callApi } from './callApi';

// 공지사항 리스트
export const getNoticeList = async (limit: number, currentPage: number) => {
  const res = await callApi({
    url: `/admin/announcement/list?currentPage=${currentPage}&limit=${limit}`,
    method: 'GET',
  });
  return res;
};

// 공지사항 상세
export const getNoticeDetail = async (noticeId: number) => {
  const res = await callApi({
    url: `/admin/announcement/${noticeId}`,
    method: 'GET',
  });
  return res;
};

// 공지사항 등록
export const postNotice = async (title: string, content: string) => {
  const res = await callApi({
    url: `/admin/announcement/announcement`,
    method: 'POST',
    data: { title: title, content: content },
  });
  return res;
};

// 공지사항 삭제
export const deletehNotice = async (noticeId: number) => {
  const res = await callApi({
    url: `/admin/announcement/${noticeId}`,
    method: 'DELETE',
  });
  return res;
};

// 공지사항 검색
export const getNoticeSearch = async (
  noticeName: string,
  limit: number,
  currentPage: number
) => {
  const res = await callApi({
    url: `/admin/announcement?title=${noticeName}&limit=${limit}&currentPage=${currentPage}`,
    method: 'GET',
  });
  return res;
};
