import { useEffect } from 'react';

import styled from 'styled-components';

import { ReactComponent as Close } from 'assets/image/close.svg';

interface I_NormalModal {
  children: React.ReactNode;
  state: boolean;
  setState: React.Dispatch<React.SetStateAction<boolean>>;
  title?: string;
  style?: React.CSSProperties;
  isClosedButton?: boolean;
  onClose?: () => void;
}

function NormalModal({
  children,
  state,
  setState,
  title,
  style,
  isClosedButton = true,
  onClose,
}: I_NormalModal) {
  useEffect(() => {
    if (state) document.body.style.overflow = 'hidden'; // body scroll 제거
  }, [state]);

  return (
    <>
      <S_Container style={style}>
        {isClosedButton && (
          <S_CloseButton>
            <Close
              onClick={() => {
                document.body.style.overflow = 'unset'; // body scroll 생성
                if (onClose) onClose();
                else setState(false);
              }}
            />
          </S_CloseButton>
        )}
        {title && <S_Title>{title}</S_Title>}
        {children}
      </S_Container>
      <S_Bg
        onClick={() => {
          document.body.style.overflow = 'unset'; // body scroll 생성
          if (onClose) onClose();
          else setState(false);
        }}
      />
    </>
  );
}

export default NormalModal;

const S_Bg = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.colors.hd1};
  opacity: 0.3;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10;
`;

const S_Title = styled.p`
  margin-top: 48px;
  font-size: 24px;
  font-weight: 700;
  line-height: 150%;
`;

const S_CloseButton = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
  :hover {
    opacity: 0.5;
  }
`;

const S_Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.colors.hd5};
  border-radius: 10px;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 11;
`;
