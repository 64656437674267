import styled from 'styled-components';

interface I_TabButton {
  title: string;
  onClick: () => void;
  isActive?: boolean;
  style?: React.CSSProperties;
}

function TabButton({ title, onClick, isActive, style }: I_TabButton) {
  return (
    <S_Container onClick={onClick} isActive={isActive} style={style}>
      <S_TabCategory>{title}</S_TabCategory>
    </S_Container>
  );
}

export default TabButton;

const S_TabCategoryCount = styled.div`
  width: 100%;
  font-weight: 600;
  text-align: right;
`;

const S_TabCategory = styled.span`
  font-weight: 800;
`;

const S_Container = styled.button<{ isActive?: boolean }>`
  width: 156px;
  height: 78px;
  display: flex;
  padding: 10px 13px;
  gap: 10px;
  background-color: ${(props) =>
    props.isActive ? props.theme.colors.ho3 : props.theme.colors.hd4};
  border-radius: 5px;
  font-size: 16px;
  color: ${(props) => props.theme.colors.hd1};
  &:hover {
    background-color: ${(props) => props.theme.colors.ho3};
  }
`;
