import { callApi } from './callApi';

// 채팅한 유저 검색
export const getChatUserList = async (category: string, userCode: string) => {
  const res = await callApi({
    url: `/admin/chat/user?category=${category}&userCode=${userCode}`,
    method: 'GET',
  });
  return res;
};

// 채팅한 유저 검색
export const getChattingList = async (
  chatRoomCode: string,
  from: string,
  to: string
) => {
  const res = await callApi({
    url: `/admin/chat/message?chatRoomCode=${chatRoomCode}&from=${from}&to=${to}`,
    method: 'GET',
  });
  return res;
};
