import styled from 'styled-components';

interface I_Input {
  state: string;
  setState: React.Dispatch<React.SetStateAction<string>>;
  style?: React.CSSProperties;
  placeholder?: string;
}

function Textarea({ state, setState, style, placeholder }: I_Input) {
  const onChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setState(event.target.value);
  };

  return (
    <S_Container style={style}>
      <S_Textarea value={state} onChange={onChange} placeholder={placeholder} />
    </S_Container>
  );
}

export default Textarea;

const S_Textarea = styled.textarea`
  width: 100%;
  height: 100%;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.02em;
  color: ${(props) => props.theme.colors.hd1};
  border: 0;
  background: ${(props) => props.theme.colors.hd5};
  resize: none;
  &::placeholder {
    color: ${(props) => props.theme.colors.hd2};
  }
  &:focus {
    outline: none;
  }
`;

const S_Container = styled.div`
  width: 100%;
  padding: 14px 16px;
  background: ${(props) => props.theme.colors.hd5};
  border: 1px solid ${(props) => props.theme.colors.hd3};
  border-radius: 12px;
`;
