import { callApi } from './callApi';
import { I_MarketingUtmForm } from 'etc/types';

// UTM 코드 등록
export const postMarketingUtm = async (data: I_MarketingUtmForm) => {
  const res = await callApi({
    url: `/admin/marketing/utm`,
    method: 'POST',
    data: data,
  });
  return res;
};

// UTM LOG 등록
export const postMarketingUtmLog = async (utmId: number) => {
  const res = await callApi({
    url: `/admin/marketing/utm/log`,
    method: 'POST',
    data: { utmId },
  });
  return res;
};

// UTM 리스트
export const getMarketingUtmList = async (limit: number, currentPage: number) => {
  const res = await callApi({
    url: `/admin/marketing/utm/list?currentPage=${currentPage}&limit=${limit}`,
    method: 'GET',
  });
  return res;
};

// UTM 리스트
export const getMarketingUtmTotalLog = async () => {
  const res = await callApi({
    url: `/admin/marketing/utm/totalLog`,
    method: 'GET',
  });
  return res;
};

// export const getMarketingUtmIdLog = async (utmId: number) => {
//   const res = await callApi({
//     url: `/admin/marketing/utm/${utmId}`,
//     method: 'GET',
//   });
//   return res;
// };


export const getMarketingUtmIdLog = async (utmName: string) => {
  const res = await callApi({
    url: `/admin/marketing/utm/nameLog?utmName=${utmName}`,
    method: 'GET',
  });
  return res;
};