import styled from 'styled-components';

interface I_LongButton {
  children: React.ReactNode;
  onClick?: () => void;
  style?: React.CSSProperties;
  [key: string]: any;
}

function LongButton({ children, onClick, style, ...rest }: I_LongButton) {
  return (
    <S_Container onClick={onClick} style={style} {...rest}>
      {children}
    </S_Container>
  );
}

export default LongButton;

const S_Container = styled.button`
  width: 300px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.colors.ho1};
  border-radius: 8px;
  font-weight: 700;
  font-size: 14px;
  letter-spacing: -0.02em;
  color: ${(props) => props.theme.colors.hd5};
  &:hover {
    opacity: 0.5;
  }
`;
