import styled from 'styled-components';

import { ReactComponent as Checkbox } from 'assets/image/checkbox.svg';

import { numberFormat } from 'utils/common';
import { I_OrderCancel } from 'etc/types';

interface I_StoreOrderRefundTableItem {
  item: I_OrderCancel;
  isCheckbox?: boolean;
}

function StoreOrderRefundTableItem({
  item,
  isCheckbox,
}: I_StoreOrderRefundTableItem) {
  return (
    <S_TableRow>
      {isCheckbox ? (
        <S_TableColData style={{ width: '38px' }}>
          <S_Checkbox>
            <Checkbox />
          </S_Checkbox>
        </S_TableColData>
      ) : (
        <></>
      )}
      <S_TableColData>{item.billLogProductId}</S_TableColData>
      <S_TableColData>-</S_TableColData>
      <S_TableColData style={{ minWidth: '279px' }}>
        <span style={{ fontWeight: '700' }}>{item.brandName}</span>
        {' ' + item.productName}
      </S_TableColData>
      <S_TableColData>{item.productOptionName}</S_TableColData>
      <S_TableColData>{item.productCount}</S_TableColData>
      <S_TableColData>
        {numberFormat(item.productPaymentPrice)}원
      </S_TableColData>
      <S_TableColData>{numberFormat(item.productPrice)}원</S_TableColData>
      <S_TableColData>
        {item.productPointDiscount
          ? `-${numberFormat(item.productPointDiscount)}`
          : '-'}
      </S_TableColData>
      <S_TableColData>
        {item.productCouponDiscount
          ? `-${numberFormat(item.productCouponDiscount)}`
          : '-'}
      </S_TableColData>
      <S_TableColData>{item.productId}</S_TableColData>
    </S_TableRow>
  );
}

export default StoreOrderRefundTableItem;

const S_Checkbox = styled.div`
  width: 16.67px;
  height: 16.67px;
  color: #aeaeae;
  margin: 0 auto;
  cursor: pointer;
`;

const S_TableColData = styled.td`
  text-align: center;
  vertical-align: middle;
  border: 1px solid ${(props) => props.theme.colors.hd3};
  padding: 10px;
`;

const S_TableRow = styled.tr`
  height: 36px;
  font-weight: 500;
  font-size: 13px;
`;
