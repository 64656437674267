import { getCookie, setCookie } from 'utils/cookie';

interface I_callApi {
  url: string;
  isOpenUrl?: boolean;
  method: 'GET' | 'POST' | 'PATCH' | 'DELETE';
  data?: any;
  isFormData?: boolean;
}

export async function callApi({
  url,
  isOpenUrl,
  method,
  data,
  isFormData,
}: I_callApi) {
  const URL = isOpenUrl ? url : `${process.env.REACT_APP_API_SERVER}${url}`;
  const TOKEN = localStorage.getItem('hm_at');

  let response;
  console.log('JSON.stringify(data) : ', JSON.stringify(data))
  response = await fetch(URL, {
    method: method,
    headers: TOKEN
      ? isFormData
        ? { Authorization: `Bearer ${TOKEN}` }
        : {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${TOKEN}`,
          }
      : {
          'Content-Type': 'application/json',
        },
    body: isFormData ? data : JSON.stringify(data),
  }).then((res) =>
    res.json().catch(() => {
      return;
    })
  );

  if (isOpenUrl)
    if (response) return { ok: true, res: response };
    else
      return {
        ok: false,
        res: {
          status: 500,
          error: {
            code: 500,
            message: 'open api server error',
          },
        },
      };

  // access token 만료
  if (response.statusCode === 313) {
    if (getCookie('hm_rt')) {
      // refresh token O
      response = await refreshApi(URL, method, data, isFormData);
    } else {
      // refresh token X
      return {
        ok: false,
        res: {
          status: 315,
          error: {
            code: 104,
            message: 'refreshToken must be a string',
          },
        },
      };
    }
  }

  if (response?.success) {
    return { ok: true, res: response.data };
  } else {
    return {
      ok: false,
      res: { status: response.statusCode, error: response.error },
    };
  }
}

// access token 재발급
const refreshApi = async (
  URL: string,
  method: 'GET' | 'POST' | 'PATCH' | 'DELETE',
  data: any,
  isFormData?: boolean
) => {
  const body = {
    refreshToken: getCookie('hm_rt'),
  };

  const response = await fetch(
    `${process.env.REACT_APP_API_SERVER}/auth/refresh`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    }
  ).then((res) =>
    res.json().catch(() => {
      return;
    })
  );

  // refresh token 만료
  if (response.statusCode === 315) {
    return response;
  }

  if (response?.success) {
    localStorage.setItem('hm_at', response.data.accessToken);
    setCookie('hm_rt', response.data.refreshToken);
    return await fetch(URL, {
      method: method,
      headers: response.data.accessToken
        ? isFormData
          ? { Authorization: `Bearer ${response.data.accessToken}` }
          : {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${response.data.accessToken}`,
            }
        : {
            'Content-Type': 'application/json',
          },
      body: isFormData ? data : JSON.stringify(data),
    });
  } else {
    return response;
  }
};
