import styled from 'styled-components';

export const S_FlexAlignCenter = styled.div`
  display: flex;
  align-items: center;
`;

export const S_FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
`;
