import { useState } from 'react';
import { I_TravelProductOption } from 'etc/types';
import styled from 'styled-components';
import MiniButton from 'components/Button/MiniButton';
import { travelproductActiveStatus, travelproductEnableStatus } from 'etc/json';
import { NavigateFunction } from 'react-router-dom';

interface OptionItemProps {
  option: I_TravelProductOption;
  index: number;
  isOptions: I_TravelProductOption[];
  setOptions: React.Dispatch<React.SetStateAction<I_TravelProductOption[]>>;
  handleUpdateOption: (updatedOption: I_TravelProductOption) => Promise<void>;
  navigate: NavigateFunction;
  id: string | undefined;
}

const OptionItem: React.FC<OptionItemProps> = ({
  option,
  index,
  isOptions,
  setOptions,
  handleUpdateOption,
  navigate,
  id,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedOption, setEditedOption] = useState<I_TravelProductOption>(option); // 수정 모드용 상태

  // 값 변경 핸들러
  const handleInputChange = (field: keyof I_TravelProductOption, value: string | number) => {
    setEditedOption((prevOption) => ({
      ...prevOption,
      [field]: value,
    }));
  };

  // 수정 모드 저장 핸들러
  const handleSave = async () => {
    setIsEditing(false);
    await handleUpdateOption(editedOption); // 부모 컴포넌트로 수정된 데이터 전달
    const updatedOptions = [...isOptions];
    updatedOptions[index] = editedOption; // 상태 업데이트
    // console.log('isOptions : ', ...isOptions)
    console.log('editedOption : ', editedOption)
    // setOptions(updatedOptions); // 부모 컴포넌트 상태 업데이트
  };

  // 수정 모드 취소 핸들러
  const handleCancel = () => {
    setIsEditing(false);
    setEditedOption(option); // 수정한 내용 취소하고 원래 값 복원
  };

  return (
    <StyledOptionItem>
      {isEditing ? (
        // 수정 모드
        <>
          <OptionInfo>
            <S_BasicInfoWrapper>
              <S_Label>옵션 ID</S_Label>
              <div>{option.id}</div>
            </S_BasicInfoWrapper>

            <S_BasicInfoWrapper>
              <S_Label>옵션명</S_Label>
              <StyledInput
                type="text"
                value={editedOption.title}
                onChange={(e) => handleInputChange('title', e.target.value)}
                style={{ width: '300px' }}
              />
            </S_BasicInfoWrapper>

            <S_BasicInfoWrapper>
              <S_Label>설명</S_Label>
              <StyledInput
                type="text"
                value={editedOption.content}
                onChange={(e) => handleInputChange('content', e.target.value)}
                style={{ width: '300px' }}
              />
            </S_BasicInfoWrapper>

            <S_BasicInfoWrapper>
              <S_Label>최대 수량</S_Label>
              <StyledInput
                type="number"
                value={editedOption.maxCount}
                onChange={(e) => handleInputChange('maxCount', Number(e.target.value))}
                style={{ width: '150px' }}
              />
            </S_BasicInfoWrapper>

            <S_BasicInfoWrapper>
              <S_Label>상태</S_Label>
              <StyledSelect
                value={editedOption.travelProductOptionStatus}
                onChange={(e) => handleInputChange('travelProductOptionStatus', e.target.value)}
                style={{ width: '150px' }}
              >
                <option value="">선택해주세요</option>
                {travelproductActiveStatus.map((status) => (
                  <option key={status.key} value={status.value}>
                    {status.name}
                  </option>
                ))}
              </StyledSelect>
            </S_BasicInfoWrapper>

            <S_BasicInfoWrapper>
              <S_Label>노출 상태</S_Label>
              <StyledSelect
                value={editedOption.travelProductOptionEnableStatus}
                onChange={(e) => handleInputChange('travelProductOptionEnableStatus', e.target.value)}
                style={{ width: '150px' }}
              >
                <option value="">선택해주세요</option>
                {travelproductEnableStatus.map((status) => (
                  <option key={status.key} value={status.value}>
                    {status.name}
                  </option>
                ))}
              </StyledSelect>
            </S_BasicInfoWrapper>

            {option.imageUrls && option.imageUrls.length > 0 ? (
              <ImageGallery>
                {option.imageUrls.map((url, idx) => (
                  <OptionImage key={idx} src={url} alt={`옵션 이미지 ${idx + 1}`} onClick={() => window.open(url)} />
                ))}
              </ImageGallery>
            ) : (
              <p>이미지가 없습니다.</p>
            )}
          </OptionInfo>

          <MiniButton onClick={handleSave}>저장</MiniButton>
          <MiniButton onClick={handleCancel}>취소</MiniButton>
        </>
      ) : (
        // 보기 모드
        <>
          <OptionInfo>
            <S_BasicInfoWrapper>
              <S_Label>옵션 ID</S_Label>
              <div>{option.id}</div>
            </S_BasicInfoWrapper>

            <S_BasicInfoWrapper>
              <S_Label>옵션명</S_Label>
              <div>{option.title}</div>
            </S_BasicInfoWrapper>

            <S_BasicInfoWrapper>
              <S_Label>설명</S_Label>
              <div>{option.content}</div>
            </S_BasicInfoWrapper>

            <S_BasicInfoWrapper>
              <S_Label>최대 수량</S_Label>
              <div>{option.maxCount}</div>
            </S_BasicInfoWrapper>

            <S_BasicInfoWrapper>
              <S_Label>상태</S_Label>
              <div>
                { 
                  (option.travelProductOptionStatus === 'ACTIVE' && '사용' )
                  || (option.travelProductOptionStatus === 'DEACITVE' && '사용안함' )
                  || ('선택안됨')
                }
              </div>
            </S_BasicInfoWrapper>

            <S_BasicInfoWrapper>
              <S_Label>노출 상태</S_Label>
              <div>
                {
                  (option.travelProductOptionEnableStatus === 'ENABLE' && '활성화' )
                  || (option.travelProductOptionEnableStatus === 'DISABLE' && '비활성화' )
                  || ('선택안됨')
                }
              </div>
            </S_BasicInfoWrapper>

            {option.imageUrls && option.imageUrls.length > 0 ? (
              <ImageGallery>
                {option.imageUrls.map((url, idx) => (
                  <OptionImage key={idx} src={url} alt={`옵션 이미지 ${idx + 1}`} onClick={() => window.open(url)} />
                ))}
              </ImageGallery>
            ) : (
              <p>이미지가 없습니다.</p>
            )}
          </OptionInfo>

          <MiniButton onClick={() => setIsEditing(true)}>수정</MiniButton>
          <MiniButton
            onClick={() => navigate(`/travelProduct/${id}/options/${option.id}`)}
            style={{ backgroundColor: '#351dbf' }}
          >
            가격정보수정
          </MiniButton>
        </>
      )}
    </StyledOptionItem>
  );
};

export default OptionItem;

// 스타일드 컴포넌트
const StyledOptionItem = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #ccc;
  padding: 10px;
  margin-bottom: 10px;
`;

const OptionInfo = styled.div`
  flex: 1;
`;

const S_BasicInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const S_Label = styled.div`
  width: 100px;
  flex-shrink: 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
`;

const StyledInput = styled.input`
  width: 100%;
  padding: 10px 16px;
  border-radius: 12px;
  font-size: 14px;
  color: #000;
  border: 1px solid #ccc;
  background: #fff;
  &:focus {
    outline: none;
    border-color: #351dbf;
  }
`;

const StyledSelect = styled.select`
  width: 100%;
  padding: 10px 16px;
  border-radius: 12px;
  font-size: 14px;
  color: #000;
  border: 1px solid #ccc;
  background: #fff;
  &:focus {
    outline: none;
    border-color: #351dbf;
  }
`;

const ImageGallery = styled.div`
  display: flex;
  gap: 8px;
  margin-top: 10px;
`;

const OptionImage = styled.img`
  width: 100px;
  height: 100px;
  object-fit: cover;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
`;