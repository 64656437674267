import { I_CareUser } from 'etc/types';
import { useNavigate } from 'react-router-dom';

import styled from 'styled-components';

interface I_CareUserManagementTableItem {
  item: I_CareUser;
}

function CareUserTableItem({ item }: I_CareUserManagementTableItem) {
  const navigate = useNavigate();

  return (
    <S_TableRow>
      <S_TableColData
        style={{ textDecoration: 'underLine', cursor: 'pointer' }}
        onClick={() => navigate(`/user/care/${item.user.id}`)}
      >
        {item.user.userCode}
      </S_TableColData>
      <S_TableColData>{item.nickname}</S_TableColData>
      <S_TableColData>{item.address + ' ' + item.addressDetail}</S_TableColData>
      <S_TableColData>{item.activitieCount}</S_TableColData>
      <S_TableColData>{item.reportCount}</S_TableColData>
      <S_TableColData>{item.rating.toFixed(1)}</S_TableColData>
      <S_TableColData>{item.reviewCount}</S_TableColData>
      <S_TableColData>
        {item.caringStatus === 'Active' ? (
          <span style={{ color: '#13A872' }}>활동 중</span>
        ) : (
          <span style={{ color: '#FC1D1D' }}>비활성화</span>
        )}
      </S_TableColData>
    </S_TableRow>
  );
}

export default CareUserTableItem;

const S_TableColData = styled.td`
  text-align: center;
  vertical-align: middle;
  border: 1px solid ${(props) => props.theme.colors.hd3};
  padding: 10px;
`;

const S_TableRow = styled.tr`
  height: 36px;
  font-weight: 500;
  font-size: 13px;
`;
