import { useNavigate } from 'react-router-dom';

import styled from 'styled-components';

import { I_StoreInquiry } from 'etc/types';
import { dateTimeFormat } from 'utils/common';

interface I_StoreInquiryTableItem {
  item: I_StoreInquiry;
}

function StoreInquiryTableItem({ item }: I_StoreInquiryTableItem) {
  const navigate = useNavigate();

  return (
    <S_TableRow>
      <S_TableColData>
        {item?.answer ? (
          <span style={{ color: '#3CBF0E' }}>답변완료</span>
        ) : (
          <span style={{ color: '#FF0000' }}>미답변</span>
        )}
      </S_TableColData>
      <S_TableColData
        style={{ textDecoration: 'underLine', cursor: 'pointer' }}
        onClick={() => navigate(`/store/inquiry/${item.id}`)}
      >
        {item.category}
      </S_TableColData>
      <S_TableColData>{dateTimeFormat(item.createdAt)}</S_TableColData>
      <S_TableColData style={{ minWidth: '248px' }}>
        {item.productName}
      </S_TableColData>
      <S_TableColData>
        {item.billLogProductId ? item.billLogProductId : item.productId}
      </S_TableColData>
      <S_TableColData>{item.userNickname}</S_TableColData>
      <S_TableColData>{item.userPhone}</S_TableColData>
    </S_TableRow>
  );
}

export default StoreInquiryTableItem;

const S_TableColData = styled.td`
  text-align: center;
  vertical-align: middle;
  border: 1px solid ${(props) => props.theme.colors.hd3};
  padding: 10px;
`;

const S_TableRow = styled.tr`
  height: 36px;
  font-weight: 500;
  font-size: 13px;
`;
