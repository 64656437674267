import { useEffect, useState } from 'react';

import styled from 'styled-components';

import { ReactComponent as Checkbox } from 'assets/image/checkbox.svg';

import MainLayout from 'components/Layout/MainLayout';
import TableButton from 'components/Button/TableButton';
import PagenationButton from 'components/Button/PagenationButton';
import TabButton from 'components/Button/TabButton';
import OrderRequest from 'components/Item/StoreDeliveryTable/OrderRequest';
import OrderBeforeDeposit from 'components/Item/StoreDeliveryTable/OrderBeforeDeposit';
import PreparingForDelivery from 'components/Item/StoreDeliveryTable/PreparingForDelivery';
import Shipping from 'components/Item/StoreDeliveryTable/Shipping';
import DeliveryCompleted from 'components/Item/StoreDeliveryTable/DeliveryCompleted';
import ModificationOfInfo from 'components/Item/StoreDeliveryTable/ModificationOfInfo';
import DeliveryDelay from 'components/Item/StoreDeliveryTable/DeliveryDelay';
import CancelDeliveryModal from 'components/Modal/CancelDeliveryModal';
import { I_Delivery } from 'etc/types';
import useLogout from 'hooks/useLogout';
import CancelUnpaidModal from 'components/Modal/CancelUnpaidModal';

import {
  getDeliveryList,
  patchDeliveryStatus,
  postDeliveryTracking,
} from 'api/order';

function StoreDelivery() {
  const Logout = useLogout();
  const [deliveryList, setDeliveryList] = useState<I_Delivery[]>([]); // 상품
  const [totalCount, setTotalCount] = useState<number>(0); // 전체 개수
  const [activePage, setActivePage] = useState<number>(1); // 현재 페이지
  const [isRefundModalOpen, setIsRefundModalOpen] = useState<boolean>(false); // 환불 모달
  const [isCancelModalOpen, setIsCancelModalOpen] = useState<boolean>(false); // 주문 취소 모달
  const [tabIndex, setTabIndex] = useState<number>(TAB[0].id); // tab
  const [tableColumnList, setTableColumnList] = useState<string[]>(
    TABLE_HEADER[0].list
  );
  const [checkItem, setCheckItem] = useState<number>(0); // 체크 항목
  const [checkBillLogId, setCheckBillLogId] = useState<number>(0);
  const [courier, setCourier] = useState<string>('선택 필요'); // 택배사
  const [trackingInput, setTrackingInput] = useState<string>(''); // 운송장 번호
  const [changeToggle, setChangeToggle] = useState<boolean>(false);

  // tab button 클릭 시
  const onTabClick = (id: number, idx: number) => {
    setTabIndex(id);
    setTableColumnList(TABLE_HEADER[idx].list);
  };

  // 배송 table pagenation
  const handlePageChange = (page: number) => {
    setActivePage(page);
  };

  // 배송 상태 변경
  const patchAsyncDeliveryStatus = async (status: number) => {
    if (checkItem === 0) {
      alert('처리할 주문을 선택하세요.');
      return;
    }

    const data = await patchDeliveryStatus(checkItem, status);
    if (data.ok) {
      if (status === 4) {
        alert(
          '주문 확인 처리가 완료되었습니다. 해당 주문은 배송 준비중으로 이동합니다.'
        );
      } else if (status === 5) {
        alert(
          '배송 지연 처리가 완료되었습니다. 해당 주문은 배송 지연으로 이동합니다.'
        );
      } else if (status === 7) {
        alert(
          '배송 완료 처리가 완료되었습니다. 해당 주문은 배송 완료로 이동합니다.'
        );
      } else {
        alert('처리가 완료되었습니다.');
      }
      getAsyncDeliveryList();
    } else {
      if (data.res.status === 315) Logout();
      else {
        alert('잠시 후 다시 시도해주세요.');
        console.error(data.res.error.message);
      }
    }
  };

  // 배송 중 처리
  const postAsyncDeliveryTracking = async () => {
    if (checkItem === 0) {
      alert('처리할 주문을 선택하세요.');
      return;
    }
    if (courier === '선택 필요') {
      alert('택배사를 선택하세요.');
      return;
    }
    if (trackingInput === '') {
      alert('운송장 번호를 작성하세요.');
      return;
    }
    if (!/^\d*$/.test(trackingInput)) {
      alert('운송장 번호는 숫자만 입력해주세요.');
      return;
    }

    const data = await postDeliveryTracking(checkItem, courier, trackingInput);
    if (data.ok) {
      alert('배송중 처리가 완료되었습니다. 해당 주문은 배송중으로 이동합니다.');
      setChangeToggle((pre) => !pre);
      getAsyncDeliveryList();
    } else {
      if (data.res.status === 315) Logout();
      else {
        alert('잠시 후 다시 시도해주세요.');
        console.error(data.res.error.message);
      }
    }
  };

  // 배송 목록
  const getAsyncDeliveryList = async () => {
    const data = await getDeliveryList(activePage, 10, tabIndex, false);
    if (data.ok) {
      setTotalCount(data.res.totalCount);
      setDeliveryList(data.res.item);
      setCheckItem(0);
      setCheckBillLogId(0);
      setCourier('선택 필요');
      setTrackingInput('');
    } else {
      if (data.res.status === 315) Logout();
      else if (data.res.status === 400)
        alert('번호나 코드는 숫자만 입력해주세요.');
      else console.error(data.res.error.message);
    }
  };

  useEffect(() => {
    getAsyncDeliveryList();
  }, [activePage, tabIndex]);

  return (
    <>
      <MainLayout>
        {/* 카테고리 */}
        <S_CategoryContainer>
          <S_CategoryTitle>주문 별 현황</S_CategoryTitle>
          <S_CategoryFilterContainer>
            {TAB.map((v, i) => (
              <TabButton
                key={i}
                title={v.title}
                isActive={tabIndex === v.id}
                onClick={() => onTabClick(v.id, i)}
              />
            ))}
          </S_CategoryFilterContainer>
        </S_CategoryContainer>

        {/* 상품 목록 */}
        <S_TableButtonContainer>
          <S_TableTitle>상품 목록({totalCount})</S_TableTitle>
          <S_TableButtonWrapper>
            {tabIndex === 5 && (
              <TableButton onClick={() => patchAsyncDeliveryStatus(4)}>
                배송 준비중 전환
              </TableButton>
            )}
            {tabIndex === 4 && (
              <TableButton onClick={postAsyncDeliveryTracking}>
                배송 중 처리
              </TableButton>
            )}
            {tabIndex === 6 && (
              <TableButton onClick={() => patchAsyncDeliveryStatus(7)}>
                배송 완료 처리
              </TableButton>
            )}
            {tabIndex === 4 && (
              <TableButton onClick={() => patchAsyncDeliveryStatus(5)}>
                배송 지연 처리
              </TableButton>
            )}
            {(tabIndex === 3 || tabIndex === 1) && (
              <TableButton onClick={() => patchAsyncDeliveryStatus(4)}>
                주문 확인 처리
              </TableButton>
            )}
            {(tabIndex === 3 || tabIndex === 4 || tabIndex === 5) && (
              <TableButton
                onClick={() =>
                  checkBillLogId !== 0 && setIsRefundModalOpen(true)
                }
              >
                주문 취소 처리
              </TableButton>
            )}
            {tabIndex === 1 && (
              <TableButton
                onClick={() => checkItem !== 0 && setIsCancelModalOpen(true)}
              >
                주문 취소 처리
              </TableButton>
            )}
          </S_TableButtonWrapper>
        </S_TableButtonContainer>

        <S_TableContainer>
          <S_TableBox isVisible={tabIndex === 4 ? true : false}>
            <S_Table>
              <thead>
                <tr>
                  {tableColumnList.map((v, i) => (
                    <S_TableHeaderItem key={i}>
                      {v === 'check' ? (
                        <Checkbox
                          width='16.67px'
                          height='16.67px'
                          color='#2E2E2E'
                        />
                      ) : (
                        v
                      )}
                    </S_TableHeaderItem>
                  ))}
                </tr>
              </thead>
              <tbody>
                {deliveryList?.map((v: I_Delivery, i: number) =>
                  tabIndex === 3 ? (
                    <OrderRequest
                      key={i}
                      item={v}
                      checkItem={checkItem}
                      setCheckItem={setCheckItem}
                      setCheckBillLogId={setCheckBillLogId}
                    />
                  ) : tabIndex === 1 ? (
                    <OrderBeforeDeposit
                      key={i}
                      item={v}
                      checkItem={checkItem}
                      setCheckItem={setCheckItem}
                      setCheckBillLogId={setCheckBillLogId}
                    />
                  ) : tabIndex === 4 ? (
                    <PreparingForDelivery
                      key={i}
                      item={v}
                      checkItem={checkItem}
                      setCheckItem={setCheckItem}
                      setCheckBillLogId={setCheckBillLogId}
                      setCourier={setCourier}
                      setTrackingInput={setTrackingInput}
                      changeToggle={changeToggle}
                    />
                  ) : tabIndex === 6 ? (
                    <Shipping
                      key={i}
                      item={v}
                      checkItem={checkItem}
                      setCheckItem={setCheckItem}
                    />
                  ) : tabIndex === 7 ? (
                    <DeliveryCompleted
                      key={i}
                      item={v}
                      checkItem={checkItem}
                      setCheckItem={setCheckItem}
                    />
                  ) : tabIndex === 0 ? (
                    <ModificationOfInfo
                      key={i}
                      item={v}
                      checkItem={checkItem}
                      setCheckItem={setCheckItem}
                    />
                  ) : tabIndex === 5 ? (
                    <DeliveryDelay
                      key={i}
                      item={v}
                      checkItem={checkItem}
                      setCheckItem={setCheckItem}
                      setCheckBillLogId={setCheckBillLogId}
                    />
                  ) : (
                    <></>
                  )
                )}
              </tbody>
            </S_Table>
          </S_TableBox>

          <PagenationButton
            activePage={activePage}
            totalCount={totalCount}
            postPerPage={10}
            handlePageChange={handlePageChange}
          />
        </S_TableContainer>
        <CancelDeliveryModal
          isOpen={isRefundModalOpen}
          onClose={() => setIsRefundModalOpen(false)}
          checkBillLogId={checkBillLogId}
          getAsyncDeliveryList={getAsyncDeliveryList}
        />
        {/* <RefundHistoryModal
          isOpen={isRefundHistoryModalOpen}
          onClose={() => setIsRefundHistoryMod
            3+0+alOpen(false)}
        /> */}
        <CancelUnpaidModal
          isOpen={isCancelModalOpen}
          onClose={() => setIsCancelModalOpen(false)}
          billLogId={checkBillLogId}
          getAsyncDeliveryList={getAsyncDeliveryList}
        />
      </MainLayout>
    </>
  );
}

export default StoreDelivery;

const S_TableHeaderItem = styled.th`
  vertical-align: middle;
  padding: 10px;
  height: 36px;
  background-color: ${(props) => props.theme.colors.hd4};
  border: 0.5px solid ${(props) => props.theme.colors.hd3};
  font-weight: 500;
  font-size: 13px;
  letter-spacing: -0.02em;
  color: ${(props) => props.theme.colors.n10};
`;

const S_Table = styled.table`
  width: 100%;
`;

const S_TableBox = styled.div<{ isVisible: boolean }>`
  overflow-x: ${(props) => (props.isVisible ? 'visible' : 'overlay')};
  &::-webkit-scrollbar {
    width: 4px;
    height: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.theme.colors.hd3};
    border-radius: 6px;
  }
`;

const S_TableContainer = styled.div`
  margin-top: 8px;
`;

const S_TableButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const S_TableTitle = styled.span`
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.02em;
  display: flex;
  align-items: flex-end;
`;

const S_TableButtonContainer = styled.div`
  margin-top: 36px;
  display: flex;
  justify-content: space-between;
`;

const S_CategoryFilterContainer = styled.div`
  min-width: 915px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

const S_CategoryTitle = styled.span`
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.02em;
`;

const S_CategoryContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const TAB = [
  {
    id: 3,
    title: '주문 요청',
  },
  {
    id: 1,
    title: '입금 전 주문',
  },
  {
    id: 4,
    title: '배송 준비 중',
  },
  {
    id: 6,
    title: '배송 중',
  },
  {
    id: 7,
    title: '배송 완료',
  },
  {
    id: 0,
    title: '배송 전 정보 수정',
  },
  {
    id: 5,
    title: '배송 지연',
  },
];

const TABLE_HEADER = [
  {
    id: 3,
    list: [
      'check',
      '상품 주문 번호',
      '주문 번호',
      '주문 일시',
      '상품 정보',
      '구매자명',
      '구매자 연락처',
      'User Code',
    ],
  },
  {
    id: 1,
    list: [
      'check',
      '상품 주문 번호',
      '주문 번호',
      '구매자명',
      '주문 일시',
      '상품 정보',
      '구매자 연락처',
      'User Code',
    ],
  },
  {
    id: 4,
    list: [
      'check',
      '상품 주문 번호',
      '주문 번호',
      '상태 처리 일시',
      '택배사',
      '운송장 번호',
      '상품 정보',
      '주문 일시',
      '구매자명',
      '구매자 연락처',
      'User Code',
    ],
  },
  {
    id: 6,
    list: [
      'check',
      '상품 주문 번호',
      '주문 번호',
      '상태 처리 일시',
      '택배사',
      '운송장 번호',
      '상품 정보',
      '주문 일시',
      '구매자명',
      '구매자 연락처',
      'User Code',
    ],
  },
  {
    id: 7,
    list: [
      'check',
      '상품 주문 번호',
      '주문 번호',
      '상태 처리 일시',
      '택배사',
      '운송장 번호',
      '상품 정보',
      '주문 일시',
      '구매자명',
      '구매자 연락처',
      'User Code',
    ],
  },
  {
    id: 0,
    list: [
      'check',
      '상품 주문 번호',
      '주문 번호',
      '주문 일시',
      '상품 정보',
      '구매자명',
      '구매자 연락처',
      'User Code',
    ],
  },
  {
    id: 5,
    list: [
      'check',
      '상품 주문 번호',
      '주문 번호',
      '상태 처리 일시',
      '택배사',
      '운송장 번호',
      '상품 정보',
      '주문 일시',
      '구매자명',
      '구매자 연락처',
      'User Code',
    ],
  },
];
