import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import styled from 'styled-components';

import { I_Return } from 'etc/types';
import { dateTimeFormat } from 'utils/common';
import RefundReasonModal from 'components/Modal/RefundReasonModal';
import ReturnRequestHistoryModal from 'components/Modal/ReturnRequestHistoryModal';

interface I_ReturnRequest {
  item: I_Return;
  status: number;
  getAsyncReturnList: () => void;
}

function ReturnRequest({ item, status, getAsyncReturnList }: I_ReturnRequest) {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isReturnModalOpen, setIsReturnModalOpen] = useState<boolean>(false);

  const productName = () => {
    const temp = item.brandCommaProduct.split(',');
    const count = Number(item.refundProductCount) - 1;
    return (
      <>
        <span style={{ fontWeight: '700' }}>{temp[0]} </span>
        <span>{temp[1]}</span>
        {count ? <span style={{ fontWeight: '700' }}> 외 {count}</span> : <></>}
      </>
    );
  };

  return (
    <S_TableRow>
      <S_TableColData>
        <span
          onClick={() => setIsReturnModalOpen(true)}
          style={{ textDecoration: 'underLine', cursor: 'pointer' }}
        >
          {item.refundLogId}
        </span>
      </S_TableColData>
      <S_TableColData>{dateTimeFormat(item.createdAt)}</S_TableColData>
      <S_TableColData>
        <span
          onClick={() => setIsModalOpen(true)}
          style={{ textDecoration: 'underLine', cursor: 'pointer' }}
        >
          {item.returnRequestReasonShippingCostYn
            ? '구매자 귀책 사유'
            : '판매자 귀책 사유'}
        </span>
      </S_TableColData>
      <S_TableColData>
        {item.paymentMethod === 'card'
          ? '신용/체크카드'
          : item.paymentMethod === 'virtualAccount'
          ? '무통장 입금'
          : '-'}
      </S_TableColData>
      <S_TableColData style={{ minWidth: '248px' }}>
        {productName()}
      </S_TableColData>
      <S_TableColData>{dateTimeFormat(item.orderCreatedAt)}</S_TableColData>
      <S_TableColData>{item.userNickname}</S_TableColData>
      <S_TableColData>{item.userPhone}</S_TableColData>
      <S_TableColData>
        <span
          onClick={() => navigate(`/user/${item.userId}`)}
          style={{ textDecoration: 'underLine', cursor: 'pointer' }}
        >
          {item.userCode}
        </span>
      </S_TableColData>

      {/* modal */}
      <td>
        {isReturnModalOpen && (
          <ReturnRequestHistoryModal
            isOpen={isReturnModalOpen}
            onClose={() => {
              setIsReturnModalOpen(false);
              document.body.style.overflow = 'unset'; // body scroll 생성}
            }}
            refundLogId={item.refundLogId}
            onSuccess={() => {
              getAsyncReturnList();
              setIsReturnModalOpen(false);
              document.body.style.overflow = 'unset'; // body scroll 생성
            }}
            status={status}
          />
        )}
      </td>
      <td>
        {isModalOpen && (
          <RefundReasonModal
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            refundLogId={item.refundLogId}
          />
        )}
      </td>
    </S_TableRow>
  );
}

export default ReturnRequest;

const S_TableColData = styled.td`
  text-align: center;
  vertical-align: middle;
  border: 1px solid ${(props) => props.theme.colors.hd3};
  padding: 10px;
`;

const S_TableRow = styled.tr`
  height: 36px;
  font-weight: 500;
  font-size: 13px;
`;
