import styled from 'styled-components';

interface I_ModalLayout {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
}

function ModalLayout({ isOpen, onClose, children }: I_ModalLayout) {
  return (
    <S_Container isOpen={isOpen}>
      {children}
      <S_Backdrop
        onClick={() => {
          document.body.style.overflow = 'unset'; // body scroll 생성
          onClose();
        }}
      />
    </S_Container>
  );
}

export default ModalLayout;

const S_Backdrop = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.colors.hd1};
  opacity: 0.3;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1;
`;

const S_Container = styled.div<{ isOpen: boolean }>`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  visibility: ${(props) => (props.isOpen ? 'visible' : 'hidden')};
`;
