import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import styled from 'styled-components';

import { I_Exchange } from 'etc/types';
import { dateTimeFormat } from 'utils/common';
import ReasonModal from 'components/Modal/ReasonModal';

interface I_ExchangeRefusal {
  item: I_Exchange;
}

function ExchangeRefusal({ item }: I_ExchangeRefusal) {
  const navigate = useNavigate();
  const [isReasonModalOpen, setIsReasonModalOpen] = useState<boolean>(false); // 요청 내역 모달

  return (
    <S_TableRow>
      <S_TableColData>{item.refundLogId}</S_TableColData>
      <S_TableColData>
        {dateTimeFormat(item.orderStatusProcessedDate)}
      </S_TableColData>
      <S_TableColData>
        <span
          onClick={() => setIsReasonModalOpen(true)}
          style={{ textDecoration: 'underLine', cursor: 'pointer' }}
        >
          작성 내용 확인
        </span>
      </S_TableColData>
      <S_TableColData style={{ minWidth: '320px' }}>
        {item.productName}
      </S_TableColData>
      <S_TableColData>{item.productOptionName}</S_TableColData>
      <S_TableColData>{item.productCount}</S_TableColData>
      <S_TableColData>{dateTimeFormat(item.createdAt)}</S_TableColData>
      <S_TableColData>{item.userNickname}</S_TableColData>
      <S_TableColData>{item.userPhone}</S_TableColData>
      <S_TableColData>
        <span
          onClick={() => navigate(`/user/${item.userId}`)}
          style={{ textDecoration: 'underLine', cursor: 'pointer' }}
        >
          {item.userCode}
        </span>
      </S_TableColData>

      <td>
        {isReasonModalOpen && (
          <ReasonModal
            isOpen={isReasonModalOpen}
            onClose={() => setIsReasonModalOpen(false)}
            status={'교환 거부'}
            isDone={true}
            product={{
              refundLogProductId: item?.refundLogProductId,
              brandName: item?.brandName,
              productName: item?.productName,
              productOptionName: item?.productOptionName,
              productCount: item?.productCount,
              delayReason: item?.processReason,
            }}
          />
        )}
      </td>
    </S_TableRow>
  );
}

export default ExchangeRefusal;

const S_Checkbox = styled.div`
  width: 16.67px;
  height: 16.67px;
  color: #aeaeae;
  margin: 0 auto;
  cursor: pointer;
`;

const S_TableColData = styled.td`
  text-align: center;
  vertical-align: middle;
  border: 1px solid ${(props) => props.theme.colors.hd3};
  padding: 10px;
`;

const S_TableRow = styled.tr`
  height: 36px;
  font-weight: 500;
  font-size: 13px;
`;
