export const CATEGORY_MAIN = ['강아지', '고양이', '다른동물'];

export const CARE_TYPE = ['대리산책', '타임케어', '데이케어'];

export const CARE_METHOD = ['댕냥이집', '돌보미집'];

export const TABLE_HEADER_ORDER_PRODUCT = [
  '상품 주문 번호',
  '주문 상태',
  '상품 정보',
  '상세 옵션',
  '주문 수량',
  '결제 금액',
  '상품 코드',
];

export const TABLE_FILTER = ['전체', '작성 필요', '작성 완료'];

export const TABLE_LIMIT = 15;

export const REPORT_CATEGORY = [
  { category: 'alert', type: '알림' },
  { category: 'storeReview', type: '스토어 리뷰' },
  { category: 'coWalk', type: '동반산책 게시글' },
  { category: 'distribution', type: '분양 게시글' },
  { category: 'caring', type: '돌봄 후기' },
  { category: 'community', type: '커뮤니티 게시글' },
  { category: 'comment', type: '커뮤니티 댓글/대댓글' },
  { category: 'market', type: '중고거래 게시글' },
];
// 택배사
export const COURIER = [
  '한진택배',
  '우체국 택배',
  'CJ대한통운',
  '로젠택배',
  '롯데택배',
  '천일택배',
  'CU 편의점택배',
  'GS Postbox 택배',
  'CWAY (Woori Express)',
  '대신택배',
  '한의사랑택배',
  '합동택배',
  '홈픽',
  '한서호남택배',
  '일양로지스',
  '경동택배',
  '건영택배',
];

export const eventTypes = [
  { value: 'Main', label: '메인 팝업' },
  { value: 'Store', label: '스토어' },
  { value: 'Petstival', label: '펫스티벌' },
  { value: 'TravelProduct', label: '여행 상품' },
];

export const linkTypes = [
  { value: 'APP', label: '앱' },
  { value: 'WEB', label: '웹' },
];

export const eventStatuTypes = [
  { value: 'PROCEEDING', label: '진행중' },
  { value: 'PREPARING', label: '준비중' },
  { value: 'END', label: '종료' },
];

export const defaultRefundRule = `
* 고객의 변심에 의한 환불 (이용일 기준)\n
- 7영업일 전 취소 시 100% 환불가능\n
- 5영업일 전 취소 시 50% 환불가능\n
- 4영업일 전 ~ 당일취소 및 노쇼 환불불가\n
*천재지변에 의한 환불\n
- 해당 이용 지역에 풍랑, 폭풍, 태풍주의보 3종류의 주의보 발효 시 (기상청 발표기준) : 100%환불\n
- 이용 시작 이후에는 환불 조항 적용 불가\n
*우천/강풍에 의한 환불\n
- 요트는 쇼량의 비가 내릴 경우 정상적으로 이용가능\n
- 우천 및 강풍 예보 시 : 이용일 1일 전 기상 확인 후 개별 연락\n
- 개별 연락을 통한 예약 취소 : 100%환불 또는 예약일 변경 가능\n
- 이용일 당일 : 우천으로 인한 환불 또는 예약일 변경 불가\n
고객센터 운영시간 내 환불가능\n
운영시간 : 10:00~17:00\n
`;