
import { EEnableStatus, EActiveStatus, ERecommendStatus, EBooleanStatus } from 'etc/enum';

export const travelproductRecommend = [
  { id: 1, key: ERecommendStatus.RECOMMEND, name: '추천', value: EBooleanStatus.TRUE, },
  { id: 2, key: ERecommendStatus.NOT_RECOMMEND, name: '추천안함', value: EBooleanStatus.FALSE, },
];

export const travelproductActiveStatus = [
  { id: 1, key: EActiveStatus.ACTIVE, name: '사용', value: EActiveStatus.ACTIVE, },
  { id: 2, key: EActiveStatus.DEACTIVE, name: '사용안함', value: EActiveStatus.DEACTIVE, },
];

export const travelproductEnableStatus = [
  { id: 1, key: EEnableStatus.ENABLE, name: '활성화', value: EEnableStatus.ENABLE, },
  { id: 2, key: EEnableStatus.DISABLE, name: '비활성화', value: EEnableStatus.DISABLE, },
];
