import { useState } from 'react';

import styled from 'styled-components';

import MainLayout from 'components/Layout/MainLayout';
import NormalButton from 'components/Button/NormalButton';

import PolicyTableItem from 'components/Item/PolicyTableItem';

const TableHeader = ['업로드 날짜', '약관 정보'];

function Policy() {
  const [searchValue, setSearchValue] = useState<string>('');

  return (
    <MainLayout>
      <S_TableContainer>
        <S_TableBox>
          <S_Table>
            <thead>
              <tr>
                {TableHeader.map((v, i) => (
                  <S_TableHeaderItem key={i}>{v}</S_TableHeaderItem>
                ))}
              </tr>
            </thead>
            <tbody>
              {Array.from({ length: 3 }).map((v: any, i: number) => (
                <PolicyTableItem key={i} />
              ))}
            </tbody>
          </S_Table>
        </S_TableBox>
      </S_TableContainer>
    </MainLayout>
  );
}

export default Policy;

const S_TableButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  margin-top: 50px;
`;

const S_TableHeaderItem = styled.th`
  vertical-align: middle;
  padding: 10px;
  height: 36px;
  background-color: ${(props) => props.theme.colors.hd4};
  border: 0.5px solid ${(props) => props.theme.colors.hd3};
  font-weight: 500;
  font-size: 13px;
  letter-spacing: -0.02em;
  color: ${(props) => props.theme.colors.n10};
`;

const S_Table = styled.table`
  width: 100%;

  th:first-child {
    width: 270px;
  }
`;

const S_TableBox = styled.div`
  overflow-x: scroll;
  &::-webkit-scrollbar {
    width: 4px;
    height: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.theme.colors.hd3};
    border-radius: 6px;
  }
`;

const S_TableContainer = styled.div`
  margin-top: 8px;
  position: relative;
`;
