import { useEffect, useState } from 'react';

import styled from 'styled-components';
import { S_FlexColumn } from 'etc/GlobalCSS';

import { ReactComponent as Close } from 'assets/image/close.svg';

import ModalLayout from 'components/Layout/ModalLayout';
import DisabledTextarea from 'components/Input/DisabledTextarea';
import useLogout from 'hooks/useLogout';

import { getDetailReason } from 'api/order';

interface I_RefundReasonModal {
  isOpen: boolean;
  onClose: () => void;
  refundLogId: number;
}

interface I_Reason {
  detailReason: string;
  reasonImages: string[];
}

function RefundReasonModal({
  isOpen,
  onClose,
  refundLogId,
}: I_RefundReasonModal) {
  const Logout = useLogout();
  const [reason, setReason] = useState<I_Reason>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const getAsyncDetailReason = async () => {
    setIsLoading(true);
    const data = await getDetailReason(refundLogId);
    if (data.ok) {
      setReason(data.res.item);
      setIsLoading(false);
    } else {
      if (data.res.status === 315) Logout();
      else console.error(data.res.error.message);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden'; // body scroll 제거
      if (refundLogId) getAsyncDetailReason();
    }
  }, [isOpen]);

  return (
    <ModalLayout isOpen={isOpen} onClose={onClose}>
      <S_Container>
        <S_CloseButton
          onClick={() => {
            document.body.style.overflow = 'unset'; // body scroll 생성
            onClose();
          }}
        >
          <Close />
        </S_CloseButton>

        <S_Title>교환/반품 사유</S_Title>

        <S_InfoContainer>
          <S_ReasonContainer>
            <S_SubTitle>상세 사유</S_SubTitle>
            {isLoading ? (
              <S_None style={{ width: '380px' }} />
            ) : reason?.detailReason ? (
              <DisabledTextarea
                text={reason?.detailReason}
                style={{ width: '380px', height: '200px' }}
              />
            ) : (
              <S_None style={{ width: '380px' }}>
                작성된 상세 사유가 없습니다.
              </S_None>
            )}
          </S_ReasonContainer>
          <S_FlexColumn style={{ gap: '16px', marginTop: '20px' }}>
            <S_SubTitle>첨부 사진</S_SubTitle>
            <S_FlexColumn style={{ gap: '20px' }}>
              {isLoading ? (
                <></>
              ) : reason?.reasonImages.length ? (
                reason?.reasonImages.map((v, i) => (
                  <S_Image key={i} src={v} onClick={() => window.open(v)} />
                ))
              ) : (
                <S_None>첨부된 사진이 없습니다.</S_None>
              )}
            </S_FlexColumn>
          </S_FlexColumn>
        </S_InfoContainer>
      </S_Container>
    </ModalLayout>
  );
}

export default RefundReasonModal;

const S_None = styled.div`
  width: 100%;
  height: 200px;
  padding: 14px 16px;
  background: ${(props) => props.theme.colors.hd4};
  border: 1px solid ${(props) => props.theme.colors.hd3};
  border-radius: 12px;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.02em;
  color: ${(props) => props.theme.colors.hd1};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const S_Image = styled.img`
  width: 100%;
  height: 502px;
  border-radius: 16px;
  cursor: pointer;
`;

const S_SubTitle = styled.span`
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: -0.32px;
`;

const S_ReasonContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const S_InfoContainer = styled.div`
  padding: 30px 31px 24px;
`;

const S_Title = styled.p`
  font-size: 24px;
  font-weight: 400;
  text-align: center;
`;

const S_CloseButton = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
  :hover {
    opacity: 0.5;
  }
`;

const S_Container = styled.div`
  width: 640px;
  height: 742px;
  background: ${(props) => props.theme.colors.hd5};
  position: relative;
  padding: 48px 38px 32px;
  border-radius: 10px;
  z-index: 10;
  overflow: auto;
`;
