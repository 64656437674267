import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import MainLayout from 'components/Layout/MainLayout';
import SearchBarInput from 'components/Input/SearchBarInput';
import PagenationButton from 'components/Button/PagenationButton';
import NormalButton from 'components/Button/NormalButton';

import { TABLE_LIMIT } from 'etc/variable';

import NoticeTableItem from 'components/Item/NoticeTableItem';
import useLogout from 'hooks/useLogout';
import { getNoticeList, getNoticeSearch } from 'api/notice';
import { I_Notice } from 'etc/types';

function Notice() {
  const Logout = useLogout();
  const navigate = useNavigate();

  const [searchValue, setSearchValue] = useState<string>('');
  const [totalCount, setTotalCount] = useState<number>(0); // 전체 개수
  const [activePage, setActivePage] = useState<number>(1); // 현재 페이지
  const [noticeList, setNoticeList] = useState<I_Notice[]>([]);
  const [isSearch, setIsSearch] = useState<boolean>(false);

  const handlePageChange = (page: number) => {
    setActivePage(page);

    if (isSearch) {
      getAsyncSearch(page);
    } else {
      getAsyncNoticeList(page);
    }
  };

  // 검색 엔터
  const onKeyPressHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      setActivePage(1);
      getAsyncSearch(1);
    }
  };

  // 검색 확인 클릭
  const onClickSearch = () => {
    setActivePage(1);
    getAsyncSearch(1);
  };

  // 검색
  const getAsyncSearch = async (page: number) => {
    if (!searchValue.trim()) {
      getAsyncNoticeList(1);
      setIsSearch(false);
    } else {
      setIsSearch(true);
      const data = await getNoticeSearch(searchValue, TABLE_LIMIT, page);

      if (data.ok) {
        // console.log('검색 결과', data.res);

        if (data.res) {
          setNoticeList(data.res.item);
          setTotalCount(data.res.totalCount);
        } else setNoticeList([]);
      } else {
        if (data.res.status === 315) Logout();
        else {
          alert('잠시 후 다시 시도해주세요.');
          console.error(data.res.error.message);
        }
      }
    }
  };

  const getAsyncNoticeList = async (page: number) => {
    const data = await getNoticeList(TABLE_LIMIT, page);
    if (data.ok) {
      // console.log(data.res);
      setNoticeList(data.res.item);
      setTotalCount(data.res.totalCount);
    } else {
      if (data.res.status === 315) Logout();
      else {
        alert('잠시 후 다시 시도해주세요.');
        console.error(data.res.error.message);
      }
    }
  };

  useEffect(() => {
    getAsyncNoticeList(1);
  }, []);

  return (
    <MainLayout>
      <SearchBarInput
        state={searchValue}
        setState={setSearchValue}
        onKeyPress={onKeyPressHandler}
        onClick={() => onClickSearch()}
      />
      <S_TableButtonContainer>
        <NormalButton
          onClick={() => navigate(`/service/notice/register`)}
          style={{ width: 103 }}
        >
          등록하기
        </NormalButton>
      </S_TableButtonContainer>
      <S_TableContainer>
        <S_TableBox>
          <S_Table>
            <thead>
              <tr>
                {TABLE_HEADER.map((v, i) => (
                  <S_TableHeaderItem key={i}>{v}</S_TableHeaderItem>
                ))}
              </tr>
            </thead>
            <tbody>
              {noticeList.map((v: I_Notice, i: number) => (
                <NoticeTableItem key={i} item={v} />
              ))}
            </tbody>
          </S_Table>
        </S_TableBox>

        <PagenationButton
          activePage={activePage}
          totalCount={totalCount}
          postPerPage={TABLE_LIMIT}
          handlePageChange={handlePageChange}
        />
      </S_TableContainer>
    </MainLayout>
  );
}

export default Notice;

const S_TableButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  margin-top: 50px;
`;

const S_TableHeaderItem = styled.th`
  vertical-align: middle;
  padding: 10px;
  height: 36px;
  background-color: ${(props) => props.theme.colors.hd4};
  border: 0.5px solid ${(props) => props.theme.colors.hd3};
  font-weight: 500;
  font-size: 13px;
  letter-spacing: -0.02em;
  color: ${(props) => props.theme.colors.n10};

  &:nth-child(1) {
    width: 250px;
  }
`;

const S_Table = styled.table`
  width: 100%;
`;

const S_TableBox = styled.div`
  overflow-x: scroll;
  &::-webkit-scrollbar {
    width: 4px;
    height: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.theme.colors.hd3};
    border-radius: 6px;
  }
`;

const S_TableContainer = styled.div`
  margin-top: 8px;
  position: relative;
`;

const TABLE_HEADER = ['등록일', '제목'];
