import { useSetRecoilState } from 'recoil';

import { isLoginAtom } from 'etc/atoms';
import { deleteCookie } from 'utils/cookie';

function useLogout() {
  const setIsLogin = useSetRecoilState(isLoginAtom);

  const Logout = () => {
    localStorage.removeItem('hm_at');
    deleteCookie('hm_rt');
    setIsLogin(false);
  };

  return Logout;
}

export default useLogout;
