import { callApi } from './callApi';

import {
  I_CategoryInsert,
  I_CategoryUpdate,
  I_ProductRegistrationForm,
  I_ProductUpdateForm,
} from 'etc/types';

const BASE_URL = '/admin/store';

// 상품 카테고리 목록
export const getCategoryList = async () => {
  const res = await callApi({
    url: `${BASE_URL}/categories`,
    method: 'GET',
  });
  return res;
};

// 상품 카테고리 수정/추가
export const postCategoryUpdate = async (
  insert: I_CategoryInsert[],
  update: I_CategoryUpdate[]
) => {
  const res = await callApi({
    url: `${BASE_URL}/category`,
    method: 'POST',
    data: {
      insertArray: insert,
      updateArray: update,
    },
  });
  return res;
};

// 브랜드 목록
export const getBrandList = async (page: number, limit: number) => {
  const res = await callApi({
    url: `${BASE_URL}/brands?page=${page}&limit=${limit}`,
    method: 'GET',
  });
  return res;
};

// 브랜드 추가
export const postBrandAdd = async (name: string) => {
  const res = await callApi({
    url: `${BASE_URL}/brand`,
    method: 'POST',
    data: {
      name: name,
    },
  });
  return res;
};

// 브랜드 숨김
export const patchBrandHide = async (brandList: number[], isHide: boolean) => {
  const res = await callApi({
    url: `${BASE_URL}/brand`,
    method: 'PATCH',
    data: {
      deleteBrandIds: brandList,
      hide: isHide,
    },
  });
  return res;
};

// 브랜드 수정
export const patchBrandUpdate = async (name: string, id: number) => {
  const res = await callApi({
    url: `${BASE_URL}/brand/${id}`,
    method: 'PATCH',
    data: {
      name: name,
    },
  });
  return res;
};

// 상품 목록
export const getProductList = async (
  page: number,
  limit: number,
  mainCategory: number,
  middleCategory: number,
  subCategory: number,
  sort: string,
  search: string
) => {
  const res = await callApi({
    url: `${BASE_URL}/products?page=${page}&limit=${limit}${
      mainCategory !== 0 ? '&mainCategory=' + mainCategory : ''
    }${middleCategory !== 0 ? '&middleCategory=' + middleCategory : ''}${
      subCategory !== 0 ? '&subCategory=' + subCategory : ''
    }&sort=${sort}${search}`,
    method: 'GET',
  });
  return res;
};

// 상품 단건 호출
export const getProduct = async (id: number) => {
  const res = await callApi({
    url: `${BASE_URL}/product/${id}`,
    method: 'GET',
  });
  return res;
};

// 상품 추가
export const postProductAdd = async (data: I_ProductRegistrationForm) => {
  const res = await callApi({
    url: `${BASE_URL}/product`,
    method: 'POST',
    data: data,
  });
  return res;
};

// 상품 수정
export const patchProductUpdate = async (
  data: I_ProductUpdateForm,
  id: number
) => {
  const res = await callApi({
    url: `${BASE_URL}/product/${id}`,
    method: 'PATCH',
    data: data,
  });
  return res;
};

// 상품 여러개 카테고리 수정
export const patchProductCategoryUpdate = async (
  mainCategory: number,
  middleCategory: number,
  subCategory: number,
  productList: number[]
) => {
  const res = await callApi({
    url: `${BASE_URL}/products/category`,
    method: 'PATCH',
    data: {
      mainCategory: mainCategory,
      middleCategory: middleCategory,
      subCategory: subCategory,
      productIds: productList,
    },
  });
  return res;
};

// 상품 여러개 숨김처리
export const patchProduct = async (productIds: number[], isHidden: boolean) => {
  const res = await callApi({
    url: `${BASE_URL}/products/hide`,
    method: 'PATCH',
    data: {
      productIds: productIds,
      isHidden: isHidden,
    },
  });
  return res;
};

// 스토어 문의 내역
export const getInquiryList = async (
  page: number,
  limit: number,
  startDate: string,
  endDate: string,
  category: string,
  answer: string,
  _search: string
) => {
  const startDateQuery = startDate ? `&startDate=${startDate}` : '';
  const endDateQuery = endDate ? `&endDate=${endDate}` : '';
  const answerYn =
    answer === '전체'
      ? ''
      : answer === '답변완료'
      ? `&answerYn=1`
      : `&answerYn=0`;
  const search = _search ? `&search=${_search}` : '';

  const res = await callApi({
    url: `${BASE_URL}/inquiries?page=${page}&limit=${limit}${startDateQuery}${endDateQuery}&category=${category}${answerYn}${search}`,
    method: 'GET',
  });
  return res;
};

// 스토어 문의 단건 호출
export const getInquiry = async (id: number) => {
  const res = await callApi({
    url: `${BASE_URL}/inquiry/${id}`,
    method: 'GET',
  });
  return res;
};

// 스토어 문의 답변 작성
export const patchInquiry = async (id: number, answer: string) => {
  const res = await callApi({
    url: `${BASE_URL}/inquiry/${id}`,
    method: 'PATCH',
    data: {
      answer: answer,
    },
  });
  return res;
};

// 스토어 상품 후기 내역
export const getReviewList = async (
  page: number,
  limit: number,
  _startDate: string,
  _endDate: string,
  _rating: string,
  search: string
) => {
  const startDate = _startDate ? `&startDate=${_startDate}` : '';
  const endDate = _endDate ? `&endDate=${_endDate}` : '';
  const rating = _rating === '전체' ? '' : `&rating=${_rating.slice(0, 1)}`;

  const res = await callApi({
    url: `${BASE_URL}/reviews?page=${page}&limit=${limit}${startDate}${endDate}${rating}${search}`,
    method: 'GET',
  });
  return res;
};

// 스토어 상품 후기 단건 호출
export const getReview = async (id: number) => {
  const res = await callApi({
    url: `${BASE_URL}/review/${id}`,
    method: 'GET',
  });
  return res;
};

// 스토어 상품 후기 숨김처리
export const patchReview = async (id: number, hidden: boolean) => {
  const res = await callApi({
    url: `${BASE_URL}/review/${id}`,
    method: 'PATCH',
    data: {
      hidden: hidden,
    },
  });
  return res;
};

// 스토어 상품 후기 포인트 지불
export const postReviewPoint = async (id: number) => {
  const res = await callApi({
    url: `${BASE_URL}/review/reward/${id}`,
    method: 'POST',
  });
  return res;
};

// 스토어 환불정보, 배송금 설정 확인
export const getOrderInfo = async () => {
  const res = await callApi({
    url: `${BASE_URL}/orderInfo`,
    method: 'GET',
  });
  return res;
};

// 스토어 환불정보, 배송금 설정 수정
interface I_patchOrderInfo {
  companyAddress: string;
  receiver: string;
  courierCompany: string;
  shippingCost: number;
  freeShippingPrice: number;
}
export const patchOrderInfo = async (data: I_patchOrderInfo) => {
  const res = await callApi({
    url: `${BASE_URL}/orderInfo`,
    method: 'PATCH',
    data: data,
  });
  return res;
};
