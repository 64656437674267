import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';

import styled from 'styled-components';

import Logo from 'assets/image/logo-humanizone.png';
import Bg1 from 'assets/image/login1.png';
import Bg2 from 'assets/image/login2.png';
import Input from 'components/Input/Input';
import LongButton from 'components/Button/LongButton';
import NormalModal from 'components/Modal/NormalModal';
import { setCookie } from 'utils/cookie';
import { isLoginAtom } from 'etc/atoms';

import { postLogin } from 'api/login';

function Login() {
  const navigate = useNavigate();
  const setIsLogin = useSetRecoilState(isLoginAtom);
  const [userId, setUserId] = useState<string>('');
  const [userPassword, setUserPassword] = useState<string>('');
  const [isPasswordModalOpen, setIsPasswordModalOpen] =
    useState<boolean>(false);

  const onSubmit = () => {
    postAsyncLogin();
  };

  const onKeyPressHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      postAsyncLogin();
    }
  };

  const postAsyncLogin = async () => {
    const data = await postLogin({
      account: userId,
      password: userPassword,
    });

    if (data.ok) {
      localStorage.setItem('hm_at', data.res.accessToken);
      localStorage.setItem('hm_ac', data.res.adminInfo.account);
      setCookie('hm_rt', data.res.refreshToken);
      setIsLogin(true);
      navigate('/admin');
    } else {
      if (data.res.status === 500) {
        alert('아이디 또는 비밀번호를 확인해주세요.');
      }
    }
  };

  return (
    <S_Container>
      <S_Logo src={Logo} />
      <S_BgBox>
        <div>
          <S_Bg1 src={Bg1} />
          <S_Text>똑똑한 반려생활의 시작</S_Text>
        </div>
        <S_Bg2 src={Bg2} />
      </S_BgBox>
      <S_FormBox>
        <S_Title>관리자 로그인</S_Title>
        <S_Form>
          <S_InputBox>
            <S_InputText>아이디</S_InputText>
            <Input
              state={userId}
              setState={setUserId}
              placeholder='아이디를 입력해주세요.'
              onKeyPress={onKeyPressHandler}
            />
          </S_InputBox>
          <S_InputBox style={{ marginTop: 30 }}>
            <S_InputText>비밀번호</S_InputText>
            <Input
              state={userPassword}
              setState={setUserPassword}
              placeholder='비밀번호를 입력해주세요.'
              type='password'
              onKeyPress={onKeyPressHandler}
            />
          </S_InputBox>
          <S_ButtonBox>
            <LongButton
              onClick={() => onSubmit()}
              style={{ width: 358, height: 50 }}
              type='button'
            >
              로그인
            </LongButton>
          </S_ButtonBox>
        </S_Form>
      </S_FormBox>
      {isPasswordModalOpen && (
        <NormalModal
          state={isPasswordModalOpen}
          setState={setIsPasswordModalOpen}
          title='임시 비밀번호 재발급 완료'
        >
          ssss
        </NormalModal>
      )}
    </S_Container>
  );
}

export default Login;

const S_ButtonBox = styled.div`
  margin-top: 80px;
`;

const S_InputText = styled.p`
  font-size: 12px;
  font-weight: 500;
  color: ${(props) => props.theme.colors.hd1};
`;

const S_InputBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 358px;
`;

const S_Form = styled.form`
  margin-top: 40px;
`;

const S_Title = styled.p`
  font-size: 24px;
  font-weight: 700;
  color: ${(props) => props.theme.colors.hd1};
`;

const S_FormBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 100px 0;
`;

const S_Text = styled.p`
  font-size: 25px;
  letter-spacing: -0.02em;
  color: ${(props) => props.theme.colors.hd1};
`;

const S_Bg2 = styled.img`
  width: 718px;
  height: 494px;
  margin-top: 50px;
`;

const S_Bg1 = styled.img`
  width: 223px;
  height: 282px;
`;

const S_BgBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding-top: 50px;
  padding-left: 64px;
`;

const S_Logo = styled.img`
  width: 130px;
  height: 28px;
  position: absolute;
  top: 37px;
  right: 42px;
`;

const S_Container = styled.div`
  display: flex;
  min-height: 100vh;
  justify-content: space-around;
`;
