import { useEffect } from 'react';
import styled from 'styled-components';
import { Line } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';

interface I_UtmLineChart {
  datas: {
    labels: string[];
    datasets: {
      label: string;
      data: number[];
      backgroundColor: string;
      borderColor: string;
      borderWidth: number;
    }[];
  };
}
Chart.register(...registerables);


function UtmLineChart({ datas }: I_UtmLineChart) {

  useEffect(() => {
    console.log('Line Chart Component Loaded');
  }, []);


  return (
    <ChartContainer>
      <Line data={datas} />
    </ChartContainer>
  );
}

export default UtmLineChart;


const ChartContainer = styled.div`
  width: 80%;
  margin: 0 auto;
  padding: 20px;
`;
