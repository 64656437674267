import { atom } from 'recoil';

// 로컬 스토리지 저장
const localStorageEffect =
  (key: string) =>
  ({ setSelf, onSet }: any) => {
    const savedValue = localStorage.getItem(key);
    if (savedValue != null) {
      setSelf(JSON.parse(savedValue));
    }

    onSet((newValue: any, _: any, isReset: boolean) => {
      isReset
        ? localStorage.removeItem(key)
        : localStorage.setItem(key, JSON.stringify(newValue));
    });
  };

// 로그인 여부
export const isLoginAtom = atom({
  key: 'log',
  default: false,
  effects: [localStorageEffect('hm_log')],
});
