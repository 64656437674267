import moment from 'moment';
import 'moment-timezone';

// 세자리 콤마
export const numberFormat = (number: number | string | undefined) => {
  return number?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const dateFormatUTC = (date: string) => {
  return moment.utc(date);
};

export const dateFormat = (date: string) => {
  return moment(date).tz('Asia/Seoul');
};

export const dateTimeFormat = (date: string | undefined) => {
  return moment(date).tz('Asia/Seoul').format('YYYY년 M월 D일 HH:mm:ss');
};
