import styled from 'styled-components';

interface I_SearchBarInput {
  state: string;
  setState: React.Dispatch<React.SetStateAction<string>>;
  onKeyPress: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onClick?: () => void;
}

function SearchBarInput({
  state,
  setState,
  onKeyPress,
  onClick,
}: I_SearchBarInput) {
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState(event.target.value);
  };

  return (
    <S_Container>
      <S_InputContainer>
        <S_Input
          placeholder='검색어를 입력해주세요.'
          value={state}
          onChange={onChange}
          onKeyPress={onKeyPress}
        />
      </S_InputContainer>
      <S_Button onClick={onClick}>검색</S_Button>
    </S_Container>
  );
}

export default SearchBarInput;

const S_Input = styled.input`
  width: 100%;
  line-height: 24px;
  color: ${(props) => props.theme.colors.hd1};
  &::placeholder {
    color: ${(props) => props.theme.colors.hd2};
  }
  &:focus {
    outline: none;
  }
`;

const S_Button = styled.button`
  min-width: 206px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.colors.ho1};
  border-radius: 12px;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: -0.02em;
  color: ${(props) => props.theme.colors.hd5};
  &:hover {
    opacity: 0.5;
  }
`;

const S_InputContainer = styled.div`
  width: 100%;
  padding: 7px 16px;
  border: 1px solid ${(props) => props.theme.colors.hd3};
  border-radius: 12px;
`;

const S_Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 32px;
`;
