import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import styled from 'styled-components';

import { ReactComponent as Checkbox } from 'assets/image/checkbox.svg';

import MainLayout from 'components/Layout/MainLayout';
import Dropdown from 'components/Dropdown/Dropdown';
import TableInput from 'components/Input/TableInput';
import MiniButton from 'components/Button/MiniButton';
import TableButton from 'components/Button/TableButton';
import StoreProductTableItem from 'components/Item/StoreProductTableItem';
import PagenationButton from 'components/Button/PagenationButton';
import NormalModal from 'components/Modal/NormalModal';
import NormalButton from 'components/Button/NormalButton';
import Input from 'components/Input/Input';
import StoreBrandTableItem from 'components/Item/StoreBrandTableItem';
import SubModal from 'components/Modal/SubModal';
import { I_Brand, I_Category, I_CategoryHash, I_Product } from 'etc/types';
import CategoryDropdown from 'components/Dropdown/CategoryDropdown';
import CategorySettingModal from 'components/Modal/CategorySettingModal';
import useLogout from 'hooks/useLogout';

import {
  getBrandList,
  getCategoryList,
  getProductList,
  patchBrandHide,
  patchBrandUpdate,
  patchProductCategoryUpdate,
  postBrandAdd,
} from 'api/store';
import DeliveryModal from 'components/Modal/DeliveryModal';

function StoreProduct() {
  const navigate = useNavigate();
  const Logout = useLogout();
  const [productList, setProductList] = useState<I_Product[]>([]); // 상품
  const [totalCount, setTotalCount] = useState<number>(0); // 전체 개수
  const [activePage, setActivePage] = useState<number>(1); // 현재 페이지
  const [BrandList, setBrandList] = useState<I_Brand[]>([]); // 브랜드
  const [totalCountBrand, setTotalCountBrand] = useState<number>(0); // 브랜드 전체 개수
  const [activePageBrand, setActivePageBrand] = useState<number>(1); // 브랜드 현재 페이지
  const [categoryHash, setCategoryHash] = useState<I_CategoryHash>();
  const [categoryList, setCategoryList] = useState<I_Category[]>([]);
  const [mainCategory, setMainCategory] = useState<number>(0);
  const [midCategory, setMidCategory] = useState<number>(0);
  const [subCategory, setSubCategory] = useState<number>(0);
  const [mainMoveCategory, setMainMoveCategory] = useState<number>(0);
  const [midMoveCategory, setMidMoveCategory] = useState<number>(0);
  const [subMoveCategory, setSubMoveCategory] = useState<number>(0);
  // 카테고리 대분류 드롭다운
  const [isCategoryMainDropdownOpen, setIsCategoryMainDropdownOpen] =
    useState<boolean>(false);
  const [selectedCategoryMainItem, setSelectedCategoryMainItem] =
    useState<string>('대분류');
  const onCategoryMainItemClick = (item: I_Category) => {
    setSelectedCategoryMainItem(item.name);
    setMainCategory(item.id);
    setMidCategory(0);
    setSelectedCategoryMidItem('중분류');
    setSubCategory(0);
    setSelectedCategorySubItem('소분류');
    setIsCategoryMainDropdownOpen(false);
  };
  // 카테고리 중분류 드롭다운
  const [isCategoryMidDropdownOpen, setIsCategoryMidDropdownOpen] =
    useState<boolean>(false);
  const [selectedCategoryMidItem, setSelectedCategoryMidItem] =
    useState<string>('중분류');
  const onCategoryMidItemClick = (item: I_Category) => {
    setSelectedCategoryMidItem(item.name);
    setMidCategory(item.id);
    setSubCategory(0);
    setSelectedCategorySubItem('소분류');
    setIsCategoryMidDropdownOpen(false);
  };
  // 카테고리 소분류 드롭다운
  const [isCategorySubDropdownOpen, setIsCategorySubDropdownOpen] =
    useState<boolean>(false);
  const [selectedCategorySubItem, setSelectedCategorySubItem] =
    useState<string>('소분류');
  const onCategorySubItemClick = (item: I_Category) => {
    setSelectedCategorySubItem(item.name);
    setSubCategory(item.id);
    setIsCategorySubDropdownOpen(false);
  };
  // 카테고리 이동 대분류 드롭다운
  const [isMoveCategoryMainDropdownOpen, setIsMoveCategoryMainDropdownOpen] =
    useState<boolean>(false);
  const [selectedMoveCategoryMainItem, setSelectedMoveCategoryMainItem] =
    useState<string>('대분류');
  const onMoveCategoryMainItemClick = (item: I_Category) => {
    setSelectedMoveCategoryMainItem(item.name);
    setMainMoveCategory(item.id);
    setMidMoveCategory(0);
    setSelectedMoveCategoryMidItem('중분류');
    setSubMoveCategory(0);
    setSelectedMoveCategorySubItem('소분류');
    setIsMoveCategoryMainDropdownOpen(false);
  };
  // 카테고리 이동 중분류 드롭다운
  const [isMoveCategoryMidDropdownOpen, setIsMoveCategoryMidDropdownOpen] =
    useState<boolean>(false);
  const [selectedMoveCategoryMidItem, setSelectedMoveCategoryMidItem] =
    useState<string>('중분류');
  const onMoveCategoryMidItemClick = (item: I_Category) => {
    setSelectedMoveCategoryMidItem(item.name);
    setMidMoveCategory(item.id);
    setSubMoveCategory(0);
    setSelectedMoveCategorySubItem('소분류');
    setIsMoveCategoryMidDropdownOpen(false);
  };
  // 카테고리 이동 소분류 드롭다운
  const [isMoveCategorySubDropdownOpen, setIsMoveCategorySubDropdownOpen] =
    useState<boolean>(false);
  const [selectedMoveCategorySubItem, setSelectedMoveCategorySubItem] =
    useState<string>('소분류');
  const onMoveCategorySubItemClick = (item: I_Category) => {
    setSelectedMoveCategorySubItem(item.name);
    setSubMoveCategory(item.id);
    setIsMoveCategorySubDropdownOpen(false);
  };
  // 판매 현황별 드롭다운
  const [isSalesDropdownOpen, setIsSalesDropdownOpen] =
    useState<boolean>(false);
  const [selectedSalesItem, setSelectedSalesItem] = useState<string>(
    SALES_STATUS[0]
  );
  const onSalesItemClick = (item: string) => {
    setSelectedSalesItem(item);
    setIsSalesDropdownOpen(false);
  };
  //상세 검색 드롭다운
  const [isDetailDropdownOpen, setIsDetailDropdownOpen] =
    useState<boolean>(false);
  const [selectedDetailItem, setSelectedDetailItem] = useState<string>(
    DETAIL_SEARCH[0]
  );
  const onDetailItemClick = (item: string) => {
    setSelectedDetailItem(item);
    setIsDetailDropdownOpen(false);
  };
  const [InputValue, setInputValue] = useState<string>(''); // 검색 input
  const [brandName, setBrandName] = useState<string>(''); // 브랜드 이름 등록 input
  const [brandNameUpdate, setBrandNameUpdate] = useState<string>(''); // 브랜드 이름 수정 input
  const [checkList, setCheckList] = useState<number[]>([]); // 체크박스 클릭한 항목
  const [checkBrandList, setCheckBrandList] = useState<number[]>([]); // 브랜드 체크박스 클릭한 항목
  const [isMoveCategoryAlertOpen, setIsMoveCategoryAlertOpen] =
    useState<boolean>(false); // 카테고리 이동 Alert
  const [isSettingCategoryModalOpen, setIsSettingCategoryModalOpen] =
    useState<boolean>(false); // 카테고리 수정 Modal
  const [isBrandModalOpen, setIsBrandModalOpen] = useState<boolean>(false); // 브랜드 등록 Modal
  const [isBrandUpdateModalOpen, setIsBrandUpdateModalOpen] =
    useState<boolean>(false); // 브랜드 수정 Modal
  const [isDeliveryModalOpen, setIsDeliveryModalOpen] =
    useState<boolean>(false); // 배송 정보 수정 Modal

  // const handleDeleteClick = () => {
  //   if (checkList.length) setIsDeleteAlertOpen(true);
  //   else alert('삭제할 항목을 선택해주세요!');
  // };
  const handleMoveClick = () => {
    if (checkList.length) setIsMoveCategoryAlertOpen(true);
    else alert('이동할 항목을 선택해주세요!');
  };

  // 검색 초기화
  const handleResetClick = () => {
    setMainCategory(0);
    setMidCategory(0);
    setSubCategory(0);
    setSelectedCategoryMainItem('대분류');
    setSelectedCategoryMidItem('중분류');
    setSelectedCategorySubItem('소분류');
    setSelectedSalesItem(SALES_STATUS[0]);
    setSelectedDetailItem(DETAIL_SEARCH[0]);
    setInputValue('');
  };

  // 상품 table pagenation
  const handlePageChange = (page: number) => {
    setActivePage(page);
  };

  // 브랜드 table pagenation
  const handlePageChangeBrand = (page: number) => {
    setActivePageBrand(page);
  };

  // 카테고리 리스트
  const getAsyncCategoryList = async () => {
    const data = await getCategoryList();
    if (data.ok) {
      setCategoryHash(data.res.categoryObject);
      setCategoryList(data.res.item);
    } else {
      if (data.res.status === 315) Logout();
      else console.error(data.res.error.message);
    }
  };

  // 브랜드 리스트
  const getAsyncBrandList = async () => {
    const data = await getBrandList(activePageBrand, 10);
    if (data.ok) {
      setBrandList(data.res.item);
      setTotalCountBrand(data.res.totalCount);
    } else {
      if (data.res.status === 315) Logout();
      else console.error(data.res.error.message);
    }
  };

  // 브랜드 등록/수정
  const asyncBrand = async (isRegister: boolean) => {
    if (isRegister) {
      // 등록인 경우
      const temp = brandName.trim();
      if (!temp) {
        alert('이름을 입력해주세요.');
        return;
      }
      if (confirm(`'${temp}' 브랜드를 추가하시겠습니까?`)) {
        const data = await postBrandAdd(temp);
        if (data.ok) {
          getAsyncBrandList();
          setBrandName('');
        } else {
          if (data.res.status === 315) Logout();
          else if (data.res.error.code === 552)
            alert('동일한 이름의 브랜드가 존재합니다.');
          else console.error(data.res.error.message);
        }
      }
    } else {
      // 수정인 경우
      const temp = brandNameUpdate.trim();
      if (!temp) {
        alert('이름을 입력해주세요.');
        return;
      }
      if (confirm(`'${temp}' 변경 하시겠습니까?`)) {
        const data = await patchBrandUpdate(temp, checkBrandList[0]);
        if (data.ok) {
          getAsyncBrandList();
          setIsBrandUpdateModalOpen(false);
          setBrandNameUpdate('');
          setCheckBrandList([]);
        } else {
          if (data.res.status === 315) Logout();
          else if (data.res.error.code === 552)
            alert('동일한 이름의 브랜드가 존재합니다.');
          else console.error(data.res.error.message);
        }
      }
    }
  };

  // 브랜드 숨김
  const deleteAsyncBrand = async (isHide: boolean) => {
    if (isHide) {
      if (
        confirm(
          '숨기면 브랜드 목록에서 보이지 않습니다. 계속 진행하시겠습니까?'
        )
      ) {
        const data = await patchBrandHide(checkBrandList, isHide);
        if (data.ok) {
          getAsyncBrandList();
          setCheckBrandList([]);
        } else {
          if (data.res.status === 315) Logout();
          else console.error(data.res.error.message);
        }
      }
    } else {
      if (confirm('숨기기 해제 하시겠습니까?')) {
        const data = await patchBrandHide(checkBrandList, isHide);
        if (data.ok) {
          getAsyncBrandList();
          setCheckBrandList([]);
        } else {
          if (data.res.status === 315) Logout();
          else console.error(data.res.error.message);
        }
      }
    }
  };

  // 상품 이동
  const patchAsyncProductCategoryUpdate = async () => {
    if (mainMoveCategory === 0) {
      alert('대분류를 선택해주세요.');
      return;
    }
    if (midMoveCategory === 0) {
      alert('중분류를 선택해주세요.');
      return;
    }
    const data = await patchProductCategoryUpdate(
      mainMoveCategory,
      midMoveCategory,
      subMoveCategory,
      checkList
    );
    if (data.ok) {
      alert('선택한 상품의 카테고리가 변경되었습니다.');
      getAsyncProductList();
      setIsMoveCategoryAlertOpen(false);
    } else {
      if (data.res.status === 315) Logout();
      else console.error(data.res.error.message);
    }
  };

  // 상품 리스트
  const getAsyncProductList = async () => {
    let search;
    if (InputValue) {
      search =
        selectedDetailItem === DETAIL_SEARCH[0]
          ? `&productId=${InputValue}`
          : selectedDetailItem === DETAIL_SEARCH[1]
          ? `&productName=${InputValue}`
          : `&brandName=${InputValue}`;
    } else search = '';
    const data = await getProductList(
      activePage,
      10,
      mainCategory,
      midCategory,
      subCategory,
      selectedSalesItem,
      search
    );
    if (data.ok) {
      setTotalCount(data.res.totalCount);
      setProductList(data.res.item);
    } else {
      if (data.res.status === 315) Logout();
      else if (data.res.error?.code === 104)
        alert('상품코드는 숫자만 입력해주세요.');
      else console.error(data.res.error.message);
    }
  };

  useEffect(() => {
    getAsyncProductList();
  }, [activePage]);

  useEffect(() => {
    getAsyncBrandList();
  }, [activePageBrand]);

  useEffect(() => {
    getAsyncCategoryList();
  }, []);

  return (
    <MainLayout>
      {/* 카테고리 */}
      <S_CategoryContainer>
        <S_CategoryTitle>상세 검색</S_CategoryTitle>
        <S_CategoryFilterContainer>
          <S_CategoryFilterRow>
            <S_CategoryFilterTitle>카테고리 검색</S_CategoryFilterTitle>
            <S_CategoryFilterWrapper>
              <CategoryDropdown
                list={categoryList}
                selectedItem={selectedCategoryMainItem}
                isOpen={isCategoryMainDropdownOpen}
                setIsOpen={setIsCategoryMainDropdownOpen}
                onClick={(item: I_Category) => onCategoryMainItemClick(item)}
              />
              <CategoryDropdown
                list={
                  categoryList?.find((item) => item.id === mainCategory)?.childs
                }
                selectedItem={selectedCategoryMidItem}
                isOpen={isCategoryMidDropdownOpen}
                setIsOpen={setIsCategoryMidDropdownOpen}
                onClick={(item: I_Category) => onCategoryMidItemClick(item)}
              />
              <CategoryDropdown
                list={
                  categoryList
                    ?.find((item) => item.id === mainCategory)
                    ?.childs?.find((child) => child.id === midCategory)?.childs
                }
                selectedItem={selectedCategorySubItem}
                isOpen={isCategorySubDropdownOpen}
                setIsOpen={setIsCategorySubDropdownOpen}
                onClick={(item: I_Category) => onCategorySubItemClick(item)}
              />
              <S_CategoryFilterWrapperType>
                판매 현황별
              </S_CategoryFilterWrapperType>
              <Dropdown
                list={SALES_STATUS}
                selectedItem={selectedSalesItem}
                isOpen={isSalesDropdownOpen}
                setIsOpen={setIsSalesDropdownOpen}
                onClick={(item: string) => onSalesItemClick(item)}
              />
            </S_CategoryFilterWrapper>
          </S_CategoryFilterRow>
          <S_CategoryFilterRow>
            <S_CategoryFilterTitle>브랜드 검색</S_CategoryFilterTitle>
            <S_CategoryFilterWrapper>
              <Dropdown
                list={DETAIL_SEARCH}
                selectedItem={selectedDetailItem}
                isOpen={isDetailDropdownOpen}
                setIsOpen={setIsDetailDropdownOpen}
                onClick={(item: string) => onDetailItemClick(item)}
              />
              <TableInput
                state={InputValue}
                setState={setInputValue}
                style={{ width: '235px' }}
                placeholder='직접 입력'
              />
            </S_CategoryFilterWrapper>
          </S_CategoryFilterRow>
        </S_CategoryFilterContainer>
        <S_ButtonWrapper>
          <TableButton onClick={handleResetClick}>검색 초기화</TableButton>
          <TableButton onClick={getAsyncProductList}>조회하기</TableButton>
        </S_ButtonWrapper>
      </S_CategoryContainer>

      {/* 상품 목록 */}
      <S_TableButtonContainer>
        <S_TableTitle>{'상품 목록(' + totalCount + ')'}</S_TableTitle>
        <S_TableButtonWrapper>
          <TableButton
            onClick={() => setIsDeliveryModalOpen(true)}
            style={{ width: '200px' }}
          >
            배송 정보 수정
          </TableButton>
          <TableButton
            onClick={() => setIsSettingCategoryModalOpen(true)}
            style={{ width: '200px' }}
          >
            상품 카테고리 수정
          </TableButton>
          <TableButton onClick={() => setIsBrandModalOpen(true)}>
            브랜드 등록
          </TableButton>
          <TableButton onClick={() => navigate('/store/product/registration')}>
            상품 등록
          </TableButton>
          <TableButton onClick={handleMoveClick}>선택 상품 이동</TableButton>
          {/* <TableButton onClick={handleDeleteClick} isDelete={true}>
            선택 항목 삭제
          </TableButton> */}
        </S_TableButtonWrapper>
      </S_TableButtonContainer>

      <S_TableContainer>
        <S_TableBox>
          <S_Table>
            <thead>
              <tr>
                {TABLE_HEADER.map((v, i) => (
                  <S_TableHeaderItem key={i}>
                    {v === 'check' ? (
                      <Checkbox
                        width='16.67px'
                        height='16.67px'
                        color='#2E2E2E'
                      />
                    ) : (
                      v
                    )}
                  </S_TableHeaderItem>
                ))}
              </tr>
            </thead>
            <tbody>
              {productList.map((v: any, i: number) => (
                <StoreProductTableItem
                  key={i}
                  product={v}
                  checkList={checkList}
                  setCheckList={setCheckList}
                  categoryHash={categoryHash}
                />
              ))}
            </tbody>
          </S_Table>
        </S_TableBox>

        <PagenationButton
          activePage={activePage}
          totalCount={totalCount}
          postPerPage={10}
          handlePageChange={handlePageChange}
        />
      </S_TableContainer>

      {/* Alert & Modal */}
      {isBrandModalOpen && (
        <NormalModal
          state={isBrandModalOpen}
          setState={setIsBrandModalOpen}
          style={{
            width: '640px',
            height: '850px',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            padding: '51px 66px 55px',
          }}
        >
          <S_ModalTitle>등록 브랜드 관리</S_ModalTitle>
          <S_BrandInputContainer>
            <S_BrandInputLabel>브랜드 등록</S_BrandInputLabel>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '16px',
                width: '100%',
              }}
            >
              <Input
                state={brandName}
                setState={setBrandName}
                style={{ width: '100%' }}
                placeholder='여기에 입력하세요'
              />
              <TableButton
                onClick={() => asyncBrand(true)}
                style={{ flexShrink: 0 }}
              >
                등록하기
              </TableButton>
            </div>
          </S_BrandInputContainer>
          <S_BrandTableContainer>
            <S_BrandTableButtonWrapper>
              <S_BrandInputLabel>등록 브랜드 목록</S_BrandInputLabel>
              <div
                style={{ display: 'flex', alignItems: 'center', gap: '8px' }}
              >
                <MiniButton
                  onClick={() => {
                    if (checkBrandList.length) {
                      if (checkBrandList.length !== 1) {
                        alert('브랜드 이름은 하나씩 변경 가능합니다.');
                        return;
                      }
                      const temp = BrandList.find(
                        (v) => v.id === checkBrandList[0]
                      )?.name;
                      if (temp) setBrandNameUpdate(temp);
                      setIsBrandUpdateModalOpen(true);
                    } else alert('브랜드를 선택해 주세요.');
                  }}
                >
                  수정하기
                </MiniButton>
                <MiniButton
                  onClick={() => {
                    if (checkBrandList.length) deleteAsyncBrand(true);
                    else alert('브랜드를 선택해 주세요.');
                  }}
                >
                  숨기기
                </MiniButton>
                <MiniButton
                  onClick={() => {
                    if (checkBrandList.length) deleteAsyncBrand(false);
                    else alert('브랜드를 선택해 주세요.');
                  }}
                >
                  숨기기 해제
                </MiniButton>
              </div>
            </S_BrandTableButtonWrapper>
            <S_TableContainer style={{ marginTop: 0 }}>
              <S_TableBox>
                <S_Table>
                  <thead>
                    <tr>
                      {TABLE_HEADER_BRAND.map((v, i) => (
                        <S_TableHeaderItem key={i}>
                          {v === 'check' ? (
                            <Checkbox
                              width='16.67px'
                              height='16.67px'
                              color='#2E2E2E'
                            />
                          ) : (
                            v
                          )}
                        </S_TableHeaderItem>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {BrandList?.map((brand: I_Brand, i: number) => (
                      <StoreBrandTableItem
                        key={i}
                        brand={brand}
                        checkList={checkBrandList}
                        setCheckList={setCheckBrandList}
                      />
                    ))}
                  </tbody>
                </S_Table>
              </S_TableBox>

              <PagenationButton
                activePage={activePageBrand}
                totalCount={totalCountBrand}
                postPerPage={10}
                handlePageChange={handlePageChangeBrand}
                style={{ margin: '40px auto 0' }}
              />
            </S_TableContainer>
          </S_BrandTableContainer>
        </NormalModal>
      )}
      {isBrandUpdateModalOpen && (
        <SubModal
          state={isBrandUpdateModalOpen}
          setState={setIsBrandUpdateModalOpen}
          style={{
            width: '537px',
            height: '288px',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            padding: '48px 78px',
          }}
        >
          <S_BrandModalTitle>브랜드 수정</S_BrandModalTitle>
          <S_BrandUpdateInputContainer>
            <Input
              state={brandNameUpdate}
              setState={setBrandNameUpdate}
              style={{ width: '100%' }}
              placeholder='여기에 입력하세요'
            />
            <NormalButton onClick={() => asyncBrand(false)}>
              수정하기
            </NormalButton>
          </S_BrandUpdateInputContainer>
        </SubModal>
      )}

      {isMoveCategoryAlertOpen && (
        <NormalModal
          state={isMoveCategoryAlertOpen}
          setState={setIsMoveCategoryAlertOpen}
          onClose={() => {
            setMainMoveCategory(0);
            setMidMoveCategory(0);
            setSubMoveCategory(0);
            setSelectedMoveCategoryMainItem('대분류');
            setSelectedMoveCategoryMidItem('중분류');
            setSelectedMoveCategorySubItem('소분류');
            setIsMoveCategoryAlertOpen(false);
          }}
          title='상품 카테고리 이동'
          style={{
            width: '640px',
            height: '359px',
            justifyContent: 'flex-start',
          }}
        >
          <S_MoveCategoryContent>
            <span>
              선택 상품(들)에 있어 이동을 원하는 카테고리를 선택해주세요
            </span>
            <S_CategoryWrapper>
              <S_CategoryWrapperLeft>이동 카테고리 선택</S_CategoryWrapperLeft>
              <S_CategoryWrapperRight>
                <CategoryDropdown
                  list={categoryList}
                  selectedItem={selectedMoveCategoryMainItem}
                  isOpen={isMoveCategoryMainDropdownOpen}
                  setIsOpen={setIsMoveCategoryMainDropdownOpen}
                  onClick={(item: I_Category) =>
                    onMoveCategoryMainItemClick(item)
                  }
                />
                <CategoryDropdown
                  list={
                    categoryList?.find((item) => item.id === mainMoveCategory)
                      ?.childs
                  }
                  selectedItem={selectedMoveCategoryMidItem}
                  isOpen={isMoveCategoryMidDropdownOpen}
                  setIsOpen={setIsMoveCategoryMidDropdownOpen}
                  onClick={(item: I_Category) =>
                    onMoveCategoryMidItemClick(item)
                  }
                />
                <CategoryDropdown
                  list={
                    categoryList
                      ?.find((item) => item.id === mainMoveCategory)
                      ?.childs?.find((child) => child.id === midMoveCategory)
                      ?.childs
                  }
                  selectedItem={selectedMoveCategorySubItem}
                  isOpen={isMoveCategorySubDropdownOpen}
                  setIsOpen={setIsMoveCategorySubDropdownOpen}
                  onClick={(item: I_Category) =>
                    onMoveCategorySubItemClick(item)
                  }
                />
              </S_CategoryWrapperRight>
            </S_CategoryWrapper>
          </S_MoveCategoryContent>
          <NormalButton onClick={patchAsyncProductCategoryUpdate}>
            이동하기
          </NormalButton>
        </NormalModal>
      )}
      <CategorySettingModal
        isOpen={isSettingCategoryModalOpen}
        onClose={() => setIsSettingCategoryModalOpen(false)}
        categories={categoryList}
        getAsyncCategoryList={getAsyncCategoryList}
      />
      <DeliveryModal
        isOpen={isDeliveryModalOpen}
        onClose={() => setIsDeliveryModalOpen(false)}
      />
    </MainLayout>
  );
}

export default StoreProduct;

export const S_ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
`;

const S_CategoryWrapperRight = styled.div`
  width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  border: 0.5px solid ${(props) => props.theme.colors.hd3};
`;

const S_CategoryWrapperLeft = styled.div`
  width: 167px;
  background-color: ${(props) => props.theme.colors.hd4};
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  border: 0.5px solid ${(props) => props.theme.colors.hd3};
`;

const S_CategoryWrapper = styled.div`
  display: flex;
  width: 508px;
  height: 120px;
`;

const S_MoveCategoryContent = styled.div`
  margin: 16px 0 32px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  span {
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
    letter-spacing: -0.02em;
    letter-spacing: -0.02em;
  }
`;

const S_BrandInputLabel = styled.span`
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
`;

const S_BrandTableButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const S_BrandTableContainer = styled.div`
  margin-top: 45px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const S_BrandUpdateInputContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 34px;
`;

const S_BrandInputContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 14px;
`;

const S_BrandModalTitle = styled.div`
  width: 100%;
  margin-bottom: 34px;
  font-weight: 700;
  font-size: 24px;
  line-height: 35px;
  letter-spacing: -0.02em;
  text-align: center;
`;

const S_ModalTitle = styled.div`
  width: 100%;
  margin-bottom: 44px;
  font-weight: 400;
  font-size: 24px;
  line-height: 35px;
  letter-spacing: -0.02em;
  text-align: center;
`;

const S_TableHeaderItem = styled.th`
  vertical-align: middle;
  height: 36px;
  background-color: ${(props) => props.theme.colors.hd4};
  border: 0.5px solid ${(props) => props.theme.colors.hd3};
  font-weight: 500;
  font-size: 13px;
  letter-spacing: -0.02em;
  color: ${(props) => props.theme.colors.n10};
`;

const S_Table = styled.table`
  width: 100%;
`;

const S_TableBox = styled.div`
  overflow-x: overlay;
  &::-webkit-scrollbar {
    width: 4px;
    height: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.theme.colors.hd3};
    border-radius: 6px;
  }
`;

const S_TableContainer = styled.div`
  margin-top: 8px;
  position: relative;
`;

const S_TableButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const S_TableTitle = styled.span`
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.02em;
  display: flex;
  align-items: flex-end;
`;

const S_TableButtonContainer = styled.div`
  margin-top: 44px;
  display: flex;
  justify-content: space-between;
`;

const S_CategoryFilterWrapperType = styled.span`
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: -0.02em;
`;

const S_CategoryFilterWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const S_CategoryFilterTitle = styled.span`
  width: 90px;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  letter-spacing: -0.02em;
`;

const S_CategoryFilterRow = styled.div`
  display: flex;
  align-items: center;
  gap: 48px;
`;

const S_CategoryFilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 14px 16px;
  border: 1px solid ${(props) => props.theme.colors.hd3};
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  letter-spacing: -0.02em;
  /* border-radius: 8px; */
`;

const S_CategoryTitle = styled.span`
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.02em;
`;

const S_CategoryContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const TABLE_HEADER = [
  'check',
  '상품코드',
  '등록 카테고리',
  '상품 명',
  '상품 가격',
  '상품 할인율',
  '포인트 적용률',
  '업데이트 날짜',
  '판매 상태',
];
const TABLE_HEADER_BRAND = ['check', '업데이트 일시', '등록 브랜드 명'];
const SALES_STATUS = ['전체', '판매중', '품절'];
const DETAIL_SEARCH = ['상품코드', '상품명', '등록브랜드'];
