import { useEffect, useState } from 'react';
import styled from 'styled-components';

import MainLayout from 'components/Layout/MainLayout';
import SearchBarInput from 'components/Input/SearchBarInput';
import PagenationButton from 'components/Button/PagenationButton';
import UserTableItem from 'components/Item/UserTableItem';
import TravelProductTableItem from 'components/Item/TravelProductTableItem';
import Dropdown from 'components/Dropdown/Dropdown';

import { I_TravelProduct } from 'etc/types';
import { getUserList, getUserNicknameSearch } from 'api/user';
// import { getPetstivalList, getPetstivalDetail } from 'api/petstival';
import { getTravelProductList } from 'api/travelProduct';
import useLogout from 'hooks/useLogout';

function List() {

  const Logout = useLogout();

  const [searchValue, setSearchValue] = useState<string>('');
  const [isUserStatusDropdownOpen, setIsUserStatusDropdownOpen] =
    useState<boolean>(false);
  const [isActivityTypeDropdownOpen, setIsActivityTypeDropdownOpen] = useState<boolean>(false);
  
  const [totalCount, setTotalCount] = useState<number>(0); // 전체 개수
  const [activePage, setActivePage] = useState<number>(1); // 현재 페이지
  const [isList, setList] = useState<I_TravelProduct[]>([]);


  const handlePageChange = (page: number) => {
    setActivePage(page);
  };


  const getAsyncList = async () => {

    const data = await getTravelProductList(
      10,
      activePage
    );

    if (data.ok) {
      setList(data.res.list);
      setTotalCount(data.res.totalCount);
    } else {
      if (data.res.status === 315) Logout();
      else {
        alert('잠시 후 다시 시도해주세요.');
        console.error(data.res.error.message);
      }
    }
  };

  useEffect(() => {
    // console.log('use Effect ::::::::::::::::: ');
    getAsyncList();
  }, [ activePage ]);


  const handlerUpdateResult = (updatePetstival: I_TravelProduct) => {

    // setList(isList =>
    //   isList.map(item => (item.id === updatePetstival.id ? { ...item, ...updatePetstival } : item))
    // );
    getAsyncList();

  };

  return (
    <MainLayout>
     
      <S_TableContainer>
        <S_TableBox>
          <S_Table>
            <thead>
              <tr>
                {TABLE_HEADER.map((v, i) => (
                  <S_TableHeaderItem key={i}>{v}</S_TableHeaderItem>
                ))}
              </tr>
            </thead>
            <tbody>
              {isList.map((v: I_TravelProduct, i: number) => (
                <TravelProductTableItem key={i} item={v} onChangeData={handlerUpdateResult}  />
              ))}
            </tbody>
          </S_Table>
        </S_TableBox>

        <PagenationButton
          activePage={activePage}
          totalCount={totalCount}
          postPerPage={10}
          handlePageChange={handlePageChange}
        />
      </S_TableContainer>
    </MainLayout>
  );
}

export default List;

const S_TableTitle = styled.span`
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.02em;
  display: flex;
  align-items: flex-end;
`;

const S_DropdownBox = styled.div`
  display: flex;
  gap: 16px;
`;

const S_TableButtonContainer = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
`;

const S_TableHeaderItem = styled.th`
  vertical-align: middle;
  padding: 10px;
  height: 36px;
  background-color: ${(props) => props.theme.colors.hd4};
  border: 0.5px solid ${(props) => props.theme.colors.hd3};
  font-weight: 500;
  font-size: 13px;
  letter-spacing: -0.02em;
  color: ${(props) => props.theme.colors.n10};
`;

const S_Table = styled.table`
  width: 100%;
`;

const S_TableBox = styled.div`
  overflow-x: scroll;
  &::-webkit-scrollbar {
    width: 4px;
    height: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.theme.colors.hd3};
    border-radius: 6px;
  }
`;

const S_TableContainer = styled.div`
  margin-top: 8px;
  position: relative;
`;

const TABLE_HEADER = [
  '상품id',
  '카테고리',
  '상품 제목',
  '도시',
  '장소',
  '주소',
  '노출상태',
  '옵션',
];