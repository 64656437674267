import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import styled from 'styled-components';

import MainLayout from 'components/Layout/MainLayout';
import NormalButton from 'components/Button/NormalButton';
import NormalModal from 'components/Modal/NormalModal';
import Input from 'components/Input/Input';

import { patchPassword } from 'api/login';
import useLogout from 'hooks/useLogout';

function AdminInfo() {
  const Logout = useLogout();
  const navigate = useNavigate();
  const account = localStorage.getItem('hm_ac');

  const [isPasswordModalOpen, setIsPasswordModalOpen] =
    useState<boolean>(false);
  const [newPassword, setNewPassword] = useState<string>('');
  const [newPasswordConfirm, setNewPasswordConfirm] = useState<string>('');
  const [validError, setValidError] = useState<boolean>(false);
  const [confirmError, setConfirmError] = useState<boolean>(false);
  const [isMaster, setIsMaster] = useState<boolean>(false);
  const masterAccount = 'humanizone_master';
  const adminAccount = 'humanizone_admin';

  const onClickOpenModal = (master: boolean) => {
    setIsMaster(master);
    setIsPasswordModalOpen(true);
  };
  const InputStyle = {
    width: 326,
    height: 30,
    display: 'flex',
    alignItems: 'center',
    borderRadius: 8,
  };

  const onCloseModal = () => {
    setValidError(false);
    setConfirmError(false);
    setNewPassword('');
    setNewPasswordConfirm('');
    setIsPasswordModalOpen(false);
  };

  const validatePassword = () => {
    const passwordRegex =
      /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,20}$/;
    const isValid = passwordRegex.test(newPassword);

    return isValid;
  };

  const passwordConfirm = () => {
    const isConfirm = newPassword === newPasswordConfirm;

    return isConfirm;
  };

  const onKeyPressHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      patchAsyncPassword();
    }
  };

  const patchAsyncPassword = async () => {
    if (!validatePassword()) {
      setValidError(true);
      if (!passwordConfirm()) {
        setConfirmError(true);
        return;
      } else {
        setConfirmError(false);
        return;
      }
    } else if (!passwordConfirm()) {
      setConfirmError(true);
      setValidError(false);
    } else {
      const data = await patchPassword({
        account: isMaster ? masterAccount : adminAccount,
        password: newPassword,
      });

      if (data.ok) {
        alert('비밀번호 변경이 완료되었습니다.');
        Logout();
        navigate('/');
      } else {
        if (data.res.status === 315) Logout();
        else {
          alert('잠시 후 다시 시도해주세요.');
          console.error(data.res.error.message);
        }
      }
    }
  };

  return (
    <MainLayout>
      <S_Title>계정 정보</S_Title>
      <S_TableContainer>
        <S_TableBox>
          <S_Table>
            <tbody>
              <tr>
                <S_TableHeaderItem>마스터 아이디</S_TableHeaderItem>
                <S_TableDataItem>{masterAccount}</S_TableDataItem>
              </tr>
            </tbody>
          </S_Table>
        </S_TableBox>
      </S_TableContainer>
      <S_ButtonBox>
        {account === masterAccount && (
          <NormalButton onClick={() => onClickOpenModal(true)}>
            비밀번호 재설정
          </NormalButton>
        )}
      </S_ButtonBox>

      <S_TableContainer style={{ marginTop: 80 }}>
        <S_TableBox>
          <S_Table>
            <tbody>
              <tr>
                <S_TableHeaderItem>어드민 아이디</S_TableHeaderItem>
                <S_TableDataItem>{adminAccount}</S_TableDataItem>
              </tr>
            </tbody>
          </S_Table>
        </S_TableBox>
      </S_TableContainer>
      <S_ButtonBox>
        {account === masterAccount && (
          <NormalButton onClick={() => onClickOpenModal(false)}>
            비밀번호 재설정
          </NormalButton>
        )}
      </S_ButtonBox>
      {isPasswordModalOpen && (
        <NormalModal
          state={isPasswordModalOpen}
          setState={setIsPasswordModalOpen}
          title='비밀번호 재설정'
          onClose={() => onCloseModal()}
        >
          <S_ModalContentBox>
            <S_TableBox>
              <S_Table>
                <tbody>
                  <tr>
                    <S_ModalTableHeaderItem>
                      새 비밀번호 입력
                    </S_ModalTableHeaderItem>
                    <S_ModelTableDataItem>
                      <Input
                        state={newPassword}
                        setState={setNewPassword}
                        placeholder='영문, 숫자, 특수문자 포함 8자~20자'
                        style={InputStyle}
                        type='password'
                        onKeyPress={onKeyPressHandler}
                      />
                    </S_ModelTableDataItem>
                  </tr>
                </tbody>
              </S_Table>
              {validError && (
                <S_Text>
                  영문,숫자,특수문자 포함 8자~20자 이내로 입력해주세요.
                </S_Text>
              )}
            </S_TableBox>
            <S_TableBox style={{ marginTop: 30 }}>
              <S_Table>
                <tbody>
                  <tr>
                    <S_ModalTableHeaderItem>
                      새 비밀번호 확인
                    </S_ModalTableHeaderItem>
                    <S_ModelTableDataItem>
                      <Input
                        state={newPasswordConfirm}
                        setState={setNewPasswordConfirm}
                        placeholder='여기에 입력하세요.'
                        style={InputStyle}
                        type='password'
                        onKeyPress={onKeyPressHandler}
                      />
                    </S_ModelTableDataItem>
                  </tr>
                </tbody>
              </S_Table>
              {confirmError && <S_Text>비밀번호가 일치하지 않습니다.</S_Text>}
            </S_TableBox>
            <S_ModalButtonBox>
              <NormalButton onClick={() => patchAsyncPassword()}>
                확인
              </NormalButton>
            </S_ModalButtonBox>
          </S_ModalContentBox>
        </NormalModal>
      )}
    </MainLayout>
  );
}

export default AdminInfo;

const S_Title = styled.div`
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 16px;
`;

const S_ModalButtonBox = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 60px;
`;

const S_Text = styled.p`
  margin-top: 5px;
  padding-left: 175px;
  font-size: 11px;
  color: ${(props) => props.theme.colors.ho6};
  position: absolute;
`;

const S_ModalContentBox = styled.div`
  padding: 40px 66px;
`;

const S_ButtonBox = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
`;

const S_TableDataItem = styled.td`
  border: 1px solid ${(props) => props.theme.colors.hd3};
  text-align: center;
  vertical-align: middle;
`;

const S_ModelTableDataItem = styled(S_TableDataItem)`
  padding: 3px 7.5px;
`;

const S_TableHeaderItem = styled.th`
  vertical-align: middle;
  padding: 10px;
  width: 350px;
  height: 36px;
  background-color: ${(props) => props.theme.colors.hd4};
  border: 0.5px solid ${(props) => props.theme.colors.hd3};
  font-weight: 500;
  font-size: 13px;
  letter-spacing: -0.02em;
  color: ${(props) => props.theme.colors.n10};
`;

const S_ModalTableHeaderItem = styled(S_TableHeaderItem)`
  width: 167px;
`;

const S_Table = styled.table`
  width: 100%;
`;

const S_TableBox = styled.div`
  &::-webkit-scrollbar {
    width: 4px;
    height: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.theme.colors.hd3};
    border-radius: 6px;
  }
`;

const S_TableContainer = styled.div`
  margin-top: 8px;
  position: relative;
`;
