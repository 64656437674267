import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import moment from 'moment';

import MainLayout from 'components/Layout/MainLayout';
import LongButton from 'components/Button/LongButton';
import Input from 'components/Input/Input';
import ImageInput from 'components/Input/ImageInput';
import Textarea from 'components/Input/Textarea';
import DateInput from 'components/Input/DateInput';

import { postImagesUpload } from 'api/images';
import { getEventDetail, updateEvent, patchEvent } from 'api/event'; // API for fetching and updating event
import useLogout from 'hooks/useLogout';
import { eventTypes, linkTypes, eventStatuTypes } from 'etc/variable'

function EventDetailEdit() {
  const { id } = useParams();
  
  const Logout = useLogout();
  const navigate = useNavigate();

  const [title, setTitle] = useState<string>('');
  const [webUrl, setWebUrl] = useState<string>('');
  const [appRoute, setAppRoute] = useState<string>('');
  const [startDate, setStartDate] = useState<string>('');
  const [endDate, setEndDate] = useState<string>('');
  const [explain, setExplain] = useState<string>('');
  const [thumbnail, setThumbnail] = useState<File>(new File([], ''));
  const [detailImage, setDetailImage] = useState<File>(new File([], ''));
  
  const [eventType, setEventType] = useState<string>('');
  const [linkType, setLinkType] = useState<string>('');
  const [eventStatus, setEventStatus] = useState<string>('');

  const [bannerImage, setBannerImage] = useState<string>('');
  const [mainImage, setMainImage] = useState<string>('');

  const today = moment(new Date()).format('YYYY-MM-DDTHH:mm');

  // Load existing event data when component mounts
  useEffect(() => {
    const fetchEvent = async () => {

      console.log('id : ', id)
      const data = await getEventDetail(Number(id)); // Fetch event detail by ID
      if (data.ok) {
        const event = data.res.item;
        setTitle(event.eventName);
        console.log('event.start : ', event.start)
        console.log('event.end : ', event.end)
        setStartDate(moment(event.start).format('YYYY-MM-DDTHH:mm'));
        setEndDate(moment(event.end).format('YYYY-MM-DDTHH:mm'));
        setExplain(event.content);
        setWebUrl(event.webUrl);
        setAppRoute(event.appRoute);
        setEventType(event.type);
        setEventStatus(event.status)
        setLinkType(event.linkType);
        setBannerImage(event.bannerImage.url)
        setMainImage(event.mainImage.url)
      } else {
        alert('이벤트를 불러오는데 실패했습니다.');
      }
    };

    fetchEvent();
  }, [id]);

  const onClickSave = async () => {
    if (!title) return alert('이벤트 명을 입력해주세요.');
    if (!startDate) return alert('시작 기간을 입력해주세요.');
    if (!endDate) return alert('종료 기간을 입력해주세요.');
    if (!eventType) return alert('이벤트 배너 위치를 선택해주세요.');
    if(!linkType) return alert('클릭시 연결타입을 선택해주세요.');
    // if (!webUrl && !appRoute) return alert('연결 웹주소 또는 앱 라우트 중 하나를 입력해주세요.');
    // if (!thumbnail.name) return alert('썸네일을 등록해주세요.');

    const formData = new FormData();
    const body = {
      eventName: title,
      content: explain,
      start: startDate,
      end: endDate,
      bannerImage: 0,
      mainImage: 0,
      type: eventType,
      linkType: linkType,
      webUrl: webUrl,
      appRoute: appRoute,
      status: eventStatus,
    };
    
    formData.append('files', thumbnail);
    formData.append('files', detailImage);
    formData.append('path', 'event');

    const uploadfile = await postImagesUpload(formData);
    if (uploadfile.ok) {
      console.log('uploadfile : ', uploadfile)
      body.bannerImage = uploadfile.res.ids[0];
      body.mainImage = uploadfile.res.ids[1];
    } else {
      if (uploadfile.res.status === 315) Logout();
      else {
        alert('잠시 후 다시 시도해주세요.');
        console.error(uploadfile.res.error.message);
        return;
      }
    }
    console.log('body : ', body)

    const data = await updateEvent(Number(id), body); // Update the event with new data
    if (data.ok) {
      alert('이벤트가 수정되었습니다.');
      navigate('/service/event');
    } else {
      if (data.res.status === 315) Logout();
      else {
        alert('잠시 후 다시 시도해주세요.');
        console.error(data.res.error.message);
      }
    }
  };

  return (
    <MainLayout>
      <S_Section>
        <S_Title>이벤트 정보 수정</S_Title>
        <S_Table>
          <tbody>
            <tr>
              <th>이벤트 명</th>
              <td colSpan={3}>
                <Input state={title ?? ''} setState={setTitle} />
              </td>
            </tr>

            <tr>
              <th style={{ verticalAlign: 'top' }}>
                이벤트 시작 기간/종료 기간
              </th>
              <td colSpan={3}>
                <S_DateBox>
                  <DateInput
                    state={startDate}
                    setState={setStartDate}
                    type="datetime-local"
                    min={today}
                  />
                  <DateInput
                    state={endDate}
                    setState={setEndDate}
                    type="datetime-local"
                    min={today}
                  />
                </S_DateBox>
              </td>
            </tr>

            <tr>
              <th className='alignTop'>이벤트 팝업 위치</th>
              <td>
                <S_Select value={eventType} onChange={(e) => setEventType(e.target.value)}>
                  <option value="">선택하세요</option>
                  {eventTypes.map((type) => (
                    <option key={type.value} value={type.value}>
                      {type.label}
                    </option>
                  ))}
                </S_Select>
              </td>
            </tr>

            <tr>
              <th>연결타입/ 연결 웹주소 / 앱라우트</th>
              <td colSpan={3}>
                <S_FlexBox>
                  <S_SelectBox>
                    <S_Select value={linkType} onChange={(e) => setLinkType(e.target.value)}>
                      <option value="">선택하세요</option>
                      {linkTypes.map((type) => (
                      <option key={type.value} value={type.value}>
                        {type.label}
                      </option>
                      ))}
                    </S_Select>
                  </S_SelectBox>

                  <S_HalfWidthInput>
                    <Input state={webUrl ?? ''} setState={setWebUrl} placeholder="https://" />
                  </S_HalfWidthInput>

                  <S_HalfWidthInput>
                    <Input state={appRoute ?? ''} setState={setAppRoute} placeholder="앱라우트" />
                  </S_HalfWidthInput>
                </S_FlexBox>
              </td>
            </tr>

            <tr>
              <th className='alignTop'>배너</th>
              <td>
                <S_ImgContainer>
                  <ImageInput setState={setThumbnail} id={1} />
                  {bannerImage && (
                      <img src={bannerImage} alt="Thumbnail Preview" style={{ width: '150px', height: '150px', marginLeft: '10px' }} />
                    )}
                </S_ImgContainer>
              </td>
            </tr>
            <tr>
              <th className='alignTop'>상세 이미지</th>
              <td colSpan={3}>
                <S_ImgContainer>
                  <S_ImgBox>
                    <ImageInput
                      style={{ width: 279, height: 279 }}
                      setState={setDetailImage}
                      id={2}
                    />
                    {mainImage && (
                      <img src={mainImage} alt="Thumbnail Preview" style={{ width: '200px', height: '200px', marginLeft: '10px' }} />
                    )}
                  </S_ImgBox>
                </S_ImgContainer>
              </td>
            </tr>
            <tr>
              <th className='alignTop'>추가 설명</th>
              <td>
                <Textarea
                  state={explain}
                  setState={setExplain}
                  style={{ height: 90 }}
                />
              </td>
            </tr>

            <tr>
              <th className='alignTop'>진행 상태</th>
              <td>
                <S_Select value={eventStatus} onChange={(e) => setEventStatus(e.target.value)}>
                  <option value="">선택하세요</option>
                  {eventStatuTypes.map((type) => (
                    <option key={type.value} value={type.value}>
                      {type.label}
                    </option>
                  ))}
                </S_Select>
              </td>
            </tr>

          </tbody>
        </S_Table>
      </S_Section>
      <S_ButtonBox>
        <LongButton onClick={() => onClickSave()}>이벤트 수정</LongButton>
      </S_ButtonBox>
    </MainLayout>
  );
}

export default EventDetailEdit;

const S_DateBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  input {
    width: 48%; /* input 요소들이 50%씩 영역을 차지 */
    height: 46px;
    border-radius: 12px;
    padding: 0 10px;
    box-sizing: border-box;
  }
`;

const S_ImgBox = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 40px;
`;

const S_Select = styled.select`
  width: 50%;
  height: 46px;
  border-radius: 12px;
  border: 1px solid #ccc;
  padding: 0 10px;
`;

const S_Table = styled.table`
  border-collapse: separate;
  border-spacing: 20px;

  th {
    width: 160px;
    text-align: left;
    font-size: 14px;
    font-weight: 500;
    line-height: 150%;
    &:nth-child(1) {
      width: 85px;
    }
    &:nth-child(3) {
      width: 120px;
    }
    &.alignTop {
      vertical-align: top;
      padding-top: 14px;
    }
  }
`;

const S_ButtonBox = styled.div`
  display: flex;
  justify-content: center;
  padding-right: 20px;
`;

const S_FlexBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 10px; /* 선택 박스와 인풋 간 간격을 조정 */
`;

const S_SelectBox = styled.div`
  width: 20%; /* 셀렉트 박스의 너비를 설정 */
`;

const S_HalfWidthInput = styled.div`
  width: 40%; /* 인풋 필드들이 50%씩 차지하게 설정 */
`;

const S_Title = styled.p`
  font-size: 16px;
  color: ${(props) => props.theme.colors.hd1};
  font-weight: 700;
`;

const S_Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 17px;
  padding: 30px 20px;

  &:last-child {
    border-bottom: 0;
  }
`;

const S_BigImg = styled.img`
  max-width: 1000px;
  max-height: 500px;
`;

const S_ModalContent = styled.div`
  padding: 13px 50px 60px;
`;

const S_FirstBox = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
  justify-content: space-between;
`;

const S_StateBox = styled.div`
  display: flex;
  align-items: center;
  gap: 18px;
`;

const S_TitleBox = styled.div`
  width: 100%;
`;

const S_ThumbnailBox = styled.div`
  width: 321px;
  height: 120px;
`;

const S_Thumbnail = styled.img`
  width: 321px;
  height: 120px;
  cursor: pointer;
  object-fit: contain;
  border: 1px solid ${(props) => props.theme.colors.hd3};
  border-radius: 16px;
`;

const S_DetailImg = styled.img`
  width: 279px;
  height: 279px;
  border-radius: 16px;
  cursor: pointer;
  object-fit: contain;
  border: 1px solid ${(props) => props.theme.colors.hd3};
`;

const S_DetailImgBox = styled.div`
  display: flex;
  justify-content: space-between;
`;
const S_ImgContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px; /* ImageInput과 img 간의 간격 */
`;
