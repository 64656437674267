import { UseFormRegisterReturn } from 'react-hook-form';

import styled from 'styled-components';

interface I_RegisterInput {
  isDisabledColor?: boolean;
  isDisabled?: boolean;
  register?: UseFormRegisterReturn;
  style?: React.CSSProperties;
  rightText?: string;
  isError?: boolean;
  errorMessage?: string; 
  [key: string]: any; // 속성
}

function RegisterInput({
  isDisabledColor,
  isDisabled,
  register,
  style,
  rightText,
  isError,
  errorMessage, 
  ...rest
}: I_RegisterInput) {
  return (
    <S_Container
      isDisabledColor={isDisabledColor}
      isDisabled={isDisabled}
      isError={isError}
      style={style}
      {...rest}
    >
      <S_Input
        {...register}
        isDisabledColor={isDisabledColor}
        isDisabled={isDisabled}
        disabled={isDisabled || isDisabledColor}
        {...rest}
      />
      {rightText && <S_RightText>{rightText}</S_RightText>}
      {isError && errorMessage && <S_ErrorMessage>{errorMessage}</S_ErrorMessage>} {/* 오류 메시지 표시 */}
    </S_Container>
  );
}

export default RegisterInput;

const S_RightText = styled.span`
  font-weight: 700;
  font-size: 14px;
  color: ${(props) => props.theme.colors.hd2};
`;

const S_Input = styled.input<{
  isDisabled?: boolean;
  isDisabledColor?: boolean;
}>`
  width: 100%;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.02em;
  color: ${(props) => props.theme.colors.hd1};
  &::placeholder {
    color: ${(props) => props.theme.colors.hd2};
  }
  &:focus {
    outline: none;
  }
  background: ${(props) =>
    props.isDisabledColor
      ? props.theme.colors.ho3
      : props.isDisabled
      ? props.theme.colors.hd4
      : props.theme.colors.hd5};
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
`;

const S_Container = styled.div<{
  isDisabled?: boolean;
  isError?: boolean;
  isDisabledColor?: boolean;
}>`
  width: 100%;
  padding: 14px 16px;
  background: ${(props) =>
    props.isDisabledColor
      ? props.theme.colors.ho3
      : props.isDisabled
      ? props.theme.colors.hd4
      : props.theme.colors.hd5};
  border: 1px solid
    ${(props) =>
      props.isError ? props.theme.colors.ho6 : props.theme.colors.hd3};
  border-radius: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  position: relative;
`;

const S_ErrorMessage = styled.span`
  color: ${(props) => props.theme.colors.ho6};  // 오류 메시지 색상
  font-size: 12px;
  font-weight: 500;
  position: absolute;
  bottom: -20px;  // 오류 메시지가 인풋 아래에 위치하도록 조정
  left: 0;
`;
