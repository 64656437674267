import { FC, useEffect, useState } from 'react';
import styled from 'styled-components';

import MainLayout from 'components/Layout/MainLayout';
import SearchBarInput from 'components/Input/SearchBarInput';
import PagenationButton from 'components/Button/PagenationButton';
import UserTableItem from 'components/Item/UserTableItem';
import TravelProductCategoryTableItem from 'components/Item/TravelProductCategoryTableItem';
import Dropdown from 'components/Dropdown/Dropdown';

import { getUserList, getUserNicknameSearch } from 'api/user';
import { getPetstivalList, getPetstivalDetail } from 'api/petstival';
import useLogout from 'hooks/useLogout';

import {
  I_ProductRegistrationForm,
  I_TravelProductCategory
} from 'etc/types';

interface CategoryListProps {
  categories: I_TravelProductCategory[];
}

const CategoryList: FC<CategoryListProps> = ({ categories }) =>  {

  const Logout = useLogout();

  const handlerUpdateResult = (update: I_TravelProductCategory) => {
    console.log('update : ', update)
  };



  return (
    <>
      <S_TableContainer>
        <S_TableBox>
          <S_Table>
            <thead>
              <tr>
                {TABLE_HEADER.map((v, i) => (
                  <S_TableHeaderItem key={i}>{v}</S_TableHeaderItem>
                ))}
              </tr>
            </thead>

            <tbody>
              {categories.map((v: I_TravelProductCategory, i: number) => (
                <TravelProductCategoryTableItem key={i} item={v} onChangeData={handlerUpdateResult}  />
              ))}
            </tbody>
          </S_Table>
        </S_TableBox>

      </S_TableContainer>
    </>
  );
}

export default CategoryList;

const S_TableTitle = styled.span`
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.02em;
  display: flex;
  align-items: flex-end;
`;

const S_DropdownBox = styled.div`
  display: flex;
  gap: 16px;
`;

const S_TableButtonContainer = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
`;

const S_TableHeaderItem = styled.th`
  vertical-align: middle;
  padding: 10px;
  height: 36px;
  background-color: ${(props) => props.theme.colors.hd4};
  border: 0.5px solid ${(props) => props.theme.colors.hd3};
  font-weight: 500;
  font-size: 13px;
  letter-spacing: -0.02em;
  color: ${(props) => props.theme.colors.n10};
`;

const S_Table = styled.table`
  width: 100%;
`;

const S_TableBox = styled.div`
  overflow-x: scroll;
  &::-webkit-scrollbar {
    width: 4px;
    height: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.theme.colors.hd3};
    border-radius: 6px;
  }
`;

const S_TableContainer = styled.div`
  margin-top: 8px;
  position: relative;
`;

const TABLE_HEADER = [
  '카테고리id',
  'name',
];


