import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import MainLayout from 'components/Layout/MainLayout';
import NormalButton from 'components/Button/NormalButton';
import NormalModal from 'components/Modal/NormalModal';
import PagenationButton from 'components/Button/PagenationButton';
import LongButton from 'components/Button/LongButton';
import DisabledInput from 'components/Input/DisabledInput';
import DisabledTextarea from 'components/Input/DisabledTextarea';

import { getCaringDetail, getCaringReview, postCaringBen } from 'api/caring';
import { I_CareApplyDetail, I_CareReview } from 'etc/types';
import { CARE_TYPE, CATEGORY_MAIN } from 'etc/variable';
import { dateFormat } from 'utils/common';
import useLogout from 'hooks/useLogout';

import { ReactComponent as Star } from 'assets/image/star.svg';

function CareUserDetail() {
  const Logout = useLogout();
  const navigate = useNavigate();
  const { id } = useParams();

  const [careUser, setCareUser] = useState<I_CareApplyDetail>({
    activitieCount: 0,
    address: '',
    addressDetail: '',
    career: 0,
    caringStatus: '',
    id: 0,
    images: [],
    introduction: '',
    nickname: '',
    options: [],
    rating: 0,
    reportCount: 0,
    reviewCount: 0,
    times: { id: 0, start: '', end: '' },
    user: { id: 0, userCode: '', pets: [] },
    weeks: [],
  });
  const [careReview, setCareReview] = useState<I_CareReview[]>([]);
  const [isReviewModalOpen, setIsReviewModalOpen] = useState<boolean>(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState<boolean>(false);
  const [activeTime, setActiveTime] = useState<string[]>([]);
  const [reviewTotalCount, setReviewTotalCount] = useState<number>(0);
  const [reviewActivePage, setReviewActivePage] = useState<number>(1);
  const [ratingAverage, setRatingAverage] = useState<number>(0);
  const [isImageModal, setIsImageModal] = useState<boolean>(false);
  const [imageUrl, setImageUrl] = useState<string>('');

  const onClickImage = (url: string) => {
    setIsImageModal(true);
    setImageUrl(url);
  };

  const handlePageChange = (page: number) => {
    setReviewActivePage(page);
  };

  const onClickSanction = async () => {
    const data = await postCaringBen(careUser.id);
    if (data.ok) {
      alert('유저의 활동이 제재되었습니다.');
      navigate('/user/care');
      // console.log(data);
    } else {
      if (data.res.status === 315) Logout();
      else {
        alert('잠시 후 다시 시도해주세요.');
        console.error(data.res.error.message);
      }
    }
  };

  const onClickReview = () => {
    if (careUser.reviewCount > 0) {
      setIsReviewModalOpen(true);
      getAsyncCaringReview();
    } else {
      alert('등록된 후기가 없습니다.');
    }
  };

  const getAsyncCaringReview = async () => {
    const data = await getCaringReview(5, reviewActivePage, careUser.id);
    if (data.ok) {
      // console.log(data.res);
      setReviewTotalCount(data.res.totalCount);
      setCareReview(data.res.reviews);
      setRatingAverage(data.res.ratingAvg);
    } else {
      if (data.res.status === 315) Logout();
      else {
        alert('잠시 후 다시 시도해주세요.');
        console.error(data.res.error.message);
      }
    }
  };

  const getAsyncUserDetail = async () => {
    const data = await getCaringDetail(Number(id));
    const times: string[] = [];

    if (data.ok) {
      // console.log(data.res.item);
      setCareUser(data.res.item);

      data.res.item.times.forEach((el: { start: string; end: string }) => {
        if (el.start === '24:00:00') {
          times.push('새벽');
        } else if (el.start === '08:00:00') {
          times.push('오전');
        } else if (el.start === '12:00:00') {
          times.push('오후');
        } else {
          times.push('저녁');
        }
      });
      setActiveTime(times);
    } else {
      if (data.res.status === 315) Logout();
      else {
        alert('잠시 후 다시 시도해주세요.');
        console.error(data.res.error.message);
      }
    }
  };

  useEffect(() => {
    getAsyncUserDetail();
  }, []);

  useEffect(() => {
    if (isReviewModalOpen) getAsyncCaringReview();
  }, [reviewActivePage]);

  return (
    <MainLayout>
      <S_Section>
        <S_Title>돌보미 활동 현황</S_Title>
        <S_Table>
          <tbody>
            <tr>
              <th>활동 현황</th>
              <td>
                <DisabledInput
                  text={
                    careUser.caringStatus === 'Active' ? '활동 중' : '비활성화'
                  }
                  style={{
                    color:
                      careUser.caringStatus === 'Active'
                        ? '#13A872'
                        : '#FC1D1D',
                  }}
                />
              </td>
              <th>프로필 신고 수</th>
              <td>
                <DisabledInput text={careUser.reportCount} />
              </td>
            </tr>
            <tr>
              <th>누적 활동 수</th>
              <td>
                <DisabledInput text={careUser.activitieCount} />
              </td>
              <th>평균 별점</th>
              <td>
                <DisabledInput text={careUser.rating.toFixed(1)} />
              </td>
            </tr>
            <tr>
              <th>후기 수</th>
              <td>
                <DisabledInput text={careUser.reviewCount} />
              </td>
            </tr>
          </tbody>
        </S_Table>
        <S_ButtonBox>
          <NormalButton onClick={() => onClickReview()}>
            후기 상세 보기
          </NormalButton>
        </S_ButtonBox>
      </S_Section>
      <S_Section>
        <S_Title>신청자 정보</S_Title>
        <S_Table>
          <tbody>
            <tr>
              <th>돌보미 닉네임</th>
              <td>
                <DisabledInput text={careUser?.nickname} />
              </td>
              <th>User Code</th>
              <td
                style={{ textDecoration: 'underline', cursor: 'pointer' }}
                onClick={() => navigate(`/user/${careUser.user.id}`)}
              >
                <DisabledInput text={careUser.user.userCode} />
              </td>
            </tr>
            <tr>
              <th>집사 경력</th>
              <td>
                <DisabledInput text={careUser.career} />
              </td>
              <th>올펫 등록 반려동물</th>
              <td>
                <DisabledInput text={careUser.user.pets.length} />
              </td>
            </tr>
            <tr>
              <th>케어 주소</th>
              <td colSpan={3}>
                <DisabledInput
                  text={careUser.address + ' ' + careUser.addressDetail}
                />
              </td>
            </tr>
            <tr>
              <th className='alignTop'>소개글</th>
              <td colSpan={3}>
                <DisabledTextarea
                  text={careUser.introduction}
                  style={{ height: 86 }}
                />
              </td>
            </tr>
          </tbody>
        </S_Table>
      </S_Section>
      <S_Section>
        <S_Title>신청 내용</S_Title>
        <S_Table>
          <tbody>
            <tr>
              <th>케어 동물 종</th>
              <td>
                <DisabledInput
                  text={careUser.options
                    .filter((el) => CATEGORY_MAIN.includes(el))
                    .join('/')}
                />
              </td>
              <th>케어 가능 방식</th>
              <td>
                <DisabledInput
                  text={careUser.options
                    .filter((el) => CARE_TYPE.includes(el))
                    .join('/')}
                />
              </td>
            </tr>
            <tr>
              <th>케어 활동 시간</th>
              <td>
                <DisabledInput text={careUser.weeks.join('/')} />
              </td>
              <td colSpan={2}>
                <DisabledInput text={activeTime.join('/')} />
              </td>
            </tr>
            <tr>
              <th className='alignTop'>케어 환경 사진</th>
              <td colSpan={3}>
                <S_CareImgBox>
                  {careUser.images?.map((v, i) => (
                    <S_CareImg
                      src={v.url}
                      key={i}
                      onClick={() => onClickImage(v.url)}
                    />
                  ))}
                </S_CareImgBox>
              </td>
            </tr>
          </tbody>
        </S_Table>
        {careUser.caringStatus === 'Active' && (
          <S_ButtonBox>
            <LongButton
              onClick={() => setIsConfirmModalOpen(true)}
              style={{ marginTop: 50, backgroundColor: '#FC1D1D' }}
            >
              활동 제재
            </LongButton>
          </S_ButtonBox>
        )}
      </S_Section>
      {isReviewModalOpen && (
        <NormalModal
          state={isReviewModalOpen}
          setState={setIsReviewModalOpen}
          title='돌보미 후기'
        >
          <S_ModalContent>
            <S_StarScore>
              <p>평균별점</p>
              <S_Score>{ratingAverage.toFixed(1)}</S_Score>
            </S_StarScore>
            <S_ReviewContent>
              <S_Count>총 {reviewTotalCount}개 리뷰</S_Count>
              <S_ReviewList>
                {careReview?.map((v: I_CareReview, i: number) => (
                  <S_ReviewItem key={i}>
                    <S_Star>
                      {Array.from({ length: v.rating }).map((v, i: number) => (
                        <Star color='#13A872' key={i} />
                      ))}
                      {Array.from({ length: 5 - v.rating }).map(
                        (v, i: number) => (
                          <Star color='#CCCCCC' key={i} />
                        )
                      )}
                    </S_Star>
                    <S_ReviewText>{v.content}</S_ReviewText>
                    <S_ReviewInfo>
                      <S_ReviewDate>
                        {dateFormat(v.createdAt).format('YY.M.DD')}
                      </S_ReviewDate>
                      <S_ReviewWriter>
                        {v.user?.profile?.nickname}
                      </S_ReviewWriter>
                    </S_ReviewInfo>
                  </S_ReviewItem>
                ))}
              </S_ReviewList>
              <S_Pagenation>
                <PagenationButton
                  activePage={reviewActivePage}
                  totalCount={reviewTotalCount}
                  postPerPage={5}
                  handlePageChange={handlePageChange}
                />
              </S_Pagenation>
            </S_ReviewContent>
          </S_ModalContent>
        </NormalModal>
      )}

      {isImageModal && (
        <NormalModal
          state={isImageModal}
          setState={setIsImageModal}
          title='케어 환경 사진'
        >
          <S_ModalContent>
            <S_BigImg src={imageUrl} />
          </S_ModalContent>
        </NormalModal>
      )}

      {isConfirmModalOpen && (
        <NormalModal
          state={isConfirmModalOpen}
          setState={setIsConfirmModalOpen}
          title='유저 활동을 제재하시겠습니까?'
        >
          <S_ModalContent>
            <S_SanctionModalContent>
              <NormalButton
                onClick={() => onClickSanction()}
                style={{ backgroundColor: '#FC1D1D' }}
              >
                확인
              </NormalButton>
            </S_SanctionModalContent>
          </S_ModalContent>
        </NormalModal>
      )}
    </MainLayout>
  );
}

export default CareUserDetail;

const S_SanctionModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  padding: 40px 100px 0;
`;

const S_BigImg = styled.img`
  max-width: 1000px;
  max-height: 500px;
`;

const S_CareImg = styled.img`
  width: 279px;
  height: 279px;
  border-radius: 16px;
  object-fit: contain;
  border: 1px solid ${(props) => props.theme.colors.hd3};
  cursor: pointer;
`;

const S_CareImgBox = styled.div`
  display: grid;
  gap: 16px;
  grid-template-columns: 1fr 1fr 1fr;
  justify-items: start;
`;

const S_Table = styled.table`
  border-collapse: separate;
  border-spacing: 20px;

  th {
    width: 120px;
    text-align: left;
    font-size: 14px;
    font-weight: 500;
    &:nth-child(1) {
      min-width: 85px;
    }
    &:nth-child(3) {
      min-width: 120px;
      padding-left: 10px;
    }
    &.alignTop {
      vertical-align: top;
      padding-top: 14px;
    }
  }
`;

const S_Pagenation = styled.div`
  margin-top: -30px;
`;

const S_ReviewWriter = styled.span`
  font-size: 12px;
  line-height: 150%;
`;

const S_ReviewDate = styled.span`
  font-size: 11px;
  line-height: 150%;
`;

const S_ReviewInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const S_ReviewText = styled.div`
  font-size: 14px;
`;

const S_Star = styled.div`
  display: flex;
  gap: 2px;
`;

const S_ReviewItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 535px;
  /* height: 100px; */
  padding: 16px;
  background-color: ${(props) => props.theme.colors.hd4};
  border-radius: 16px;
`;

const S_ReviewList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const S_Count = styled.p`
  font-size: 12px;
  font-weight: 500;
  color: ${(props) => props.theme.colors.hd1};
  position: absolute;
  top: 0;
  right: 0;
`;

const S_ReviewContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 30px;
  position: relative;
`;

const S_Score = styled.span`
  font-size: 20px;
  color: ${(props) => props.theme.colors.ho1};
  font-weight: 700;
  line-height: 150%;
`;

const S_StarScore = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;

  p {
    font-size: 12px;
  }
`;

const S_ModalContent = styled.div`
  padding: 13px 50px 60px;
`;

const S_ButtonBox = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-right: 20px;
`;

const S_Title = styled.p`
  font-size: 16px;
  color: ${(props) => props.theme.colors.hd1};
  font-weight: 700;
`;

const S_Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 17px;
  padding: 30px 20px;
  border-bottom: 1px solid ${(props) => props.theme.colors.hd4};

  &:last-child {
    border-bottom: 0;
  }

  td {
    min-width: 380px;
  }
`;
