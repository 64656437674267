import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import MainLayout from 'components/Layout/MainLayout';
import LongButton from 'components/Button/LongButton';
import DisabledInput from 'components/Input/DisabledInput';
import DisabledTextarea from 'components/Input/DisabledTextarea';
import NormalModal from 'components/Modal/NormalModal';

import { dateFormat } from 'utils/common';
import {
  getReportDetail,
  postReportHidden,
  postReportSanction,
} from 'api/report';
import useLogout from 'hooks/useLogout';

import { ReactComponent as QMark } from 'assets/image/questionmark.svg';
import { ReactComponent as Checkbox } from 'assets/image/checkbox.svg';
import { ReactComponent as CheckboxCk } from 'assets/image/checkbox-checked.svg';
import { I_ReportDetail } from 'etc/types';
import { REPORT_CATEGORY } from 'etc/variable';

interface I_ReportImage {
  imageId?: { url: string };
  id?: number;
  image?: { id: number; url: string };
}

function ReportDetail() {
  const navigate = useNavigate();
  const Logout = useLogout();
  const { id } = useParams();

  const [checkboxState, setCheckboxState] = useState<{
    check1: boolean;
    check2: boolean;
  }>({
    check1: false,
    check2: false,
  });
  const [isImageModal, setIsImageModal] = useState<boolean>(false);
  const [imageUrl, setImageUrl] = useState<string>('');
  const [report, setReport] = useState<I_ReportDetail>(reportDetail);

  const onClickCheck = (id: number) => {
    if (id == 1) {
      setCheckboxState({
        check1: !checkboxState.check1,
        check2: checkboxState.check2,
      });
    } else {
      setCheckboxState({
        check1: checkboxState.check1,
        check2: !checkboxState.check2,
      });
    }
  };

  const onClickImage = (url: string) => {
    setIsImageModal(true);
    setImageUrl(url);
  };

  const onClickSave = () => {
    if (checkboxState.check2) {
      postAsyncReportHidden();
      postAsyncReportSanction();
    } else if (checkboxState.check1) {
      postAsyncReportHidden();
    }
  };

  const postAsyncReportHidden = async () => {
    // console.log(report.id);
    // console.log(report.targetPost);
    const data = await postReportHidden(report.id);

    if (data.ok) {
      alert('게시글 숨김 처리가 완료되었습니다.');
      navigate('/service/report');
    } else {
      if (data.res.status === 315) Logout();
      else {
        alert('잠시 후 다시 시도해주세요.');
        console.log(data.res.error.message);
      }
    }
  };

  const postAsyncReportSanction = async () => {
    const data = await postReportSanction(report.id, report.targetUser.id);

    if (data.ok) {
      alert('유저 제재 처리가 완료되었습니다.');
      navigate('/service/report');
    } else {
      if (data.res.status === 315) Logout();
      else {
        alert('잠시 후 다시 시도해주세요.');
        console.error(data.res.error.message);
      }
    }
  };

  const getAsyncReportDetail = async () => {
    const data = await getReportDetail(Number(id));

    if (data.ok) {
      // console.log(data.res);
      setReport(data.res);
    } else {
      if (data.res.status === 315) Logout();
      else {
        alert('잠시 후 다시 시도해주세요.');
        console.error(data.res.error.message);
      }
    }
  };

  useEffect(() => {
    getAsyncReportDetail();
  }, []);

  return (
    <MainLayout>
      <S_Section style={{ borderBottom: 0, paddingBottom: 0 }}>
        <S_Title>신고 정보</S_Title>
        <S_Table>
          <tbody>
            <tr>
              <th>신고 일시</th>
              <td colSpan={3}>
                <DisabledInput
                  text={dateFormat(report.createdAt).format('YYYY년 M월 D일')}
                />
              </td>
            </tr>
            <tr>
              <th>신고 유형</th>
              <td>
                <DisabledInput
                  text={
                    REPORT_CATEGORY.find(
                      (el) => el.category === report.category.name
                    )?.type
                  }
                  style={{ backgroundColor: '#C4E9DC' }}
                />
              </td>
            </tr>
            <tr>
              <th className='alignTop'>신고 사유</th>
              <td colSpan={3}>
                <DisabledTextarea
                  text={report.reason}
                  style={{ height: 127 }}
                />
              </td>
            </tr>
            <tr>
              <th className='alignTop'>상세 내용</th>
              <td colSpan={3}>
                <DisabledTextarea
                  text={report.reportData?.content}
                  style={{ height: 127 }}
                />
              </td>
            </tr>
            <tr>
              <th className='alignTop'>참조 이미지</th>
              <td colSpan={3}>
                <S_ImgBox>
                  {report.category.name === 'market' ? (
                    <>
                      {report.reportData?.images?.map(
                        (v: I_ReportImage, i: number) => (
                          <S_Img
                            src={v.image?.url}
                            key={i}
                            onClick={() => onClickImage(v.image!.url)}
                          />
                        )
                      )}
                    </>
                  ) : report.category.name === 'storeReview' ? (
                    <>
                      {report.reportData?.reviewImages?.map(
                        (v: { imageId: { url: string } }, i: number) => (
                          <S_Img
                            src={v.imageId.url}
                            key={i}
                            onClick={() => onClickImage(v.imageId.url)}
                          />
                        )
                      )}
                    </>
                  ) : (
                    <>
                      {report.reportData?.images?.map(
                        (v: I_ReportImage, i: number) => (
                          <S_Img
                            key={i}
                            src={v.imageId?.url}
                            onClick={() => onClickImage(v.imageId!.url)}
                          />
                        )
                      )}
                    </>
                  )}
                </S_ImgBox>
              </td>
            </tr>
          </tbody>
        </S_Table>
      </S_Section>
      <S_Section>
        <S_Title>작성 유저 내용</S_Title>
        <S_Table>
          <tbody>
            <tr>
              <th>작성 유저</th>
              <td colSpan={3}>
                <DisabledInput text={report.targetUser?.profile?.nickname} />
              </td>
            </tr>
            <tr>
              <th>작성 유저 코드</th>
              <td
                style={{ textDecoration: 'underline', cursor: 'pointer' }}
                onClick={() => navigate(`/user/${report.targetUser.id}`)}
              >
                <DisabledInput text={report.targetUser.userCode} />
              </td>
              <th>신고 누적 수</th>
              <td>
                <DisabledInput text={report.targetUser.reportCount} />
              </td>
            </tr>
          </tbody>
        </S_Table>
      </S_Section>

      <S_Section>
        <S_Title>신고 유저 내용</S_Title>
        <S_Table>
          <tbody>
            <tr>
              <th>신고 유저</th>
              <td>
                <DisabledInput text={report.reporterUser.profile?.nickname} />
              </td>
              <th>신고 유저 코드</th>
              <td
                style={{ textDecoration: 'underline', cursor: 'pointer' }}
                onClick={() => navigate(`/user/${report.reporterUser.id}`)}
              >
                <DisabledInput text={report.reporterUser.userCode} />
              </td>
            </tr>
          </tbody>
        </S_Table>

        <S_ConfirmBox>
          <S_CheckItem onClick={() => onClickCheck(1)}>
            {checkboxState.check1 ? (
              <CheckboxCk width='32' height='32' color='#FF0000' />
            ) : (
              <Checkbox width='32' height='32' color='#FF0000' />
            )}
            게시글 숨김 처리 : 해당 신고 내용을 블라인드 처리(서비스 내에서
            숨기기) 하시겠습니까?
          </S_CheckItem>
          <S_CheckItem onClick={() => onClickCheck(2)}>
            {checkboxState.check2 ? (
              <CheckboxCk width='32' height='32' color='#FF0000' />
            ) : (
              <Checkbox width='32' height='32' color='#FF0000' />
            )}
            유저 제재 처리 : 해당 게시글 작성 유저에게 다음과 같은 신고 제재
            정책을 적용하시겠습니까?
          </S_CheckItem>
        </S_ConfirmBox>

        <S_PolicyBox>
          <S_PolciyTitle>
            <QMark />
            유저 신고 및 제재 정책
          </S_PolciyTitle>
          <S_PolicyContent>
            <S_PolicyItem>
              유저의 신고 후, 관리자에 의해 2가지의 신고 처리가 가능합니다
            </S_PolicyItem>
            <S_PolicyItem>
              1차 신고 처리 : 앱 내 게시글 숨기기/ 작성 유저의 활동에는 영향을
              주지 않습니다.
            </S_PolicyItem>
            <S_PolicyItem>
              2차 신고 처리 : 유저 활동 제재 처리/작성 유저의 활동 제재 횟수가
              누적됩니다.
            </S_PolicyItem>
            <S_PolicyItemIndent style={{ marginLeft: 20 }}>
              2차 신고 처리로 인한 유저 활동 제재 정책
            </S_PolicyItemIndent>
            <S_PolicyItemIndent>
              누적 3회 간 : 한 달간 활동 제한(팝업 제공)
            </S_PolicyItemIndent>
            <S_PolicyItemIndent>
              누적 4회 차 : 1년 간 활동 제한(팝업 제공)
            </S_PolicyItemIndent>
            <S_PolicyItemIndent>
              누적 5회 : 가입 계정 영구 정지(앱 내 유입 불가)
            </S_PolicyItemIndent>
          </S_PolicyContent>
        </S_PolicyBox>
        {report.status === 'NECESSARY' && (
          <S_ButtonBox>
            <LongButton onClick={() => onClickSave()}>저장하기</LongButton>
          </S_ButtonBox>
        )}
      </S_Section>

      {isImageModal && (
        <NormalModal
          state={isImageModal}
          setState={setIsImageModal}
          title='참조 이미지'
        >
          <S_ModalContent>
            <S_BigImg src={imageUrl} />
          </S_ModalContent>
        </NormalModal>
      )}
    </MainLayout>
  );
}

export default ReportDetail;

const S_BigImg = styled.img`
  max-width: 1000px;
  max-height: 500px;
`;

const S_ModalContent = styled.div`
  padding: 13px 50px 60px;
`;

const S_CheckItem = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  color: ${(props) => props.theme.colors.ho6};
  font-weight: 500;
  cursor: pointer;
`;

const S_ConfirmBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 7px;
`;

const S_PolicyItem = styled.li`
  padding-left: 15px;
  font-size: 12px;
  color: ${(props) => props.theme.colors.hd2};
  line-height: 150%;
  position: relative;

  &::before {
    content: '';
    display: block;
    width: 4px;
    height: 4px;
    margin: auto;
    background-color: ${(props) => props.theme.colors.hd2};
    border-radius: 50%;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
  }
`;

const S_PolicyItemIndent = styled(S_PolicyItem)`
  margin-left: 40px;
`;

const S_PolicyContent = styled.ul`
  margin-top: 4px;
  margin-left: 22px;
`;

const S_PolciyTitle = styled.p`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 12px;
  color: ${(props) => props.theme.colors.hd2};
  font-weight: 700;
  line-height: 150%;
`;

const S_PolicyBox = styled.div`
  height: 180px;
  padding: 16px;
  background-color: ${(props) => props.theme.colors.hd4};
  border-radius: 12px;
`;

const S_Img = styled.img`
  width: 139px;
  height: 139px;
  border-radius: 16px;
  cursor: pointer;
  object-fit: cover;
`;

const S_ImgBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;

const S_Table = styled.table`
  border-collapse: separate;
  border-spacing: 20px;

  th {
    width: 120px;
    text-align: left;
    font-size: 14px;
    font-weight: 500;
    &:nth-child(1) {
      min-width: 85px;
    }
    &:nth-child(3) {
      min-width: 120px;
      padding-left: 20px;
    }
    &.alignTop {
      vertical-align: top;
      padding-top: 14px;
    }
  }
`;

const S_ButtonBox = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-right: 20px;
`;
const S_Title = styled.p`
  font-size: 16px;
  color: ${(props) => props.theme.colors.hd1};
  font-weight: 700;
`;

const S_Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 17px;
  padding: 30px 20px;
  border-bottom: 1px solid ${(props) => props.theme.colors.hd4};

  &:last-child {
    border-bottom: 0;
  }

  td {
    min-width: 380px;
  }
`;

const reportDetail = {
  id: 0,
  reason: '',
  targetPost: 0,
  createdAt: '',
  status: '',
  category: {
    name: '',
  },
  reporterUser: {
    id: 0,
    reportCount: 0,
    userCode: '',
    profile: { nickname: '' },
  },
  targetUser: {
    id: 0,
    reportCount: 0,
    userCode: '',
    profile: { nickname: '' },
  },
  reportData: {
    id: 0,
    content: '',
    images: [],
  },
};
