import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useFieldArray, useForm } from 'react-hook-form';

import styled from 'styled-components';

import { ReactComponent as Delete } from 'assets/image/minus-circle.svg';

import MainLayout from 'components/Layout/MainLayout';
import MiniButton from 'components/Button/MiniButton';
import LongButton from 'components/Button/LongButton';
import RegisterInput from 'components/Input/RegisterInput';
import CategoryDropdown from 'components/Dropdown/CategoryDropdown';
import BrandDropdown from 'components/Dropdown/BrandDropdown';
import {
  I_Brand,
  I_Category,
  I_Option,
  I_ProductRegistrationForm,
} from 'etc/types';
import useLogout from 'hooks/useLogout';
import Spinner from 'components/Spinner/Spinner';

import { getBrandList, getCategoryList, postProductAdd } from 'api/store';
import { postImageUpload, postImagesUpload } from 'api/images';

function ProductRegistration() {
  const navigate = useNavigate();
  const Logout = useLogout();
  const [categoryList, setCategoryList] = useState<I_Category[]>();
  const [BrandList, setBrandList] = useState<I_Brand[]>([]); // 브랜드
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // 리턴받은 이미지 url 주소
  const [ isUploadedFiles, setUploadedFiles ] = useState([]);

  // 카테고리 대분류 드롭다운
  const [isCategoryMainDropdownOpen, setIsCategoryMainDropdownOpen] =
    useState<boolean>(false);
  const [selectedCategoryMainItem, setSelectedCategoryMainItem] =
    useState<string>('대분류');
  const onCategoryMainItemClick = (item: I_Category) => {
    setValue('mainCategory', item.id);
    setSelectedCategoryMainItem(item.name);
    setValue('middleCategory', 0);
    setSelectedCategoryMidItem('중분류');
    setValue('subCategory', 0);
    setSelectedCategorySubItem('소분류');
    setIsCategoryMainDropdownOpen(false);
  };
  // 카테고리 중분류 드롭다운
  const [isCategoryMidDropdownOpen, setIsCategoryMidDropdownOpen] =
    useState<boolean>(false);
  const [selectedCategoryMidItem, setSelectedCategoryMidItem] =
    useState<string>('중분류');
  const onCategoryMidItemClick = (item: I_Category) => {
    setValue('middleCategory', item.id);
    setSelectedCategoryMidItem(item.name);
    setValue('subCategory', 0);
    setSelectedCategorySubItem('소분류');
    setIsCategoryMidDropdownOpen(false);
  };
  // 카테고리 소분류 드롭다운
  const [isCategorySubDropdownOpen, setIsCategorySubDropdownOpen] =
    useState<boolean>(false);
  const [selectedCategorySubItem, setSelectedCategorySubItem] =
    useState<string>('소분류');
  const onCategorySubItemClick = (item: I_Category) => {
    setValue('subCategory', item.id);
    setSelectedCategorySubItem(item.name);
    setIsCategorySubDropdownOpen(false);
  };

  const {
    register,
    watch,
    getValues,
    setValue,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<I_ProductRegistrationForm>({
    mode: 'onChange',
    shouldFocusError: true,
    defaultValues: {
      mainCategory: 0,
      middleCategory: 0,
      subCategory: 0,
      brand: 0,
      thumbnail: 0,
      name: '',
      price: 0,
      options: [
        {
          option: '',
          price: 0,
          limitPointPercent: 0,
          discountPercent: 0,
          discountAppliedProductPrice: 0,
          defaultOption: true,
        },
      ],
      titleImages: [], // 대표 이미지
      mainImages: [],
      tFiles: [],
      mFiles: [],
    },
  });

  const {
    fields: optionFields,
    append: optionAppend,
    remove: optionRemove,
  } = useFieldArray({
    control,
    name: 'options',
  });

  watch();

  // input 추가
  const onAddClick = () => {
    if (optionFields.length < 16)
      optionAppend({
        option: '',
        price: 0,
        limitPointPercent: 0,
        discountPercent: 0,
        discountAppliedProductPrice: 0,
        defaultOption: false,
      });
  };
  // input 삭제
  const onRemoveClick = (idx: number) => {
    optionRemove(idx);
  };

  // 제출
  const onSubmit = async (data: I_ProductRegistrationForm) => {
    setIsLoading(true);

    // option 형변환
    data.options.forEach((v: I_Option, i) => {
      data.options[i].discountPercent = Number(data.options[i].discountPercent);
      data.options[i].price = Number(data.options[i].price);
    });
    data.price = data.options[0].price;

    const addData = await postProductAdd(data);
    if (addData.ok) {
      alert('상품이 등록되었습니다. 생성한 상품 상세페이지로 이동합니다.');
      setIsLoading(false);
      navigate(`/store/product/${addData.res.item}`);
    } else {
      setIsLoading(false);
      if (addData.res.status === 315) Logout();
      else {
        console.error(addData.res.error.message);
        alert('잠시 후 다시 시도해주세요.');
        return;
      }
    }
  };

  // 카테고리 리스트
  const getAsyncCategoryList = async () => {
    const data = await getCategoryList();
    if (data.ok) {
      setCategoryList(data.res.item);
    } else {
      if (data.res.status === 315) Logout();
      else console.error(data.res.error.message);
    }
  };

  // 브랜드 리스트
  const getAsyncBrandList = async () => {
    const data = await getBrandList(1, 10000);
    if (data.ok) {
      setBrandList(data.res.item);
    } else {
      if (data.res.status === 315) Logout();
      else console.error(data.res.error.message);
    }
  };

  // 파일 업로드
  const fileUpload = async (files: FileList | [], isTitle: boolean) => {
    const formData = new FormData();
    const changeArray = Array.from(files);
    changeArray.forEach((v: any) => {
      formData.append('files', v);
    });
    formData.append('path', 'product');
    console.log('formData : ', formData);
    const ImagesData = await postImagesUpload(formData);
    if (ImagesData.ok) {
      if (isTitle) {
        setValue('titleImages', ImagesData.res.ids);
        setValue('thumbnail', ImagesData.res.ids[0]);
      } else {
        setValue('mainImages', ImagesData.res.ids);
        setUploadedFiles(ImagesData.res.fileArray); 
        console.log('ImagesData.res.fileArray : ', ImagesData.res.fileArray)
      }
    } else {
      setIsLoading(false);
      if (ImagesData.res.status === 315) Logout();
      else {
        console.error(ImagesData.res.error.message);
        alert('잠시 후 다시 시도해주세요.');
      }
    }
  };

  const title = watch('tFiles');
  useEffect(() => {
    if (title.length)
      if (title.length <= 10) fileUpload(title, true);
      else {
        setValue('tFiles', []);
        alert('최대 10개 이미지까지 업로드 가능합니다.');
      }
  }, [title]);
  const main = watch('mFiles');

  useEffect(() => {
    if (main.length)
      if (main.length <= 10) {
        console.log('main in')
        fileUpload(main, false); 
      }
      else {
        setValue('mFiles', []);
        alert('최대 10개 이미지까지 업로드 가능합니다.');
      }
  }, [main]);

  useEffect(() => {
    getAsyncCategoryList();
    getAsyncBrandList();
  }, []);

  return (
    <MainLayout>
      <form onSubmit={handleSubmit(onSubmit)}>
        <S_TopContainer>
          <S_BasicInfoTitle>기본 정보</S_BasicInfoTitle>
          <S_BasicInfoContainer>
            <S_BasicInfoRowWrapper>
              <S_BasicInfoWrapper>
                <S_Label>상품명</S_Label>
                <RegisterInput
                  isError={errors?.name ? true : false}
                  register={register('name', {
                    required: 'Required field.',
                  })}
                  style={{ width: '380px' }}
                />
              </S_BasicInfoWrapper>
              <S_BasicInfoWrapper>
                <S_Label>브랜드</S_Label>
                <BrandDropdown
                  isError={errors?.brand ? true : false}
                  list={BrandList}
                  field='brand'
                  register={register('brand', {
                    validate: {
                      checkCategory: (id) => {
                        return id === 0 ? 'Required field.' : true;
                      },
                    },
                  })}
                  getValues={getValues}
                  setValue={setValue}
                  style={{ minWidth: '380px' }}
                />
              </S_BasicInfoWrapper>
            </S_BasicInfoRowWrapper>
            <S_BasicInfoRowWrapper style={{ marginTop: '12px' }}>
              <S_BasicInfoWrapper>
                <S_Label style={{ color: '#13A872' }}>기본 옵션</S_Label>
                <RegisterInput
                  isError={errors?.options?.[0]?.option ? true : false}
                  register={register(`options.${0}.option`, {
                    required: 'Required field.',
                  })}
                  style={{ width: '380px' }}
                />
              </S_BasicInfoWrapper>
              <S_BasicInfoWrapper>
                <S_Label>포인트 적용률</S_Label>
                <RegisterInput
                  isError={
                    errors?.options?.[0]?.limitPointPercent ? true : false
                  }
                  register={register(`options.${0}.limitPointPercent`, {
                    required: 'Required field.',
                    onChange: (e) => {
                      e.target.value = e.target.value.replace(/[^0-9]/gm, '');
                    },
                    // pattern: {
                    //   value: /^[0-9]{0,2}$/,
                    //   message: '0~99',
                    // },
                    pattern: {
                      value: /^(100|[0-9]{1,2})$/,
                      message: '0~100',
                    },
                  })}
                  placeholder='0~99'
                  style={{ width: '380px' }}
                  rightText='%'
                />
              </S_BasicInfoWrapper>
            </S_BasicInfoRowWrapper>
            <S_BasicInfoRowWrapper>
              <S_BasicInfoWrapper>
                <S_Label>상품 가격</S_Label>
                <RegisterInput
                  isError={errors?.options?.[0]?.price ? true : false}
                  register={register(`options.${0}.price`, {
                    required: 'Required field.',
                    onChange: (e) => {
                      const temp = e.target.value.replace(/[^0-9]/gm, '');
                      e.target.value = temp;
                      setValue(
                        `options.${0}.discountAppliedProductPrice`,
                        Math.floor(
                          (temp *
                            (100 - getValues(`options.${0}.discountPercent`))) /
                            100 /
                            10
                        ) * 10
                      );
                    },
                  })}
                  style={{ width: '380px' }}
                  rightText='원'
                />
              </S_BasicInfoWrapper>
              <S_BasicInfoWrapper>
                <S_Label>상품 할인율</S_Label>
                <RegisterInput
                  isError={errors?.options?.[0]?.discountPercent ? true : false}
                  register={register(`options.${0}.discountPercent`, {
                    required: 'Required field.',
                    onChange: (e) => {
                      // const temp = e.target.value.replace(/[^0-9]/gm, '');

                      // 소수점 허용, 숫자와 소수점 이외의 문자는 필터링
                      const temp = e.target.value.replace(/[^0-9.]/g, '');

                      // 두 개 이상의 소수점 방지
                      if ((temp.match(/\./g) || []).length > 1) {
                        e.target.value = temp.slice(0, temp.lastIndexOf('.'));
                      } else {
                        e.target.value = temp;
                      }
                      
                      setValue(
                        `options.${0}.discountAppliedProductPrice`,
                        Math.floor(
                          (getValues(`options.${0}.price`) * (100 - parseFloat(temp || '0'))) /
                            100 /
                            10
                        ) * 10
                      );
                    },
                    pattern: {
                      value: /^\d{0,2}(\.\d{0,2})?$/, // 소수점 두 자리까지 허용
                      message: '0~99.99 사이 값만 허용',
                    },
                  })}
                  style={{ width: '380px' }}
                  placeholder="0~99.99"
                  rightText='%'
                  // type='number'
                />
              </S_BasicInfoWrapper>
            </S_BasicInfoRowWrapper>
            <S_BasicInfoRowWrapper>
              <S_BasicInfoWrapper>
                <S_Label style={{ color: '#888888' }}>할인 판매가</S_Label>
                <RegisterInput
                  isDisabled={true}
                  register={register(
                    `options.${0}.discountAppliedProductPrice`
                  )}
                  style={{ width: '380px' }}
                  rightText='원'
                />
              </S_BasicInfoWrapper>
            </S_BasicInfoRowWrapper>
            <S_BasicInfoRowWrapper style={{ marginTop: '12px' }}>
              <S_BasicInfoWrapper>
                <S_Label>카테고리</S_Label>
                <S_CategoryWrapper>
                  <CategoryDropdown
                    list={categoryList}
                    selectedItem={selectedCategoryMainItem}
                    isOpen={isCategoryMainDropdownOpen}
                    setIsOpen={setIsCategoryMainDropdownOpen}
                    onClick={(item: I_Category) =>
                      onCategoryMainItemClick(item)
                    }
                    register={register('mainCategory', {
                      validate: {
                        checkCategory: (id) => {
                          return id === 0 ? 'Required field.' : true;
                        },
                      },
                    })}
                    isError={errors?.mainCategory ? true : false}
                    style={{ width: '200px' }}
                  />
                  <CategoryDropdown
                    list={
                      categoryList?.find(
                        (item) => item.id === getValues('mainCategory')
                      )?.childs
                    }
                    selectedItem={selectedCategoryMidItem}
                    isOpen={isCategoryMidDropdownOpen}
                    setIsOpen={setIsCategoryMidDropdownOpen}
                    onClick={(item: I_Category) => onCategoryMidItemClick(item)}
                    register={register('middleCategory', {
                      validate: {
                        checkCategory: (id) => {
                          return id === 0 ? 'Required field.' : true;
                        },
                      },
                    })}
                    isError={errors?.middleCategory ? true : false}
                    style={{ width: '200px' }}
                  />
                  <CategoryDropdown
                    list={
                      categoryList
                        ?.find((item) => item.id === getValues('mainCategory'))
                        ?.childs?.find(
                          (child) => child.id === getValues('middleCategory')
                        )?.childs
                    }
                    selectedItem={selectedCategorySubItem}
                    isOpen={isCategorySubDropdownOpen}
                    setIsOpen={setIsCategorySubDropdownOpen}
                    onClick={(item: I_Category) => onCategorySubItemClick(item)}
                    style={{ width: '200px' }}
                  />
                </S_CategoryWrapper>
              </S_BasicInfoWrapper>
            </S_BasicInfoRowWrapper>
          </S_BasicInfoContainer>
        </S_TopContainer>
        <S_MidContainer>
          <S_BasicInfoTitle>추가 정보</S_BasicInfoTitle>
          <S_AddInfoContainer>
            {optionFields
              .slice(1, optionFields.length)
              .map((optionField, optionFieldIdx) => (
                <S_FieldContainer key={optionFieldIdx}>
                  <S_BasicInfoRowWrapper style={{ position: 'relative' }}>
                    <S_BasicInfoWrapper>
                      <S_Label style={{ color: '#13A872' }}>
                        추가 옵션{optionFieldIdx + 1}
                      </S_Label>
                      <RegisterInput
                        isError={
                          errors?.options?.[optionFieldIdx + 1]?.option
                            ? true
                            : false
                        }
                        register={register(
                          `options.${optionFieldIdx + 1}.option`,
                          {
                            required: 'Required field.',
                          }
                        )}
                        style={{ width: '380px' }}
                      />
                    </S_BasicInfoWrapper>
                    <S_BasicInfoWrapper>
                      <S_Label>포인트 적용률</S_Label>
                      <RegisterInput
                        isError={
                          errors?.options?.[optionFieldIdx + 1]
                            ?.limitPointPercent
                            ? true
                            : false
                        }
                        register={register(
                          `options.${optionFieldIdx + 1}.limitPointPercent`,
                          {
                            required: 'Required field.',
                            onChange: (e) => {
                              e.target.value = e.target.value.replace(
                                /[^0-9]/gm,
                                ''
                              );
                            },
                            // pattern: {
                            //   value: /^[0-9]{0,2}$/,
                            //   message: '0~99',
                            // },
                            pattern: {
                              value: /^(100|[0-9]{1,2})$/,
                              message: '0~100',
                            },
                          }
                        )}
                        placeholder='0~99'
                        style={{ width: '380px' }}
                        rightText='%'
                      />
                    </S_BasicInfoWrapper>
                    <S_RemoveButton
                      onClick={() => onRemoveClick(optionFieldIdx + 1)}
                    >
                      <Delete />
                    </S_RemoveButton>
                  </S_BasicInfoRowWrapper>
                  <S_BasicInfoRowWrapper>
                    <S_BasicInfoWrapper>
                      <S_Label>상품 가격</S_Label>
                      <RegisterInput
                        isError={
                          errors?.options?.[optionFieldIdx + 1]?.price
                            ? true
                            : false
                        }
                        register={register(
                          `options.${optionFieldIdx + 1}.price`,
                          {
                            required: 'Required field.',
                            onChange: (e) => {
                              const temp = e.target.value.replace(
                                /[^0-9]/gm,
                                ''
                              );
                              e.target.value = temp;
                              setValue(
                                `options.${
                                  optionFieldIdx + 1
                                }.discountAppliedProductPrice`,
                                Math.floor(
                                  (temp *
                                    (100 -
                                      getValues(
                                        `options.${
                                          optionFieldIdx + 1
                                        }.discountPercent`
                                      ))) /
                                    100 /
                                    10
                                ) * 10
                              );
                            },
                          }
                        )}
                        style={{ width: '380px' }}
                        rightText='원'
                      />
                    </S_BasicInfoWrapper>
                    <S_BasicInfoWrapper>
                      <S_Label>상품 할인율</S_Label>
                      <RegisterInput
                        isError={
                          errors?.options?.[optionFieldIdx + 1]?.discountPercent
                            ? true
                            : false
                        }
                        register={register(
                          `options.${optionFieldIdx + 1}.discountPercent`,
                          {
                            required: 'Required field.',
                            onChange: (e) => {
                              // 소수점 허용, 숫자와 소수점 이외의 문자는 필터링
                              const temp = e.target.value.replace(/[^0-9.]/g, '');

                              // 두 개 이상의 소수점 방지
                              if ((temp.match(/\./g) || []).length > 1) {
                                e.target.value = temp.slice(0, temp.lastIndexOf('.'));
                              } else {
                                e.target.value = temp;
                              }

                              // discountAppliedProductPrice 값 설정
                              setValue(
                                `options.${optionFieldIdx + 1}.discountAppliedProductPrice`,
                                Math.floor(
                                  (getValues(`options.${optionFieldIdx + 1}.price`) *
                                    (100 - parseFloat(temp || '0'))) /
                                    100 /
                                    10
                                ) * 10
                              );
                            },
                            pattern: {
                              value: /^\d{0,2}(\.\d{0,2})?$/, // 소수점 두 자리까지 허용
                              message: '0~99.99 사이 값만 허용',
                            },
                          }
                        )}
                        style={{ width: '380px' }}
                        placeholder="0~99.99"
                        rightText="%"
                        // type='number'
                      />
                    </S_BasicInfoWrapper>
                  </S_BasicInfoRowWrapper>
                  <S_BasicInfoRowWrapper>
                    <S_BasicInfoWrapper>
                      <S_Label style={{ color: '#888888' }}>
                        할인 판매가
                      </S_Label>
                      <RegisterInput
                        isDisabled={true}
                        register={register(
                          `options.${
                            optionFieldIdx + 1
                          }.discountAppliedProductPrice`
                        )}
                        style={{ width: '380px' }}
                        rightText='원'
                      />
                    </S_BasicInfoWrapper>
                  </S_BasicInfoRowWrapper>
                </S_FieldContainer>
              ))}
          </S_AddInfoContainer>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <MiniButton onClick={onAddClick} type='button'>
              옵션 추가
            </MiniButton>
          </div>
        </S_MidContainer>
        <S_BottomContainer>
          <S_ImageTitle>상품 사진 첨부</S_ImageTitle>
          <S_ImageContainer>
            <div>
              <S_BasicInfoRowWrapper>
                <S_BasicInfoWrapper>
                  <S_Label>대표 이미지</S_Label>
                  <S_InputContainer
                    isDisabled={true}
                    isError={errors?.tFiles ? true : false}
                  >
                    <input
                      type='file'
                      id='tFiles'
                      accept='.jpeg,.jpg,.png,.bmp'
                      multiple
                      style={{ display: 'none' }}
                      {...register('tFiles', {
                        validate: {
                          checkLength: () => {
                            const { tFiles } = getValues();
                            return tFiles.length > 0 ? true : 'Required field.';
                          },
                        },
                      })}
                    />
                    <S_ImageList>
                      {Array.from(getValues('tFiles')).map((f) => (
                        <span key={f.name}>{f.name}</span>
                      ))}
                    </S_ImageList>
                  </S_InputContainer>
                  <S_AddImage htmlFor='tFiles'>사진 추가</S_AddImage>
                </S_BasicInfoWrapper>
              </S_BasicInfoRowWrapper>
              <S_BasicInfoRowWrapper>
                <S_BasicInfoWrapper>
                  <S_Label />
                  <S_ImageDesc>
                    *최대 5개 이미지 첨부 가능
                    <br />
                    *가로 길이 x 세로 길이(px) : 860 x 5000(px)
                    <br />
                    *제한 용량 : 20MB
                    <br />
                    *지원 양식 : JPG/JPEG/PNG
                  </S_ImageDesc>
                </S_BasicInfoWrapper>
              </S_BasicInfoRowWrapper>
            </div>
            <div>
              <S_BasicInfoRowWrapper>
                <S_BasicInfoWrapper>
                  <S_Label>상세 이미지</S_Label>
                  <S_InputContainer
                    isDisabled={true}
                    isError={errors?.mFiles ? true : false}
                  >
                    <input
                      type='file'
                      id='mFiles'
                      accept='.jpeg,.jpg,.png,.bmp'
                      multiple
                      style={{ display: 'none' }} {
                        ...register('mFiles', {
                        validate: {
                          checkLength: () => {
                            const { mFiles } = getValues();
                            console.log('mFiles : ', mFiles)
                            console.log('getValues() : ', getValues())
                            return mFiles.length > 0 ? true : 'Required field.';
                          },
                        },
                      })
                    }
                    />
                    <S_ImageList>
                      {Array.from(getValues('mFiles')).map((f) => (
                        <span key={f.name}>{f.name}</span>
                      ))}
                    </S_ImageList>
                    
                  </S_InputContainer>
                  <S_AddImage htmlFor='mFiles'>사진 추가</S_AddImage>
                </S_BasicInfoWrapper>

                <FilesContainer>
                  {isUploadedFiles && 
                    isUploadedFiles.map((file, index) => {
                      // console.log('file : ', file, 'index : ', index)
                      const [key, value] = Object.entries(file)[0]; 
                      // console.log('key : ', key, 'value : ', value)

                      return (
                        <FileWrapper key={key}>
                          <p>{key} 이미지</p>
                          <img src={value as string} width="200" alt={`Uploaded file ${key}`} />
                        </FileWrapper>
                      );
                    })
                  }
                </FilesContainer>
               
              </S_BasicInfoRowWrapper>
              <S_BasicInfoRowWrapper>
                <S_BasicInfoWrapper>
                  <S_Label />
                  <S_ImageDesc>
                    *최대 3개 이미지 첨부 가능
                    <br />
                    *가로 길이 x 세로 길이(px) : 860 x 5000(px)
                    <br />
                    *제한 용량 : 20MB
                    <br />
                    *지원 양식 : JPG/JPEG/PNG
                  </S_ImageDesc>
                </S_BasicInfoWrapper>
              </S_BasicInfoRowWrapper>
            </div>
          </S_ImageContainer>
        </S_BottomContainer>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <LongButton>{isLoading ? <Spinner /> : '등록하기'}</LongButton>
        </div>
      </form>
    </MainLayout>
  );
}

export default ProductRegistration;

const S_RemoveButton = styled.div`
  position: absolute;
  right: -40px;
  width: 24px;
  height: 24px;
  cursor: pointer;
`;

const S_ImageDesc = styled.span`
  margin-top: 8px;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  color: ${(props) => props.theme.colors.hd2};
`;

const S_ImageTitle = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.02em;
`;

const S_ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin-top: 16px;
`;

const S_BottomContainer = styled.div`
  padding: 24px 0 64px;
`;

const S_FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const S_AddInfoContainer = styled.div`
  margin: 16px 0 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const S_MidContainer = styled.div`
  padding: 24px 0 32px;
  border-bottom: 1px solid ${(props) => props.theme.colors.hd4};
`;

const S_CategoryWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const S_AddImage = styled.label`
  width: 111px;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.colors.ho1};
  border-radius: 8px;
  font-weight: 700;
  font-size: 13px;
  letter-spacing: -0.02em;
  color: ${(props) => props.theme.colors.hd5};
  margin-left: 23px;
  cursor: pointer;
  &:hover {
    opacity: 0.5;
  }
`;

const S_ImageList = styled.div`
  font-weight: 400;
  font-size: 13px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  span {
    width: 346px;
    line-height: 18px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const S_InputContainer = styled.div<{
  isDisabled?: boolean;
  isError?: boolean;
}>`
  width: 380px;
  min-height: 46px;
  padding: 14px 16px;
  background: ${(props) =>
    props.isDisabled ? props.theme.colors.hd4 : props.theme.colors.hd5};
  border: 1px solid
    ${(props) =>
      props.isError ? props.theme.colors.ho6 : props.theme.colors.hd3};
  border-radius: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
`;

const S_Label = styled.div`
  width: 100px;
  flex-shrink: 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.02em;
`;

const S_BasicInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const S_BasicInfoRowWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 45px;
  width: 100%;
`;

const S_BasicInfoTitle = styled.span`
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.02em;
`;

const S_BasicInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 16px;
`;

const S_TopContainer = styled.div`
  padding-bottom: 32px;
  border-bottom: 1px solid ${(props) => props.theme.colors.hd4};
`;


const FilesContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 200px;
`;

const FileWrapper = styled.div`
  margin-right: 10px;
`;
