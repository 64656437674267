import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import MainLayout from 'components/Layout/MainLayout';
import LongButton from 'components/Button/LongButton';
import Input from 'components/Input/Input';
import DateInput from 'components/Input/DateInput';
import Dropdown from 'components/Dropdown/Dropdown';

import { getBrandList, postCoupon } from 'api/coupon';
import useLogout from 'hooks/useLogout';
import { I_BrandProduct, I_CouponForm } from 'etc/types';

interface I_FilteredProduct {
  id: number;
  name: string;
  thumbnail?: {
    id: number;
    createdAt: string;
    key: string;
    updateAt: string;
    url: string;
  };
}

function RegisterCoupon() {
  const navigate = useNavigate();
  const Logout = useLogout();

  const [title, setTitle] = useState<string>('');
  const [discountRate, setDiscountRate] = useState<string>('');
  const [minimumAmount, setMinimumAmount] = useState<string>('');
  const [maximumDiscountAmount, setMaximumDiscountAmount] =
    useState<string>('');
  const [isUserDropdownOpen, setIsUserDropdownOpen] = useState<boolean>(false);
  const [isBrandDropdownOpen, setIsBrandDropdownOpen] =
    useState<boolean>(false);
  const [isProductDropdownOpen, setIsProductDropdownOpen] =
    useState<boolean>(false);
  const [selectedUserItem, setSelectedUserItem] = useState<string>(
    USER_LIST[0]
  );
  const [selectedBrandItem, setSelectedBrandItem] = useState<string>('전체');
  const [selectedProductItem, setSelectedProductItem] =
    useState<string>('전체');
  const [fromDate, setFromDate] = useState<string>('');
  const [toDate, setToDate] = useState<string>('');
  const [brandProductList, setBrandProductList] = useState<I_BrandProduct[]>(
    []
  );
  const [brandList, setBrandList] = useState<string[]>([]);
  const [productList, setProductList] = useState<any>([
    { id: 0, name: '전체' },
  ]);

  const onUserItemClick = (item: string) => {
    setSelectedUserItem(item);
    setIsUserDropdownOpen(false);
  };

  const onBrandItemClick = (item: string) => {
    setSelectedBrandItem(item);
    setIsBrandDropdownOpen(false);

    const filteredProducts: I_FilteredProduct[] = brandProductList
      .filter((brand) => brand.name === item)
      .map((product) => product.products)[0];

    if (
      !filteredProducts.some((product: I_FilteredProduct) => product.id === 0)
    ) {
      filteredProducts.unshift({ id: 0, name: '전체' });
    }

    setProductList(filteredProducts);
    setSelectedProductItem('전체');
  };

  const onProductItemClick = (item: string) => {
    setSelectedProductItem(item);
    setIsProductDropdownOpen(false);
  };

  const onClickPost = async () => {
    const brandId: any[] = brandList.filter((item: any) => {
      item.name === selectedBrandItem;
      // console.log(item);
    });

    const productId: any = productList.filter(
      (item: any) => item.name === selectedProductItem
    );

    const body: I_CouponForm = {
      brand: brandId[0]?.id === 0 ? null : brandId[0]?.id,
      product: !productId ? 0 : productId[0]?.id,
      name: title,
      membership: selectedUserItem === '전체' ? false : true,
      minBuyPrice: Number(minimumAmount),
      maxDiscountPrice: Number(maximumDiscountAmount),
      discountPercent: Number(discountRate),
      startDate: fromDate,
      endDate: toDate,
    };

    if (!title) {
      alert('쿠폰 이름을 입력해주세요.');
    } else if (!discountRate) {
      alert('할인율을 입력해주세요.');
    } else if (!minimumAmount) {
      alert('최소 사용 금액을 입력해주세요.');
    } else if (!maximumDiscountAmount) {
      alert('최대 할인 금액을 입력해주세요.');
    } else if (!fromDate || !toDate) {
      alert('쿠폰 사용 기간을 입력해주세요.');
    } else {
      const data = await postCoupon(body);

      if (data.ok) {
        alert('쿠폰이 등록되었습니다.');
        navigate('/store/coupon');
      } else {
        if (data.res.status === 315) Logout();
        else {
          alert('잠시 후 다시 시도해주세요.');
          console.error(data.res.error.message);
        }
      }
    }
  };

  const getAsyncBrandList = async () => {
    const data = await getBrandList();
    if (data.ok) {
      // console.log(data.res.item.brandProducts);
      setBrandProductList(data.res.item.brandProducts);
      const brand = data.res.item.brandProducts;
      brand.unshift({ id: 0, name: '전체', products: [] });
      setBrandList(brand);
    } else {
      if (data.res.status === 315) Logout();
      else {
        alert('잠시 후 다시 시도해주세요.');
        console.error(data.res.error.message);
      }
    }
  };

  useEffect(() => {
    getAsyncBrandList();
  }, []);

  return (
    <MainLayout>
      <S_Section>
        <S_Title>쿠폰 상세 기입</S_Title>
        <S_Table>
          <tbody>
            <tr>
              <th>쿠폰 이름</th>
              <td colSpan={3}>
                <Input state={title} setState={setTitle} />
              </td>
            </tr>
            <tr>
              <th>할인율</th>
              <td>
                <Input
                  state={discountRate}
                  setState={setDiscountRate}
                  type='number'
                  rightText='%'
                />
              </td>
              <th>적용 유저</th>
              <td>
                <Dropdown
                  list={USER_LIST}
                  selectedItem={selectedUserItem}
                  isOpen={isUserDropdownOpen}
                  setIsOpen={setIsUserDropdownOpen}
                  onClick={(item: string) => onUserItemClick(item)}
                  // style={{ width: '100%', height: 46 }}
                />
              </td>
            </tr>
            <tr>
              <th>할인 브랜드</th>
              <td>
                <Dropdown
                  list={brandList.map((item: any) => item.name)}
                  selectedItem={selectedBrandItem}
                  isOpen={isBrandDropdownOpen}
                  setIsOpen={setIsBrandDropdownOpen}
                  onClick={(item: string) => onBrandItemClick(item)}
                  style={{ width: 200 }}
                />
              </td>
              <th>할인 상품</th>
              <td>
                <Dropdown
                  list={productList.map((item: any) => item.name)}
                  selectedItem={selectedProductItem}
                  isOpen={isProductDropdownOpen}
                  setIsOpen={setIsProductDropdownOpen}
                  onClick={(item: string) => onProductItemClick(item)}
                  style={{ width: 200 }}
                />
              </td>
            </tr>
          </tbody>
        </S_Table>
      </S_Section>

      <S_Section style={{ borderBottom: 0 }}>
        <S_Table>
          <tbody>
            <tr>
              <th>최소 사용 금액</th>
              <td>
                <Input
                  state={minimumAmount}
                  setState={setMinimumAmount}
                  rightText='원'
                  type='number'
                  value={minimumAmount}
                />
              </td>
              <th>최대 할인 금액</th>
              <td>
                <Input
                  state={maximumDiscountAmount}
                  setState={setMaximumDiscountAmount}
                  rightText='원'
                  type='number'
                />
              </td>
            </tr>
            <tr>
              <th>쿠폰 사용 기간</th>
              <td colSpan={3}>
                <S_DateBox>
                  <DateInput
                    state={fromDate}
                    setState={setFromDate}
                    type='datetime-local'
                    max={toDate}
                  />
                  ~
                  <DateInput
                    state={toDate}
                    setState={setToDate}
                    type='datetime-local'
                    min={fromDate}
                  />
                </S_DateBox>
              </td>
            </tr>
          </tbody>
        </S_Table>
      </S_Section>
      <S_ButtonBox>
        <LongButton onClick={() => onClickPost()}>쿠폰 생성하기</LongButton>
      </S_ButtonBox>
    </MainLayout>
  );
}

export default RegisterCoupon;

const S_DateBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  input {
    width: 45%;
    height: 46px;
    border-radius: 12px;
  }
`;

const S_Table = styled.table`
  border-collapse: separate;
  border-spacing: 20px;

  td {
    width: 40%;

    /* li {
      padding: 16.5px 16px;
    } */
  }
  th {
    min-width: 100px;
    text-align: left;
    font-size: 14px;
    font-weight: 500;
    &:nth-child(1) {
      width: 100px;
    }
    &:nth-child(3) {
      width: 100px;
      text-align: right;
      padding-right: 15px;
    }
    &.alignTop {
      vertical-align: top;
      padding-top: 14px;
    }
  }
`;

const S_ButtonBox = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 50px;
  padding-right: 20px;
`;

const S_Title = styled.p`
  font-size: 16px;
  color: ${(props) => props.theme.colors.hd1};
  font-weight: 700;
`;

const S_Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 17px;
  padding: 30px 20px;
  border-bottom: 1px solid ${(props) => props.theme.colors.hd4};

  &:last-child {
    border-bottom: 0;
  }
`;

const USER_LIST = ['전체', '유료'];
