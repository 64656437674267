import styled from 'styled-components';

import { I_Point } from 'etc/types';
import { numberFormat } from 'utils/common';

interface I_PointTableItem {
  item: I_Point;
  onClick: () => void;
}

function PointTableItem({ item, onClick }: I_PointTableItem) {
  return (
    <S_TableRow>
      <S_TableColData
        style={{
          textDecoration: item.user ? 'underline' : 'none',
          cursor: item.user ? 'pointer' : 'default',
        }}
        onClick={onClick}
      >
        {item.user?.userCode ? (
          item.user?.userCode
        ) : (
          <S_Withdraw>탈퇴유저</S_Withdraw>
        )}
      </S_TableColData>
      <S_TableColData>
        {item.user?.profile?.nickname
          ? item.user?.profile.nickname
          : '프로필 없음'}
      </S_TableColData>
      <S_TableColData>
        {item.point == 0 ? 0 : `+ ${numberFormat(item.point)}`}
      </S_TableColData>
      <S_TableColData>
        {item.usedPoint == 0 ? 0 : `- ${numberFormat(item.usedPoint)}`}
      </S_TableColData>
    </S_TableRow>
  );
}

export default PointTableItem;

const S_Withdraw = styled.span`
  color: ${(props) => props.theme.colors.ho6};
`;

const S_TableColData = styled.td`
  text-align: center;
  vertical-align: middle;
  border: 1px solid ${(props) => props.theme.colors.hd3};
  padding: 10px;
`;

const S_TableRow = styled.tr`
  height: 36px;
  font-weight: 500;
  font-size: 13px;
`;
