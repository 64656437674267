import styled from 'styled-components';

interface I_DisabledInput {
  text: string | number | undefined | null;
  inlineText?: string;
  style?: React.CSSProperties;
}

function DisabledInput({ text, inlineText, style }: I_DisabledInput) {
  return (
    <S_Container style={style}>
      <S_Input>{text}</S_Input>
      {inlineText && <S_InlineText>{inlineText}</S_InlineText>}
    </S_Container>
  );
}

export default DisabledInput;

const S_InlineText = styled.span`
  font-weight: 700;
  font-size: 14px;
  color: ${(props) => props.theme.colors.hd2};
`;

const S_Input = styled.div`
  width: 100%;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.02em;
  color: inherit;
  cursor: inherit;
  &::placeholder {
    color: ${(props) => props.theme.colors.hd2};
  }
`;

const S_Container = styled.div`
  width: 100%;
  height: 48px;
  padding: 14px 16px;
  background: ${(props) => props.theme.colors.hd4};
  border: 1px solid ${(props) => props.theme.colors.hd3};
  border-radius: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
