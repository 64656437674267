import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import styled from 'styled-components';

import Logo from 'assets/image/logo.png';

import useLogout from 'hooks/useLogout';

function Header() {
  const navigate = useNavigate();
  const Logout = useLogout();

  return (
    <S_Container>
      <S_LogoWeb src={Logo} onClick={() => navigate('/')} />
      <S_LogoutButton onClick={Logout}>로그아웃</S_LogoutButton>
    </S_Container>
  );
}

export default Header;

const S_LogoutButton = styled.div`
  width: 80px;
  height: 37px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.colors.ho1};
  border-radius: 47px;
  font-weight: 600;
  font-size: 14px;
  color: ${(props) => props.theme.colors.hd5};
  cursor: pointer;
  &:hover {
    opacity: 0.5;
  }
`;

const S_LogoWeb = styled.img`
  max-width: 170px;
  cursor: pointer;
`;

const S_Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px;
  width: 100%;
  height: 53px;
  background: ${(props) => props.theme.colors.hd5};
  position: fixed;
  z-index: 10;
  border-bottom: 2px solid ${(props) => props.theme.colors.ho1};
`;
