import styled from 'styled-components';

import { I_RefundLogProduct } from 'etc/types';
import { numberFormat } from 'utils/common';
import MiniButton from 'components/Button/MiniButton';

interface I_StoreRequestModalTableItem2 {
  idx: number;
  item: I_RefundLogProduct;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setIndex: React.Dispatch<React.SetStateAction<number>>;
}

function StoreRequestModalTableItem2({
  idx,
  item,
  setIsModalOpen,
  setIndex,
}: I_StoreRequestModalTableItem2) {
  return (
    <S_TableRow>
      <S_TableColData>
        {item.delayReason ? (
          <S_DisabledButton>완료</S_DisabledButton>
        ) : (
          <MiniButton
            onClick={() => {
              setIndex(idx);
              setIsModalOpen(true);
            }}
            style={{ width: '40px' }}
          >
            거부
          </MiniButton>
        )}
      </S_TableColData>
      <S_TableColData>{item.id}</S_TableColData>
      <S_TableColData style={{ minWidth: '279px' }}>
        <span style={{ fontWeight: '700' }}>{item.brandName}</span>{' '}
        {item.productName}
      </S_TableColData>
      <S_TableColData>{item.productOptionName}</S_TableColData>
      <S_TableColData>{item.productCount}</S_TableColData>
      <S_TableColData>
        {item.productPaymentPrice
          ? `${numberFormat(item.productPaymentPrice)}원`
          : '-'}
      </S_TableColData>
      <S_TableColData>
        {item.productPrice ? `${numberFormat(item.productPrice)}원` : '-'}
      </S_TableColData>
      <S_TableColData>
        {item.productPointDiscount
          ? `-${numberFormat(item.productPointDiscount)}원`
          : '-'}
      </S_TableColData>
      <S_TableColData>
        {item.productCouponDiscount
          ? `-${numberFormat(item.productCouponDiscount)}원`
          : '-'}
      </S_TableColData>
      <S_TableColData>{item.productId}</S_TableColData>
    </S_TableRow>
  );
}

export default StoreRequestModalTableItem2;

const S_DisabledButton = styled.div`
  width: 40px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.colors.hd4};
  border-radius: 8px;
  font-weight: 700;
  font-size: 11px;
  letter-spacing: -0.02em;
  color: ${(props) => props.theme.colors.hd5};
`;

const S_TableColData = styled.td`
  text-align: center;
  vertical-align: middle;
  border: 1px solid ${(props) => props.theme.colors.hd3};
  padding: 10px;
`;

const S_TableRow = styled.tr`
  height: 36px;
  font-weight: 500;
  font-size: 13px;
`;
