import { useEffect, useState } from 'react';

import styled from 'styled-components';
import { S_ButtonWrapper } from './StoreProduct';

import MainLayout from 'components/Layout/MainLayout';
import Dropdown from 'components/Dropdown/Dropdown';
import TableInput from 'components/Input/TableInput';
import PagenationButton from 'components/Button/PagenationButton';
import DateInput from 'components/Input/DateInput';
import StoreOrderTableItem from 'components/Item/StoreOrderTableItem';
import { I_Order } from 'etc/types';
import TableButton from 'components/Button/TableButton';
import useLogout from 'hooks/useLogout';

import { getOrderList } from 'api/order';

function StoreOrder() {
  const Logout = useLogout();
  const [orderList, setOrderList] = useState<I_Order[]>([]); // 상품
  const [totalCount, setTotalCount] = useState<number>(0); // 전체 개수
  const [activePage, setActivePage] = useState<number>(1); // 현재 페이지
  // 드롭다운
  const [isFilterDropdownOpen, setIsFilterDropdownOpen] =
    useState<boolean>(false);
  const [selectedFilterItem, setSelectedFilterItem] = useState<string>(
    DETAIL_SEARCH[0]
  );
  const onFilterItemClick = (item: string) => {
    setSelectedFilterItem(item);
    setIsFilterDropdownOpen(false);
  };
  const [InputValue, setInputValue] = useState<string>('');
  const [fromDate, setFromDate] = useState<string>('');
  const [toDate, setToDate] = useState<string>('');

  // 검색 초기화
  const handleResetClick = () => {
    setSelectedFilterItem(DETAIL_SEARCH[0]);
    setInputValue('');
    setFromDate('');
    setToDate('');
  };

  // 주문 table pagenation
  const handlePageChange = (page: number) => {
    setActivePage(page);
  };

  // 주문 목록
  const getAsyncOrderList = async () => {
    let search;
    if (InputValue) {
      search =
        selectedFilterItem === DETAIL_SEARCH[0]
          ? `&billLogProductId=${InputValue}`
          : selectedFilterItem === DETAIL_SEARCH[1]
          ? `&billLogId=${InputValue}`
          : selectedFilterItem === DETAIL_SEARCH[2]
          ? `&productName=${InputValue}`
          : `&userId=${InputValue.replace('#', '%23')}`;
    } else search = '';
    const data = await getOrderList(activePage, 10, search, fromDate, toDate);
    if (data.ok) {
      setTotalCount(data.res.totalCount);
      setOrderList(data.res.item);
    } else {
      if (data.res.status === 315) Logout();
      else if (data.res.status === 400)
        alert('번호나 코드는 숫자만 입력해주세요.');
      else console.error(data.res.error.message);
    }
  };

  useEffect(() => {
    getAsyncOrderList();
  }, [activePage]);

  return (
    <MainLayout>
      {/* 카테고리 */}
      <S_CategoryContainer>
        <S_CategoryTitle>상세 검색</S_CategoryTitle>
        <S_CategoryFilterContainer>
          <S_CategoryFilterRow>
            <S_CategoryFilterTitle>주문번호 검색</S_CategoryFilterTitle>
            <S_CategoryFilterWrapper>
              <Dropdown
                list={DETAIL_SEARCH}
                selectedItem={selectedFilterItem}
                isOpen={isFilterDropdownOpen}
                setIsOpen={setIsFilterDropdownOpen}
                onClick={(item: string) => onFilterItemClick(item)}
              />
              <TableInput
                state={InputValue}
                setState={setInputValue}
                style={{ width: '235px' }}
              />
            </S_CategoryFilterWrapper>
          </S_CategoryFilterRow>
          <S_CategoryFilterRow>
            <S_CategoryFilterTitle>기간</S_CategoryFilterTitle>
            <S_CategoryFilterWrapper>
              <div>
                <DateInput
                  state={fromDate}
                  setState={setFromDate}
                  max={toDate}
                />
                <span> ~ </span>
                <DateInput
                  state={toDate}
                  setState={setToDate}
                  startDate={fromDate}
                  min={fromDate}
                />
              </div>
            </S_CategoryFilterWrapper>
          </S_CategoryFilterRow>
        </S_CategoryFilterContainer>
        <S_ButtonWrapper>
          <TableButton onClick={handleResetClick}>검색 초기화</TableButton>
          <TableButton onClick={getAsyncOrderList}>조회하기</TableButton>
        </S_ButtonWrapper>
      </S_CategoryContainer>

      {/* 상품 목록 */}
      <S_TableButtonContainer>
        <S_TableTitle>{'상품 목록(' + totalCount + ')'}</S_TableTitle>
      </S_TableButtonContainer>

      <S_TableContainer>
        <S_TableBox>
          <S_Table>
            <thead>
              <tr>
                {TABLE_HEADER.map((v, i) => (
                  <S_TableHeaderItem key={i}>{v}</S_TableHeaderItem>
                ))}
              </tr>
            </thead>
            <tbody>
              {orderList.map((v: any, i: number) => (
                <StoreOrderTableItem key={i} item={v} />
              ))}
            </tbody>
          </S_Table>
        </S_TableBox>

        <PagenationButton
          activePage={activePage}
          totalCount={totalCount}
          postPerPage={10}
          handlePageChange={handlePageChange}
        />
      </S_TableContainer>
    </MainLayout>
  );
}

export default StoreOrder;

const S_TableHeaderItem = styled.th`
  vertical-align: middle;
  padding: 10px;
  height: 36px;
  background-color: ${(props) => props.theme.colors.hd4};
  border: 0.5px solid ${(props) => props.theme.colors.hd3};
  font-weight: 500;
  font-size: 13px;
  letter-spacing: -0.02em;
  color: ${(props) => props.theme.colors.n10};
`;

const S_Table = styled.table`
  width: 100%;
`;

const S_TableBox = styled.div`
  overflow-x: overlay;
  &::-webkit-scrollbar {
    width: 4px;
    height: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.theme.colors.hd3};
    border-radius: 6px;
  }
`;

const S_TableContainer = styled.div`
  margin-top: 8px;
  position: relative;
`;

const S_TableTitle = styled.span`
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.02em;
  display: flex;
  align-items: flex-end;
`;

const S_TableButtonContainer = styled.div`
  margin-top: 24px;
  display: flex;
  justify-content: space-between;
`;

const S_CategoryFilterWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const S_CategoryFilterTitle = styled.span`
  width: 90px;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  letter-spacing: -0.02em;
`;

const S_CategoryFilterRow = styled.div`
  display: flex;
  align-items: center;
  gap: 48px;
`;

const S_CategoryFilterContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 14px 16px;
  border: 1px solid ${(props) => props.theme.colors.hd3};
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  letter-spacing: -0.02em;
  /* border-radius: 8px; */
`;

const S_CategoryTitle = styled.span`
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.02em;
`;

const S_CategoryContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const TABLE_HEADER = [
  '상품 주문 번호',
  '주문 번호',
  '주문 일시',
  '주문 상품',
  '주문 상태',
  '상태 처리 일시',
  '구매자명',
  '구매자 연락처',
  'User Code',
];
const DETAIL_SEARCH = ['상품 주문 번호', '주문 번호', '상품명', '유저코드'];
