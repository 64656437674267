import { useEffect, useState } from 'react';

import styled from 'styled-components';

import { ReactComponent as Close } from 'assets/image/close.svg';

import ModalLayout from 'components/Layout/ModalLayout';
import StoreOrderRefundTableItem from 'components/Item/StoreOrderRefundTableItem';
import useLogout from 'hooks/useLogout';
import { I_CancelHistory } from 'etc/types';
import { dateTimeFormat, numberFormat } from 'utils/common';

import { getCancelHistory } from 'api/order';

interface I_CancelHistoryModal {
  onClose: () => void;
  isOpen: boolean;
  refundLogId: number;
}

function CancelHistoryModal({
  onClose,
  isOpen,
  refundLogId,
}: I_CancelHistoryModal) {
  const Logout = useLogout();
  const [history, setHistory] = useState<I_CancelHistory>();

  // 결제 취소 내역
  const getAsyncCancelHistory = async () => {
    const data = await getCancelHistory(refundLogId);
    if (data.ok) {
      setHistory(data.res.item);
    } else {
      if (data.res.status === 315) Logout();
      else console.error(data.res.error.message);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden'; // body scroll 제거
      if (refundLogId) getAsyncCancelHistory();
    }
  }, [isOpen]);

  return (
    <ModalLayout isOpen={isOpen} onClose={onClose}>
      <S_Container>
        <S_CloseButton
          onClick={() => {
            document.body.style.overflow = 'unset'; // body scroll 생성
            onClose();
          }}
        >
          <Close />
        </S_CloseButton>
        <S_ModalTitle>결제 취소 내역</S_ModalTitle>
        <S_ModalContent>
          <S_ModalContentCol>
            <S_Label>주문 취소 상품</S_Label>
            <S_TableBox>
              <S_Table>
                <thead>
                  <tr>
                    {TABLE_HEADER_ORDER.map((v, i) => (
                      <S_TableHeaderItem key={i}>{v}</S_TableHeaderItem>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {history?.cancelPaymentProducts.map((v: any, i: number) => (
                    <StoreOrderRefundTableItem key={i} item={v} />
                  ))}
                </tbody>
              </S_Table>
            </S_TableBox>
          </S_ModalContentCol>
          <S_ModalContentCol>
            <S_Label>환불 정보</S_Label>
            <S_TableBox>
              <S_Table>
                <thead>
                  <tr>
                    {TABLE_HEADER_PAYMENT.map((v, i) => (
                      <S_TableHeaderItem key={i}>{v}</S_TableHeaderItem>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  <S_TableRow>
                    <S_TableColData style={{ width: '274px' }}>
                      {history?.paymentData.paidAt &&
                        dateTimeFormat(history?.paymentData.paidAt)}
                    </S_TableColData>
                    <S_TableColData>
                      {history?.paymentData.paymentMethod === 'card'
                        ? '신용/체크카드'
                        : history?.paymentData.paymentMethod ===
                          'virtualAccount'
                        ? '무통장 입금'
                        : '-'}
                    </S_TableColData>
                  </S_TableRow>
                </tbody>
              </S_Table>
            </S_TableBox>
            <S_TableBox style={{ marginLeft: '274px' }}>
              <S_Table>
                <thead>
                  <tr>
                    {TABLE_HEADER_REFUND.map((v, i) => (
                      <S_TableHeaderItem key={i}>{v}</S_TableHeaderItem>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  <S_TableRow>
                    <S_TableColData style={{ width: '50%' }}>
                      {dateTimeFormat(history?.cancelPaymentData.cancelledAt)}
                    </S_TableColData>
                    <S_TableColData>
                      {numberFormat(
                        history?.cancelPaymentData.cancelPaymentPrice
                      ) + '원'}
                    </S_TableColData>
                  </S_TableRow>
                </tbody>
              </S_Table>
            </S_TableBox>
          </S_ModalContentCol>
        </S_ModalContent>
      </S_Container>
    </ModalLayout>
  );
}

export default CancelHistoryModal;

const S_TableColData = styled.td`
  text-align: center;
  vertical-align: middle;
  border: 1px solid ${(props) => props.theme.colors.hd3};
  padding: 10px;
`;

const S_TableRow = styled.tr`
  height: 36px;
  font-weight: 500;
  font-size: 13px;
`;

const S_TableHeaderItem = styled.th`
  vertical-align: middle;
  padding: 10px;
  height: 36px;
  background-color: ${(props) => props.theme.colors.hd4};
  border: 0.5px solid ${(props) => props.theme.colors.hd3};
  font-weight: 500;
  font-size: 13px;
  letter-spacing: -0.02em;
  color: ${(props) => props.theme.colors.n10};
`;

const S_Table = styled.table`
  width: 100%;

  th:first-child {
    width: 40px;
  }
`;

const S_TableBox = styled.div`
  overflow-x: scroll;
  &::-webkit-scrollbar {
    width: 4px;
    height: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.theme.colors.hd3};
    border-radius: 6px;
  }
`;

const S_Label = styled.span`
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.02em;
`;

const S_ModalContentCol = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const S_ModalContentRow = styled.div`
  display: flex;
  align-items: center;
  gap: 40px;
`;

const S_ModalContent = styled.div`
  margin-top: 52px;
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

const S_ModalTitle = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 35px;
  letter-spacing: -0.02em;
  text-align: center;
`;

const S_CloseButton = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
  :hover {
    opacity: 0.5;
  }
`;

const S_Container = styled.div`
  width: 100%;
  height: 100%;
  background: ${(props) => props.theme.colors.hd5};
  padding: 70px 70px 144px;
  overflow-y: overlay;
  z-index: 10;
`;

const TABLE_HEADER_ORDER = [
  '상품 주문 번호',
  '주문 상태',
  '상품 정보',
  '상세 옵션',
  '주문 수량',
  '최종 결제 금액',
  '상품 판매가',
  '사용 포인트',
  '쿠폰 할인',
  '상품 번호',
];
const TABLE_HEADER_PAYMENT = ['결제 일시', '결제 방식'];
const TABLE_HEADER_REFUND = ['결제 취소 일시', '최종 환불 금액'];
