import { useEffect, useState } from 'react';

import styled from 'styled-components';

import { ReactComponent as Checkbox } from 'assets/image/checkbox.svg';

import MainLayout from 'components/Layout/MainLayout';
import PagenationButton from 'components/Button/PagenationButton';
import TabButton from 'components/Button/TabButton';
import StoreConfirmTableItem from 'components/Item/StoreConfirmTableItem';
import useLogout from 'hooks/useLogout';
import { I_Confirm } from 'etc/types';

import { getConfirmList } from 'api/order';

function StoreConfirm() {
  const Logout = useLogout();
  const [confirmList, setConfirmList] = useState<I_Confirm[]>([]); // 반품
  const [totalCount, setTotalCount] = useState<number>(0); // 전체 개수
  const [activePage, setActivePage] = useState<number>(1); // 현재 페이지
  const [tabIndex, setTabIndex] = useState<number>(TAB[0].id); // tab
  const [tableColumnList, setTableColumnList] = useState<string[]>(
    TABLE_HEADER[0].list
  );

  // tab button 클릭 시
  const onTabClick = (id: number, idx: number) => {
    setTabIndex(id);
    setTableColumnList(TABLE_HEADER[idx].list);
  };

  // table pagenation
  const handlePageChange = (page: number) => {
    setActivePage(page);
  };

  // 목록 api
  const getAsyncConfirmList = async () => {
    const data = await getConfirmList(activePage, 10, tabIndex);
    if (data.ok) {
      setTotalCount(data.res.totalCount);
      setConfirmList(data.res.item);
    } else {
      if (data.res.status === 315) Logout();
      else if (data.res.status === 400)
        alert('번호나 코드는 숫자만 입력해주세요.');
      else console.error(data.res.error.message);
    }
  };

  useEffect(() => {
    getAsyncConfirmList();
  }, [activePage, tabIndex]);

  return (
    <MainLayout>
      {/* 카테고리 */}
      <S_CategoryContainer>
        <S_CategoryTitle>주문 별 현황</S_CategoryTitle>
        <S_CategoryFilterContainer>
          {TAB.map((v, i) => (
            <TabButton
              key={v.id}
              title={v.title}
              isActive={tabIndex === v.id}
              onClick={() => onTabClick(v.id, i)}
            />
          ))}
        </S_CategoryFilterContainer>
      </S_CategoryContainer>

      {/* 상품 목록 */}
      <S_TableButtonContainer>
        <S_TableTitle>상품 목록({totalCount})</S_TableTitle>
      </S_TableButtonContainer>

      <S_TableContainer>
        <S_TableBox>
          <S_Table>
            <thead>
              <tr>
                {tableColumnList.map((v, i) => (
                  <S_TableHeaderItem key={i}>
                    {v === 'check' ? (
                      <Checkbox
                        width='16.67px'
                        height='16.67px'
                        color='#2E2E2E'
                      />
                    ) : (
                      v
                    )}
                  </S_TableHeaderItem>
                ))}
              </tr>
            </thead>
            <tbody>
              {confirmList?.map((v: I_Confirm, i: number) =>
                tabIndex === 8 ? (
                  <StoreConfirmTableItem key={i} item={v} />
                ) : (
                  <tr key={i} />
                )
              )}
            </tbody>
          </S_Table>
        </S_TableBox>

        <PagenationButton
          activePage={activePage}
          totalCount={totalCount}
          postPerPage={10}
          handlePageChange={handlePageChange}
        />
      </S_TableContainer>
    </MainLayout>
  );
}

export default StoreConfirm;

const S_TableHeaderItem = styled.th`
  vertical-align: middle;
  padding: 10px;
  height: 36px;
  background-color: ${(props) => props.theme.colors.hd4};
  border: 0.5px solid ${(props) => props.theme.colors.hd3};
  font-weight: 500;
  font-size: 13px;
  letter-spacing: -0.02em;
  color: ${(props) => props.theme.colors.n10};
`;

const S_Table = styled.table`
  width: 100%;
`;

const S_TableBox = styled.div`
  overflow-x: overlay;
  &::-webkit-scrollbar {
    width: 4px;
    height: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.theme.colors.hd3};
    border-radius: 6px;
  }
`;

const S_TableContainer = styled.div`
  margin-top: 8px;
  position: relative;
`;

const S_TableTitle = styled.span`
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.02em;
  display: flex;
  align-items: flex-end;
`;

const S_TableButtonContainer = styled.div`
  margin-top: 36px;
  display: flex;
  justify-content: space-between;
`;

const S_CategoryFilterContainer = styled.div`
  min-width: 915px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

const S_CategoryTitle = styled.span`
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.02em;
`;

const S_CategoryContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const TAB = [
  {
    id: 8,
    title: '구매 확정',
  },
];

const TABLE_HEADER = [
  {
    id: 8,
    list: [
      '구매 확정 일시',
      '상품 주문 번호',
      '주문 번호',
      '주문 상태',
      '상품 명',
      '주문 일시',
      '구매자명',
      '구매자 연락처',
    ],
  },
];
