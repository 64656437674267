import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import styled from 'styled-components';
import MainLayout from 'components/Layout/MainLayout';
import { 
  getTravelProductOptionPrice, 
  postTravelProductOptionPrice,
  postTravelProductOptionPriceDateUpdate 
} from 'api/travelProduct'; // API 함수들
import {
   I_TravelProductOptionPrice, I_PostTravelProductOptionPrice, I_PostTravelProductOptionPriceDate
  } from 'etc/types'; // 옵션 가격 인터페이스

import RegisterInput from 'components/Input/RegisterInput';
import LongButton from 'components/Button/LongButton';
import Spinner from 'components/Spinner/Spinner';
import DatePicker, { DateObject } from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";

function TravelProductOptionPriceDetail() {
  const { travelProductId, travelProductOptionId } = useParams<{ travelProductId: string, travelProductOptionId: string }>();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isOptionList, setOptionList] = useState<I_TravelProductOptionPrice[]>([]);
  const [isEditMode, setIsEditMode] = useState<number | null>(null); // 수정 모드 상태
  const [selectedDates, setSelectedDates] = useState<DateObject[]>([]);
  const today = new Date(); // 오늘
  today.setHours(0, 0, 0, 0);
  const format = "YYYY-MM-DD";
  
  // 가격을 위한 폼
  const { register: registerPriceForm, handleSubmit: handleSubmitPriceForm, formState: { errors: priceErrors }, reset: resetPriceForm, setValue: setValuePriceForm } = useForm<I_PostTravelProductOptionPrice>({
    defaultValues: {
      price: 0,
      salePercent: 0,
    },
  });

  // 날짜 리스트를 위한 폼
  const { register: registerDateForm, handleSubmit: handleSubmitDateForm, formState: { errors: dateErrors }, reset: resetDateForm,  control: controlDateForm, setValue: setValueDateForm } = useForm<I_PostTravelProductOptionPriceDate>({
    defaultValues: {
      price: 0,
      salePercent: 0,
      dateList: [],
    },
  });

  // 가격 리스트 불러오기
  const getTravelProductOptionPriceApi = async () => {
    setIsLoading(true);
    try {
      const response = await getTravelProductOptionPrice(Number(travelProductId), Number(travelProductOptionId));
      if (response.ok) {
        setOptionList(response.res.list);
      } else {
        console.error('가격 리스트 불러오기 실패');
      }
    } catch (error) {
      console.error('가격 리스트 에러:', error);
    } finally {
      setIsLoading(false);
    }
  };

  // 가격 등록 핸들러
  const handlePriceSubmit = async (data: I_PostTravelProductOptionPrice) => {
    try {
      console.log('handlePriceSubmit data : ', data);
      
      const result = await postTravelProductOptionPrice( Number(travelProductId), Number(travelProductOptionId), data );
      if (result.ok) {
        alert('가격이 등록되었습니다.');
        getTravelProductOptionPriceApi(); // 등록 후 가격 리스트 다시 불러오기
        resetPriceForm(); // 폼 초기화
      } else {
        alert(result.res.error.code + ': ' + result.res.error.message);
      }
    } catch (error) {
      console.error('가격 등록 에러:', error);
    }
  };

  // 수정 모드 핸들러
  const toggleEditMode = (priceId: number) => {
    setIsEditMode(priceId);
    const selectedPrice = isOptionList.find(item => item.id === priceId);
    console.log('selectedPrice: ', selectedPrice)
    if (selectedPrice) {
      setValueDateForm('price', selectedPrice.price);
      setValueDateForm('salePercent', selectedPrice.salePercent);

      // DateObject로 변환해서 DatePicker에 넣을 값 설정
      const formattedDates = selectedPrice.travelProductOptionsPriceDate.map(
        (dateObj) => new DateObject({ date: dateObj.date, format: "YYYY-MM-DD" })
      );
      setSelectedDates(formattedDates);  // DatePicker에 사용할 날짜 값 설정
    }
  };

  // 가격 수정 핸들러
  const handlePriceDateUpdate = async (priceId: number, data: I_PostTravelProductOptionPriceDate) => {

    setIsLoading(true);
    try {

      const result = await postTravelProductOptionPriceDateUpdate( Number(travelProductId), Number(travelProductOptionId), priceId, data );
      if (result.ok) {
        alert('업데이트 됐습니다.');
        getTravelProductOptionPriceApi(); // 등록 후 가격 리스트 다시 불러오기
        resetPriceForm(); // 폼 초기화
      } else {
        alert(result.res.error.code + ': ' + result.res.error.message);
      }

      
      console.log(' handlePriceUpdate priceId : ', priceId , ' data : ', data)
      // 수정 API 요청 (postUpdateTravelProductOptionPrice) 추가 필요
      setIsEditMode(null); // 수정 모드 종료
      getTravelProductOptionPriceApi(); // 수정 후 가격 리스트 다시 불러오기
    } catch (error) {
      console.error('가격 수정 에러:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (travelProductId && travelProductOptionId) {
      console.log('travelProductId : ', travelProductId, ' travelProductOptionId  : ', travelProductOptionId );
      getTravelProductOptionPriceApi();
    }
  }, [travelProductId, travelProductOptionId]);

  
  return (
    <MainLayout>
      <Container>
        <Title>옵션 가격 등록</Title>

        {/* 가격 등록 폼 */}
        <form onSubmit={handleSubmitPriceForm(handlePriceSubmit)}>
          <S_FieldContainer>
            <S_BasicInfoRowWrapper>
              <S_BasicInfoWrapper>
                <S_Label>가격</S_Label>
                <RegisterInput
                  isError={!!priceErrors.price}
                  register={registerPriceForm('price', { 
                    required: '가격을 입력해주세요',
                    valueAsNumber: true
                  })}
                  placeholder="가격을 입력하세요"
                  style={{ width: '300px' }}
                  type="number"
                />
                {priceErrors.price && <S_ErrorMessage>{priceErrors.price.message}</S_ErrorMessage>}
              </S_BasicInfoWrapper>

              <S_BasicInfoWrapper>
                <S_Label>할인율</S_Label>
                <RegisterInput
                  isError={!!priceErrors.salePercent}
                  register={registerPriceForm('salePercent', { 
                    required: '할인율을 입력해주세요',
                    valueAsNumber: true
                  })}
                  placeholder="할인율을 입력하세요"
                  style={{ width: '300px' }}
                  type="number"
                />
                {priceErrors.salePercent && <S_ErrorMessage>{priceErrors.salePercent.message}</S_ErrorMessage>}
              </S_BasicInfoWrapper>
            </S_BasicInfoRowWrapper>

            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '30px' }}>
              <LongButton>{isLoading ? <Spinner /> : '등록하기'}</LongButton>
            </div>
          </S_FieldContainer>
        </form>

        <Divider />

        {/* 등록된 가격 리스트 */}
        <Title>등록된 가격 리스트</Title>
        {isLoading ? (
          <Spinner />
        ) : (
          <PriceList>
            {isOptionList.map((priceItem) => (
              <PriceItem key={priceItem.id}>
                {isEditMode === priceItem.id ? (
                  // 수정 가능한 폼
                  <form onSubmit={handleSubmitDateForm((data) => handlePriceDateUpdate(Number(priceItem.id), data))}>
                    <S_BasicInfoWrapper>
                      <S_Label>id</S_Label>
                      <div>{priceItem.id}</div>
                    </S_BasicInfoWrapper>
                    <S_BasicInfoWrapper>
                      <S_Label>가격</S_Label>
                      <RegisterInput
                        isError={!!dateErrors.price}
                        register={registerDateForm('price', { 
                          required: '가격을 입력해주세요',
                          valueAsNumber: true
                        })}
                        placeholder="가격을 입력하세요"
                        style={{ width: '300px' }}
                        type="number"
                      />
                      {dateErrors.price && <S_ErrorMessage>{dateErrors.price.message}</S_ErrorMessage>}
                    </S_BasicInfoWrapper>

                    <S_BasicInfoWrapper>
                      <S_Label>할인율</S_Label>
                      <RegisterInput
                        isError={!!dateErrors.salePercent}
                        register={registerDateForm('salePercent', { 
                          required: '할인율을 입력해주세요',
                          valueAsNumber: true
                        })}
                        placeholder="할인율을 입력하세요"
                        style={{ width: '300px' }}
                        type="number"
                      />
                      {dateErrors.salePercent && <S_ErrorMessage>{dateErrors.salePercent.message}</S_ErrorMessage>}
                    </S_BasicInfoWrapper>

                    <S_BasicInfoWrapper>
                      <S_Label>날짜</S_Label>
                      <Controller
                        control={controlDateForm}
                        name={`dateList`}
                        render={({ field: { onChange, value } }) => (
                          <DatePicker
                            value={selectedDates}  // DatePicker에 미리 설정된 날짜를 보여줌
                            onChange={(dates) => {
                              // const formattedDates = dates.map((date) => ({
                              //   date: date.format("YYYY-MM-DD")
                              // }));
                              // const formattedDates = dates.map((date) => date.format("YYYY-MM-DD"));
                              // 날짜를 "YYYY-MM-DD" 형식으로 변환
                              const formattedDates = dates.map((date) => date.format("YYYY-MM-DD"));

                              // 기존의 dateList 배열에 새 날짜들을 push 하는 대신 배열 전체를 업데이트
                              const updatedDateList = [...formattedDates];

                              onChange(updatedDateList);  // 선택된 날짜들을 폼 상태에 업데이트
                              setSelectedDates(dates);   // 선택된 날짜들을 상태에 저장
                            }}
                            multiple
                            sort
                            format={format}
                            minDate={today} 
                            plugins={[<DatePanel key="date-panel" />]}
                          />
                        )}
                      />
                        <S_SelectedDatesModify>
                          {selectedDates.map((dateItem, index) => (
                            <S_DateItem key={index}>
                              {dateItem.format("YYYY-MM-DD")}
                            </S_DateItem>
                          ))}
                        </S_SelectedDatesModify>
                    </S_BasicInfoWrapper>
                     

                    <div style={{ display: 'flex', gap: '10px', marginTop: '20px' }}>
                      <LongButton type="submit" >저장</LongButton>
                      <LongButton type="button" onClick={() => setIsEditMode(null)}>취소</LongButton>
                    </div>
                  </form>
                ) : (
                  // 보기 모드
                  <>

                    <S_BasicInfoWrapper>
                      <S_Label>가격</S_Label>
                      <div>{priceItem.price}</div>
                    </S_BasicInfoWrapper>

                    <S_BasicInfoWrapper>
                      <S_Label>할인율</S_Label>
                      <div>{priceItem.salePercent || '없음'}</div>
                    </S_BasicInfoWrapper>

                    <S_BasicInfoWrapper>
                      <S_Label>날짜 리스트</S_Label>
                      <S_SelectedDates>
                        {priceItem.travelProductOptionsPriceDate.map((dateItem) => (
                          <S_DateItem key={dateItem.id}>
                            {dateItem.date}
                          </S_DateItem>
                        ))}
                      </S_SelectedDates>
                    </S_BasicInfoWrapper>

                    <S_BasicInfoWrapper style={{ marginTop: '10px' }}>
                      <LongButton onClick={() => toggleEditMode(Number(priceItem.id))}>수정</LongButton>
                    </S_BasicInfoWrapper>
                  </>
                )}
              </PriceItem>
            ))}
          </PriceList>
        )}
      </Container>
    </MainLayout>
  );
}

export default TravelProductOptionPriceDetail;

// 스타일드 컴포넌트
const Container = styled.div`
  padding: 20px;
`;

const Title = styled.h2`
  font-size: 24px;
  margin-bottom: 20px;
`;

const S_FieldContainer = styled.div`
  margin: 16px 0 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const S_BasicInfoRowWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 45px;
  width: 100%;
  margin-top: 10px;
`;

const S_BasicInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const S_Label = styled.div`
  width: 100px;
  flex-shrink: 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
`;

const S_ErrorMessage = styled.span`
  color: red;
  font-size: 12px;
`;

const PriceList = styled.ul`
  list-style-type: none;
  padding: 0;
`;

const PriceItem = styled.li`
  border: 1px solid #ccc;
  padding: 10px;
  margin-bottom: 10px;
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #ddd;
  margin: 20px 0;
`;

const S_SelectedDates = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;  /* DatePicker와 약간의 간격 추가 */
  gap: 10px;  /* 각 날짜 간의 간격 */
`;


const S_SelectedDatesModify = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: 10px;  /* DatePicker와 약간의 간격 추가 */
  margin-top:  10px;  /* DatePicker와 약간의 간격 추가 */
  gap: 10px;  /* 각 날짜 간의 간격 */
`;

const S_DateItem = styled.div`
  padding: 8px 12px;
  background-color: ${(props) => props.theme.colors.hd5};
  border-radius: 8px;
  font-size: 14px;
  color: ${(props) => props.theme.colors.ho1};
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
`;
