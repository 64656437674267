import { I_CouponForm } from 'etc/types';
import { callApi } from './callApi';

// 쿠폰 리스트 & 검색
export const getCouponList = async (
  limit: number,
  currentPage: number,
  search: string
) => {
  const res = await callApi({
    url: `/admin/store/coupons?page=${currentPage}&limit=${limit}${search}`,
    method: 'GET',
  });
  return res;
};

// 쿠폰 상세
export const getCouponDetail = async (couponId: number) => {
  const res = await callApi({
    url: `/admin/store/coupon/${couponId}`,
    method: 'GET',
  });
  return res;
};

// 쿠폰 등록
export const postCoupon = async (body: I_CouponForm) => {
  const res = await callApi({
    url: `/admin/store/coupon`,
    method: 'POST',
    data: body,
  });
  return res;
};

// 쿠폰 등록
export const deleteCoupon = async (couponId: number) => {
  const res = await callApi({
    url: `/admin/store/coupon/${couponId}`,
    method: 'DELETE',
  });
  return res;
};

// 쿠폰 - 브랜드 호출
export const getBrandList = async () => {
  const res = await callApi({
    url: `/admin/store/couponOptions`,
    method: 'GET',
  });
  return res;
};
