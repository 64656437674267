import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { I_Breed } from 'etc/types';

interface KindTableItem {
  item: I_Breed;
  kindType: number;
}

function KindTableItem({ item, kindType }: KindTableItem) {
  const navigate = useNavigate();

  return (
    <S_TableRow>
      <S_TableColData>{item.animalType.animal}</S_TableColData>
      {kindType !== 3 ? (
        <S_TableColData
          style={{ textDecoration: 'underLine', cursor: 'pointer' }}
          onClick={() => navigate(`/service/kind/${item.id}`)}
        >
          {item.breed}
        </S_TableColData>
      ) : (
        <S_TableColData>{item.breed}</S_TableColData>
      )}

      <S_TableColData>{item.code}</S_TableColData>
      <S_TableColData>
        {!item.directInput ? '기본등록' : '직접등록'}
      </S_TableColData>
    </S_TableRow>
  );
}

export default KindTableItem;

const S_TableColData = styled.td`
  text-align: center;
  vertical-align: middle;
  border: 1px solid ${(props) => props.theme.colors.hd3};
  padding: 10px;
`;

const S_TableRow = styled.tr`
  height: 36px;
  font-weight: 500;
  font-size: 13px;
`;
