import { useEffect, useState } from 'react';

import styled from 'styled-components';
import { S_FlexColumn } from 'etc/GlobalCSS';

import { ReactComponent as Checkbox } from 'assets/image/checkbox.svg';
import { ReactComponent as Close } from 'assets/image/close.svg';

import ModalLayout from 'components/Layout/ModalLayout';
import DisabledInput from 'components/Input/DisabledInput';
import LongButton from 'components/Button/LongButton';
import {
  I_CancelBillLogProduct,
  I_CancelRequestHistory,
  I_RefundLogProduct,
} from 'etc/types';
import useLogout from 'hooks/useLogout';
import { dateTimeFormat, numberFormat } from 'utils/common';
import StoreRequestModalTableItem2 from 'components/Item/StoreRequestModalTableItem2';
import ReasonModal from './ReasonModal';
import Input from 'components/Input/Input';
import MiniButton from 'components/Button/MiniButton';
import Spinner from 'components/Spinner/Spinner';

import {
  getCancelRequestHistory,
  patchRequestConfirm,
  patchReturnStatus,
  postCancel,
} from 'api/order';

interface I_ReturnRequestHistoryModal {
  onClose: () => void;
  isOpen: boolean;
  onSuccess: () => void;
  refundLogId: number;
  status: number;
}

function ReturnRequestHistoryModal({
  onClose,
  isOpen,
  onSuccess,
  refundLogId,
  status,
}: I_ReturnRequestHistoryModal) {
  const Logout = useLogout();
  const [history, setHistory] = useState<I_CancelRequestHistory>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [extraShippingCost, setExtraShippingCost] = useState<number>(0);
  const [totalAmount, setTotalAmount] = useState<number>(0);
  const [exTotAmount, setExTotAmount] = useState<number>(0); // 전체 환불 예정 금액 - 부분 환불 요청
  const [returnList, setReturnList] = useState<number[]>([]);
  const [isCancelPossi, setIsCancelPossi] = useState<boolean>(true);
  const [isReasonModalOpen, setIsReasonModalOpen] = useState<boolean>(false); // 요청 내역 모달
  const [reasonIndex, setReasonIndex] = useState<number>(0);
  const [addInput, setAddInput] = useState<string>('');

  const handleExtraCost = () => {
    if (!addInput.trim()) {
      alert('값을 입력해주세요.');
      return;
    }

    if (/^\d*$/.test(addInput)) {
      const temp = Number(addInput);
      if (temp <= totalAmount) {
        setExTotAmount(totalAmount - temp);
      } else {
        alert('전체 환불 예정 금액 보다 금액이 큽니다.');
      }
    } else {
      alert('숫자만 입력해주세요.');
    }
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const temp = event.target.value.replace(/[^0-9]/gm, '');
    setAddInput(temp);
    if (!temp) {
      setExTotAmount(0);
    }
  };

  const calcRefund = (data: I_CancelRequestHistory) => {
    const lenOrder = data.billLog.billLogProducts.length;
    const sumOrder = data.billLog.billLogProducts.reduce(
      (sum: number, billLogProduct: I_CancelBillLogProduct) =>
        sum + billLogProduct.productPaymentPrice,
      0
    );
    const lenCancel = data.refundLogProducts.filter(
      (v) => v.delayReason === null
    ).length;
    const sumCancel = data.refundLogProducts.reduce(
      (sum: number, billLogProduct: I_RefundLogProduct) => {
        if (billLogProduct.delayReason === null) {
          return sum + billLogProduct.productPaymentPrice;
        } else {
          return sum;
        }
      },
      0
    );
    setReturnList(
      data.refundLogProducts
        .filter((v) => v.delayReason === null)
        .map((v) => v.refundLogProductId)
    );

    if (sumCancel === 0) setIsCancelPossi(false);
    else setIsCancelPossi(true);

    const shipping = data.billLog.shippingCost;
    setExtraShippingCost(0);

    // 전체 취소
    if (lenOrder === lenCancel) {
      setTotalAmount(sumOrder + shipping);
      return;
    }

    // 부분 취소
    if (shipping === 0) {
      // 배송비 내지 않은 경우
      if (sumOrder - sumCancel < data.orderInfo.freeShippingPrice) {
        // 주문 금액에서 취소 상품 금액 제외했을 때 무료 배송 조건 만족 못할 경우
        setExtraShippingCost(data.orderInfo.shippingCost); // 추가 배송비 발생
        setTotalAmount(sumCancel - data.orderInfo.shippingCost); // 발생된 배송비 만큼 환불 금액에서 제외
      } else {
        setTotalAmount(sumCancel);
      }
    } else {
      // 배송비 낸 경우
      setTotalAmount(sumCancel);
    }
  };

  // 요청 확인
  const patchAsyncRequestConfirm = async () => {
    setIsLoading(true);
    const data = await patchRequestConfirm(refundLogId);
    if (data.ok) {
      alert('반품 요청이 확인되어 반품 수거 중으로 이동합니다.');
      setIsLoading(false);
      onSuccess();
    } else {
      if (data.res.status === 315) Logout();
      else {
        alert('잠시 후 다시 시도해주세요.');
        console.error(data.res.error.message);
      }
    }
  };

  // 수거 완료
  const patchAsyncRefundStatus = async () => {
    setIsLoading(true);
    const data = await patchReturnStatus(returnList);
    if (data.ok) {
      alert(
        '반품 수거 완료 처리가 완료되었습니다. 해당 주문은 반품 수거 완료로 이동합니다.'
      );
      setIsLoading(false);
      onSuccess();
    } else {
      if (data.res.status === 315) Logout();
      else {
        alert('잠시 후 다시 시도해주세요.');
        console.error(data.res.error.message);
      }
    }
  };

  // 취소
  const postAsyncCancel = async () => {
    if (exTotAmount === 0 && addInput) {
      alert('부분 환불 요청의 값을 적용해주시거나 지워주세요.');
      return;
    }

    setIsLoading(true);
    const data = await postCancel(refundLogId, totalAmount, addInput);
    if (data.ok) {
      alert('결제가 취소 되었습니다.');
      setIsLoading(false);
      onSuccess();
    } else {
      if (data.res.status === 315) Logout();
      else {
        alert('잠시 후 다시 시도해주세요.');
        console.error(data.res.error.message);
      }
    }
  };

  // 내역
  const getAsyncCancelRequestHistory = async () => {
    const data = await getCancelRequestHistory(refundLogId);
    if (data.ok) {
      setHistory(data.res.item);
      calcRefund(data.res.item);
    } else {
      if (data.res.status === 315) Logout();
      else console.error(data.res.error.message);
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden'; // body scroll 제거
      if (refundLogId) {
        getAsyncCancelRequestHistory();
      }
    }
  }, [isOpen]);

  return (
    <ModalLayout isOpen={isOpen} onClose={onClose}>
      <S_Container>
        <S_CloseButton
          onClick={() => {
            onClose();
            setAddInput('');
          }}
        >
          <Close />
        </S_CloseButton>
        <S_ContentContainer>
          <S_ModalTitle>반품 요청 내역</S_ModalTitle>

          <S_ModalContent>
            <S_FlexColumn style={{ gap: '18px' }}>
              <S_ModalContentWrapper>
                <S_ModalContentRow>
                  <S_Label>주문 번호</S_Label>
                  <DisabledInput
                    text={history?.billLog?.id}
                    style={{ width: '380px' }}
                  />
                </S_ModalContentRow>
                <S_ModalContentRow>
                  <S_Label>요청 일시</S_Label>
                  <DisabledInput
                    text={dateTimeFormat(history?.createdAt)}
                    style={{ width: '380px' }}
                  />
                </S_ModalContentRow>
              </S_ModalContentWrapper>
              <S_ModalContentRow>
                <S_Label>고객 환불 계좌</S_Label>
                <DisabledInput
                  text={history?.refundBankAccount?.bank}
                  style={{ width: '380px' }}
                />
                <DisabledInput
                  text={history?.refundBankAccount?.accountNumber}
                  style={{ width: '380px', marginLeft: '-24px' }}
                />
              </S_ModalContentRow>
            </S_FlexColumn>

            {/* table */}
            <S_ModalContentCol>
              <S_Label>반품 요청 상품</S_Label>
              <S_TableBox>
                <S_Table>
                  <thead>
                    <tr>
                      {TABLE_HEADER_ORDER.map((v, i) => (
                        <S_TableHeaderItem key={i}>
                          {v === 'check' ? (
                            <Checkbox
                              width='16.67px'
                              height='16.67px'
                              color='#2E2E2E'
                            />
                          ) : (
                            v
                          )}
                        </S_TableHeaderItem>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {history?.refundLogProducts.map(
                      (v: I_RefundLogProduct, i: number) => (
                        <StoreRequestModalTableItem2
                          key={i}
                          idx={i}
                          item={v}
                          setIsModalOpen={setIsReasonModalOpen}
                          setIndex={setReasonIndex}
                        />
                      )
                    )}
                  </tbody>
                </S_Table>
              </S_TableBox>
            </S_ModalContentCol>

            <S_ModalContentCol>
              <S_Label>결제 정보</S_Label>
              <S_TableBox style={{ overflowX: 'visible' }}>
                <S_Table>
                  <thead>
                    <tr>
                      {TABLE_HEADER_PAYMENT.map((v, i) => (
                        <S_TableHeaderItem key={i}>{v}</S_TableHeaderItem>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    <S_TableRow>
                      <S_TableColData style={{ width: '274px' }}>
                        {history?.billLog?.payment?.createdAt
                          ? dateTimeFormat(history?.billLog?.payment?.createdAt)
                          : '-'}
                      </S_TableColData>
                      <S_TableColData>
                        {history?.billLog?.payment?.paymentMethod === 'card'
                          ? '신용/체크카드'
                          : history?.billLog?.payment?.paymentMethod ===
                            'virtualAccount'
                          ? '무통장 입금'
                          : '-'}
                      </S_TableColData>
                    </S_TableRow>
                  </tbody>
                </S_Table>
              </S_TableBox>
            </S_ModalContentCol>

            <S_ModalContentCol style={{ marginTop: '-15px' }}>
              <S_Label>환불 예정 정보</S_Label>
              <S_TableBox style={{ overflowX: 'visible' }}>
                <S_Table>
                  <thead>
                    <tr>
                      {TABLE_HEADER_REFUND.map((v, i) => (
                        <S_TableHeaderItem key={i}>{v}</S_TableHeaderItem>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    <S_TableRow>
                      <S_TableColData style={{ width: '193px' }}>
                        {numberFormat(history?.billLog?.shippingCost)}원
                      </S_TableColData>
                      <S_TableColData style={{ width: '193px' }}>
                        {numberFormat(extraShippingCost)}원
                      </S_TableColData>
                      <S_TableColData style={{ width: '193px' }}>
                        {history?.shippingPayment}
                      </S_TableColData>
                      <S_TableColData>
                        {exTotAmount ? (
                          <>
                            {`${numberFormat(exTotAmount)}원`}
                            {`(${numberFormat(totalAmount)}원 - ${numberFormat(
                              Number(addInput)
                            )}원)`}
                          </>
                        ) : (
                          `${numberFormat(totalAmount)}원`
                        )}
                      </S_TableColData>
                    </S_TableRow>
                  </tbody>
                </S_Table>
              </S_TableBox>
            </S_ModalContentCol>
          </S_ModalContent>

          {status === 2 && (
            <S_AddInput>
              <S_Label style={{ marginRight: '24px' }}>부분 환불 요청</S_Label>
              <Input
                state={addInput}
                setState={setAddInput}
                rightText='원'
                onCustomChange={onChange}
                style={{ width: '380px' }}
              />
              <MiniButton onClick={handleExtraCost}>적용</MiniButton>
            </S_AddInput>
          )}

          {status === 0 &&
            (isCancelPossi ? (
              <LongButton
                onClick={patchAsyncRequestConfirm}
                style={{
                  float: 'right',
                  marginTop: '64px',
                }}
              >
                {isLoading ? <Spinner /> : '요청 확인'}
              </LongButton>
            ) : (
              <S_DisabledButton
                style={{
                  float: 'right',
                  marginTop: '46px',
                }}
              >
                요청 확인
              </S_DisabledButton>
            ))}
          {status === 1 &&
            (isCancelPossi ? (
              <LongButton
                onClick={patchAsyncRefundStatus}
                style={{
                  float: 'right',
                  marginTop: '64px',
                }}
              >
                {isLoading ? <Spinner /> : '수거 완료 처리'}
              </LongButton>
            ) : (
              <S_DisabledButton
                style={{
                  float: 'right',
                  marginTop: '46px',
                }}
              >
                수거 완료 처리
              </S_DisabledButton>
            ))}
          {status === 2 &&
            (isCancelPossi ? (
              <LongButton
                onClick={postAsyncCancel}
                style={{
                  background: '#FC1D1D',
                  float: 'right',
                  marginTop: '46px',
                }}
              >
                {isLoading ? <Spinner /> : '결제 취소'}
              </LongButton>
            ) : (
              <S_DisabledButton
                style={{
                  float: 'right',
                  marginTop: '46px',
                }}
              >
                결제 취소
              </S_DisabledButton>
            ))}
        </S_ContentContainer>
      </S_Container>

      <ReasonModal
        isOpen={isReasonModalOpen}
        onClose={() => setIsReasonModalOpen(false)}
        onSuccess={getAsyncCancelRequestHistory}
        status='반품 거부'
        isDone={false}
        product={history?.refundLogProducts[reasonIndex]}
      />
    </ModalLayout>
  );
}

export default ReturnRequestHistoryModal;

const S_DisabledButton = styled.div`
  width: 300px;
  height: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.colors.hd4};
  border-radius: 8px;
  font-weight: 700;
  font-size: 14px;
  letter-spacing: -0.02em;
  color: ${(props) => props.theme.colors.hd5};
`;

const S_AddInput = styled.div`
  margin-top: 32px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
`;

const S_TableColData = styled.td`
  text-align: center;
  vertical-align: middle;
  border: 1px solid ${(props) => props.theme.colors.hd3};
  padding: 10px;
`;

const S_TableRow = styled.tr`
  height: 36px;
  font-weight: 500;
  font-size: 13px;
`;

const S_TableHeaderItem = styled.th`
  vertical-align: middle;
  padding: 10px;
  height: 36px;
  background-color: ${(props) => props.theme.colors.hd4};
  border: 0.5px solid ${(props) => props.theme.colors.hd3};
  font-weight: 500;
  font-size: 13px;
  letter-spacing: -0.02em;
  color: ${(props) => props.theme.colors.n10};
`;

const S_Table = styled.table`
  width: 100%;

  th:first-child {
    width: 40px;
  }
`;

const S_TableBox = styled.div`
  overflow-x: scroll;
  &::-webkit-scrollbar {
    width: 4px;
    height: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.theme.colors.hd3};
    border-radius: 6px;
  }
`;

const S_Label = styled.span`
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.02em;
  width: 90px;
`;

const S_ModalContentCol = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const S_ModalContentRow = styled.div`
  display: flex;
  align-items: center;
  gap: 40px;
`;

const S_ModalContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const S_ModalContent = styled.div`
  margin-top: 34px;
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

const S_ModalTitle = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 35px;
  letter-spacing: -0.02em;
  text-align: center;
`;

const S_CloseButton = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
  :hover {
    opacity: 0.5;
  }
`;

const S_ContentContainer = styled.div`
  width: 1300px;
  margin: 0 auto;
  position: relative;
`;

const S_Container = styled.div`
  width: 100%;
  height: 100%;
  background: ${(props) => props.theme.colors.hd5};
  padding: 70px 70px 44px;
  overflow-y: overlay;
  z-index: 10;
`;

const TABLE_HEADER_ORDER = [
  '반품 거부',
  '상품 주문 번호',
  '상품 정보',
  '상세 옵션',
  '주문 수량',
  '최종 결제 금액',
  '상품 판매가',
  '사용 포인트',
  '쿠폰 할인',
  '상품 번호',
];
const TABLE_HEADER_PAYMENT = ['결제 일시', '결제 방식'];
const TABLE_HEADER_REFUND = [
  '배송비',
  '추가 발생 배송비',
  '배송비 청구 방식',
  '전체 환불 예정 금액',
];
