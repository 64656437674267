import { useEffect, useState } from 'react';

import styled from 'styled-components';
import { S_FlexAlignCenter, S_FlexColumn } from 'etc/GlobalCSS';

import { ReactComponent as Close } from 'assets/image/close.svg';
import { ReactComponent as ArrowRight } from 'assets/image/arrow-right.svg';

import ModalLayout from 'components/Layout/ModalLayout';
import DisabledInput from 'components/Input/DisabledInput';
import Textarea from 'components/Input/Textarea';
import NormalButton from 'components/Button/NormalButton';
import DisabledTextarea from 'components/Input/DisabledTextarea';
import useLogout from 'hooks/useLogout';
import Spinner from 'components/Spinner/Spinner';

import { postReason } from 'api/order';

interface I_RefusalProduct {
  id?: number; // 상품 주문 번호
  orderStatus?: number;
  brandId?: number;
  brandName: string | undefined;
  productId?: number;
  productName: string | undefined;
  productOptionName?: string;
  productCount?: number;
  productPrice?: number;
  productCouponDiscount?: number;
  productPointDiscount?: number;
  productPaymentPrice?: number;
  delayReason?: string | null;
  refundLogProductId?: number;
}

interface I_ReasonModal {
  isOpen: boolean;
  onClose: () => void;
  onSuccess?: () => void;
  status: string;
  isDone: boolean;
  product: I_RefusalProduct | undefined;
}

function ReasonModal({
  isOpen,
  onClose,
  onSuccess,
  status,
  isDone,
  product,
}: I_ReasonModal) {
  const Logout = useLogout();
  const [reason, setReason] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onSubmit = async () => {
    if (!reason.trim()) {
      alert('사유를 작성해주세요.');
      return;
    }

    if (product && product.refundLogProductId) {
      setIsLoading(true);
      const data = await postReason(
        product.refundLogProductId,
        reason,
        status === '교환 지연' ? false : true
      );
      if (data.ok) {
        if (status === '교환 지연') {
          alert('지연 처리되었습니다.');
        } else {
          alert('거부 처리되었습니다.');
        }
        setIsLoading(false);
        setReason('');
        if (onSuccess) onSuccess();
        onClose();
      } else {
        if (data.res.status === 315) Logout();
        else {
          alert('잠시 후 다시 시도해주세요.');
          console.error(data.res.error.message);
        }
      }
    } else {
      alert('사유를 작성해주세요.');
    }
  };

  return (
    <ModalLayout isOpen={isOpen} onClose={onClose}>
      <S_Container>
        <S_CloseButton
          onClick={() => {
            setReason('');
            onClose();
          }}
        >
          <Close />
        </S_CloseButton>

        <S_Title>{status} 안내</S_Title>
        <S_InfoContainer>
          <S_SubTitle>상품 정보</S_SubTitle>
          <S_FlexColumn style={{ gap: '8px', marginTop: '16px' }}>
            <S_FlexAlignCenter style={{ justifyContent: 'space-between' }}>
              <S_Label>브랜드</S_Label>
              <DisabledInput
                text={product?.brandName}
                style={{ width: '380px' }}
              />
            </S_FlexAlignCenter>
            <S_FlexAlignCenter style={{ justifyContent: 'space-between' }}>
              <S_Label>상품 정보</S_Label>
              <DisabledInput
                text={product?.productName}
                style={{ width: '380px' }}
              />
            </S_FlexAlignCenter>
            <S_FlexAlignCenter style={{ justifyContent: 'space-between' }}>
              <S_Label style={{ display: 'flex', alignItems: 'center' }}>
                <ArrowRight width='18px' height='18px' />
                <span>상세 옵션</span>
              </S_Label>
              <DisabledInput
                text={product?.productOptionName}
                style={{ width: '380px' }}
              />
            </S_FlexAlignCenter>
            <S_FlexAlignCenter style={{ justifyContent: 'space-between' }}>
              <S_Label style={{ display: 'flex', alignItems: 'center' }}>
                <ArrowRight width='18px' height='18px' />
                <span>수량</span>
              </S_Label>
              <DisabledInput
                text={product?.productCount}
                style={{ width: '380px' }}
              />
            </S_FlexAlignCenter>
          </S_FlexColumn>
        </S_InfoContainer>
        <S_ReasonContainer>
          <S_SubTitle>사유 작성</S_SubTitle>
          {isDone ? (
            <DisabledTextarea
              text={product?.delayReason ? product?.delayReason : ''}
              style={{ width: '380px', height: '200px' }}
            />
          ) : (
            <Textarea
              state={reason}
              setState={setReason}
              style={{ width: '380px', height: '200px' }}
            />
          )}
        </S_ReasonContainer>
        {!isDone && (
          <NormalButton onClick={onSubmit} style={{ margin: '7px auto 0' }}>
            {isLoading ? <Spinner /> : '저장'}
          </NormalButton>
        )}
      </S_Container>
    </ModalLayout>
  );
}

export default ReasonModal;

const S_Label = styled.span`
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: -0.28px;
`;

const S_SubTitle = styled.span`
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: -0.32px;
`;

const S_ReasonContainer = styled.div`
  padding: 24px 31px 57px;
  display: flex;
  justify-content: space-between;
`;

const S_InfoContainer = styled.div`
  padding: 30px 31px 24px;
  border-bottom: 1px solid ${(props) => props.theme.colors.hd4};
`;

const S_Title = styled.p`
  font-size: 24px;
  font-weight: 400;
  text-align: center;
`;

const S_CloseButton = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
  :hover {
    opacity: 0.5;
  }
`;

const S_Container = styled.div`
  width: 640px;
  height: 742px;
  background: ${(props) => props.theme.colors.hd5};
  position: relative;
  padding: 48px 38px 32px;
  border-radius: 10px;
  z-index: 10;
`;
