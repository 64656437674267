import styled from 'styled-components';

import { ReactComponent as ArrowDown } from 'assets/image/arrow-down.svg';
import { useEffect, useRef } from 'react';

interface I_DropDown {
  list: string[];
  selectedItem: string;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onClick: (i: string) => void;
  style?: React.CSSProperties;
}

function Dropdown({
  list,
  selectedItem,
  isOpen,
  setIsOpen,
  onClick,
  style,
}: I_DropDown) {
  const dropdownRef = useRef<HTMLDivElement>(null);

  /* 외부영역 클릭했을 때 드롭다운 닫기 */
  function handleClickOutside(e: MouseEvent): void {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(e.target as Node)
    ) {
      setIsOpen(false);
    }
  }

  useEffect(() => {
    document.addEventListener('mouseup', handleClickOutside);
    return () => {
      document.removeEventListener('mouseup', handleClickOutside);
    };
  }, []);

  return (
    <S_Container isDropDownOpen={isOpen} ref={dropdownRef} style={style}>
      <S_DropDownList
        isDropDownOpen={isOpen}
        isScroll={list.length > 7 ? true : false}
      >
        {list.map((v, i) => (
          <S_DropDownItem key={i} onClick={() => onClick(v)}>
            {v}
          </S_DropDownItem>
        ))}
      </S_DropDownList>
      <S_DropDownBox onClick={() => setIsOpen(!isOpen)}>
        {selectedItem}
        <S_ArrowButton>
          <ArrowDown width='13px' height='13px' />
        </S_ArrowButton>
      </S_DropDownBox>
    </S_Container>
  );
}

export default Dropdown;

const S_ArrowButton = styled.div`
  width: 13px;
  height: 13px;
`;

const S_DropDownItem = styled.li`
  padding: 8px 16px;
  font-weight: 500;
  font-size: 13px;
  cursor: pointer;
  transition: 0.5s;
  &:hover {
    background-color: ${(props) => props.theme.colors.ho3};
  }
`;

const S_DropDownList = styled.ul<{
  isDropDownOpen: boolean;
  isScroll: boolean;
}>`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: ${(props) => (props.isDropDownOpen ? '210px' : '0')};
  background: ${(props) => props.theme.colors.hd4};
  border-radius: 8px;
  overflow-y: ${(props) => (props.isScroll ? 'scroll' : 'hidden')};
  position: absolute;
  top: 0;
  z-index: 2;
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.theme.colors.hd3};
    border-radius: 6px;
  }
`;

const S_DropDownBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 4px;
  padding: 8px 16px;
  width: 100%;
  background: ${(props) => props.theme.colors.hd4};
  border-radius: 8px;
  font-weight: 500;
  font-size: 13px;
  z-index: 1;
  cursor: pointer;
`;

const S_Container = styled.div<{ isDropDownOpen: boolean }>`
  width: 128px;
  display: inline-flex;
  position: relative;
`;
