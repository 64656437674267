import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import MainLayout from 'components/Layout/MainLayout';
import SearchBarInput from 'components/Input/SearchBarInput';
import PagenationButton from 'components/Button/PagenationButton';
import NormalButton from 'components/Button/NormalButton';
import EventTableItem from 'components/Item/EventTableItem';

import { TABLE_LIMIT } from 'etc/variable';
import { getEventList, getEventSearch } from 'api/event';
import useLogout from 'hooks/useLogout';
import { I_Event } from 'etc/types';

import { ReactComponent as Checkbox } from 'assets/image/checkbox.svg';

function Event() {
  const Logout = useLogout();
  const navigate = useNavigate();

  const [searchValue, setSearchValue] = useState<string>('');
  const [totalCount, setTotalCount] = useState<number>(0); // 전체 개수
  const [activePage, setActivePage] = useState<number>(1); // 현재 페이지
  const [eventList, setEventList] = useState<I_Event[]>([]);
  const [isSearch, setIsSearch] = useState<boolean>(false);
  const limit = 10;

  const handlePageChange = (page: number) => {
    setActivePage(page);

    if (isSearch) {
      getAsyncSearch(page);
    } else {
      getAsyncEventList(page);
    }
  };

  // 검색 엔터
  const onKeyPressHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      setActivePage(1);
      getAsyncSearch(1);
    }
  };

  // 검색 확인
  const onClickSearch = () => {
    setActivePage(1);
    getAsyncSearch(1);
  };

  // 검색
  const getAsyncSearch = async (page: number) => {
    if (!searchValue.trim()) {
      getAsyncEventList(1);
      setIsSearch(false);
    } else {
      setIsSearch(true);
      const data = await getEventSearch(searchValue, page, limit);

      if (data.ok) {
        // console.log(data.res);
        if (data.res) {
          setEventList(data.res.item);
          setTotalCount(data.res.totalCount);
        } else setEventList([]);
      } else {
        if (data.res.status === 315) Logout();
        else {
          alert('잠시 후 다시 시도해주세요.');
          console.error(data.res.error.message);
        }
      }
    }
  };

  const getAsyncEventList = async (page: number) => {
    const data = await getEventList(limit, page);
    if (data.ok) {
      // console.log(data.res.item);
      setEventList(data.res.item);
      setTotalCount(data.res.totalCount);
    } else {
      if (data.res.status === 315) Logout();
      else {
        alert('잠시 후 다시 시도해주세요.');
        console.error(data.res.error.message);
      }
    }
  };

  useEffect(() => {
    getAsyncEventList(1);
  }, []);

  return (
    <MainLayout>
      <SearchBarInput
        state={searchValue}
        setState={setSearchValue}
        onKeyPress={onKeyPressHandler}
        onClick={() => onClickSearch()}
      />
      <S_TableButtonContainer>
        <NormalButton
          onClick={() => navigate('/service/event/register')}
          style={{ width: 103 }}
        >
          이벤트 등록
        </NormalButton>
      </S_TableButtonContainer>
      <S_TableContainer>
        <S_TableBox>
          <S_Table>
            <thead>
              <tr>
                {TABLE_HEADER.map((v, i) => (
                  <S_TableHeaderItem key={i}>
                    {v === 'check' ? (
                      <Checkbox
                        width='16.67px'
                        height='16.67px'
                        color='#2E2E2E'
                      />
                    ) : (
                      v
                    )}
                  </S_TableHeaderItem>
                ))}
              </tr>
            </thead>
            <tbody>
              {eventList.map((v: I_Event, i: number) => (
                <EventTableItem key={i} item={v} />
              ))}
            </tbody>
          </S_Table>
        </S_TableBox>

        <PagenationButton
          activePage={activePage}
          totalCount={totalCount}
          postPerPage={TABLE_LIMIT}
          handlePageChange={handlePageChange}
        />
      </S_TableContainer>
    </MainLayout>
  );
}

export default Event;

const S_TableButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  margin-top: 50px;
`;

const S_TableHeaderItem = styled.th`
  vertical-align: middle;
  padding: 10px;
  height: 36px;
  background-color: ${(props) => props.theme.colors.hd4};
  border: 0.5px solid ${(props) => props.theme.colors.hd3};
  font-weight: 500;
  font-size: 13px;
  letter-spacing: -0.02em;
  color: ${(props) => props.theme.colors.n10};
`;

const S_Table = styled.table`
  width: 100%;

  th:first-child {
    min-width: 200px;
  }
  th:nth-child(2) {
    width: 350px;
  }
`;

const S_TableBox = styled.div`
  overflow-x: scroll;
  &::-webkit-scrollbar {
    width: 4px;
    height: 8px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.theme.colors.hd3};
    border-radius: 6px;
  }
`;

const S_TableContainer = styled.div`
  margin-top: 8px;
  position: relative;
`;

const TABLE_HEADER = ['이벤트 명', '진행 기간', '관련 썸네일', '진행 상태'];
