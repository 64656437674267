import { callApi } from './callApi';

const BASE_URL = '/admin';

// 파일 업로드
export const postImageUpload = async (file: any) => {
  const res = await callApi({
    url: `${BASE_URL}/uploadFile`,
    method: 'POST',
    isFormData: true,
    data: file,
  });
  return res;
};

// 파일s 업로드
export const postImagesUpload = async (files: any) => {
  const res = await callApi({
    url: `${BASE_URL}/uploadFiles`,
    method: 'POST',
    isFormData: true,
    data: files,
  });
  return res;
};


// 상품 카테고리 목록
export const postUploadFiles = async (file: File | Blob) => {
  const res = await callApi({
    url: `${BASE_URL}/uploadFiles`,
    method: 'POST',
    data: file,
  });
  return res;
};
