import moment from 'moment';

import styled from 'styled-components';

import { ReactComponent as Checkbox } from 'assets/image/checkbox.svg';
import { ReactComponent as CheckboxChecked } from 'assets/image/checkbox-checked.svg';

import { I_Brand } from 'etc/types';

interface I_StoreBrandTableItem {
  brand: I_Brand;
  checkList: number[];
  setCheckList: React.Dispatch<React.SetStateAction<number[]>>;
}

function StoreBrandTableItem({
  brand,
  checkList,
  setCheckList,
}: I_StoreBrandTableItem) {
  const onCheckBoxClick = (check: boolean) => {
    if (check) setCheckList((pre) => [...pre, brand.id]);
    else setCheckList((pre) => pre.filter((v) => v !== brand.id));
  };

  return (
    <S_TableRow>
      <S_TableColData style={{ width: '38px' }}>
        {checkList.includes(brand.id) ? (
          <S_Checkbox onClick={() => onCheckBoxClick(false)}>
            <CheckboxChecked />
          </S_Checkbox>
        ) : (
          <S_Checkbox onClick={() => onCheckBoxClick(true)}>
            <Checkbox />
          </S_Checkbox>
        )}
      </S_TableColData>
      <S_TableColData>
        {moment(brand.updatedAt).utcOffset(9).format('YYYY년 M월 D일')}
      </S_TableColData>
      <S_TableColDataColor isHidden={brand.IsHidden} style={{ width: '333px' }}>
        {brand.name}
      </S_TableColDataColor>
    </S_TableRow>
  );
}

export default StoreBrandTableItem;

const S_Checkbox = styled.div`
  width: 16.67px;
  height: 16.67px;
  color: #aeaeae;
  margin: 0 auto;
  cursor: pointer;
`;

const S_TableColDataColor = styled.td<{ isHidden: boolean }>`
  text-align: center;
  vertical-align: middle;
  border: 1px solid ${(props) => props.theme.colors.hd3};
  background: ${(props) =>
    props.isHidden ? props.theme.colors.hd4 : props.theme.colors.hd5};
  padding: 10px;
`;

const S_TableColData = styled.td`
  text-align: center;
  vertical-align: middle;
  border: 1px solid ${(props) => props.theme.colors.hd3};
  padding: 10px;
`;

const S_TableRow = styled.tr`
  height: 36px;
  font-weight: 500;
  font-size: 13px;
`;
