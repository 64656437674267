import styled from 'styled-components';

import { I_RefundLogProduct } from 'etc/types';
import { numberFormat } from 'utils/common';

interface I_StoreRequestModalTableItem {
  item: I_RefundLogProduct;
}

function StoreRequestModalTableItem({ item }: I_StoreRequestModalTableItem) {
  return (
    <S_TableRow>
      <S_TableColData>{item.id}</S_TableColData>
      <S_TableColData style={{ minWidth: '279px' }}>
        <span style={{ fontWeight: '700' }}>{item.brandName}</span>{' '}
        {item.productName}
      </S_TableColData>
      <S_TableColData>{item.productOptionName}</S_TableColData>
      <S_TableColData>{item.productCount}</S_TableColData>
      <S_TableColData>
        {item.productPaymentPrice
          ? `${numberFormat(item.productPaymentPrice)}원`
          : '-'}
      </S_TableColData>
      <S_TableColData>
        {item.productPrice ? `${numberFormat(item.productPrice)}원` : '-'}
      </S_TableColData>
      <S_TableColData>
        {item.productPointDiscount
          ? `-${numberFormat(item.productPointDiscount)}원`
          : '-'}
      </S_TableColData>
      <S_TableColData>
        {item.productCouponDiscount
          ? `-${numberFormat(item.productCouponDiscount)}원`
          : '-'}
      </S_TableColData>
      <S_TableColData>{item.productId}</S_TableColData>
    </S_TableRow>
  );
}

export default StoreRequestModalTableItem;

const S_TableColData = styled.td`
  text-align: center;
  vertical-align: middle;
  border: 1px solid ${(props) => props.theme.colors.hd3};
  padding: 10px;
`;

const S_TableRow = styled.tr`
  height: 36px;
  font-weight: 500;
  font-size: 13px;
`;
