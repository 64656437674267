import styled from 'styled-components';

interface I_PageContentLayout {
  children: React.ReactNode;
}

function PageContentLayout({ children }: I_PageContentLayout) {
  return <S_PageContent>{children}</S_PageContent>;
}

export default PageContentLayout;

const S_PageContent = styled.main`
  /* width: 1358px; */
  min-width: 1180px;
  height: 100%;
  padding: 40px 82px 144px;
`;
