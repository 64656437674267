import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import MainLayout from 'components/Layout/MainLayout';
import NormalButton from 'components/Button/NormalButton';
import NormalModal from 'components/Modal/NormalModal';
import LongButton from 'components/Button/LongButton';
import DisabledInput from 'components/Input/DisabledInput';
import StorePurchaseTableItem from 'components/Item/StorePurchaseTableItem';
import PagenationButton from 'components/Button/PagenationButton';
import PetTableItem from 'components/Item/PetTableItem';
import PointHistoryModal from 'components/Modal/PointHistoryModal';

import { I_FcmMessage } from 'etc/types';

import useLogout from 'hooks/useLogout';
import { sendMultiPush, getFcmMessageList } from 'api/fcm';

function FcmSend() {

  const Logout = useLogout();
  const navigate = useNavigate();
  const { id } = useParams();
  const [ isTitle, setTitle ] = useState<string>('');
  const [ isContent, setContent ] = useState<string>('');
  const [ isImageUrl, setImageUrl ] = useState<string>('');
  const [ isData, setData ] = useState<string>('');
  
  const [ isCategory, setCategory ] = useState<string>('');
  const [ isCategoryId, setCategoryId ] = useState<string>('');
  const [ isTarget, setTarget ] = useState<string>('all');
  const [ isMessageid, setMessageId ] = useState<number>();
  
  const [ totalCount, setTotalCount ] = useState<number>(0); // 전체 개수
  const [ activePage, setActivePage ] = useState<number>(1); // 현재 페이지
  const [ isFcmMessageList, setFcmMessageList ] = useState<I_FcmMessage[]>([]);
  const [ isFcmMessageTotalCount, setFcmMessageTotalCount ] = useState<number>(0); // 전체 개수

  useEffect(() => {
    getAsyncFcmList();

    if(isCategory){
      console.log('isCategory : ', isCategory)
    }
    if(isCategoryId){
      console.log('isCategoryId : ', isCategoryId)
    }
    if(isTarget){
      console.log('isTarget : ', isTarget)
    }
    if(isMessageid){
      console.log('isMessageid : ', isMessageid)
    }
  }, [ isCategory, isCategoryId, isTarget, isMessageid ]);

  const handlerChangeCategory = (option: string) => {
    setCategory(option);
  };

  const handlerChangeTarget = (option: string) => {
    setTarget(option);
  };

  const handlerChangeMessage = (message: I_FcmMessage) => {
    console.log('message : ', message)
    // setSelectedMessage(message);
    if(message.id) setMessageId(message.id);
    if(message.title) setTitle(message.title);
    if(message.content) setContent(message.content);
    if(message.imageUrl) setImageUrl(message.imageUrl);
  };

  const getAsyncFcmList = async () => {
    const data = await getFcmMessageList(
      activePage,
      10,
    );

    // console.log('data : ', data.res);
    if (data.ok) {
      setFcmMessageList(data.res.list);
      setFcmMessageTotalCount(data.res.totalCount);
    } else {
      if (data.res.status === 315) Logout();
      else {
        alert('잠시 후 다시 시도해주세요.');
        console.error(data.res.error.message);
      }
    }
  };

  const postSendPush = () => {
    
    if(!isCategory) return alert('옵션을 선택해주세요');
    if(!isTitle) return alert('푸시 제목을 입력해주세요');
    if(!isContent) return alert('푸시 내용을 입력해주세요');

    const data = sendMultiPush({
        title: isTitle,
        content: isContent,
        category: isCategory,
        // categoryId: 33,
        // detail: 'post',
        // image: 'https://humanizone-s3.s3.amazonaws.com/img/petstival/chunbong01.jpeg'
    });

    // 비동기 처리했을경우 리턴받기까지 너무 오래걸려서 동기로 변경.
    alert('푸시 전송요청을 완료했습니다.');

    // if (data.ok) {
    //   console.log('data.ok : ', data.ok);
    //   return alert(`전송: ${data.res.successCount}, 실패: ${data.res.failureCount} 입니다.`)
    // } else {
    //   if (data.res.status === 315) Logout();
    //   else {
    //     alert('잠시 후 다시 시도해주세요.');
    //     console.error(data.res.error.message);
    //   }
    // }
  };

  return (
    <MainLayout>
      <S_Section>
        <FormContainer>
          <DeviceFrame>
            <DeviceHeader>
              <h3>푸시 전송</h3>
            </DeviceHeader>
            <DeviceBody>
              <PushForm>
                <FormGroup>
                  <Label htmlFor="title">Title</Label>
                  <Input
                    type="text"
                    id="title"
                    value={isTitle}
                    onChange={(e) => setTitle(e.target.value)}
                    required
                  />
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="message">Message</Label>
                  <TextArea
                    id="message"
                    value={isContent}
                    onChange={(e) => setContent(e.target.value)}
                    required
                  ></TextArea>
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="data">Data</Label>
                  <Input
                    type="text"
                    id="data"
                    value={isData}
                    onChange={(e) => setData(e.target.value)}
                  />
                </FormGroup>
                <SubmitButton type="button" onClick={() => postSendPush()}>전송</SubmitButton>
              </PushForm>
            </DeviceBody>
          </DeviceFrame>

          <SelectContainer>
            <SelectGroup>
              <Label>옵션</Label>
              <CheckboxGroup>
                <CheckboxLabel>
                  <Checkbox
                    type="checkbox"
                    id="petstival"
                    checked={isCategory === 'petstival'}
                    onChange={() => handlerChangeCategory('petstival')}
                  />
                  펫스티벌
                </CheckboxLabel>
                <CheckboxLabel>
                  <Checkbox
                    type="checkbox"
                    id="community"
                    checked={isCategory === 'community'}
                    onChange={() => handlerChangeCategory('community')}
                  />
                  커뮤니티
                </CheckboxLabel>
                <CheckboxLabel>
                  <Checkbox
                    type="checkbox"
                    id="store"
                    checked={isCategory === 'store'}
                    onChange={() => handlerChangeCategory('store')}
                  />
                  스토어
                </CheckboxLabel>
              </CheckboxGroup>
            </SelectGroup>
          </SelectContainer>

          <SelectContainer>
            <SelectGroup>
              <Label>전달대상</Label>
              <CheckboxGroup>
                <CheckboxLabel>
                  <Checkbox
                    type="checkbox"
                    id="all"
                    checked={isTarget === 'all'}
                    onChange={() => handlerChangeTarget('all')}
                  />
                  전체유저
                </CheckboxLabel>
              </CheckboxGroup>
            </SelectGroup>
          </SelectContainer>

          <SelectContainer>
            <SelectGroup>
              <Label>메세지 리스트</Label>
              <CheckboxGroup>
                {isFcmMessageList.map((message) => (
                  <CheckboxLabel key={message.id}>
                    <Checkbox
                      type="checkbox"
                      id={`message-${message.id}`}
                      value={message.id}
                      checked={isMessageid === message.id}
                      onChange={() => handlerChangeMessage(message)}
                    />
                    {message.title}
                  </CheckboxLabel>
                ))}
              </CheckboxGroup>
            </SelectGroup>
          </SelectContainer>
        </FormContainer>
      </S_Section>
    </MainLayout>
  );
}

export default FcmSend;

const FormContainer = styled.div`
  display: flex;
  gap: 20px;
`;

const DeviceFrame = styled.div`
  width: 360px;
  height: 640px;
  border: 16px black solid;
  border-top-width: 32px;
  border-bottom-width: 32px;
  border-radius: 36px;
  background: #f9f9f9;
  margin: 0;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const DeviceHeader = styled.div`
  background-color: #222;
  color: white;
  padding: 10px;
  text-align: center;
`;

const DeviceBody = styled.div`
  padding: 20px;
  flex-grow: 1;
  overflow-y: auto;
`;

const PushForm = styled.form`
  display: flex;
  flex-direction: column;
`;

const FormGroup = styled.div`
  margin-bottom: 15px;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
`;

const Input = styled.input`
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
`;

const TextArea = styled.textarea`
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
`;

const SubmitButton = styled.button`
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 5px;

  &:hover {
    background-color: #0056b3;
  }
`;

const SelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const SelectGroup = styled.div`
  display: flex;
  flex-direction: column;
`;

const CheckboxGroup = styled.div`
  display: flex;
  flex-direction: column;
  background: #f1f1f1;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
`;

const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
`;

const Checkbox = styled.input`
  margin-right: 5px;
`;

const S_Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 17px;
  padding: 30px 20px;
  border-bottom: 1px solid ${(props) => props.theme.colors.hd4};

  &:last-child {
    border-bottom: 0;
  }

  td > div {
    min-width: 380px;
  }
`;