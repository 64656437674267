import { useNavigate } from 'react-router-dom';

import styled from 'styled-components';

import MainLayout from 'components/Layout/MainLayout';

import firebaseImg from 'assets/image/firebase.png';
import LongButton from 'components/Button/LongButton';

function Firebase() {
  const navigate = useNavigate();

  return (
    <MainLayout>
      <S_Container>
        <S_Img src={firebaseImg} />
        <LongButton
          onClick={() => navigate('/')}
          style={{ width: 360, height: 87, borderRadius: 16 }}
        >
          이동하기
        </LongButton>
      </S_Container>
    </MainLayout>
  );
}

export default Firebase;

const S_Img = styled.img`
  width: 379px;
  height: 423px;
`;

const S_Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 70px;
  padding-top: 50px;
`;
