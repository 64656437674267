import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import MainLayout from 'components/Layout/MainLayout';
import LongButton from 'components/Button/LongButton';
import DisabledInput from 'components/Input/DisabledInput';
import Textarea from 'components/Input/Textarea';
import { getBreedDetail, postBreedHealthInfo } from 'api/breed';
import useLogout from 'hooks/useLogout';
import { I_Breed } from 'etc/types';

function KindInfoDetail() {
  const Logout = useLogout();
  const navigate = useNavigate();
  const { id } = useParams();

  const [content, setContent] = useState<string>('');
  const [kindInfo, setKindInfo] = useState<I_Breed>({
    animalType: { id: 0, animal: '' },
    id: 0,
    breed: '',
    code: '',
    directInput: false,
    healthInfo: [{ id: 0, healthInfo: '' }],
  });

  const onClickSave = async () => {
    const data = await postBreedHealthInfo(Number(id), content);

    if (data.ok) {
      alert('상세 내용이 저장되었습니다.');
      navigate('/service/kind');
    } else {
      if (data.res.status === 315) Logout();
      else {
        alert('잠시 후 다시 시도해주세요.');
        console.error(data.res.error.message);
      }
    }
  };

  const getAsyncBreedDetail = async () => {
    const data = await getBreedDetail(Number(id));
    if (data.ok) {
      // console.log(data.res);
      setContent(data.res.healthInfo[0]?.healthInfo);
      setKindInfo(data.res);
    } else {
      if (data.res.status === 315) Logout();
      else {
        alert('잠시 후 다시 시도해주세요.');
        console.error(data.res.error.message);
      }
    }
  };

  useEffect(() => {
    getAsyncBreedDetail();
  }, []);

  return (
    <MainLayout>
      <S_Section>
        <S_Title>상세 정보</S_Title>
        <S_Table>
          <tbody>
            <tr>
              <th>동물 종</th>
              <td>
                <DisabledInput text={kindInfo?.animalType.animal} />
              </td>
            </tr>
            <tr>
              <th>품종 명</th>
              <td>
                <DisabledInput text={kindInfo.breed} />
              </td>
            </tr>
            <tr>
              <th>품종 코드</th>
              <td>
                <DisabledInput text={kindInfo.code} />
              </td>
            </tr>
            <tr>
              <th className='alignTop'>상세 내용</th>
              <td colSpan={3}>
                <Textarea
                  state={content}
                  setState={setContent}
                  style={{ height: 127 }}
                />
              </td>
            </tr>
          </tbody>
        </S_Table>
        <S_ButtonBox>
          <LongButton onClick={() => onClickSave()}>저장하기</LongButton>
        </S_ButtonBox>
      </S_Section>
    </MainLayout>
  );
}

export default KindInfoDetail;

const S_ButtonBox = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 50px;
  padding-right: 20px;
`;

const S_Table = styled.table`
  border-collapse: separate;
  border-spacing: 20px;

  th {
    width: 120px;
    text-align: left;
    font-size: 14px;
    font-weight: 500;
    &:nth-child(1) {
      width: 85px;
    }
    &:nth-child(3) {
      width: 120px;
    }
    &.alignTop {
      vertical-align: top;
      padding-top: 14px;
    }
  }
`;

const S_Title = styled.p`
  font-size: 16px;
  color: ${(props) => props.theme.colors.hd1};
  font-weight: 700;
`;

const S_Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 17px;
  padding: 30px 20px;
  border-bottom: 1px solid ${(props) => props.theme.colors.hd4};

  &:last-child {
    border-bottom: 0;
  }
`;
