import styled from 'styled-components';

interface I_Input {
  isDisabled?: boolean;
  state: string;
  setState: React.Dispatch<React.SetStateAction<string>>;
  placeholder?: string;
  style?: React.CSSProperties;
  setWatchState?: React.Dispatch<React.SetStateAction<string>>;
  [key: string]: any;
}

function TableInput({
  isDisabled,
  state,
  setState,
  style,
  setWatchState,
  ...rest
}: I_Input) {
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState(event.target.value);
    if (setWatchState) setWatchState(event.target.value);
  };

  return (
    <S_Container isDisabled={isDisabled} style={style}>
      <S_Input
        value={state}
        onChange={onChange}
        isDisabled={isDisabled}
        disabled={isDisabled}
        {...rest}
      />
    </S_Container>
  );
}

export default TableInput;

const S_Input = styled.input<{ isDisabled?: boolean }>`
  width: 100%;
  font-weight: 500;
  font-size: inherit;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: ${(props) => props.theme.colors.hd1};
  &::placeholder {
    color: ${(props) => props.theme.colors.hd2};
  }
  background: ${(props) =>
    props.isDisabled ? props.theme.colors.hd4 : props.theme.colors.hd5};
`;

const S_Container = styled.div<{ isDisabled?: boolean }>`
  width: 100%;
  padding: 4px 19px;
  background: ${(props) =>
    props.isDisabled ? props.theme.colors.hd4 : props.theme.colors.hd5};
  border: 0.5px solid ${(props) => props.theme.colors.hd3};
  border-radius: 8px;
  font-size: 11px;
`;
