import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useFieldArray, useForm } from 'react-hook-form';

import styled from 'styled-components';
import { S_FlexColumn } from 'etc/GlobalCSS';

import { ReactComponent as Delete } from 'assets/image/minus-circle.svg';

import MainLayout from 'components/Layout/MainLayout';
import MiniButton from 'components/Button/MiniButton';
import LongButton from 'components/Button/LongButton';
import RegisterInput from 'components/Input/RegisterInput';
import {
  I_Brand,
  I_Category,
  I_CategoryHash,
  I_Image,
  I_ProductUpdateForm,
} from 'etc/types';
import BrandDropdown from 'components/Dropdown/BrandDropdown';
import CategoryDropdown from 'components/Dropdown/CategoryDropdown';
import useLogout from 'hooks/useLogout';

import {
  getBrandList,
  getCategoryList,
  getProduct,
  patchProduct,
  patchProductUpdate,
} from 'api/store';
import { postImagesUpload } from 'api/images';

function ProductDetail() {
  const { id } = useParams();
  const Logout = useLogout();
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [isHidden, setIsHidden] = useState<boolean>(false);
  const [categoryHash, setCategoryHash] = useState<I_CategoryHash>();
  const [categoryList, setCategoryList] = useState<I_Category[]>([]);
  const [BrandList, setBrandList] = useState<I_Brand[]>([]); // 브랜드
  
  const [isMainImages, setMainImages] = useState<string[]>([]);
  const [isDetailImages, setDetailImages] = useState<string[]>([]);

  // 카테고리 대분류 드롭다운
  const [isCategoryMainDropdownOpen, setIsCategoryMainDropdownOpen] =
    useState<boolean>(false);
  const [selectedCategoryMainItem, setSelectedCategoryMainItem] =
    useState<string>('대분류');
  const onCategoryMainItemClick = (item: I_Category) => {
    setValue('mainCategory', item.id);
    setSelectedCategoryMainItem(item.name);
    setValue('middleCategory', 0);
    setSelectedCategoryMidItem('중분류');
    setValue('subCategory', 0);
    setSelectedCategorySubItem('소분류');
    setIsCategoryMainDropdownOpen(false);
  };
  // 카테고리 중분류 드롭다운
  const [isCategoryMidDropdownOpen, setIsCategoryMidDropdownOpen] =
    useState<boolean>(false);
  const [selectedCategoryMidItem, setSelectedCategoryMidItem] =
    useState<string>('중분류');
  const onCategoryMidItemClick = (item: I_Category) => {
    setValue('middleCategory', item.id);
    setSelectedCategoryMidItem(item.name);
    setValue('subCategory', 0);
    setSelectedCategorySubItem('소분류');
    setIsCategoryMidDropdownOpen(false);
  };
  // 카테고리 소분류 드롭다운
  const [isCategorySubDropdownOpen, setIsCategorySubDropdownOpen] =
    useState<boolean>(false);
  const [selectedCategorySubItem, setSelectedCategorySubItem] =
    useState<string>('소분류');
  const onCategorySubItemClick = (item: I_Category) => {
    setValue('subCategory', item.id);
    setSelectedCategorySubItem(item.name);
    setIsCategorySubDropdownOpen(false);
  };

  const {
    register,
    watch,
    getValues,
    setValue,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<I_ProductUpdateForm>({
    mode: 'onChange',
    shouldFocusError: true,
    defaultValues: {
      mainCategory: 0,
      middleCategory: 0,
      subCategory: 0,
      brand: 0,
      thumbnail: 0,
      name: '',
      price: 0,
      options: [],
      deleteOptions: [],
      titleImages: [],
      mainImages: [],
      tFiles: [],
      mFiles: [],
    },
  });
  const {
    fields: optionFields,
    append: optionAppend,
    remove: optionRemove,
  } = useFieldArray({
    control,
    keyName: 'customId',
    name: 'options',
  });
  watch();

  // input 추가
  const onAddClick = () => {
    if (optionFields.length < 16)
      optionAppend({
        option: '',
        price: 0,
        limitPointPercent: 0,
        discountPercent: 0,
        discountAppliedProductPrice: 0,
        defaultOption: false,
      });
  };
  // input 삭제
  const onRemoveClick = (idx: number, optionId?: number) => {
    if (optionId) {
      setValue('deleteOptions', [...getValues('deleteOptions'), optionId]);
    }
    optionRemove(idx);
  };

  const patchAsyncProduct = async () => {
    if (
      isHidden
        ? confirm('해당 상품을 일시 품절 해제로 변경하시겠습니까?')
        : confirm('해당 상품을 일시 품절로 변경하시겠습니까?')
    ) {
      const data = await patchProduct([Number(id)], !isHidden);
      if (data.ok) {
        alert('수정 되었습니다.');
        getAsyncProduct(Number(id));
      } else {
        if (data.res.status === 315) Logout();
        else {
          alert('잠시 후 다시 시도해주세요.');
          console.error(data.res.error.message);
        }
      }
    }
  };

  // 제출
  const onSubmit = async (data: I_ProductUpdateForm) => {
    const resData = await patchProductUpdate(data, Number(id));
    if (resData.ok) {
      alert('수정 되었습니다.');
      setIsDisabled(true);
    } else {
      if (resData.res.status === 315) Logout();
      else {
        alert('잠시 후 다시 시도해주세요.');
        console.error(resData.res.error.message);
      }
    }
  };

  const getAsyncProduct = async (id: number) => {
    const categoryData = await getCategoryList();
    if (categoryData.ok) {
      setCategoryList(categoryData.res.item);
      setCategoryHash(categoryData.res.categoryObject);
      const categoryHashTemp = categoryData.res.categoryObject;

      const data = await getProduct(id);
      if (data.ok) {
        const product = data.res.item;

        if(product.mainImages.length>0){
          const imageURLs = product.mainImages.map((image: any) => image.url);
          setMainImages(imageURLs)
        }
        
        if(product.titleImages.length>0){
          const imageURLs = product.titleImages.map((image: any) => image.url);
          setDetailImages(imageURLs)
        }

        setIsHidden(product.hidden);
        setValue('mainCategory', product.mainCategory);
        setValue('middleCategory', product.middleCategory);
        setValue('subCategory', product.subCategory);
        setSelectedCategoryMainItem(categoryHashTemp[product.mainCategory]);
        setSelectedCategoryMidItem(
          categoryHashTemp[product.middleCategory]
            ? categoryHashTemp[product.middleCategory]
            : '중분류'
        );
        setSelectedCategorySubItem(
          categoryHashTemp[product.subCategory]
            ? categoryHashTemp[product.subCategory]
            : '소분류'
        );
        setValue('brand', product.brand.id);
        setValue('thumbnail', product.thumbnail.id);
        setValue('name', product.name);
        setValue('price', product.price);
        setValue('options', product.options);
        setValue(
          'titleImages',
          product.titleImages.map((image: I_Image) => image.id)
        );
        setValue(
          'mainImages',
          product.mainImages.map((image: I_Image) => image.id)
        );
        // setValue('tFiles', product.titleImages);
        // setValue('mFiles', product.mainImages);
      } else {
        if (data.res.status === 315) Logout();
        else {
          alert('잠시 후 다시 시도해주세요.');
          console.error(data.res.error.message);
        }
      }
    } else {
      if (categoryData.res.status === 315) Logout();
      else {
        console.error(categoryData.res.error.message);
      }
    }
  };

  // 브랜드 리스트
  const getAsyncBrandList = async () => {
    const data = await getBrandList(1, 10000);
    if (data.ok) {
      setBrandList(data.res.item);
    } else {
      if (data.res.status === 315) Logout();
      else {
        console.error(data.res.error.message);
      }
    }
  };

  // 파일 업로드
  const fileUpload = async (files: FileList | [], isTitle: boolean) => {
    const formData = new FormData();
    const changeArray = Array.from(files);
    changeArray.forEach((v: any) => {
      formData.append('files', v);
    });
    formData.append('path', 'product');
    const ImagesData = await postImagesUpload(formData);
    if (ImagesData.ok) {
      if (isTitle) {
        setValue('titleImages', ImagesData.res.ids);
        setValue('thumbnail', ImagesData.res.ids[0]);
      } else setValue('mainImages', ImagesData.res.ids);
    } else {
      if (ImagesData.res.status === 315) Logout();
      else {
        console.error(ImagesData.res.error.message);
        alert('잠시 후 다시 시도해주세요.');
      }
    }
  };

  const title = watch('tFiles');
  useEffect(() => {
    if (title.length)
      if (title.length <= 10) fileUpload(title, true);
      else {
        setValue('tFiles', []);
        alert('최대 10개 이미지까지 업로드 가능합니다.');
      }
  }, [title]);
  const main = watch('mFiles');
  useEffect(() => {
    if (main.length)
      if (main.length <= 10) fileUpload(main, false);
      else {
        setValue('mFiles', []);
        alert('최대 10개 이미지까지 업로드 가능합니다.');
      }
  }, [main]);

  useEffect(() => {
    getAsyncProduct(Number(id));
  }, [id]);

  useEffect(() => {
    getAsyncBrandList();
  }, []);

  const renderFiles = (isTitle: boolean) => {
    const images = getValues(isTitle ? 'titleImages' : 'mainImages');
    const files = getValues(isTitle ? 'tFiles' : 'mFiles');
    if (images.length) {
      if (Array.isArray(images)) {
        return (
          <>
            {images.map((i: number) => (
              <span key={i}>{i}</span>
            ))}
          </>
        );
      } else {
        return (
          <>
            {Array.from(files).map((f) => (
              <span key={f.name}>{f.name}</span>
            ))}
          </>
        );
      }
    } else {
      return <></>;
    }
  };

  return (
    <MainLayout>
      <form onSubmit={handleSubmit(onSubmit)}>
        <S_TopContainer>
          <S_BasicInfoTitle>기본 정보</S_BasicInfoTitle>
          <S_BasicInfoContainer>
            <S_BasicInfoRowWrapper>
              <S_BasicInfoWrapper>
                <S_Label>상품명</S_Label>
                <RegisterInput
                  isDisabled={isDisabled}
                  isError={errors?.name ? true : false}
                  register={register('name', {
                    required: 'Required field.',
                  })}
                  style={{ width: '380px' }}
                />
              </S_BasicInfoWrapper>
              <S_BasicInfoWrapper>
                <S_Label>브랜드</S_Label>
                <BrandDropdown
                  isDisabled={isDisabled}
                  isError={errors?.brand ? true : false}
                  list={BrandList}
                  field='brand'
                  register={register('brand', {
                    validate: {
                      checkCategory: (id) => {
                        return id === 0 ? 'Required field.' : true;
                      },
                    },
                  })}
                  getValues={getValues}
                  setValue={setValue}
                  style={{ minWidth: '380px' }}
                />
              </S_BasicInfoWrapper>
            </S_BasicInfoRowWrapper>
            {optionFields.slice(0, 1).map((_, idx) => (
              <S_FlexColumn key={idx} style={{ gap: '8px', marginTop: '12px' }}>
                <S_BasicInfoRowWrapper>
                  <S_BasicInfoWrapper>
                    <S_Label style={{ color: '#13A872' }}>기본 옵션</S_Label>
                    <RegisterInput
                      isDisabled={isDisabled}
                      isError={errors?.options?.[idx]?.option ? true : false}
                      register={register(`options.${idx}.option`, {
                        required: 'Required field.',
                      })}
                      style={{ width: '380px' }}
                    />
                  </S_BasicInfoWrapper>
                  <S_BasicInfoWrapper>
                    <S_Label>포인트 적용률</S_Label>
                    <RegisterInput
                      isDisabled={isDisabled}
                      isError={
                        errors?.options?.[idx]?.limitPointPercent ? true : false
                      }
                      register={register(`options.${idx}.limitPointPercent`, {
                        required: 'Required field.',
                        onChange: (e) => {
                          e.target.value = e.target.value.replace(
                            /[^0-9]/gm,
                            ''
                          );
                        },
                        pattern: {
                          value: /^(100|[0-9]{1,2})$/,
                          message: '0~100',
                        },
                      })}
                      placeholder='0~99'
                      style={{ width: '380px' }}
                      rightText='%'
                    />
                  </S_BasicInfoWrapper>
                </S_BasicInfoRowWrapper>
                <S_BasicInfoRowWrapper>
                  <S_BasicInfoWrapper>
                    <S_Label>상품 가격</S_Label>
                    <RegisterInput
                      isDisabled={isDisabled}
                      isError={errors?.options?.[idx]?.price ? true : false}
                      register={register(`options.${idx}.price`, {
                        required: 'Required field.',
                        onChange: (e) => {
                          const temp = e.target.value.replace(/[^0-9]/gm, '');
                          e.target.value = temp;
                          setValue(
                            `options.${idx}.discountAppliedProductPrice`,
                            Math.floor(
                              (temp *
                                (100 -
                                  getValues(
                                    `options.${idx}.discountPercent`
                                  ))) /
                                100 /
                                10
                            ) * 10
                          );
                        },
                      })}
                      style={{ width: '380px' }}
                      rightText='원'
                    />
                  </S_BasicInfoWrapper>
                  <S_BasicInfoWrapper>
                    <S_Label>상품 할인율</S_Label>
                    <RegisterInput
                      isDisabled={isDisabled}
                      isError={errors?.options?.[idx]?.discountPercent ? true : false}
                      register={register(`options.${idx}.discountPercent`, {
                        required: 'Required field.',
                        onChange: (e) => {
                          // 소수점 허용, 숫자와 소수점 이외의 문자는 필터링
                          const temp = e.target.value.replace(/[^0-9.]/g, '');

                          // 두 개 이상의 소수점 방지
                          if ((temp.match(/\./g) || []).length > 1) {
                            e.target.value = temp.slice(0, temp.lastIndexOf('.'));
                          } else {
                            e.target.value = temp;
                          }

                          // discountAppliedProductPrice 값 설정
                          setValue(
                            `options.${idx}.discountAppliedProductPrice`,
                            Math.floor(
                              (getValues(`options.${idx}.price`) *
                                (100 - parseFloat(temp || '0'))) /
                                100 /
                                10
                            ) * 10
                          );
                        },
                        pattern: {
                          value: /^\d{0,2}(\.\d{0,2})?$/, // 소수점 두 자리까지 허용
                          message: '0~99.99 사이 값만 허용',
                        },
                      })}
                      style={{ width: '380px' }}
                      placeholder="0~99.99"
                      rightText="%"
                    />
                  </S_BasicInfoWrapper>
                </S_BasicInfoRowWrapper>
                <S_BasicInfoRowWrapper>
                  <S_BasicInfoWrapper>
                    <S_Label style={{ color: '#888888' }}>할인 판매가</S_Label>
                    <RegisterInput
                      isDisabledColor={isDisabled}
                      isDisabled={true}
                      register={register(
                        `options.${0}.discountAppliedProductPrice`
                      )}
                      style={{ width: '380px' }}
                      rightText='원'
                    />
                  </S_BasicInfoWrapper>
                </S_BasicInfoRowWrapper>
              </S_FlexColumn>
            ))}

            <S_BasicInfoRowWrapper style={{ marginTop: '12px' }}>
              <S_BasicInfoWrapper>
                <S_Label>카테고리</S_Label>
                <S_CategoryWrapper>
                  <CategoryDropdown
                    isDisabled={isDisabled}
                    list={categoryList}
                    selectedItem={selectedCategoryMainItem}
                    isOpen={isCategoryMainDropdownOpen}
                    setIsOpen={setIsCategoryMainDropdownOpen}
                    onClick={(item: I_Category) =>
                      onCategoryMainItemClick(item)
                    }
                    register={register('mainCategory', {
                      validate: {
                        checkCategory: (id) => {
                          return id === 0 ? 'Required field.' : true;
                        },
                      },
                    })}
                    isError={errors?.mainCategory ? true : false}
                    style={{ width: '200px' }}
                  />
                  <CategoryDropdown
                    isDisabled={isDisabled}
                    list={
                      categoryList?.find(
                        (item) => item.id === getValues('mainCategory')
                      )?.childs
                    }
                    selectedItem={selectedCategoryMidItem}
                    isOpen={isCategoryMidDropdownOpen}
                    setIsOpen={setIsCategoryMidDropdownOpen}
                    onClick={(item: I_Category) => onCategoryMidItemClick(item)}
                    register={register('middleCategory', {
                      validate: {
                        checkCategory: (id) => {
                          return id === 0 ? 'Required field.' : true;
                        },
                      },
                    })}
                    isError={errors?.middleCategory ? true : false}
                    style={{ width: '200px' }}
                  />
                  <CategoryDropdown
                    isDisabled={isDisabled}
                    list={
                      categoryList
                        ?.find((item) => item.id === getValues('mainCategory'))
                        ?.childs?.find(
                          (child) => child.id === getValues('middleCategory')
                        )?.childs
                    }
                    selectedItem={selectedCategorySubItem}
                    isOpen={isCategorySubDropdownOpen}
                    setIsOpen={setIsCategorySubDropdownOpen}
                    onClick={(item: I_Category) => onCategorySubItemClick(item)}
                    style={{ width: '200px' }}
                  />
                </S_CategoryWrapper>
              </S_BasicInfoWrapper>
            </S_BasicInfoRowWrapper>
          </S_BasicInfoContainer>
        </S_TopContainer>
        <S_MidContainer>
          <S_BasicInfoTitle>추가 정보</S_BasicInfoTitle>
          <S_AddInfoContainer>
            {optionFields
              .slice(1, optionFields.length)
              .map((v, optionFieldIdx) => (
                <S_FieldContainer key={optionFieldIdx}>
                  <S_BasicInfoRowWrapper style={{ position: 'relative' }}>
                    <S_BasicInfoWrapper>
                      <S_Label style={{ color: '#13A872' }}>
                        추가 옵션{optionFieldIdx + 1}
                      </S_Label>
                      <RegisterInput
                        isDisabled={isDisabled}
                        isError={
                          errors?.options?.[optionFieldIdx + 1]?.option
                            ? true
                            : false
                        }
                        register={register(
                          `options.${optionFieldIdx + 1}.option`,
                          {
                            required: 'Required field.',
                          }
                        )}
                        style={{ width: '380px' }}
                      />
                    </S_BasicInfoWrapper>
                    <S_BasicInfoWrapper>
                      <S_Label>포인트 적용률</S_Label>
                      <RegisterInput
                        isDisabled={isDisabled}
                        isError={
                          errors?.options?.[optionFieldIdx + 1]
                            ?.limitPointPercent
                            ? true
                            : false
                        }
                        register={register(
                          `options.${optionFieldIdx + 1}.limitPointPercent`,
                          {
                            required: 'Required field.',
                            onChange: (e) => {
                              e.target.value = e.target.value.replace(
                                /[^0-9]/gm,
                                ''
                              );
                            },
                            // pattern: {
                            //   value: /^[0-9]{0,2}$/,
                            //   message: '0~99',
                            // },
                            pattern: {
                              value: /^(100|[0-9]{1,2})$/,
                              message: '0~100',
                            },
                          }
                        )}
                        placeholder='0~100'
                        style={{ width: '380px' }}
                        rightText='%'
                      />
                    </S_BasicInfoWrapper>
                    {!isDisabled && (
                      <S_RemoveButton
                        onClick={() => onRemoveClick(optionFieldIdx + 1, v.id)}
                      >
                        <Delete />
                      </S_RemoveButton>
                    )}
                  </S_BasicInfoRowWrapper>
                  <S_BasicInfoRowWrapper>
                    <S_BasicInfoWrapper>
                      <S_Label>상품 가격</S_Label>
                      <RegisterInput
                        isDisabled={isDisabled}
                        isError={
                          errors?.options?.[optionFieldIdx + 1]?.price
                            ? true
                            : false
                        }
                        register={register(
                          `options.${optionFieldIdx + 1}.price`,
                          {
                            required: 'Required field.',
                            onChange: (e) => {
                              const temp = e.target.value.replace(
                                /[^0-9]/gm,
                                ''
                              );
                              e.target.value = temp;
                              setValue(
                                `options.${
                                  optionFieldIdx + 1
                                }.discountAppliedProductPrice`,
                                Math.floor(
                                  (temp *
                                    (100 -
                                      getValues(
                                        `options.${
                                          optionFieldIdx + 1
                                        }.discountPercent`
                                      ))) /
                                    100 /
                                    10
                                ) * 10
                              );
                            },
                          }
                        )}
                        style={{ width: '380px' }}
                        rightText='원'
                      />
                    </S_BasicInfoWrapper>
                    <S_BasicInfoWrapper>
                      <S_Label>상품 할인율</S_Label>
                      <RegisterInput
                        isDisabled={isDisabled}
                        isError={
                          errors?.options?.[optionFieldIdx + 1]?.discountPercent ? true : false
                        }
                        register={register(`options.${optionFieldIdx + 1}.discountPercent`, {
                          required: 'Required field.',
                          onChange: (e) => {
                            // 숫자와 소수점만 허용하고, 두 개 이상의 소수점은 방지
                            const temp = e.target.value.replace(/[^0-9.]/g, '');

                            // 두 개 이상의 소수점 입력을 방지
                            if ((temp.match(/\./g) || []).length > 1) {
                              e.target.value = temp.slice(0, temp.lastIndexOf('.'));
                            } else {
                              e.target.value = temp;
                            }

                            // 할인율에 따른 상품 가격 계산
                            setValue(
                              `options.${optionFieldIdx + 1}.discountAppliedProductPrice`,
                              Math.floor(
                                (getValues(`options.${optionFieldIdx + 1}.price`) *
                                  (100 - parseFloat(temp || '0'))) /
                                  100 /
                                  10
                              ) * 10
                            );
                          },
                          pattern: {
                            value: /^\d{0,2}(\.\d{0,2})?$/, // 소수점 두 자리까지 허용
                            message: '0~99.99 사이 값만 허용',
                          },
                        })}
                        style={{ width: '380px' }}
                        placeholder="0~99.99"
                        rightText="%"
                      />
                    </S_BasicInfoWrapper>
                  </S_BasicInfoRowWrapper>
                  <S_BasicInfoRowWrapper>
                    <S_BasicInfoWrapper>
                      <S_Label style={{ color: '#888888' }}>
                        할인 판매가
                      </S_Label>
                      <RegisterInput
                        isDisabledColor={isDisabled}
                        isDisabled={true}
                        register={register(
                          `options.${
                            optionFieldIdx + 1
                          }.discountAppliedProductPrice`
                        )}
                        style={{ width: '380px' }}
                        rightText='원'
                      />
                    </S_BasicInfoWrapper>
                  </S_BasicInfoRowWrapper>
                </S_FieldContainer>
              ))}
          </S_AddInfoContainer>
          {!isDisabled && (
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <MiniButton onClick={onAddClick} type='button'>
                옵션 추가
              </MiniButton>
            </div>
          )}
        </S_MidContainer>
        <S_BottomContainer>
          <S_ImageTitle>상품 사진 첨부</S_ImageTitle>
          <S_ImageContainer>
            <div>
              <S_BasicInfoRowWrapper>
                <S_BasicInfoWrapper>
                  <S_Label>대표 이미지</S_Label>
                  <S_InputContainer
                    isDisabled={true}
                    isError={errors?.tFiles ? true : false}
                  >
                    <input
                      type='file'
                      id='tFiles'
                      accept='.jpeg,.jpg,.png,.bmp'
                      multiple
                      style={{ display: 'none' }}
                      {...register('tFiles', {
                        validate: {
                          checkLength: () => {
                            const { titleImages } = getValues();
                            return titleImages.length > 0
                              ? true
                              : 'Required field.';
                          },
                        },
                      })}
                    />
                    <S_ImageList>{renderFiles(true)}</S_ImageList>
                  </S_InputContainer>
                  {!isDisabled && (
                    <S_AddImage htmlFor='tFiles'>사진 변경</S_AddImage>
                  )}
                </S_BasicInfoWrapper>
              </S_BasicInfoRowWrapper>
              <S_BasicInfoRowWrapper>
                <S_BasicInfoWrapper>
                  <S_Label />
                  <S_ImageDesc>
                    *최대 5개 이미지 첨부 가능
                    <br />
                    *가로 길이 x 세로 길이(px) : 860 x 5000(px)
                    <br />
                    *제한 용량 : 20MB
                    <br />
                    *지원 양식 : JPG/JPEG/PNG
                  </S_ImageDesc>
                </S_BasicInfoWrapper>
                
                <FilesContainer>
                  {isDetailImages && 
                    isDetailImages.map((imageUrl, index) => {
                      return (
                        <FileWrapper key={`imageUrl_`+index}>
                          {/* <p>{key} 이미지</p> */}
                          <img src={imageUrl} height="300" alt={`Uploaded file ${index}`} />
                        </FileWrapper>
                      );
                    })
                  }
                </FilesContainer>
              
              </S_BasicInfoRowWrapper>


            </div>
            <div>
              <S_BasicInfoRowWrapper>
                <S_BasicInfoWrapper>
                  <S_Label>상세 이미지</S_Label>
                  <S_InputContainer
                    isDisabled={true}
                    isError={errors?.mFiles ? true : false}
                  >
                    <input
                      type='file'
                      id='mFiles'
                      accept='.jpeg,.jpg,.png,.bmp'
                      multiple
                      style={{ display: 'none' }}
                      {...register('mFiles', {
                        validate: {
                          checkLength: () => {
                            const { mainImages } = getValues();
                            return mainImages.length > 0
                              ? true
                              : 'Required field.';
                          },
                        },
                      })}
                    />
                    <S_ImageList>{renderFiles(false)}</S_ImageList>
                  </S_InputContainer>
                  {!isDisabled && (
                    <S_AddImage htmlFor='mFiles'>사진 변경</S_AddImage>
                  )}
                </S_BasicInfoWrapper>

                <FilesContainer>
                  {isMainImages && 
                    isMainImages.map((imageUrl, index) => {
                      return (
                        <FileWrapper key={`imageUrl_`+index}>
                          {/* <p>{key} 이미지</p> */}
                          <img src={imageUrl} height="300" alt={`Uploaded file ${index}`} />
                        </FileWrapper>
                      );
                    })
                  }
                </FilesContainer>

              </S_BasicInfoRowWrapper>
              <S_BasicInfoRowWrapper>
                <S_BasicInfoWrapper>
                  <S_Label />
                  <S_ImageDesc>
                    *최대 3개 이미지 첨부 가능
                    <br />
                    *가로 길이 x 세로 길이(px) : 860 x 5000(px)
                    <br />
                    *제한 용량 : 20MB
                    <br />
                    *지원 양식 : JPG/JPEG/PNG
                  </S_ImageDesc>
                </S_BasicInfoWrapper>
              </S_BasicInfoRowWrapper>
            </div>
          </S_ImageContainer>
        </S_BottomContainer>
        <div
          style={{ display: 'flex', justifyContent: 'flex-end', gap: '15px' }}
        >
          {isHidden ? (
            <LongButton
              onClick={patchAsyncProduct}
              style={{ background: '#FC1D1D' }}
              type='button'
            >
              일시 품절 해제
            </LongButton>
          ) : (
            <LongButton
              onClick={patchAsyncProduct}
              style={{ background: '#FC1D1D' }}
              type='button'
            >
              일시 품절
            </LongButton>
          )}

          {isDisabled ? (
            <LongButton onClick={() => setIsDisabled(false)} type='button'>
              수정하기
            </LongButton>
          ) : (
            <></>
          )}
          {!isDisabled ? <LongButton>저장하기</LongButton> : <></>}
        </div>
      </form>
    </MainLayout>
  );
}

export default ProductDetail;

const S_Image = styled.img`
  width: 100%;
  max-height: 200px;
  object-fit: contain;
`;

const S_RemoveButton = styled.div`
  position: absolute;
  right: -40px;
  width: 24px;
  height: 24px;
  cursor: pointer;
`;

const S_ImageDesc = styled.span`
  margin-top: 8px;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  color: ${(props) => props.theme.colors.hd2};
`;

const S_ImageTitle = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.02em;
`;

const S_ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin-top: 16px;
`;

const S_BottomContainer = styled.div`
  padding: 24px 0 64px;
`;

const S_FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const S_AddInfoContainer = styled.div`
  margin: 16px 0 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const S_MidContainer = styled.div`
  padding: 24px 0 32px;
  border-bottom: 1px solid ${(props) => props.theme.colors.hd4};
`;

const S_CategoryWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

const S_AddImage = styled.label`
  width: 111px;
  height: 34px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.colors.ho1};
  border-radius: 8px;
  font-weight: 700;
  font-size: 13px;
  letter-spacing: -0.02em;
  color: ${(props) => props.theme.colors.hd5};
  margin-left: 23px;
  cursor: pointer;
  &:hover {
    opacity: 0.5;
  }
`;

const S_ImageList = styled.div`
  font-weight: 400;
  font-size: 13px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  span {
    width: 346px;
    line-height: 18px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const S_InputContainer = styled.div<{
  isDisabled?: boolean;
  isError?: boolean;
}>`
  width: 380px;
  min-height: 46px;
  padding: 14px 16px;
  background: ${(props) =>
    props.isDisabled ? props.theme.colors.hd4 : props.theme.colors.hd5};
  border: 1px solid
    ${(props) =>
      props.isError ? props.theme.colors.ho6 : props.theme.colors.hd3};
  border-radius: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
`;

const S_Label = styled.div`
  width: 100px;
  flex-shrink: 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.02em;
`;

const S_BasicInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const S_BasicInfoRowWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 45px;
  width: 100%;
`;

const S_BasicInfoTitle = styled.span`
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.02em;
`;

const S_BasicInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 16px;
`;

const S_TopContainer = styled.div`
  padding-bottom: 32px;
  border-bottom: 1px solid ${(props) => props.theme.colors.hd4};
`;


const FilesContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 200px;
`;

const FileWrapper = styled.div`
  margin-right: 10px;
`;
