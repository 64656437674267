import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import MainLayout from 'components/Layout/MainLayout';
import LongButton from 'components/Button/LongButton';
import RegisterInput from 'components/Input/RegisterInput';
import Spinner from 'components/Spinner/Spinner';
import { postMarketingUtm } from 'api/marketingUtm';
import useLogout from 'hooks/useLogout';
import { getMarketingUtmTotalLog, getMarketingUtmIdLog } from 'api/marketingUtm';


import UtmLineChart from 'components/Chart/UtmChart';
import UtmChartMonth from 'components/Chart/UtmChartMonth';
import Dropdown from '../../../components/Dropdown/Dropdown';


function UtmChart() {
  const navigate = useNavigate();
  const Logout = useLogout();
  

  const [selectedActivityTypeItem, setSelectedActivityTypeItem] = useState<string>('');
  const [isActivityTypeDropdownOpen, setIsActivityTypeDropdownOpen] = useState<boolean>(false);

  const onActivityTypeItemClick = (item: string) => {
    // console.log('onActivityTypeItemClick  item :' ,item);
    setSelectedActivityTypeItem(item);
    setIsActivityTypeDropdownOpen(false);

    if(item){
      console.log('셀렉트박스 item : ', item)
      getAsyncUtmNameList(item);
    }
  };

  const [isSelectList, setSelectList] = useState<string[]>([]);


  const [totalChartData, setTotalChartData] = useState({
    labels: [],
    datasets: [
      {
        label: '전체 방문자',
        data: [],
        backgroundColor: 'rgba(255, 99, 132, 0.2)',
        borderColor: 'rgba(255, 99, 132, 1)',
        borderWidth: 1,
      },
    ],
  });

  const [nameChartData, setNameChartData] = useState({
    labels: [],
    datasets: [
      {
        label: '일별 방문자',
        data: [],
        backgroundColor: 'rgba(255, 99, 132, 0.2)',
        borderColor: 'rgba(255, 99, 132, 1)',
        borderWidth: 1,
      },
    ],
  });

  useEffect(() => {
    getAsyncUtmList();
  }, []);

  useEffect(() => {
    console.log('UTM 등록 페이지 렌더링');
    if(isSelectList[0]){
      setSelectedActivityTypeItem(isSelectList[0]);
      getAsyncUtmNameList(isSelectList[0]);
    }
  }, [isSelectList]);


  const getAsyncUtmList = async () => {

    const data = await getMarketingUtmTotalLog();

    if (data.ok) {
      console.log('data : ', data);

      setSelectList(data.res.labels);
      
      setTotalChartData({
        labels: data.res.labels,
        datasets: [{
          label: '전체 방문자', 
          data: data.res.data,
          backgroundColor: 'rgba(255, 99, 132, 0.2)',
          borderColor: 'rgba(255, 99, 132, 1)',
          borderWidth: 1,
        }],
      });

      
      // setChartData(prevData => ({
      //   ...prevData,
      //   labels: data.res.labels,
      //   datasets: [{
      //     ...prevData.datasets[0],
      //     data: data.res.data,
      //   }]
      // }));



    } else {
      if (data.res.status === 315) Logout();
      else {
        alert('잠시 후 다시 시도해주세요.');
        console.error(data.res.error.message);
      }
    }
  };

  const getAsyncUtmNameList = async (utmName: string) => {

    const data = await getMarketingUtmIdLog(utmName);

    if (data.ok) {

      setNameChartData({
        labels: data.res.labels,
        datasets: [{
          label: '일별 방문자', // 필요에 따라 라벨을 변경
          data: data.res.data,
          backgroundColor: 'rgba(0, 72, 255, 0.441)',
          borderColor: '#224bed',
          borderWidth: 1,
        }],
      });

    } else {
      if (data.res.status === 315) Logout();
      else {
        alert('잠시 후 다시 시도해주세요.');
        console.error(data.res.error.message);
      }
    }
  };

  return (
    <MainLayout>
      <ChartContainer>
        <ChartWrapper>
          <UtmLineChart datas={totalChartData} />
        </ChartWrapper>
        
        <ChartWrapper>
          <DropdownWrapper>
            <Dropdown
              list={isSelectList}
              selectedItem={selectedActivityTypeItem}
              isOpen={isActivityTypeDropdownOpen}
              setIsOpen={setIsActivityTypeDropdownOpen}
              onClick={(item: string) => onActivityTypeItemClick(item)}
            />
          </DropdownWrapper>
          
          <UtmChartMonth datas={nameChartData} />


        </ChartWrapper>
      </ChartContainer>
    </MainLayout>
  );
}

export default UtmChart;

// 스타일 정의
const ChartContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  padding-top: 20px;
  
`;

const ChartWrapper = styled.div`
  flex: 1;
  position: relative; // relative 위치 설정
`;

const DropdownWrapper = styled.div`
  position: absolute;
  top: 10px; // 원하는 위치에 맞게 조정
  right: 10px; // 원하는 위치에 맞게 조정
  z-index: 10;
`;