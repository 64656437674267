import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import styled from 'styled-components';

import { ReactComponent as Checkbox } from 'assets/image/checkbox.svg';
import { ReactComponent as CheckboxChecked } from 'assets/image/checkbox-checked.svg';

import { I_Delivery } from 'etc/types';
import TableInput from 'components/Input/TableInput';
import Dropdown from 'components/Dropdown/Dropdown';
import { COURIER } from 'etc/variable';
import { dateTimeFormat } from 'utils/common';

interface I_PreparingForDelivery {
  item: I_Delivery;
  checkItem: number;
  setCheckItem: React.Dispatch<React.SetStateAction<number>>;
  setCheckBillLogId: React.Dispatch<React.SetStateAction<number>>;
  setCourier: React.Dispatch<React.SetStateAction<string>>;
  setTrackingInput: React.Dispatch<React.SetStateAction<string>>;
  changeToggle: boolean;
}

function PreparingForDelivery({
  item,
  checkItem,
  setCheckItem,
  setCheckBillLogId,
  setCourier,
  setTrackingInput,
  changeToggle,
}: I_PreparingForDelivery) {
  const navigate = useNavigate();
  const [input, setInput] = useState<string>(''); // 운송장 번호
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<string>('선택 필요');
  const handleItemClick = (el: string) => {
    setSelectedItem(el);
    setIsDropdownOpen(false);
    if (checkItem === item.id) setCourier(el);
  };

  const onCheckboxClick = (isCheck: boolean) => {
    if (isCheck) {
      setCheckItem(0);
      setCheckBillLogId(0);
      setCourier('선택 필요');
      setTrackingInput('');
    } else {
      setCheckItem(item.id);
      setCheckBillLogId(item.billLogId);
      setCourier(selectedItem);
      setTrackingInput(input);
    }
  };

  useEffect(() => {
    setInput('');
    setSelectedItem('선택 필요');
  }, [changeToggle]);

  return (
    <S_TableRow>
      <S_TableColData style={{ width: '38px' }}>
        {checkItem === item.id ? (
          <S_Checkbox onClick={() => onCheckboxClick(true)}>
            <CheckboxChecked />
          </S_Checkbox>
        ) : (
          <S_Checkbox onClick={() => onCheckboxClick(false)}>
            <Checkbox />
          </S_Checkbox>
        )}
      </S_TableColData>
      <S_TableColData>{item.id}</S_TableColData>
      <S_TableColData>
        <span
          onClick={() => navigate(`/store/order/${item.billLogId}`)}
          style={{ textDecoration: 'underLine', cursor: 'pointer' }}
        >
          {item.billLogId}
        </span>
      </S_TableColData>
      <S_TableColData>
        {dateTimeFormat(item.orderStatusProcessedDate)}
      </S_TableColData>
      <S_TableColData
        style={{
          padding: '4px 10px',
          width: '149px',
          minWidth: '149px',
        }}
      >
        <Dropdown
          list={COURIER}
          selectedItem={selectedItem}
          isOpen={isDropdownOpen}
          setIsOpen={setIsDropdownOpen}
          onClick={handleItemClick}
        />
      </S_TableColData>
      <S_TableColData
        style={{ padding: '4px 10px', width: '149px', minWidth: '149px' }}
      >
        <TableInput
          state={input}
          setState={setInput}
          setWatchState={setTrackingInput}
        />
      </S_TableColData>
      <S_TableColData style={{ minWidth: '260px' }}>
        {item.productName}
      </S_TableColData>
      <S_TableColData>{dateTimeFormat(item.createdAt)}</S_TableColData>
      <S_TableColData>{item.userNickname}</S_TableColData>
      <S_TableColData>{item.userPhone}</S_TableColData>
      <S_TableColData>
        <span
          onClick={() => navigate(`/user/${item.userId}`)}
          style={{ textDecoration: 'underLine', cursor: 'pointer' }}
        >
          {item.userCode}
        </span>
      </S_TableColData>
    </S_TableRow>
  );
}

export default PreparingForDelivery;

const S_Checkbox = styled.div`
  width: 16.67px;
  height: 16.67px;
  color: #aeaeae;
  margin: 0 auto;
  cursor: pointer;
`;

const S_TableColData = styled.td`
  text-align: center;
  vertical-align: middle;
  border: 1px solid ${(props) => props.theme.colors.hd3};
  padding: 10px;
`;

const S_TableRow = styled.tr`
  height: 36px;
  font-weight: 500;
  font-size: 13px;
`;
